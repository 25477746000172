import React, { FC } from 'react';
import {observer} from "mobx-react-lite";
import Dropdown from "react-bootstrap/Dropdown";
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { useRootStore } from '../../../loaders/useRootStore';
import { ClaimType } from '@yakoffice/publisher-types';
import {Button} from "react-bootstrap";
import {getGameVersionStatusClass} from "./GameVersionSelector";

interface PropsType{
  [key: string]: any,
  selectedGameVersions: IGameVersion[],
  handleGameVersionSelected? : (gameVersion : IGameVersion) => void,
  handleGameVersionUnselected? : (gameVersion : IGameVersion) => void,
}

export const GameVersionMultiSelector : FC<PropsType> = observer(({selectedGameVersions, handleGameVersionSelected, handleGameVersionUnselected, ...props}) => {
  const rootStore = useRootStore();

  const handleToggleGameVersion = (gameVersion: IGameVersion) => {
    selectedGameVersions.find(gv => gv === gameVersion)
      ? handleGameVersionUnselected && handleGameVersionUnselected(gameVersion)
      : handleGameVersionSelected && handleGameVersionSelected(gameVersion)
  }

  return rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.ViewAllGameVersions)
    ? <Dropdown align="end" autoClose={false} {...props} data-testid={'ddlGameVersionMultiSelector'}>
      <Button variant="info">Select Version(s): {selectedGameVersions.length}</Button>
      <Dropdown.Toggle split variant="info" data-testid={'ddlGameVersionMultiSelectorToggle'} />
      <Dropdown.Menu>
        {rootStore.gameVersionStore.gameVersions
          .filter(gv => !gv.isArchived())
          .map(gameVersion => {
            return <Dropdown.Item key={gameVersion.id}
                                  as="button"
                                  onClick={() => handleToggleGameVersion(gameVersion)}
                                  data-testid={`gameVersionMultiSelectorButton${gameVersion.name}`}>
              <i className={`fas ${selectedGameVersions.find(gv => gv === gameVersion) != null ? "fa-check-square": "fa-square"} text-${getGameVersionStatusClass(gameVersion)} me-1`}/> {gameVersion.name}
            </Dropdown.Item>
          })}
      </Dropdown.Menu>
      </Dropdown>
    : null
})

