import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import type {ICategory} from "../model/category/Category";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";

interface useCategoryFromStoreResult{
    isLoaded : boolean;
    category : ICategory | null;
}

export const useCategoryFromStore  = () => {

  const rootStore                     = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const { categoryId } = useParams() as {categoryId: string};
  const [result, setResult] = useState<useCategoryFromStoreResult>({ isLoaded: false, category: null})

  useEffect(() => {
    setResult({ isLoaded: false, category: null})

    // Note:  If you change gameVersion then, because useEffects run from bottom to top, this one will run
    // again before the useCategories one which loads the categories
    if(rootStore.categoryStore.categories.length === 0)
      return;

    const category = rootStore.categoryStore.categories.find(c => c.id === parseInt(categoryId));
    if(category){
      rootStore.categoryStore.setCurrentCategory(category);
      setResult({ isLoaded: true, category: category})
    }
    else{
      publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading Category",
        message: `Could not find category with id ${categoryId}`,
        footer: "Please try refreshing"

      });
    }

    return () => {
      rootStore.categoryStore.clearCurrentCategory();
    }

  }, [rootStore.categoryStore, rootStore.categoryStore.categories, publishNotification, categoryId]);

  return result;
};
