import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {IExperiment} from "../model/experiment/Experiment";
import type {IExperimentSearchParams} from "../api/requests/ExperimentApiGateway";

interface useDistributedExperimentsResult{
    isLoaded : boolean;
    experiments : IExperiment[]
}

export const useFindExperiments = (searchParams : IExperimentSearchParams) => {
  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useDistributedExperimentsResult>({ isLoaded: false, experiments: [] })

  useEffect(() => {
    setResult({ isLoaded: false, experiments: [] })

    rootStore.experimentStore.findExperiments(searchParams)
      .then((entities) => {
        setResult({ isLoaded: true, experiments: entities })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error finding experiments",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.experimentStore, publishNotification, searchParams]);

  return result;
};
