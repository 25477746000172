import {useContext, useEffect, useState} from "react";
import RootStoreContext from "../../../model/RootStoreContext";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import {IDistributionSearchParams} from "../../../api/requests/distribution/DistributionApiGateway";

interface useDistributionsCountResult{
    isLoaded : boolean;
    count    : number | null
}

export const useDistributionsCount = (searchParams: IDistributionSearchParams) => {
    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useDistributionsCountResult>({isLoaded: false, count: null});

    useEffect(() => {
        setResult({isLoaded: false, count: null})

        rootStore.distributionStore.countDistributions(searchParams)
            .then((count) => {
                setResult({isLoaded: true, count: count})
            })
            .catch(e => publishNotification({
                notificationType: NotificationType.Error,
                title: "Error counting distributions",
                message: `${e.message}`,
                footer: "Please try refreshing"
            }))
    }, [rootStore.distributionStore, publishNotification, searchParams, rootStore.gameVersionStore.currentGameVersion, rootStore.gameEnvironmentStore.currentGameEnvironment]);

    return result;
};
