import {IDistribution} from "../../../model/distributions/Distribution";
import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../../../model/RootStoreContext";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import {IDistributionSearchParams} from "../../../api/requests/distribution/DistributionApiGateway";

export interface useDistributionsResult{
    isLoaded : boolean;
    distributions : IDistribution[] | null
}

export const useDistributions = (searchParams: IDistributionSearchParams) => {
  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useDistributionsResult>({ isLoaded: false, distributions: [] })

  useEffect(() => {
    setResult({ isLoaded: false, distributions: [] })

    rootStore.distributionStore.loadDistributions(searchParams)
      .then(() => {
        setResult({ isLoaded: true, distributions: rootStore.distributionStore.distributions })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading distributions",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.distributionStore, publishNotification, searchParams, rootStore.gameVersionStore.currentGameVersion, rootStore.gameEnvironmentStore.currentGameEnvironment]);

  return result;
};
