import React, {FC} from "react";
import {IEntityVersion} from "../../../model/entity/EntityVersion";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { observer } from 'mobx-react-lite';

interface PropsType {
    entityVersion : IEntityVersion;
    handleTargetVersionChange: (targetVersion: number) => void;
}

export const EntityVersionSelect : FC<PropsType> = observer((props) => {

  const versionIds = props.entityVersion.isNewEntity() ? [] : [...Array(props.entityVersion.entity.versionMax).keys()].map(i => i + 1).reverse();

  const getButtonText = (forVersion: number) => {
    return forVersion === props.entityVersion.entity.versionCurrent
      ? `${forVersion}  (Current)`
      : `${forVersion}`;
  };

  return (
    <ButtonGroup className="ms-0 me-auto">
      <DropdownButton variant="light" as={ButtonGroup} title={getButtonText(props.entityVersion.version)}
                      id="bg-nested-dropdown">
        <React.Fragment>
          {versionIds.map(v => {
            return (
              <Dropdown.Item key={`v${v}`} active={props.entityVersion.version === v} onClick={() => props.handleTargetVersionChange(v)}>
                {getButtonText(v)}
              </Dropdown.Item>
            )
          })}
        </React.Fragment>
      </DropdownButton>
    </ButtonGroup>
  );
});
