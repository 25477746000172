import React, { FC } from 'react';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { useSpecificationsForKinds } from '../../../loaders/useSpecificationsForKinds';
import { KindGameEnvironmentIndex } from './KindGameEnvironmentIndex';
import { Route, Routes } from 'react-router';
import { useKindVersion } from '../../../loaders/useKindVersion';
import {PubRouteItems} from "../../routes";
import {matchDeeply} from "@yakoffice/shared-components";

export const KindIndex : FC = () => {

  const kindVersionResult = useKindVersion();
  const specificationsForKindsResult = useSpecificationsForKinds();

   return !kindVersionResult.isLoaded || kindVersionResult.kindVersion == null || !specificationsForKindsResult.isLoaded //|| !entitySelectionResult.isLoaded
    ?<CustomSpinner spinnerText="Loading kind and specifications..." />
    :<Routes>
      <Route path={matchDeeply(PubRouteItems.GameEnvironments,PubRouteItems.GameEnvironmentIdParam)} element={<KindGameEnvironmentIndex />}/>
    </Routes>
}
