import React, { FC } from 'react';
import {observer}       from 'mobx-react-lite';
import {ISelection}     from "../../../model/selections/Selection";
import {IKindProperty}  from "../../../model/kind/KindProperty";
import Form             from 'react-bootstrap/Form';

interface PropsType {
    selections     : ISelection[];
    property       : IKindProperty;
}

export const KindPropertySelectionInput : FC<PropsType> = observer((props) => {

  const handleSelectionChange = (e: any) => {

    const selectionId = parseFloat(e.target.value);
    props.property.setSelection(selectionId, null);
  }

  const handleDefaultSelectionOption = (e: any) => {
    if (props.property.selectionId)
      props.property.setSelection(props.property.selectionId, e.target.value)
  }

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>Selection</Form.Label>
        <Form.Select id="selections"
                      value={props.property.selectionId ? props.property.selectionId : "default"}
                      onChange={handleSelectionChange}
                      data-testid="ddlKindPropertySelection">
          <option value="default" disabled hidden>Select a selection...</option>
          {props.selections.map(s =>
            <option key={s.id} value={s.id}>{s.name}</option>
          )}
        </Form.Select>
      </Form.Group>
      {props.property.selectionId &&
      <Form.Group>
        <Form.Label>Default property value</Form.Label>
        <Form.Select id="default" value={props.property.default ? props.property.default : "default"}
                      onChange={handleDefaultSelectionOption} data-testid="inputDefaultValue">
          <option value="default" disabled hidden>Select...</option>
          <option value="" className={"text-danger"}>(empty)</option>
          {props.selections.map(s => {
            if (s.id === props.property.selectionId)
              return s.options.map(o =>
                <option key={o.value} value={o.value}>{o.name}</option>
              );
            else
              return null;
          })}
        </Form.Select>
      </Form.Group>
      }
    </div>
  )
})
