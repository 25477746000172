import {Instance, types, getRoot} from "mobx-state-tree"
import {pub_api} from "../../index";
import {
  DistributionId,
  ICategoryVm, ICopyEntitiesResultVm,
  IDistributionDiffVm, IDistributionDto,
  IDistributionVm
} from '@yakoffice/publisher-types';
import {flow} from "mobx";
import {RootStore} from "../../../model/RootStore";
import {ApiRequest} from "../ApiRequest";
import type {IPaginationSearchParams} from "../IPaginationSearchParams";

export interface IDistributionSearchParams extends IPaginationSearchParams{
    gameVersionId?: number
    gameEnvironmentId?: number
    hideFailed?: boolean
}

export const DistributionApiGateway = types.model(
    "DistributionApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl(gameVersionId?: number, gameEnvironmentId?: number) : string {
          const routeGameVersionId = gameVersionId ?? getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion().id;
          const routeGameEnvironmentId = gameEnvironmentId ?? getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment().id;
          return `${pub_api}/versions/${routeGameVersionId}/game-environments/${routeGameEnvironmentId}/distributions/`;
        },
    }))
    .actions(self => ({
        getDistributions: flow(function* () {
            return yield self.apiRequest.get<IDistributionVm[]>(self.getRootUrl())
        }),
        getDistribution: flow(function* (distributionId: number) {
            return yield self.apiRequest.get<IDistributionVm>(self.getRootUrl()+distributionId)
        }),
        findDistributions: flow(function* (searchParams : IDistributionSearchParams) {
          const queryString = buildSearchQueryString(searchParams);
            return yield self.apiRequest.get<IDistributionVm[]>(`${self.getRootUrl(searchParams.gameVersionId, searchParams.gameEnvironmentId)}?${queryString}`)
        }),
        countDistributions: flow(function* (searchParams : IDistributionSearchParams) {
          const queryString = buildSearchQueryString(searchParams);
            return yield self.apiRequest.get<number>(`${self.getRootUrl(searchParams.gameVersionId, searchParams.gameEnvironmentId)}count?${queryString}`)
        }),
        getDistributionDiff: flow(function* (gameVersionId: number, gameEnvironmentId: number, distributionId: DistributionId, diffGameVersionId: number, diffGameEnvironmentId: number, diffDistributionId: DistributionId) {
            const url = `${pub_api}/versions/${gameVersionId}/game-environments/${gameEnvironmentId}/distributions/${distributionId}/differences/versions/${diffGameVersionId}/game-environments/${diffGameEnvironmentId}/distributions/${diffDistributionId}`
            return yield self.apiRequest.get<IDistributionDiffVm>(url);
        }),
        distribute: flow(function* (distributionDto : IDistributionDto) {
            return yield self.apiRequest.put<ICategoryVm>(self.getRootUrl()+"actions/distribute", distributionDto)
        }),
        deleteDistribution: flow(function* (distributionId: number) {
            yield self.apiRequest.delete(`${self.getRootUrl()}${distributionId}`)
        }),
        copyEntities: flow(function* (gameVersionId: number, gameEnvironmentId: number, distributionId: DistributionId, toGameEnvironmentId: number) {
          return yield self.apiRequest.put<ICopyEntitiesResultVm>(`${self.getRootUrl(gameVersionId, gameEnvironmentId)}${distributionId}/copy-to/game-environments/${toGameEnvironmentId}`, null)
        }),
        downloadEntitiesAsJSON: flow(function* (searchParams : IDistributionSearchParams) {
          const queryString = buildSearchQueryString(searchParams);
          return yield self.apiRequest.get<ICategoryVm>(self.getRootUrl()+`distribution-as-json?${queryString}`)
      })
    }))

function buildSearchQueryString(searchParams: IDistributionSearchParams) {
    let queryString = "";
    if (searchParams.hideFailed)
        queryString += `hide-failed=${searchParams.hideFailed}&`;
    if (searchParams.pageNumber)
        queryString += `page-number=${searchParams.pageNumber}&`;
    if (searchParams.pageSize)
        queryString += `page-size=${searchParams.pageSize}&`;
    return queryString;
}

export interface IDistributionApiGateway extends Instance<typeof DistributionApiGateway> {}
