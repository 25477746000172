import React, { FC, useEffect, useState }       from 'react';
import {useNavigate, useParams}                  from 'react-router-dom';
import {observer}                               from 'mobx-react-lite';
import moment                                   from 'moment';
import 'moment/locale/en-gb.js';
import {Calendar, Event, momentLocalizer}       from 'react-big-calendar';
import {Form}                                   from 'react-bootstrap';
import Container                                from 'react-bootstrap/cjs/Container';
import Dropdown                                 from 'react-bootstrap/cjs/Dropdown';
import DropdownButton                           from 'react-bootstrap/cjs/DropdownButton';
import Row                                      from 'react-bootstrap/Row';
import Col                                      from 'react-bootstrap/Col';
import {ViewHeader}                             from '@yakoffice/view-header';
import {CustomSpinner}                          from '@yakoffice/custom-spinner';
import {EntityStatus}                           from '@yakoffice/publisher-types';
import { useGetCalendarLink, useGetEntityLink } from '../../routes';
import {GameEnvironmentSelector}                from '../common/GameEnvironmentSelector';
import {useGameEnvironmentCssClass}             from '../common/useGameEnvironmentCssClass';
import { IGameEnvironment }                     from '../../../model/gameEnvironment/GameEnvironment';
import { IGameVersion }                         from '../../../model/gameVersion/GameVersion';
import { IEntityVersion }                       from '../../../model/entity/EntityVersion';
import {useFindEntities}                        from '../../../loaders/useFindEntities';
import { GameVersionSelector }                  from '../common/GameVersionSelector';
import {Events}                                 from './Events';
import {EventProps}                             from './EventProps';
import { EntityCalendarKey }                    from './EntityCalendarKey';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.sass';


export const EntityCalendar : FC = observer(() => {

  const allStatuses        = [EntityStatus.Draft, EntityStatus.Published, EntityStatus.Stopped, EntityStatus.Archived];
  const localizer          = momentLocalizer(moment);
  const navigate            = useNavigate();
  const cssClass           = useGameEnvironmentCssClass();
  const getCalendarLink    = useGetCalendarLink();
  const getEntityLink      = useGetEntityLink();
  const {gameEnv}          = useParams() as {gameEnv: string};

  const [searchParameters, setSearchParameters] = useState(() => ({
    hasPropertyWithKeys :["startTime", "endTime", "dayOfTheWeek"],
    gameEnvironmentId   : parseInt(gameEnv),
    status: [EntityStatus.Published],
  }))
  const [activeKinds, setActiveKinds] = useState<number[]>([]);

  const findEntitiesResult = useFindEntities(searchParameters)

  useEffect(() => setSearchParameters(current => ({...current, gameEnvironmentId: parseInt(gameEnv)})), [gameEnv])

  const onGameVersionChanged      = (targetGv: IGameVersion) => navigate(getCalendarLink({gameVersionId: targetGv.id}))
  const onGameEnvironmentChanged  = (targetGe: IGameEnvironment) => navigate(getCalendarLink({gameEnvironmentId: targetGe.id}));

  const eventTooltip = (e: Event) => {
    const startTime = e.resource.properties.find( (p: any) => p.kindPropertyKey === "startTime");
    const endTime   = e.resource.properties.find( (p: any) => p.kindPropertyKey === "endTime");
    return `Kind Name: ${e.resource.kindVersionSummary.name} \nStart Time: ${startTime ? formatDateTimeString(startTime.value) : "none"} \nEnd Time: ${endTime ? formatDateTimeString(endTime.value) : "none"}`;
  }

  const redirectToEntity = (e: Event) => navigate(getEntityLink({
    categoryId: e.resource.kindVersionSummary.kindCategoryId,
    kindId: e.resource.kindVersionSummary.kindId,
    entityId: e.resource.entity.id,
  }));

  const title = (e: Event) => {

    return `${e.resource.name ? e.resource.name : "Unknown Kind"} | ${e.resource.description}`;
  }

  const toggleStatusValues = (e: any) => {
    setSearchParameters(current => ({
      ...current,
      status: current.status.includes(e.target.dataset.value)
        ? current.status.filter(s => s !== e.target.dataset.value)
        : [...current.status, e.target.dataset.value]
    }))
  }

  const getEntitiesWithActiveKinds = () => {

    const entitiesWithActiveKinds: IEntityVersion[] = [];

    findEntitiesResult.entityVersions.forEach(entityVersion => {
      if(activeKinds.includes(entityVersion.kindVersionSummary.kindId))
        entitiesWithActiveKinds.push(entityVersion);
    })

    return entitiesWithActiveKinds;
  }

  return (
    <div id="entitiesCalendar" className={cssClass.name}>
        <Container>
           <ViewHeader title="Calendar"
             customAction={
               <>
                 <GameVersionSelector handleGameVersionChange={onGameVersionChanged}/>
                 <GameEnvironmentSelector handleGameEnvironmentChange={onGameEnvironmentChanged} />
               </>
             }
           />
        </Container>

        <Container>
        {!findEntitiesResult.isLoaded
           ?
              <CustomSpinner spinnerText="Finding Entities..." />
           :
              <Row>
                <Col lg={9}>
                  <div className="text-end mb-2 mt-5 text-capitalize">
                    <DropdownButton id="statusSelector"
                                    title={searchParameters.status.length > 0 ? searchParameters.status.map(status => {
                                      return <span key={status} className="me-1"><i className={`${searchParameters.status[searchParameters.status.indexOf(status)]} fas fa-circle`} />{status} </span>
                                    }) : <React.Fragment>{allStatuses.map(status => { return <i key={status} className={`${status} fas fa-circle`} />})} "All Status'</React.Fragment>}
                                    variant="secondary"
                                    size="sm">
                      <Dropdown.ItemText>
                        {allStatuses.map(status => {
                          if(searchParameters.status.includes(status))
                            return <Form.Check key={`toggle-${status}`} type="checkbox" label={status} data-value={status} onChange={toggleStatusValues} checked />
                          else
                            return <Form.Check key={`toggle-${status}`} type="checkbox" label={status} data-value={status} onChange={toggleStatusValues} />
                        })}
                      </Dropdown.ItemText>
                    </DropdownButton>
                  </div>

                  <Calendar
                    localizer={localizer}
                    culture={moment.locale()}
                    events={Events(getEntitiesWithActiveKinds())}
                    style={{height: 850}}
                    popup={true}
                    getNow={() => new Date()}
                    views={{
                      month:  true,
                      week:   true,
                      day:    true,
                      agenda: true,
                    }}
                    timeslots={4}
                    onDoubleClickEvent={(e: Event) => redirectToEntity(e)}
                    titleAccessor={(e: Event) => title(e)}
                    tooltipAccessor={(e: Event) => eventTooltip(e)}
                    eventPropGetter={(event, start, end, isSelected) => EventProps(event, isSelected)}
                  />
                </Col>
                <Col lg={3}>
                  <EntityCalendarKey entityVersions={findEntitiesResult.entityVersions} activeKinds={activeKinds} setActiveKinds={setActiveKinds} />
                </Col>
              </Row>
          }
        </Container>
    </div>
  );
})

const dateTimeStringFormat = "DD MMM YYYY HH:mm [UTC]"

const formatDateTimeString = (value: string) => {
  return moment(value).format(dateTimeStringFormat);
}
