import { detach,destroy, flow, getRoot, getSnapshot, Instance, types } from 'mobx-state-tree';
import {IKindVersion, KindVersion}                                from "./KindVersion";
import {IKindVersionSearchParams, KindVersionApiGateway} from "../../api/requests/kinds/kindVersionApiGateway";
import {
  IKindVersionDto,
  IKindVersionPatchDto,
  IKindVersionPropertyPatchDto,
  IKindVersionVm,
  KindPropertyType, KindStatus,
  ValidationType
} from '@yakoffice/publisher-types';
import {IKindPropertyDto, IValidationRuleDto}  from "@yakoffice/publisher-types";
import { formatError, GenerateId } from '../Utils';
import {ICategory} from "../category/Category";
import { Kind } from './Kind';
import { RootStore } from '../RootStore';
import { IGameEnvironment } from '../gameEnvironment/GameEnvironment';

export const KindVersionStore = types.model(
    "KindVersionStore",
    {
        apiGateway: KindVersionApiGateway,
        kindVersions: types.array(KindVersion),
        currentKindVersion: types.maybeNull(types.reference(KindVersion)),
        isLoading: false
    })
    .views(self => ({
        getCurrentKindVersion() : IKindVersion{
            if(self.currentKindVersion)
                return self.currentKindVersion;

            throw new Error("The current kind version has not been set")
        }
    }))
    .actions(self => ({
      clearCurrentKindVersion() {
        self.currentKindVersion = null;
      },
      setCurrentKindVersion(kindVersion: IKindVersion) {
        self.currentKindVersion = kindVersion;
      },
      addKindVersion() {
        const rootStore = getRoot<typeof RootStore>(self);
        const kindVersion = KindVersion.create({
          kind: getSnapshot(Kind.create({
            id: GenerateId(),
            categoryId: rootStore.categoryStore.getCurrentCategory().id,
            status: KindStatus.Live
          })), version: 0
        });
        self.kindVersions.push(kindVersion);
        return kindVersion as IKindVersion;
      },
      saveKindVersion: flow(function* (kindVersion: IKindVersion) {
        try {
          const kindVersionDto = MapToDto(kindVersion);

          if (kindVersion.isNewKind())
            yield self.apiGateway.createKind(kindVersionDto);
          else
            yield self.apiGateway.createKindVersion(kindVersion.kind.id, kindVersionDto)

        } catch (e) {
          throw formatError(e);
        }
      }),
      patchKindVersion: flow(function* (kindId: number, patchDto: IKindVersionPatchDto) {
        try {
          yield self.apiGateway.patchCurrentKindVersion(kindId, patchDto);
        } catch (e) {
          throw formatError(e);
        }
      }),
      patchKindVersionProperty: flow(function* (kindId: number, propertyId: number, patchDto: IKindVersionPropertyPatchDto) {
        try {
          yield self.apiGateway.patchCurrentKindVersionProperty(kindId, propertyId, patchDto);
        } catch (e) {
          throw formatError(e);
        }
      }),
      deleteKind: flow(function* (kindVersion: IKindVersion) {
        try {
          yield self.apiGateway.deleteKind(kindVersion.kind.id)
        } catch (e) {
          throw formatError(e);
        }
      }),
      archiveKind: flow(function* (kindVersion: IKindVersion) {
        try {
          yield self.apiGateway.archiveKind(kindVersion.kind.id)
        } catch (e) {
          throw formatError(e);
        }
      }),
      unArchiveKind: flow(function* (kindVersion: IKindVersion) {
        try {
          yield self.apiGateway.unArchiveKind(kindVersion.kind.id)
        } catch (e) {
          throw formatError(e);
        }
      }),
      moveKind: flow(function* (kindVersion: IKindVersion, targetCategory: ICategory) {
        try {
          yield self.apiGateway.moveKind(kindVersion.kind.id, targetCategory.id);
        } catch (e) {
          throw formatError(e);
        }
      }),
      loadCurrentVersionForKind: flow(function* (kindId: number) {
        self.isLoading = true;

        try {
          const kindVm = yield self.apiGateway.getCurrentVersionForKind(kindId);
          const kindVersion = KindVersion.create(MapKindVmToKindModel(kindVm));

          const existing = self.kindVersions.find(c => c.id === kindVersion.id);
          if (existing){
            detach(existing)
          }
          self.kindVersions.push(kindVersion);

          self.isLoading = false;
          return kindVersion as IKindVersion;
        } catch (e:any) {
          throw new Error(`Failed to load Kind version: ${e.message}`);
        }
      }),
      loadVersionForCurrentKind: flow(function* (version: number) {
        try {
          const currentVersion: IKindVersion = self.getCurrentKindVersion();
          let desiredVersion = self.kindVersions.find(e => e.kind.id === currentVersion.kind.id && e.version === version);
          if (!desiredVersion) {
            const vm = (yield self.apiGateway.getVersionForKind(currentVersion.kind.id, version)) as IKindVersionVm;
            desiredVersion = KindVersion.create(MapKindVmToKindModel(vm));
            self.kindVersions.push(desiredVersion);
          }
          return desiredVersion as IKindVersion;

        } catch (e:any) {
          throw new Error(`Failed to load specific version for Kind: ${e.message}`);
        }
      }),
      loadCurrentKindVersionsForCurrentCategory: flow(function* () {
        self.isLoading = true;

        // To prevent an invalid reference(s)
        self.currentKindVersion = null;

        try {
          const kindVersionVms = (yield self.apiGateway.getCurrentKindVersionsForCurrentCategory({})) as IKindVersionVm[];
          const kindVersions = kindVersionVms.map(vm => KindVersion.create(MapKindVmToKindModel(vm)))
          self.kindVersions.replace(kindVersions);
          self.isLoading = false;
        } catch (e:any) {
          throw new Error(`Failed to load Kinds: ${e.message}`);
        }
      }),
      // NOTE:  This route is filtered by game version and not category
      findAllCurrentKindVersions: flow(function* (searchParams: IKindVersionSearchParams) {
        try {
          self.isLoading = true;
          const vms = (yield self.apiGateway.getCurrentKindVersions(searchParams)) as IKindVersionVm[];

          const kindVersions = vms.map(vm => KindVersion.create(MapKindVmToKindModel(vm)))
          self.isLoading = false;
          return kindVersions;
        } catch (e:any) {
          throw new Error(`Failed to find entities: ${e.message}`);
        }
      }),
      createSheet: flow(function* (kindVersion: IKindVersion, entityStatuses: string[]) {
        try {
          const kind = yield self.apiGateway.createSheet(kindVersion.kind.id, entityStatuses);
          kindVersion.updateKind(kind);
        } catch (e:any) {
          throw formatError(e);
        }
      }),
      sheetExists: flow(function* (kindVersion: IKindVersion, gameEnvironment: IGameEnvironment) {
        try {
          return yield self.apiGateway.sheetExists(kindVersion.kind.id, gameEnvironment.id);
        } catch (e) {
          throw formatError(e);
        }
      }),
      refreshSheet: flow(function* (kindVersion: IKindVersion, entityStatuses: string[]) {
        try {
          yield self.apiGateway.refreshSheet(kindVersion.kind.id, entityStatuses);
        } catch (e) {
          throw formatError(e);
        }
      }),
      uploadSheet: flow(function* (kindVersion: IKindVersion) {
        try {
          return yield self.apiGateway.uploadSheet(kindVersion.kind.id);
        } catch (e) {
          throw formatError(e);
        }
      }),
      deleteSheet: flow(function* (kindVersion: IKindVersion) {
        try {
          yield self.apiGateway.deleteSheet(kindVersion.kind.id);
          kindVersion.updateKind(getSnapshot(Kind.create({
            ...kindVersion.kind,
            googleSheetId: null
          })));
        } catch (e) {
          throw formatError(e);
        }
      }),
      clearStore() {
        self.kindVersions.forEach(k => destroy(k))
      }
    }));

export const createIdFromIdAndVersion = (id: number, version: number) => `${id}-${version}`

export const MapKindVmToKindModel = (vm: IKindVersionVm) => {

  return {
    id: createIdFromIdAndVersion(vm.kind.id, vm.version),
    kind: vm.kind,
    version: vm.version,
    name: vm.name,
    description: vm.description,
    comment: vm.comment,
    kindHexColour: vm.kind.hexColour,
    isSpecificationForKinds: vm.isSpecificationForKinds,
    isSpecificationForExperiments: vm.isSpecificationForExperiments,
    canUseSpecifications: vm.canUseSpecifications,
    createdAt: vm.createdAt,
    createdBy: vm.createdBy,
    updatedAt: vm.updatedAt,
    updatedBy: vm.updatedBy,
    properties: vm.properties.map(prop => {
      return {
        id: prop.id,
        previousId: prop.previousId,
        default: prop.default,
        description: prop.description,
        key: prop.key,
        type: (KindPropertyType as any)[prop.type],
        selectionId: prop.selectionId,
        entitySelectionKindId: prop.entitySelectionKindId,
        validationRules: prop.validationRules.map(vr => {
          return {
            id: vr.id,
            type: (ValidationType as any)[vr.type],
            value: vr.value
          }
        }),
      }
    })
  }
}

const MapToDto = (kindVersion: IKindVersion): IKindVersionDto => {
  return {
    name: kindVersion.name,
    description: kindVersion.description,
    comment: kindVersion.comment,
    isSpecificationForKinds: kindVersion.isSpecificationForKinds,
    isSpecificationForExperiments: kindVersion.isSpecificationForExperiments,
    canUseSpecifications: kindVersion.canUseSpecifications,
    kindHexColour: kindVersion.kindHexColour,
    properties: kindVersion.properties.map((prop: any): IKindPropertyDto => {
      return {
        id: prop.id,
        default: prop.default,
        description: prop.description,
        key: prop.key,
        type: prop.type,
        selectionId: prop.selectionId,
        entitySelectionKindId: prop.entitySelectionKindId,
        validationRules: prop.validationRules.map((vr: any): IValidationRuleDto => {
          return {
            type: vr.type,
            value: vr.value
          }
        })
      }
    })
  }
}

export interface IKindVersionStore extends Instance<typeof KindVersionStore> {}
