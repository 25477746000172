import {destroy, flow, getParentOfType, getRoot, Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";
import type {IValidationRule} from "./ValidationRule";
import {ValidationRule} from "./ValidationRule";
import {KindPropertyType, ValidationType} from "@yakoffice/publisher-types";
import type {IKindVersion} from "./KindVersion";
import {KindVersion} from "./KindVersion";
import type {IRootStore} from "../RootStore";
import {RootStore} from "../RootStore";
import {equalsIgnoreCase} from "../../utilities";
import type {IKindPropertyType} from "./KindPropertyType";
import {PropertyUpdatingStatus} from "../PropertyUpdatingStatus";

export const KindProperty = types.model(
    "KindProperty",
    {
        id: types.optional(types.identifierNumber, GenerateId),
        previousId: types.maybeNull(types.number),
        default: types.maybeNull(types.string),
        description: "",
        key: "",
        type: types.optional(types.enumeration<KindPropertyType>("type", Object.values(KindPropertyType)), KindPropertyType.Boolean),
        selectionId: types.maybeNull(types.number),
        entitySelectionKindId: types.maybeNull(types.number),
        validationRules: types.array(ValidationRule),
        descriptionStatus: types.optional(PropertyUpdatingStatus, {updated: false, updating: false}),
        isNew: false
    })
    .views(self => ({
            getKind() : IKindVersion{
                    return getParentOfType(self, KindVersion);
            },
            getOtherProperties() : IKindProperty[]{
                    return getParentOfType(self, KindVersion).properties.filter(p => p.key && p !== self);
            },
            get validationTypes(){
                    // TODO:  It' doesn't feel right accessing the propertyTypes this way.  Will be a pain to unit test
                    // Either pass them in or inject the store somehow but through the environment doesn't make sense....
                    const rootStore : IRootStore = getRoot<typeof RootStore>(self);
                    const propertyTypes :IKindPropertyType[] = rootStore.kindPropertyTypeStore.kindPropertyTypes;
                    const propertyType = propertyTypes.find(pt => equalsIgnoreCase(pt.propertyType, self.type));
                    if(propertyType)
                            return propertyType.validationTypes;

                    return [];
            },
            isRequired() {
                    const validationRule = self.validationRules.find(vr => equalsIgnoreCase(ValidationType.Required, vr.type));

                    if(validationRule && validationRule.value != null)
                            return equalsIgnoreCase(validationRule.value,true.toString());

                    return false;
            }
    }))
    .actions(self => ({
            setKey(key: string){
                    self.key = key;
            },
            setDescription: flow( function*(description: string) {
                    self.description = description;

                    if(!self.isNew) {
                      try {
                        self.descriptionStatus.updated = false;
                        self.descriptionStatus.updating = true;
                        const rootStore = getRoot(self) as any;
                        const parent = getParentOfType(self, KindVersion) as any;
                        yield rootStore.kindVersionStore.patchKindVersionProperty(parent.kind.id, self.id, {description: description});
                        self.descriptionStatus.updating = false
                        self.descriptionStatus.updated = true;
                      } catch (e) {
                        self.descriptionStatus.updating = false
                        throw e;
                      }
                    }
            }),
            setType(type: KindPropertyType){
                    self.type = type;
                    self.validationRules.clear();
                    self.default = null;

                    if(!equalsIgnoreCase(type, KindPropertyType.Selection))
                            self.selectionId = null;

                    if(!equalsIgnoreCase(type, KindPropertyType.EntitySelection))
                            self.entitySelectionKindId = null;
            },
            setSelection(selectionId: number, defaultValue: string | null){
                    self.selectionId = selectionId;
                    self.default = defaultValue;
            },
            setEntitySelection(kindId: number, defaultValue: string | null){
                    self.entitySelectionKindId = kindId;
                    self.default = defaultValue;
            },
            setDefault(defaultValue: string | null){
                    self.default = defaultValue && equalsIgnoreCase(self.type, KindPropertyType.Hexadecimal)
                        ? defaultValue.toUpperCase()
                        : defaultValue === "" ? null : defaultValue
            },
            addValidationRule(){
                    const vr = ValidationRule.create({type: ValidationType.Required, value: true.toString()});
                    self.validationRules.push(vr);
                    return vr;
            },
            deleteValidationRule(vr : IValidationRule) {
              const start = self.validationRules.indexOf(vr);
              self.validationRules.splice(start, 1)
              destroy(vr);
            }
    }));


export interface IKindProperty extends Instance<typeof KindProperty> {}
