import { useEffect, useState }  from 'react';
import { ISearchResultVm } from '@yakoffice/publisher-types';
import { NotificationType, usePublishNotification } from '@yakoffice/notification-handler';
import { useRootStore } from './useRootStore';

interface useGetSearchResults{
  isLoaded    : boolean;
  searchResults  : ISearchResultVm[];
}

export const useGetSearchResult = (searchValue: string) => {

  const rootStore = useRootStore();
  const publishNotification = usePublishNotification();
  const [result, setResult] = useState<useGetSearchResults>(() => ({isLoaded: false, searchResults: []}));

  useEffect(() => {
    setResult({ isLoaded: false, searchResults: [] })
    if(searchValue.length > 2){
      rootStore.gameVersionStore.searchInGameVersion(rootStore.gameVersionStore.currentGameVersion ?? rootStore.gameVersionStore.gameVersions[0], searchValue, 10)
        .then(results => {
          setResult({ isLoaded: true, searchResults: results })
        })
        .catch(e => {
          publishNotification({
            notificationType: NotificationType.Error,
            title: "Error loading Kind version",
            message: e.message,
            footer: "Please try refreshing"

          });
        })
    }
  }, [rootStore.gameVersionStore, publishNotification, searchValue]);

  return result;
};
