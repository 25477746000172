import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import Dropdown from "react-bootstrap/Dropdown";
import {getGameEnvironmentClass} from "./useGameEnvironmentCssClass";
import {useRootStore} from "../../../loaders/useRootStore";
import {Button} from "react-bootstrap";

interface PropsType {
  [key: string]: any,
  gameEnvironments?: IGameEnvironment[]
  selectedGameEnvironments: IGameEnvironment[],
  handleGameEnvironmentSelected? : (gameEnvironment : IGameEnvironment) => void,
  handleGameEnvironmentUnselected? : (gameEnvironment : IGameEnvironment) => void,
}

export const GameEnvironmentMultiSelector : FC<PropsType> = observer(({ gameEnvironments, selectedGameEnvironments, handleGameEnvironmentSelected, handleGameEnvironmentUnselected, ...props}) => {
  const rootStore = useRootStore();

  const handleToggleGameEnvironment = (gameEnvironment: IGameEnvironment) => {
    selectedGameEnvironments.find(gv => gv === gameEnvironment)
      ? handleGameEnvironmentUnselected && handleGameEnvironmentUnselected(gameEnvironment)
      : handleGameEnvironmentSelected && handleGameEnvironmentSelected(gameEnvironment)
  }

  return (
    <Dropdown align="end" autoClose={false} {...props} data-testid={'ddlGameEnvironmentMultiSelector'}>
      <Button variant="info">Select Environment(s): {selectedGameEnvironments.length}</Button>
      <Dropdown.Toggle split variant="info" data-testid={'ddlGameEnvironmentMultiSelectorToggle'}/>
      <Dropdown.Menu>
        {(gameEnvironments ?? rootStore.gameEnvironmentStore.gameEnvironments)
          .map(gameEnvironment => {
            return <Dropdown.Item key={gameEnvironment.id}
                                  as="button"
                                  onClick={() => handleToggleGameEnvironment(gameEnvironment)}
                                  data-testid={`gameEnvironmentMultiSelectorButton${gameEnvironment.name}`}>
              <i
                className={`fas ${selectedGameEnvironments.find(gv => gv === gameEnvironment) != null ? "fa-check-square" : "fa-square"} text-${getGameEnvironmentClass(gameEnvironment)} me-1`}/> {gameEnvironment.name}
            </Dropdown.Item>
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
})

