import React, { FC, useState }                        from 'react';
import {observer}                                     from 'mobx-react-lite';
import Container                                      from 'react-bootstrap/Container';
import Row                                            from 'react-bootstrap/Row';
import Col                                            from 'react-bootstrap/Col';
import {IconCard}                                     from '@yakoffice/icon-card'
import {ViewHeader}                                   from '@yakoffice/view-header';
import {PubRouteItems,} from '../../routes';
import {IGameVersion}                                 from '../../../model/gameVersion/GameVersion';
import { FilterButton }                               from '../common/FilterButton';
import { GameVersionCard }                            from './GameVersionCard';


interface PropsType {
  gameVersions: IGameVersion[];
}


export const GameVersions : FC<PropsType> = observer((props) => {

  const [filterArchived, setFilterArchived] = useState(true);

  return  <div id="gameVersions">
    <Container>
      <ViewHeader
        title="Game Versions"
        customAction={<FilterButton filterText="Archived"
                                    filterOn={filterArchived}
                                    numberOfFilteredItems={props.gameVersions.filter(c => (c.status === "archived")).length}
                                    onClick={() => setFilterArchived(!filterArchived)} />}
      />
    </Container>
    <Container>
      <Row>
        <GameVersionCard gameVersions={props.gameVersions} filterArchived={filterArchived} />
        <Col xl="2" lg="3" md="3" sm="12" xs="12" className="mb-4">
          <IconCard icon={<i className="fas fa-folder-plus" />} title={"Add New Game Version"} link={PubRouteItems.AddGameVersion} />
        </Col>
      </Row>
    </Container>
  </div>
});
