import React, {FC} from "react";
import Button, {ButtonProps} from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

interface PropsType {
    filterText : string
    filterOn: boolean
    numberOfFilteredItems: number
    onClick : () => void
}

export const FilterButton : FC<PropsType> = (props) => {

  const btnClass: ButtonProps["variant"] = props.filterOn ? "primary" : "warning";
  const btnText = (props.filterOn ? "Show " : "Hide ") + props.filterText;

  return props.numberOfFilteredItems > 0
    ? <Button variant={btnClass} onClick={props.onClick}>
      {btnText}<Badge bg="light" text="dark" className="ms-1 d-none d-md-inline-block">{props.numberOfFilteredItems}</Badge>
    </Button>
    : null;
}
