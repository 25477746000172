import React, {FC}                              from 'react';
import {observer}                               from 'mobx-react-lite';
import {CustomSpinner}                          from '@yakoffice/custom-spinner';
import {useGameEnvironment}                     from '../../../loaders/useGameEnvironment';
import {GameEnvironment}                        from "./GameEnvironment";

export const GameEnvironmentContainer : FC = observer(() => {

    const gameEnvironmentResult    = useGameEnvironment();

    return (gameEnvironmentResult.isLoaded && gameEnvironmentResult.gameEnvironment
        ? <GameEnvironment gameEnvironment={gameEnvironmentResult.gameEnvironment} />
        : <CustomSpinner spinnerText={'Loading game environment'}/>)});
