import React, {FC} from "react";
import { formatISODateStringAsLocal } from '../common/Utils';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {IExperiment} from "../../../model/experiment/Experiment";
import { observer } from 'mobx-react-lite';
import { InfoBox, InfoBoxHeader, InfoBoxBody } from '@yakoffice/info-box';
import { CollapsePanel } from '@yakoffice/shared-components';
import { AbbreviatedString } from '../../components/abbreviated-string/AbbreviatedString';

interface PropsType {
    experiment : IExperiment
}

export const ExperimentHightlights : FC<PropsType> = observer((props) => {
    return (
      <CollapsePanel>
        <Row>
          <Col sm="8" md="3">
            <InfoBox>
              <InfoBoxHeader><i className="far fa-calendar-alt" /> Last updated</InfoBoxHeader>
              <InfoBoxBody>{props.experiment.updatedAt != null && formatISODateStringAsLocal(props.experiment.updatedAt)}</InfoBoxBody>
            </InfoBox>
          </Col>
          <Col sm="8" md="3">
            <InfoBox>
              <InfoBoxHeader><i className="far fa-user" /> Updated by</InfoBoxHeader>
              <InfoBoxBody>{props.experiment.lastAuthor}</InfoBoxBody>
            </InfoBox>
          </Col>
          <Col sm="8" md="6">
            <InfoBox>
              <InfoBoxHeader><i className="far fa-comment" /> Last comment</InfoBoxHeader>
              <InfoBoxBody>"<AbbreviatedString value={props.experiment.comment} lengthToAbbreviate={70} />"</InfoBoxBody>
            </InfoBox>
          </Col>
        </Row>
      </CollapsePanel>
    )
});
