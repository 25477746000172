import React, { FC } from 'react';
import {observer} from "mobx-react-lite";
import { IEntityVersionVm } from '@yakoffice/publisher-types';
import { formatISODateStringAsLocal } from '../common/Utils';
import {useGameEnvironmentCssClass} from '../common/useGameEnvironmentCssClass';
import { useGetEntityVersionLink } from '../../routes';
import {useNavigate} from 'react-router-dom';
import {YoPublisherTable, YoTableClassNames} from '../../components/yo-publisher-table/YoPublisherTable';
import { Checked } from '../common/Checked';
import { Form } from 'react-bootstrap';

interface PropsType {
    className?: string
    checkedEntityVersions  : Checked<IEntityVersionVm>[];
    displayCheckBoxes?: boolean,
    checkAll?: boolean
    handleToggleCheckAll?: () => void,
    handleCheckChanged?: (checkedEntityVersion: Checked<IEntityVersionVm>, checked: boolean) => void
}

export const EntityVersionTable : FC<PropsType> = observer(({className, checkedEntityVersions, displayCheckBoxes = false, checkAll, handleToggleCheckAll, handleCheckChanged}) => {

  const cssClass = useGameEnvironmentCssClass();
  const getEntityVersionLink = useGetEntityVersionLink()
  const navigate = useNavigate();

  const redirectToEntityVersion = (entityVersion: IEntityVersionVm) => {
    navigate(getEntityVersionLink({
      categoryId: entityVersion.kindVersionSummary.kindCategoryId,
      kindId: entityVersion.kindVersionSummary.kindId,
      gameEnvironmentId: entityVersion.entity.gameEnvironmentId,
      entityId: entityVersion.entity.id,
      version: entityVersion.version }));
  }

  const displayKindVersion = new Set(checkedEntityVersions.map(ce => ce.value.kindVersionSummary.version)).size > 1;

  return <YoPublisherTable responsive variant={`${className ? className : cssClass.name}`} className={YoTableClassNames.MinWidth100} data-testid="tblEntities">
    <thead className="table-dark">
      <tr>
        {displayCheckBoxes &&
          <th><Form.Check checked={checkAll} onChange={handleToggleCheckAll}/></th>
        }
        <th>Entity Name</th>
        {displayKindVersion && <th>Kind Version</th>}
        <th>Description</th>
        <th>Last Updated</th>
        <th>Author</th>
      </tr>
    </thead>
    <tbody>
    {checkedEntityVersions.map(checkedEntityVersion =>
      <tr key={checkedEntityVersion.value.entity.id} className="linked-row" data-testid={checkedEntityVersion.value.name}>
        {displayCheckBoxes &&
          <td><Form.Check checked={checkedEntityVersion.checked} onChange={() => handleCheckChanged && handleCheckChanged(checkedEntityVersion, !checkedEntityVersion.checked)} data-testid="cbEntity"/></td>
        }
        <td onClick={() => redirectToEntityVersion(checkedEntityVersion.value)} className="text-info cursor-pointer">
          {checkedEntityVersion.value.name}
        </td>
        {displayKindVersion && <td>{checkedEntityVersion.value.kindVersionSummary.version}</td>}
        <td className="max-width cursor-pointer" onClick={() => redirectToEntityVersion(checkedEntityVersion.value)}>
          {checkedEntityVersion.value.description}
        </td>
        <td onClick={() => redirectToEntityVersion(checkedEntityVersion.value)} className="cursor-pointer">
          {formatISODateStringAsLocal(checkedEntityVersion.value.createdAt)}
        </td>
        <td onClick={() => redirectToEntityVersion(checkedEntityVersion.value)} className="cursor-pointer">
          {checkedEntityVersion.value.createdBy}
        </td>
      </tr>
    )}
    </tbody>
  </YoPublisherTable>
})
