import { detach, flow, Instance, types } from 'mobx-state-tree';
import { ISelection, Selection } from './Selection';
import { SelectionApiGateway } from '../../api/requests/selections/selectionApiGateway';
import { formatError } from '../Utils';
import { ISelectionDto, ISelectionVm } from '@yakoffice/publisher-types';

export const SelectionStore = types.model(
  'SelectionStore',
  {
    apiGateway:       SelectionApiGateway,
    selections:       types.array(Selection),
    currentSelection: types.maybeNull(types.reference(Selection)),
    isLoading:        false
  })
  .views(self => ({
    getCurrentSelection(): ISelection {
      if (self.currentSelection)
        return self.currentSelection;

      throw new Error('The current selection has not been set');
    }
  }))
  .actions(self => ({
    setCurrentSelection(selectionId: number) {

      const selectedSelection = self.selections.find(selection => selection.id === selectionId);

      if (selectedSelection)
        self.currentSelection = selectedSelection;
      else
        throw new Error(`Selection with id ${selectionId} does not exist`);
    },
    clearCurrentSelection() {
      self.currentSelection = null;
    },
    addSelection() {
      const selection = detach(Selection.create({ isNew: true }));
      return selection as ISelection;
    },

    loadSelections: flow(function* (archived: boolean) {
      self.isLoading = true;
      self.currentSelection = null;

      try {
        const selectionVms = (yield self.apiGateway.getSelections(archived)) as ISelectionVm[];

        const selections = selectionVms.map(vm => Selection.create(MapToSelectionModel(vm)));

        self.selections.replace(selections);
        self.isLoading = false;
      } catch (e: any) {
        throw new Error(`Failed to load selections.  Error message: ${e.message}`);
      }
    }),

    getSelection: flow(function* (selectionId: number) {

      self.isLoading = true;

      try {
        const selectionVm = yield self.apiGateway.getSelection(selectionId);
        const selection = Selection.create(MapToSelectionModel(selectionVm));

        const indexOf = self.selections.findIndex(_selection => _selection.id === selection.id);

        if (indexOf === -1)
          self.selections.push(selection);
        else
          self.selections.splice(indexOf, 1, selection);

        self.isLoading = false;

        return selection as ISelection;

      } catch (e: any) {
        throw new Error(`Failed to load selection: ${e.message}`);
      }
    }),

    saveSelection: flow(function* (selection: ISelection) {
      try {
        const selectionDto = MapToDto(selection);

        if (selection.isNewSelection())
          yield self.apiGateway.saveSelection(selectionDto);
        else
          yield self.apiGateway.updateSelection(selection.id, selectionDto);

      } catch (e) {
        throw formatError(e);
      }
    }),

    deleteSelection: flow(function* (selection: ISelection) {
      try {
        yield self.apiGateway.deleteSelection(selection.id); //flagged for same name
      } catch (e) {
        throw formatError(e);
      }
    }),

    copySelectionToGameVersion: flow(function* (selection: ISelection, gameVersionId: number) {

      try {
        const selectionDto = MapToDto(selection);

        const selectionInTargetGameVersion = yield self.apiGateway.findSelectionInGameVersion(selection.name, gameVersionId);

        if(selectionInTargetGameVersion[0])
          yield self.apiGateway.updateSelectionInOtherGameVersion(selectionInTargetGameVersion[0].id, gameVersionId, selectionDto);

        else
          yield self.apiGateway.saveSelectionToNewGameVersion(selectionDto, gameVersionId);

      } catch (e) {
        throw formatError(e);
      }
    }),

    archiveSelection: flow(function* (selection: ISelection, archive: boolean) {
      try {
        const selectionDto = MapToDto(selection);

        selectionDto.archived = archive;

        if (selection.isNewSelection())
          yield self.apiGateway.saveSelection(selectionDto);
        else
          yield self.apiGateway.updateSelection(selection.id, selectionDto);

      } catch (e) {
        throw formatError(e);
      }
    }),
    createSheet:      flow(function* (selection: ISelection) {
      try {
        const updatedSelection = yield self.apiGateway.createSheet(selection.id);
        selection.setGoogleSheetId(updatedSelection.googleSheetId);
      } catch (e) {
        throw formatError(e);
      }
    }),
    refreshSheet:     flow(function* (selection: ISelection) {
      try {
        yield self.apiGateway.refreshSheet(selection.id);
      } catch (e) {
        throw formatError(e);
      }
    }),
    uploadSheet:      flow(function* (selection: ISelection) {
      try {
        yield self.apiGateway.uploadSheet(selection.id);
      } catch (e) {
        throw formatError(e);
      }
    }),
    deleteSheet:      flow(function* (selection: ISelection) {
      try {
        yield self.apiGateway.deleteSheet(selection.id);
        selection.setGoogleSheetId(null);
      } catch (e) {
        throw formatError(e);
      }
    })
  }));


export const MapToSelectionModel = (vm: ISelectionVm) => {

  return {
    id:             vm.id,
    name:           vm.name,
    description:    vm.description,
    archived:       vm.archived,
    googleSheetId:  vm.googleSheetId,
    options:        vm.options.map(so => {
      return {
        id:        so.id,
        name:      so.name,
        value:     so.value,
        createdAt: so.createdAt,
        author:    so.author
      };
    }),
    createdAt:      vm.createdAt,
    originalAuthor: vm.originalAuthor,
    updatedAt:      vm.updatedAt,
    lastAuthor:     vm.lastAuthor
  };
};

const MapToDto = (selection: ISelection): ISelectionDto => {
  return {
    name:        selection.name,
    description: selection.description,
    archived:    selection.archived,
    options:     selection.options.map(option => {
      return {
        name:  option.name,
        value: option.value
      };
    })
  };
};


export interface ISelectionStore extends Instance<typeof SelectionStore> {
}
