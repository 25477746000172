import {destroy, detach, flow, getRoot, Instance, types} from 'mobx-state-tree';
import { EntityStatus, IExperimentDto, IExperimentVm, KindPropertyType } from '@yakoffice/publisher-types';
import { formatError, GenerateId } from '../Utils';
import { Experiment, IExperiment } from './Experiment';
import { ExperimentApiGateway, ExperimentStatus, IExperimentSearchParams } from '../../api/requests/ExperimentApiGateway';
import { IGameEnvironment } from '../gameEnvironment/GameEnvironment';
import { IEntityVersion } from '../entity/EntityVersion';
import { IEntityVersionSearchParams } from '../../api/requests/entities/entityVersionApiGateway';
import { IEntityVersionStore } from '../entity/EntitytVersionStore';
import { RootStore } from '../RootStore';
import { IGameVersion } from '../gameVersion/GameVersion';
import { ExperimentProperty, IExperimentProperty } from './ExperimentProperty';
import { EntityProperty, IEntityProperty } from '../entity/EntityProperty';
import { IKindVersion } from '../kind/KindVersion';
import { ISpecificationsForExperimentsStore } from '../kind/SpecificationKindStore';
import { VariantValue } from './VariantValue';
import { IVariant, Variant } from './Variant';


export const ExperimentStore = types.model(
    "ExperimentStore",
    {
        apiGateway        : ExperimentApiGateway,
        experiments       : types.array(Experiment),
        currentExperiment : types.maybeNull(types.reference(Experiment)),
        isLoading         : false
    }
)
    .views( self => ({
        getCurrentExperiment() : IExperiment{
            if(self.currentExperiment)
                return self.currentExperiment;

            throw new Error("The current entity has not been set")
        },

    }))
    .actions( self => {
      const getCurrentGameVersion = (): IGameVersion => {
        return getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion();
      }
      const getCurrentGameEnvironment = (): IGameEnvironment => {
        return getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment();
      }
      const clearCurrentExperiment = () => {
        self.currentExperiment = null;
      }
      const setCurrentExperiment = (experiment: IExperiment) => {
        self.currentExperiment = experiment;
      }
      const addExperiment = () => {
        const experiment = Experiment.create({
          gameVersionId: getCurrentGameVersion().id,
          gameEnvironmentId: getCurrentGameEnvironment().id,
          variants: [Variant.create({name: "A"})],
          isNew: true });
        self.experiments.push(experiment);

        return experiment as IExperiment;
      }
      const loadExperiments = flow(function* (searchParams: IExperimentSearchParams) {
        try {
          self.isLoading = true;

          // To prevent an invalid reference
          self.currentExperiment = null;

          const vms = (yield self.apiGateway.findExperiments(searchParams)) as IExperimentVm[];
          const experiments = vms.map(vm => Experiment.create({ ...MapToExperimentModel(vm) }))
          self.experiments.forEach(deleted => detach(deleted))
          self.experiments.replace(experiments);
          self.isLoading = false;
        } catch (e:any) {
          throw new Error(`Failed to load Experiments: ${e.message}`);
        }
      })
      const getExperiment = flow(function* (experimentId: number) {

        self.isLoading = true;

        try {
          const vm = (yield self.apiGateway.getExperiment(experimentId)) as IExperimentVm;
          const experiment = Experiment.create({ ...MapToExperimentModel(vm) });

          const existing = self.experiments.find(e => e.id === experiment.id);
          if (existing){
            detach(existing)
          }
          self.experiments.push(experiment);

          self.isLoading = false;
          return experiment as IExperiment;
        } catch (e:any) {
          throw new Error(`Failed to load Experiment: ${e.message}`);
        }
      })
      const findExperiments = flow(function* (searchParams: IExperimentSearchParams) {
        try {
          self.isLoading = true;

          const vms = (yield self.apiGateway.findExperiments(searchParams)) as IExperimentVm[];
          const experiments = vms.map(vm => Experiment.create({ ...MapToExperimentModel(vm) }))
          self.isLoading = false;
          return experiments;
        } catch (e:any) {
          throw new Error(`Failed to find experiments: ${e.message}`);
        }
      })
      const countExperiments = flow(function* (searchParams: IExperimentSearchParams) {
        try {
          return (yield self.apiGateway.countExperiments(searchParams)) as number;
        } catch (e:any) {
          throw new Error(`Failed to count experiments: ${e.message}`);
        }
      })
      const deleteExperiment = flow(function* (experiment: IExperiment) {

        try {
          yield self.apiGateway.deleteExperiment(experiment.id);
        } catch (e:any) {
          throw formatError(e);
        }
      })
      const applyExperimentVariantToEntities = flow(function* (experiment: IExperiment, variant: IVariant) {

        try {
          yield self.apiGateway.applyExperimentVariantToEntities(experiment.id, variant.id);
        } catch (e:any) {
          throw formatError(e);
        }
      })
      const updateExperimentStatusById = flow(function* (experimentId: number, status: ExperimentStatus) {
        try {
          yield self.apiGateway.updateExperimentStatus(experimentId, status);
        } catch (e:any) {
          throw formatError(e);
        }
      })
      const updateExperimentStatus = flow(function* (experiment: IExperiment, status: ExperimentStatus) {
        yield updateExperimentStatusById(experiment.id, status);
      })
      const findCurrentEntityVersions = flow(function* (searchParams: IEntityVersionSearchParams) {
        try {
          const rootStore = getRoot<typeof RootStore>(self);
          const entityVersionStore =   rootStore.entityVersionStore as IEntityVersionStore;
          return yield entityVersionStore.findAllCurrentEntityVersions(searchParams);
        } catch (e:any) {
          throw new Error(`Failed to find entity versions: ${e.message}`);
        }
      })
      const copyExperimentProperties = flow(function* (experiment: IExperiment, targetGV: IGameVersion, targetGE: IGameEnvironment) {
        const copiedProperties : IExperimentProperty[] = [];
        for (const experimentProperty of experiment.properties) {
          // Note: This could be optimised to reduce requests (i.e. query per kindName and entityName in []
          const entityVersions : IEntityVersion[] = yield findCurrentEntityVersions({
            gameVersionId: targetGV.id,
            gameEnvironmentId: targetGE.id,
            kindName: experimentProperty.kindName,
            name: experimentProperty.entityName,
            status: EntityStatus.Published
          })

          const existingProperty = entityVersions
            .find(() => true)?.properties
            .find(p => p.kindPropertyKey === experimentProperty.kindPropertyKey)

          if(existingProperty != null)
            copiedProperties.push(ExperimentProperty.create({entityPropertyId: existingProperty.id, values: experimentProperty.values.map(v => VariantValue.create({...v, id: GenerateId()}))}))
          else
            throw new Error(`Could not find matching published entity (${experimentProperty.entityName}) with property (${experimentProperty.kindPropertyKey}) in game environment (${targetGE.name})`)
        }
        return copiedProperties;
      })
      const copyExperimentSpecifications = flow(function* (experiment: IExperiment, targetGV: IGameVersion) {
        const copiedSpecifications : IEntityProperty[] = [];

        if(experiment.specifications.length > 0) {
          const rootStore = getRoot<typeof RootStore>(self);
          const specificationKindStore = rootStore.specificationsForExperimentsStore as ISpecificationsForExperimentsStore;
          const originSpecificationKinds = specificationKindStore.getKinds()
          const targetSpecificationKinds: IKindVersion[] = yield specificationKindStore.findSpecifications(targetGV);

          for (const experimentSpecificationProperty of experiment.specifications) {
            const originSpecificationKind = originSpecificationKinds.find(s => s.kind.id === experimentSpecificationProperty.kindPropertyKindId)

            const targetSpecificationProperty = targetSpecificationKinds
              .find(s => s.name === originSpecificationKind?.name)?.properties
              .find(p => p.key === experimentSpecificationProperty.kindPropertyKey)

            if (targetSpecificationProperty != null)
              copiedSpecifications.push(EntityProperty.create({kindPropertyId: targetSpecificationProperty.id,value: experimentSpecificationProperty.value}))
            else
              throw new Error(`Could not find matching specification for kind (${originSpecificationKind?.name}) and property (${experimentSpecificationProperty.kindPropertyKey}) in game version (${targetGV.name})`)
          }
        }
        return copiedSpecifications;
      })
      const copyExperiment = flow(function* (experiment: IExperiment, targetGV: IGameVersion | null = null, targetGE: IGameEnvironment | null = null) {
        try {
          // Note: We always copy properties and specifications (i.e. even if same GV and GE) in case they have been updated since the experiment was created
          const targetGeOrCurrent = targetGE ?? getCurrentGameEnvironment();
          const targetGvOrCurrent = targetGV ?? getCurrentGameVersion();
          const copiedProperties = yield copyExperimentProperties(experiment, targetGvOrCurrent, targetGeOrCurrent);
          const copiedSpecifications = yield copyExperimentSpecifications(experiment, targetGvOrCurrent);
          return experiment.copyExperiment(targetGvOrCurrent, targetGeOrCurrent, copiedProperties, copiedSpecifications)
        } catch (e:any) {
          throw formatError(e);
        }
      })
      const clearStore = () => {
        self.experiments.forEach(k => destroy(k))
      }
      return {
        clearCurrentExperiment,
        setCurrentExperiment,
        addExperiment,
        loadExperiments,
        getExperiment,
        findExperiments,
        countExperiments,
        deleteExperiment,
        applyExperimentVariantToEntities,
        updateExperimentStatus,
        updateExperimentStatusById,
        copyExperiment,
        clearStore
      }
    })
  .actions(self => ({
             createSheet: flow(function* (experiment: IExperiment, experimentId: number) {
               try {
                 const updatedExperiment = yield self.apiGateway.createSheet(experimentId);
                 experiment.setGoogleSheetId(updatedExperiment.googleSheetId);
                 return updatedExperiment;
               } catch (e) {
                 throw formatError(e);
               }
             }),
             refreshSheet: flow(function* (experimentId: number) {
               try {
                 yield self.apiGateway.refreshSheet(experimentId);
               } catch (e) {
                 throw formatError(e);
               }
             }),
             uploadSheet: flow(function* (experiment: IExperiment) {
               try {
                 return yield self.apiGateway.uploadSheet(experiment.id);
               } catch (e) {
                 throw formatError(e);
               }
             }),
             deleteSheet: flow(function* (experiment:IExperiment) {
               try {
                 yield self.apiGateway.deleteSheet(experiment.id);
                 experiment.setGoogleSheetId(null);
               } catch (e) {
                 throw formatError(e);
               }
             }),
           }))
  .actions( self => {
    const saveExperiment = flow(function* (experiment: IExperiment, andPublish: boolean) {

      try {
        const experimentDto = MapToDto(experiment);

        let vm: IExperimentVm;

        if (experiment.isNewExperiment())
          vm = yield self.apiGateway.saveExperiment(experiment.gameVersionId, experiment.gameEnvironmentId, experimentDto);

        else
          vm = yield self.apiGateway.updateExperiment(experiment.gameVersionId, experiment.gameEnvironmentId, experiment.id, experimentDto)

        if(andPublish)
          yield self.updateExperimentStatusById(vm.id, ExperimentStatus.Publish);

        return vm;

      } catch (e) {
        throw formatError(e);
      }
    })

    return {
      saveExperiment,
    }
  })

const MapToExperimentModel = (vm: IExperimentVm) => {
  return {
    id: vm.id,
    gameVersionId: vm.gameVersionId,
    gameEnvironmentId: vm.gameEnvironmentId,
    name: vm.name,
    description: vm.description,
    status: vm.status,
    comment: vm.comment,
    lowerBound: vm.lowerBound,
    upperBound: vm.upperBound,
    googleSheetId: vm.googleSheetId,
    variants: vm.variants.map(variant => {
      return {
        id: variant.id,
        name: variant.name,
        description: variant.description,
        allocation: variant.allocation
      }
    }),
    inAnyDistribution: vm.inAnyDistribution,
    inLatestDistribution: vm.inLatestDistribution,
    createdAt: vm.createdAt,
    originalAuthor: vm.originalAuthor,
    updatedAt: vm.updatedAt,
    lastAuthor: vm.lastAuthor,
    properties: vm.properties.map(property => {
      return {
        id: property.id,
        values: property.values.map(variantValueVm => {
          return {
            variantId: variantValueVm.variantId,
            value: variantValueVm.value
          }
        }),
        kindCategoryId: property.kindCategoryId,
        kindId: property.kindId,
        kindName: property.kindName,
        entityName: property.entityName,
        entityId: property.entityId,
        entityVersion: property.entityVersion,
        entityPropertyId: property.entityPropertyId,
        entityPropertyValue: property.entityPropertyValue,
        kindPropertyKindId: property.kindPropertyKindId,
        kindPropertyId: property.kindPropertyId,
        kindPropertyKey: property.kindPropertyKey,
        kindPropertyType: (KindPropertyType as any)[property.kindPropertyType],
        kindPropertySelectionId: property.kindPropertySelectionId,
        kindPropertyEntitySelectionKindId: property.kindPropertyEntitySelectionKindId,

      }
    }),
    specifications: vm.specifications.map(specification => {
      return {
        id: specification.id,
        kindPropertyId: specification.kindPropertyId,
        kindPropertyKindId: specification.kindPropertyKindId,
        kindPropertyKey: specification.kindPropertyKey,
        value: specification.value
      }
    })
  }
};

const MapToDto = (experiment: IExperiment): IExperimentDto => {
  return {
    name: experiment.name,
    description: experiment.description,
    comment: experiment.comment,
    lowerBound: experiment.lowerBound,
    upperBound: experiment.upperBound,
    googleSheetId: experiment.googleSheetId,
    variants: experiment.variants.map(variant => {
      return {
        id: variant.id,
        name: variant.name,
        description: variant.description,
        allocation: variant.allocation
      }
    }),
    properties: experiment.properties.map(property => {
      return {
        entityPropertyId: property.entityPropertyId,
        values: property.values.map(variantValue => {
          return {
            variantId: variantValue.variantId,
            value: variantValue.value
          }
        })
      }
    }),
    specifications: experiment.specifications.map(specification => {
      return {
        kindPropertyId: specification.kindPropertyId,
        value: specification.value
      }
    })
  }
};

export interface IExperimentStore extends Instance<typeof ExperimentStore> {}
