import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import RootStoreContext from '../model/RootStoreContext';
import { ISelection } from '../model/selections/Selection';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { useShowModal } from '@yakoffice/custom-modal';
import { NotificationType, usePublishNotification } from '@yakoffice/notification-handler';
import { IGameVersion } from '../model/gameVersion/GameVersion';
import Form from 'react-bootstrap/Form';
import { Alert } from 'react-bootstrap';
import { Checked } from '../presentation/views/common/Checked';

export interface ISelectionsController {
  copySelectionsPrompt: (checkedSelections: Checked<ISelection>[], checkedGameVersions: Checked<IGameVersion>[], handleCheckGameEnv: (e: any, gv: Checked<IGameVersion>) => void) => void,
  batchCopy: (selections: ISelection[], gameVersions: IGameVersion[], onSuccessCallback: () => void) => void,
  copySelections: (selections: ISelection[], gameVersions: IGameVersion[], onSuccessCallback: () => void) => void,
}

export const useSelectionsController = () => {

    // Context Hooks
    const showModal = useShowModal();
    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const copySelectionsPrompt = (
      checkedSelections: Checked<ISelection>[],
      checkedGameVersions: Checked<IGameVersion>[],
      handleCheckGameVersions: (e: boolean, gv: Checked<IGameVersion>) => void) => {

      const initiateBatchCopy = () => {

        if(checkedGameVersions.filter(gv => gv.checked).length !== 0)
          batchCopy(
            checkedSelections.filter(selection => selection.checked).map(_selection => _selection.value),
            checkedGameVersions.filter(gameVersion => gameVersion.checked).map(_gameVersion => _gameVersion.value)
          );
        else
          showModal(modal);
      };

      const modalBody = () => {
        return (
          <div>
            `Which game versions do you want to copy to?`

            {checkedGameVersions.filter((gv) => gv.value !== rootStore.gameVersionStore.currentGameVersion).map(gv => {
              return (
                <Form.Check type='checkbox' key={gv.value.id.toString()} id={gv.value.id.toString()} onChange={(event) => handleCheckGameVersions(event.target.checked, gv)} label={gv.value.name} data-testid={`copySelectionsToGameVersion-${gv.value.name}`} />
              );
            })}
          </div>
        );
      };

      const modal = {
        show:     true,
        title:    'Copy Selections',
        body:     modalBody(),
        action:   <Button variant='success' onClick={initiateBatchCopy} data-testId={'confirmCopy1'}>OK</Button>,
        canClose: true
      }

      showModal(modal);
    };

    const batchCopy = (selections: ISelection[], gameVersions: IGameVersion[]) => {

      const modalBody = () => {
        return (
          <div>
            Are you sure you want to copy these selections:
            <ul>
              {selections.map(s => <li key={s.id}>{s.name}</li>)}
            </ul>
            To the following game versions:
            <ul>
              {gameVersions.map(gv => <li key={gv.id}>{gv.name}</li>)}
            </ul>
            <Alert variant={'warning'} className={'mt-3'}><i className="fas fa-exclamation-triangle mr-1" /> If a selection of the same name exists in the target game version then it will be overwritten!</Alert>
          </div>
        );
      };

      showModal(
        {
          show:     true,
          title:    'Copy Selections',
          body:     modalBody(),
          action:   <Button variant='success' onClick={() => copySelections(selections, gameVersions)} data-testId={'confirmCopy2'}>OK</Button>,
          canClose: true
        }
      );
    };

    const copySelections = async (selections: ISelection[], gameVersions: IGameVersion[]) => {

      let count = 1;

      for (const selection of selections) {

        for (const gameVersion of gameVersions) {
          try {
            showModal(
              {
                show:     true,
                title:    'Copy Selections',
                body:     <CustomSpinner spinnerText={`Copying ${count++} of ${selections.length} selections`}
                                         position={'relative'} />,
                canClose: false
              }
            );

            await rootStore.selectionStore.copySelectionToGameVersion(selection, gameVersion.id);
          } catch (e: any) {
            publishNotification({
                                  notificationType: NotificationType.Error,
                                  title:            `Error copying selection ${selection.name}`,
                                  message:          e.message
                                });
          }
        }
      }

      showModal(
        {
          show:     true,
          title:    'Copy Selections',
          body:     <div>All done!</div>,
          canClose: true
        }
      );
    };

    const controller: ISelectionsController = {
      copySelectionsPrompt,
      batchCopy,
      copySelections
    };

    return controller;
  }
;
