import {flow, Instance, types} from "mobx-state-tree";
import {IProject, Project} from "./Project";
import {ProjectApiGateway} from "../../api/requests/projects/projectApiGateway";
import { IProjectVm } from '@yakoffice/publisher-types';

export const ProjectStore = types.model(
    "ProjectStore",
    {
        apiGateway: ProjectApiGateway,
        projects: types.array(Project),
        currentProject: types.maybeNull(types.reference(Project)),
        isLoading: false,
    })
    .views(self => ({
        getCurrentProject() : IProject{
            if(self.currentProject)
                return self.currentProject;

            throw new Error("The current project has not been set")
        }
    }))
    .actions(self => ({
        clearCurrentProject(){
          self.currentProject = null;
        },
        setCurrentProject(projectId : number){
            const selectedProject = self.projects.find(project => project.id === projectId);
            if(selectedProject)
                self.currentProject = selectedProject;
            else
                throw new Error(`Project with id ${projectId} does not exist`)
        },
        loadProjects : flow(function*() {
            self.isLoading = true;

            // To prevent an invalid reference
            self.currentProject = null;

            try {
                const projectVms = yield self.apiGateway.getProjects();

                const projects = projectVms.map((projectVm : IProjectVm) => Project.create(MapToKindModel(projectVm)))
                self.projects.replace(projects);
                self.isLoading = false;
            } catch (e:any) {
                throw new Error(`Failed to load projects: ${e.message}`);
            }})
    }));

const MapToKindModel = (vm: IProjectVm) => {
    return {
        id: vm.id,
        name: vm.name,
        imageUrl: vm.imageUrl,
        createdAt: vm.createdAt,
        originalAuthor: vm.originalAuthor,
        updatedAt: vm.updatedAt,
        lastAuthor: vm.lastAuthor
    }
};

export interface IProjectStore extends Instance<typeof ProjectStore> {}
