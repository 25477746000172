import {
  MouseEventHandler,
  MouseEvent,
  useCallback,
  useRef
} from 'react';

export const useDoubleClick = <T = Element>(doubleClick : MouseEventHandler<T>, click: MouseEventHandler<T> = () => {return}, timeout = 500) => {

  const clickTimeout = useRef<number | null>();

  return useCallback((event : MouseEvent<T>) => {
    if (clickTimeout.current != null) {
      window.clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }

    if (click && event.detail === 1)
      clickTimeout.current = window.setTimeout(() => {click(event)}, timeout);

    if (event.detail % 2 === 0)
      doubleClick(event);

  }, [doubleClick, click, timeout]);
};
