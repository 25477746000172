import React, {FC} from 'react';
import {PropertyValueDisplay} from './PropertyValueDisplay';
import type {IEntityProperty} from "../../../model/entity/EntityProperty";
import type {IKindVersion} from "../../../model/kind/KindVersion";
import {formatISODateString} from "./Utils";
import {SpecificationPropertiesFormatter} from './SpecificationPropertiesFormatter';
import { Link } from 'react-router-dom';
import { IEntityVersion } from '../../../model/entity/EntityVersion';

interface PropTypes{
    entityProperties: IEntityProperty[],
    specificationKindVersions: IKindVersion[],
    entityLink?: (entityVersion: IEntityVersion) => string,
    entityVersion?: IEntityVersion,
}

export const SpecificationProperties : FC<PropTypes> = ({entityProperties, specificationKindVersions, entityLink, entityVersion}) => {

    const renderProperty = (entityProperty : IEntityProperty) => {
        const specificationKindVersion = specificationKindVersions.find(sk => sk.kind.id === entityProperty.kindPropertyKindId);
        const specificationProperty = specificationKindVersion?.properties.find(sp => sp.key === entityProperty.kindPropertyKey);

         return <div key={entityProperty.kindPropertyId} data-testid={`specification${entityProperty.kindPropertyKey}`}>
                <span>{entityProperty.kindPropertyKey}: </span>
                {specificationProperty
                ? <PropertyValueDisplay kindProperty={specificationProperty} value={entityProperty.value}/>
                : formatISODateString(entityProperty.value) || SpecificationPropertiesFormatter(entityProperty)
                }
            </div>
        }

    return entityProperties.length > 0
        ? <Link to={entityLink && entityVersion ? entityLink(entityVersion) : '#'}>{entityProperties.map(ep => renderProperty(ep))}</Link>
        : null
}
