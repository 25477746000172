import { Instance, types } from 'mobx-state-tree';
import {GenerateIdString } from '../Utils';

export const kindVersionProperties = {
  id: types.optional(types.identifier, GenerateIdString),
  version: types.number,
  name: '',
  canUseSpecifications: false,
  isSpecificationForKinds: false,
};

export const KindVersionSummary = types.model(
  "KindVersionSummary",
  {
    ...kindVersionProperties,
    kindId: types.number,
    kindStatus: types.string,
    kindCategoryId: types.number,
    kindVersionCurrent: types.number,
    kindHexColour: types.maybeNull(types.string),
  }
)

export interface IKindVersionSummary extends Instance<typeof KindVersionSummary> {}
