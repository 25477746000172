// INTERFACES

import { useRootStore } from '../../loaders/useRootStore';
import { DistributionId } from '@yakoffice/publisher-types';

export interface IMatchParams {
  projectId: string;
  gameVersionId: string;
  gameEnv: string;
  selectionId: string;
  categoryId: string;
  kindId: string;
  entityId: string;
  versionId: string;
}

export enum PubRouteItems {
  Projects                  = 'projects',
  ProjectIdParam            = ':projectId',

  GameVersions              = 'versions',
  AddGameVersion            = 'add-game-version',
  CurrentGameVersion        = 'current',
  GameVersionIdParam        = ':gameVersionId',

  GameEnvironments          = 'game-environments',
  AddGameEnvironment        = 'add-game-environment',
  GameEnvironmentIdParam    = ':gameEnv',

  Categories                = 'categories',
  AddCategory               = 'add-category',
  AddSubCategory            = 'add-sub-category',
  CategoryIdParam           = ':categoryId',

  Kinds                       = 'kinds',
  AddKind                     = 'add-kind',
  KindIdParam                 = ':kindId',

  Entities                    = 'entities',
  AddEntity                   = 'add-entity',
  EntityIdParam               = ':entityId',
  EntityVersions              = 'versions',
  EntityVersionIdParam        = ':versionId',


  Experiments                 = 'experiments',
  AddExperiment               = 'add-experiment',
  ExperimentIdParam           = ':experimentId',

  Distributions               = 'distributions',
  CurrentDistribution         = 'current',
  DistributionIdParam         = ':distributionId',
  DistributionExperiments     = 'experiments',
  Differences                 = 'differences',
  DiffGameVersionIdParam      = ':diffGameVersionId',
  DiffGameEnvironmentIdParam  = ':diffGameEnv',
  DiffDistributionIdParam     = ':diffDistributionId',

  Selections                  = 'selections',
  AddSelection                = 'add-selection',
  SelectionIdParam            = ':selectionId',

  Calendar                  = 'calendar',
}
export enum PubRoutes {
  PROJECT = '/projects/:projectId',

  GAME_VERSIONS = '/projects/:projectId/versions',
  ADD_GAME_VERSION = '/projects/:projectId/versions/add-game-version',
  GAME_VERSION_CURRENT = '/projects/:projectId/versions/current',
  GAME_VERSION = '/projects/:projectId/versions/:gameVersionId',

  GAME_ENVIRONMENTS = '/projects/:projectId/game-environments',
  ADD_GAME_ENVIRONMENT = '/projects/:projectId/game-environments/add-game-environment',
  GAME_ENVIRONMENT = '/projects/:projectId/game-environments/:gameEnv',

  CATEGORIES = '/projects/:projectId/versions/:gameVersionId/categories',
  ADD_CATEGORY = '/projects/:projectId/versions/:gameVersionId/categories/add-category',
  CATEGORY = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId',
  ADD_SUB_CATEGORY = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/add-sub-category',

  KINDS = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds',
  ADD_KIND = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/add-kind',
  KIND = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/:kindId',
  KIND_GAME_ENVIRONMENT = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/:kindId/game-environments/:gameEnv',

  ENTITIES = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/:kindId/game-environments/:gameEnv/entities',
  ADD_ENTITY = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/:kindId/game-environments/:gameEnv/entities/add-entity',
  ENTITY = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/:kindId/game-environments/:gameEnv/entities/:entityId',
  ENTITY_VERSION = '/projects/:projectId/versions/:gameVersionId/categories/:categoryId/kinds/:kindId/game-environments/:gameEnv/entities/:entityId/versions/:versionId',

  EXPERIMENTS = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/experiments',
  ADD_EXPERIMENT = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/experiments/add-experiment',
  EXPERIMENT = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/experiments/:experimentId',

  DISTRIBUTIONS = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/distributions',
  DISTRIBUTION_CURRENT = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/distributions/current',
  DISTRIBUTION = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/distributions/:distributionId',
  DISTRIBUTION_EXPERIMENTS = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/distributions/:distributionId/experiments',
  DISTRIBUTION_DIFF = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/distributions/:distributionId/differences/versions/:diffGameVersionId/game-environments/:diffGameEnv/distributions/:diffDistributionId',

  SELECTIONS = '/projects/:projectId/versions/:gameVersionId/selections',
  ADD_SELECTION = '/projects/:projectId/versions/:gameVersionId/selections/add-selection',
  SELECTION = '/projects/:projectId/versions/:gameVersionId/selections/:selectionId',

  CALENDAR = '/projects/:projectId/versions/:gameVersionId/game-environments/:gameEnv/calendar',
}

//Projects
export const useGetProjectLink = () => {
  const rootStore = useRootStore();
  return (overrides?: {projectId?: number}) => {
    const linkProjectId = overrides?.projectId ?? rootStore.projectStore.getCurrentProject().id;
    return `/projects/${linkProjectId}`
  }
}

//Game Versions
export const useGetGameVersionsLink = () => {
  const getProjectLink = useGetProjectLink();
  return (overrides?: {projectId?: number}) => {
    return `${getProjectLink({projectId: overrides?.projectId})}/versions`;
  }
}
export const useAddGameVersionLink = () => {
  const getGameVersionsLink = useGetGameVersionsLink();
  return `${getGameVersionsLink()}/add-game-version`
}
export const useGetGameVersionLink = () => {
  const rootStore = useRootStore();
  const getGameVersionsLink = useGetGameVersionsLink();
  return (overrides?: {gameVersionId?: number}) => {
    const linkGameVersionId = overrides?.gameVersionId ?? rootStore.gameVersionStore.currentGameVersion?.id ?? "current";
    return `${getGameVersionsLink()}/${linkGameVersionId}`;
  }
}

//Game Environments
export const useGetGameEnvironmentsLink = () => {
  const getProjectLink = useGetProjectLink();
  return (overrides?: {projectId?: number}) => {
    return `${getProjectLink({projectId: overrides?.projectId})}/game-environments`;
  }
}
export const useAddGameEnvironmentLink = () => {
  const getGameEnvironmentsLink = useGetGameEnvironmentsLink();
  return `${getGameEnvironmentsLink()}/add-game-environment`
}
export const useGetGameEnvironmentLink = () => {
  const rootStore = useRootStore();
  const getGameEnvironmentsLink = useGetGameEnvironmentsLink();
  return (overrides?: {gameEnvironmentId?: number}) => {
    const linkGameEnvironmentId = overrides?.gameEnvironmentId ?? rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id;
    return `${getGameEnvironmentsLink()}/${linkGameEnvironmentId}`;
  }
}

//Selections
export const useGetSelectionsLink = () => {
  const getGameVersionLink = useGetGameVersionLink();
  return (overrides?: { gameVersionId?: number }) => {
    return `${getGameVersionLink(overrides)}/selections`;
  }
}
export const useGetAddSelectionLink = () => {
  const getSelectionsLink = useGetSelectionsLink();
  return () => `${getSelectionsLink()}/add-selection`;
}
export const useGetSelectionLink = () => {
  const rootStore = useRootStore();
  const getSelectionsLink = useGetSelectionsLink();
  return (overrides?: {selectionId?: number}) => {
    const linkSelectionId = overrides?.selectionId ?? rootStore.selectionStore.getCurrentSelection().id;
    return `${getSelectionsLink()}/${linkSelectionId}`;
  }
}

//Experiments
export const useGetExperimentsLink = () => {
  const rootStore = useRootStore();
  const getGameVersionLink = useGetGameVersionLink();
  return (overrides?: {gameVersionId?: number, gameEnvironmentId?: number}) => {
    const linkGameEnvironmentId = overrides?.gameEnvironmentId ?? rootStore.gameEnvironmentStore.currentGameEnvironment?.id ?? "current";
    return `${getGameVersionLink(overrides)}/game-environments/${linkGameEnvironmentId}/experiments`;
  }
}
export const useAddExperimentLink = () => {
  const getExperimentsLink = useGetExperimentsLink();
  return `${getExperimentsLink()}/add-experiment`
}
export const useGetExperimentLink = () => {
  const rootStore = useRootStore();
  const getGameVersionLink = useGetGameVersionLink();
  return (overrides?: {experimentId: number}) => {
    const linkGameEnvironmentId = rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id;
    const linkExperimentId = overrides?.experimentId ?? rootStore.experimentStore.getCurrentExperiment().id;
    return `${getGameVersionLink()}/game-environments/${linkGameEnvironmentId}/experiments/${linkExperimentId}`;
  }
}

//Distributions
export const useGetDistributionsLink = () => {
  const rootStore = useRootStore();
  const getGameVersionLink = useGetGameVersionLink();
  return (overrides?: { gameVersionId?: number, gameEnvironmentId?: number }) => {
    const linkGameEnvironmentId = overrides?.gameEnvironmentId ?? rootStore.gameEnvironmentStore.currentGameEnvironment?.id ?? "current";
    return `${getGameVersionLink(overrides)}/game-environments/${linkGameEnvironmentId}/distributions`;
  }
}
export const useGetDistributionPreviewLink = () => {
  const getDistributionsLink = useGetDistributionsLink();
  return (overrides?: { gameVersionId?: number, gameEnvironmentId?: number }) => {
    return `${getDistributionsLink(overrides)}/current`;
  }
}

export const useGetDistributionLink = () => {
  const getDistributionsLink = useGetDistributionsLink();
  return (overrides: { gameVersionId?: number, gameEnvironmentId?: number, distributionId: DistributionId}) => {
        return `${getDistributionsLink(overrides)}/${overrides.distributionId}`;
  }
}

export const useGetDistributionExperimentsLink = () => {
  const getDistributionLink = useGetDistributionLink();
  return (overrides: { gameVersionId?: number, gameEnvironmentId?: number, distributionId: DistributionId}) => {
    return `${getDistributionLink(overrides)}/experiments`;
  }
}

export const useGetDistributionDiffLink = () => {
  const getDistributionLink = useGetDistributionLink();
  return (overrides: { gameVersionId?: number, gameEnvironmentId?: number, distributionId: DistributionId, diffGameVersionId: number, diffGameEnvironmentId: number, diffDistributionId: DistributionId }) => {
    return `${getDistributionLink({...overrides, distributionId: overrides.distributionId })}/differences/versions/${overrides.diffGameVersionId}/game-environments/${overrides.diffGameEnvironmentId}/distributions/${overrides.diffDistributionId}`;
  }
}

//Calendar
export const useGetCalendarLink = () => {
  const rootStore = useRootStore();
  const getGameVersionLink = useGetGameVersionLink();
  return (overrides?: { gameVersionId?: number, gameEnvironmentId?: number }) => {
    const linkGameEnvironmentId = overrides?.gameEnvironmentId ?? rootStore.gameEnvironmentStore.currentGameEnvironment?.id ?? "current";
    return `${getGameVersionLink(overrides)}/game-environments/${linkGameEnvironmentId}/calendar`;
  }
}

//Categories
export const useGetCategoriesLink = () => {
  const getGameVersionsLink = useGetGameVersionLink();
  return (overrides?: {gameVersionId?: number}) => {
    return `${getGameVersionsLink(overrides)}/categories`;
  }
}
export const useAddCategoryLink = () => {
  const getCategoriesLink = useGetCategoriesLink()
  return `${getCategoriesLink()}/add-category`
}
export const useGetAddSubCategoryLink = () => {
  const rootStore = useRootStore();
  const getCategoriesLink = useGetCategoriesLink()
  return () => {
    const parentCategoryId = rootStore.categoryStore.getCurrentCategory().id
    return `${getCategoriesLink()}/${parentCategoryId}/add-sub-category`
  }
}
export const useGetCategoryLink = () => {
  const rootStore = useRootStore();
  const getCategoriesLink = useGetCategoriesLink()
  return (overrides?: {gameVersionId?: number, categoryId?: number}) => {
    const linkCategoryId = overrides?.categoryId ?? rootStore.categoryStore.getCurrentCategory().id;
    return `${getCategoriesLink(overrides)}/${linkCategoryId}`
  }
}


//Kinds
export const useGetKindsLink = () => {
  const getCategoryLink = useGetCategoryLink();
  return (overrides?: {projectId?: number, gameVersionId?: number, categoryId?: number}) => {
    return `${getCategoryLink(overrides)}/kinds`
  }
}
export const useAddKindLink = () => {
  const getKindsLink = useGetKindsLink();
  return `${getKindsLink()}/add-kind`
}
export const useGetKindLink = () => {
  const rootStore = useRootStore();
  const getKindsLink = useGetKindsLink();
  return (overrides?: {gameVersionId?: number, categoryId?: number, kindId?: number}) => {
    const linkKindId = overrides?.kindId ?? rootStore.kindVersionStore.getCurrentKindVersion().kind.id;
    return `${getKindsLink(overrides)}/${linkKindId}`
  }
}

//Entities
export const useGetEntitiesLink = () => {
  const rootStore = useRootStore();
  const getKindLink = useGetKindLink();
  return (overrides?: {gameVersionId?: number, categoryId?: number, kindId?: number, gameEnvironmentId?: number}) => {
    const linkGameEnvironmentId = overrides?.gameEnvironmentId ?? rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id;
    return `${getKindLink(overrides)}/game-environments/${linkGameEnvironmentId}/entities`
  }
}
export const useAddEntityLink = () => {
  const getEntitiesLink = useGetEntitiesLink();
  return `${getEntitiesLink()}/add-entity`
}
export const useGetEntityLink = () => {
  const rootStore = useRootStore();
  const getEntitiesLink = useGetEntitiesLink()
  return (overrides?: {gameVersionId?: number, categoryId?: number, kindId?: number, gameEnvironmentId?: number, entityId?: number}) => {
    const linkEntityId = overrides?.entityId ?? rootStore.entityVersionStore.getCurrentEntityVersion().entity.id;
    return `${getEntitiesLink(overrides)}/${linkEntityId}`
  }
}

export const useGetEntityVersionLink = () => {
  const getEntityLink = useGetEntityLink()
  return (overrides: {categoryId?: number, kindId?: number, gameEnvironmentId?: number, entityId?: number, version: number}) => {
    return `${getEntityLink(overrides)}/versions/${overrides.version}`
  }
}


// For Testing Only
export function link_CATEGORY (project: number | string, gameVersionId: number | string, categoryId: number | string) {
  return `/projects/${project}/versions/${gameVersionId}/categories/${categoryId}`
}
export function link_CATEGORIES (project: number | string, gameVersionId: number | string) {
  return `/projects/${project}/versions/${gameVersionId}/categories`;
}
export function link_KINDS (project: number | string, gameVersionId: number | string, categoryId: number | string) {
  return `/projects/${project}/versions/${gameVersionId}/categories/${categoryId}/kinds`
}
export function link_KIND (project: number | string, gameVersionId: number | string, categoryId: number | string, kindId: number | string) {
  return `/projects/${project}/versions/${gameVersionId}/categories/${categoryId}/kinds/${kindId}`
}
export function link_ENTITIES (project: number | string, gameVersionId: number | string, categoryId: number | string, kindId: number | string, gameEnv: string | number) {
  return `/projects/${project}/versions/${gameVersionId}/categories/${categoryId}/kinds/${kindId}/game-environments/${gameEnv}/entities`
}
export function link_SELECTIONS (project: number | string, gameVersionId: number | string) {
  return `/projects/${project}/versions/${gameVersionId}/selections`
}
export function link_ADD_ENTITY (project: number | string, gameVersionId: number | string, categoryId: number | string, kindId: number | string, gameEnv: string | number) {
    return `/projects/${project}/versions/${gameVersionId}/categories/${categoryId}/kinds/${kindId}/${gameEnv}/entities/add-entity`
}
