import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import { useDistribution } from './useDistribution';
import { DistributionExperiments } from './DistributionExperiments';

export const DistributionExperimentsContainer : FC = observer(() => {

  const distributionResult = useDistribution()

  return distributionResult.isLoaded && distributionResult.distribution != null
    ? <DistributionExperiments distribution={distributionResult.distribution} />
    : <CustomSpinner spinnerText={'Loading distribution experiments...'} />

})
