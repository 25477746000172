import React, { FC, useState }                  from 'react';
import { observer }                             from 'mobx-react-lite';
import Row                                      from 'react-bootstrap/Row';
import Col                                      from 'react-bootstrap/Col';
import { InfoBox, InfoBoxHeader, InfoBoxBody }  from '@yakoffice/info-box';
import type {IKindVersion}                           from "../../../model/kind/KindVersion";
import { formatISODateStringAsLocal }           from '../common/Utils';
import { CollapsePanel }                        from '@yakoffice/shared-components';
import { AbbreviatedString }                    from '../../components/abbreviated-string/AbbreviatedString';


interface PropsType {
    kindVersion : IKindVersion,
}


export const KindHighlights : FC<PropsType> = observer((props) => {

  // Cache comment so that it is not updated when the comment is changed duriung saving
  const [comment] = useState(props.kindVersion.comment);

  return <CollapsePanel>

    <Row>
      <Col sm="4" md="3">
        <InfoBox>
          <InfoBoxHeader><i className="far fa-calendar-alt" /> Last updated</InfoBoxHeader>
          <InfoBoxBody>{formatISODateStringAsLocal(props.kindVersion.updatedAt)}</InfoBoxBody>
        </InfoBox>
      </Col>
      <Col sm="4" md="3">
        <InfoBox>
          <InfoBoxHeader><i className="far fa-user" /> Updated by</InfoBoxHeader>
          <InfoBoxBody>{props.kindVersion.updatedBy}</InfoBoxBody>
        </InfoBox>
      </Col>
      <Col sm="4" md="6">
        <InfoBox>
          <InfoBoxHeader><i className="far fa-comment" /> Last comment</InfoBoxHeader>
          <InfoBoxBody>"<AbbreviatedString value={comment} lengthToAbbreviate={70}/>"</InfoBoxBody>
        </InfoBox>
      </Col>
    </Row>
  </CollapsePanel>
});
