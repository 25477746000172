import React, { FC } from 'react';
import { createSliderWithTooltip, Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { RangeProps } from 'rc-slider/lib/Range';

const RangeWithToolTip = createSliderWithTooltip(Range);

export interface TakenRange{
  lower: number
  upper: number
}

export interface PropTypes {
  ranges: TakenRange[]
}

export const TakenUserRanges : FC<PropTypes> = ({ ranges}) => {

  const rangeProps : RangeProps = {}
  rangeProps.trackStyle = [];
  const values = [];
  for (const range of ranges) {
    rangeProps.marks = {...rangeProps.marks, [range.lower]: range.lower, [range.upper]: range.upper}
    rangeProps.trackStyle = [...rangeProps.trackStyle, { backgroundColor: 'red' }, { backgroundColor: 'transparent' }]
    values.push(range.lower, range.upper)
  }
  rangeProps.marks = {0:0, ...rangeProps.marks, 1:1};

  return <RangeWithToolTip
    min={0}
    max={1}
    step={0.01}
    count={1}
    included={true}
    value={values}
    trackStyle={rangeProps.trackStyle}
    marks={rangeProps.marks}
    disabled={true}
  />
}
