import React, {FC}                            from "react";
import {observer}                             from "mobx-react-lite";
import {useSetGameEnvironmentFromParam} from "../common/useSetGameEnvironmentFromParam";
import {EntityCalendar}                       from './EntityCalendar';

export const EntityCalendarContainer : FC = observer(() => {

  useSetGameEnvironmentFromParam();

  return <EntityCalendar />
})
