import React, {FC, useContext} from "react";
import {Link} from "react-router-dom";
import {ICategory} from "../../../model/category/Category";
import {IKindVersion} from "../../../model/kind/KindVersion";
import { observer } from "mobx-react-lite";
import {ClaimType} from "@yakoffice/publisher-types";
import RootStoreContext from "../../../model/RootStoreContext";
import { useGetCategoriesLink, useGetCategoryLink, useGetEntitiesLink, useGetKindLink, useGetKindsLink } from '../../routes';

interface PropsType {
    category : ICategory
    kindVersion : IKindVersion;
}

export const EntityHeaderLinks : FC<PropsType> = observer((props) => {

    const rootStore = useContext(RootStoreContext);
    const getCategoriesLink = useGetCategoriesLink()
    const getCategoryLink = useGetCategoryLink()
    const getKindsLink = useGetKindsLink()
    const getKindLink = useGetKindLink()
    const getEntitiesLink = useGetEntitiesLink()

    return <React.Fragment>
            <Link to={getCategoriesLink()}>Categories</Link>
            {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory)
                ?<Link to={getCategoryLink()}>{props.category.name}</Link>
                :<span>{props.category.name} / </span>
            }
            <Link to={getKindsLink()}>Kinds</Link>
            {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind)
                ?<Link to={getKindLink()}>{props.kindVersion.name}</Link>
                :<span>{props.kindVersion.name} / </span>
            }
            <Link to={getEntitiesLink()}>Entities</Link>
        </React.Fragment>
    ;
})
