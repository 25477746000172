import React, {ChangeEvent, FC} from 'react';
import {observer} from 'mobx-react-lite';
import Form from "react-bootstrap/Form";

interface PropTypes{
    pageSize: number
    handleSetPageSize: (pageNumber: number) => void
}

export const YakofficePageSize : FC<PropTypes> = observer(({pageSize, handleSetPageSize}) => {

    const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        handleSetPageSize(parseInt(e.target.value))
    }

    return (
        <Form.Select size="sm" className="w-auto" id="pageSize" value={pageSize} onChange={handleSelectionChange}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
        </Form.Select>
    )
})

