import React, {FC, useContext} from "react";
import {observer} from "mobx-react-lite";
import {IKindProperty} from "../../../model/kind/KindProperty";
import {KindPropertyType} from "@yakoffice/publisher-types";
import RootStoreContext from "../../../model/RootStoreContext";
import Form from "react-bootstrap/Form";
import {formatISODateString} from "../common/Utils";

interface PropsType {
    kindProperty : IKindProperty;
    getPropertyValue: () => string | null;
}

export const ReadonlyEntityPropertyFactory : FC<PropsType> = observer((props) => {
  const rootStore = useContext(RootStoreContext);

  const formatPropertyValue = (value: string | null) => value != null ? value : "";

  let value = "";
  let formattedDate:string|undefined = undefined;

  switch (props.kindProperty.type) {
    case KindPropertyType.Boolean:
    case KindPropertyType.String:
    case KindPropertyType.Integer:
    case KindPropertyType.Decimal:
    case KindPropertyType.Hexadecimal:
      value = formatPropertyValue(props.getPropertyValue());
      break;
    case KindPropertyType.DateTime:
      formattedDate = formatISODateString(formatPropertyValue(props.getPropertyValue()));
      if (formattedDate != null)
        value = formattedDate;
      break;
    case KindPropertyType.Selection: {
      const selection = rootStore.selectionStore.selections.find(s => s.id === props.kindProperty.selectionId)
      if (selection) {
        const option = selection.options.find(so => so.value === props.getPropertyValue())
        if (option) {
          value = option.name;
          break;
        }
      }
      value = formatPropertyValue(props.getPropertyValue());
      break;
    }
    case KindPropertyType.EntitySelection: {
      const selection = rootStore.entitySelectionStore.getSelections().find(s => s.id === props.kindProperty.entitySelectionKindId);
      if (selection) {
        const option = selection.options.find(so => so.value === props.getPropertyValue())
        if (option) {
          value = option.name;
          break;
        }
      }
      value = formatPropertyValue(props.getPropertyValue());
      break;
    }
    default:
      value = "";
  }

  return <Form.Control type="text" id={props.kindProperty.id.toString()} value={value} disabled={true}/>;
});
