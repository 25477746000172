import Button from "react-bootstrap/Button";
import {GameVersionSelector} from "../common/GameVersionSelector";
import {ButtonGroup} from "react-bootstrap";
import {GameEnvironmentSelector} from "../common/GameEnvironmentSelector";
import React, {FC, useContext, useEffect, useState} from "react";
import {IDistribution} from "../../../model/distributions/Distribution";
import RootStoreContext from "../../../model/RootStoreContext";
import {useNavigate} from "react-router";
import {IGameVersion} from "../../../model/gameVersion/GameVersion";
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import {useGetDistributionDiffLink} from "../../routes";
import {observer} from "mobx-react-lite";

interface PropsType {
  checkedDistributions : IDistribution[]
  handleComparePublishedWithLastDistribution : (diffGameVersion: IGameVersion, diffGameEnvironment: IGameEnvironment, rightDiff: boolean) => void
}

export const CompareDistributionsButton : FC<PropsType> = observer(({checkedDistributions, handleComparePublishedWithLastDistribution}) => {

  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const getDistributionDiffLink = useGetDistributionDiffLink()
  const [rightDiff, setRightDiff] = useState(true);
  const [diffGameVersion, setDiffGameVersion] = useState(rootStore.gameVersionStore.getCurrentGameVersion())
  const [diffGameEnvironment, setDiffGameEnvironment] = useState(rootStore.gameEnvironmentStore.getCurrentGameEnvironment())

  useEffect(() => {
    setDiffGameEnvironment(rootStore.gameEnvironmentStore.getCurrentGameEnvironment())
  },[rootStore.gameEnvironmentStore, rootStore.gameEnvironmentStore.currentGameEnvironment])

  const handleCompare = () => {
    const currentGameVersion = rootStore.gameVersionStore.getCurrentGameVersion();
    const currentGameEnvironment = rootStore.gameEnvironmentStore.getCurrentGameEnvironment();

    if(checkedDistributions.length === 0)
      handleComparePublishedWithLastDistribution(diffGameVersion, diffGameEnvironment, rightDiff);
    else if(checkedDistributions.length === 1)
      navigate(getDistributionDiffLink({
        gameVersionId: rightDiff ? currentGameVersion.id : diffGameVersion.id,
        gameEnvironmentId: rightDiff ? currentGameEnvironment.id : diffGameEnvironment.id,
        distributionId: rightDiff ? checkedDistributions[0].id : "published",
        diffGameVersionId: rightDiff ? diffGameVersion.id : currentGameVersion.id,
        diffGameEnvironmentId: rightDiff ? diffGameEnvironment.id : currentGameEnvironment.id,
        diffDistributionId: rightDiff ? 'published' : checkedDistributions[0].id
      }));
    else if(checkedDistributions.length > 1)
      navigate(getDistributionDiffLink({
        gameVersionId: currentGameVersion.id,
        gameEnvironmentId: currentGameEnvironment.id,
        distributionId: (rightDiff ? checkedDistributions[0]: checkedDistributions[1]).id,
        diffGameVersionId: currentGameVersion.id,
        diffGameEnvironmentId: currentGameEnvironment.id,
        diffDistributionId: (rightDiff? checkedDistributions[1]: checkedDistributions[0]).id
      }));
  }

  return <ButtonGroup>
    <Button variant="outline-primary" onClick={() => setRightDiff(previous => !previous)}>
      {checkedDistributions.length === 0 && "Published "}
      {checkedDistributions.length > 0 && `${checkedDistributions[0].id} `}
      <i className={`fas fa-angle-double-${rightDiff? "right" : "left"}`}/>
      {checkedDistributions.length === 0 && " Latest in"}
      {checkedDistributions.length === 1 && " Published in"}
      {checkedDistributions.length === 2 && ` ${checkedDistributions[1].id}`}
    </Button>
    <GameVersionSelector
      as={ButtonGroup}
      selectedGameVersion={diffGameVersion}
      handleGameVersionChange={gv => setDiffGameVersion(gv)}
      hidden={checkedDistributions.length === 2}
      data-testid="ddlCompareGv"
    />
    <GameEnvironmentSelector
      as={ButtonGroup}
      className="ms-0"
      selectedGameEnvironment={diffGameEnvironment}
      handleGameEnvironmentChange={ge => setDiffGameEnvironment(ge)}
      hidden={checkedDistributions.length === 2}
      data-testid="ddlCompareGe"
    />
    <Button variant="outline-primary" onClick={handleCompare} data-testid="btnCompare"><i className="fas fa-exchange-alt" /> Compare</Button>
  </ButtonGroup>
})
