// @ts-ignore: Don't know why
// import $ from 'jquery';
// @ts-ignore: Don't know why
import moment from 'moment';

export function equalsIgnoreCase(left: string, right: string){
  if(left && right)
    return left.toLowerCase().localeCompare(right.toLowerCase()) === 0;

  return left === right;
}

export function camelToTitle(camelCase: string) {
  return camelCase
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim()
}

export function thousandsSeparators(num: number) {

  const numParts   = num.toString().split(".");
  numParts[0]    = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return numParts.join(".");
}

export function compareDateToNow(date: string, local: boolean) {

  const now = moment.utc();

  if(local) {
    const utcOffset = moment().utcOffset();
    now.add(utcOffset, 'minutes');
  }

  const nowParsed   = Date.parse(now.format());
  const dateParsed  = Date.parse(date);

  return nowParsed > dateParsed;
}

export function orderArray(valueA: string | number, valueB: string | number, descending: boolean) {

  if((typeof valueA === 'string') && (typeof valueB === 'string')) {
    if(!!valueA.split(/(\d+)/)[1] && !!valueB.split(/(\d+)/)[1]) {

      if(descending)
        return -valueA.split(/(\d+)/)[1] - -valueB.split(/(\d+)/)[1]
      else
        return +valueA.split(/(\d+)/)[1] - +valueB.split(/(\d+)/)[1]
    }
  }

  if(descending)
    return (valueA < valueB) ? 1 : -1;
  else
    return (valueA > valueB) ? 1 : -1;
}

export function naturalSort(a: any, b: any, descending: boolean) {

  if(!descending)
    return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
  else
    return b.localeCompare(a, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})

}


// export function dynamicSort(property: any) {
//
//     let sortOrder = 1;
//
//     if(property[0] === "-") {
//         sortOrder = -1;
//         property = property.substr(1);
//     }
//
//     return function (a: any, b: any) {
//
//         let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//
//         return result * sortOrder;
//     }
// }
//
// export function clone(obj: any) {
//
//     if (null == obj || "object" != typeof obj) return obj;
//
//     let copy = obj.constructor();
//
//     for (let attr in obj) {
//
//         if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
//     }
//
//     return copy;
// }
//
//
// export function showModal(id: string) {
//     // @ts-ignore
//     $(`#${id}`).modal("show");
// }
//
// export function closeAlert(id: string) {
//     // @ts-ignore
//     $(`#${id}`).alert('close')
// }
//
// export function toast() {
//     // @ts-ignore
//     $('.toast').toast({
//                           'autohide': false
//                       });
// }
//
// export function activeTab(id: string) {
//
//     // @ts-ignore
//     $(`#${id}`).tab('show')
// }
//
// export function hideToast(target: any) {
//     // @ts-ignore
//     $(`#${target}`).toast('hide');
//     const a = document.getElementById(target);
//     if(a)
//         a.classList.add('d-none');
// }
//
// export function sortObjectByKey(object: any) {
//
//         const ordered: any = {};
//
//         Object.keys(object).sort().forEach(function(key) {
//             ordered[key] = object[key];
//         });
//
//         return ordered;
// }
//
//
//
//
//
// export function moveArrayItem(arr: any[], old_index: number, new_index: number) {
//     while (old_index < 0) {
//         old_index += arr.length;
//     }
//
//     while (new_index < 0) {
//         new_index += arr.length;
//     }
//
//     if (new_index >= arr.length) {
//         let k = new_index - arr.length;
//
//         while ((k--) + 1) {
//             arr.push(undefined);
//         }
//     }
//
//     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
//
//     return arr;
// }
//
// export function deviceWidthMobile(): boolean {
//     let width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
//     return width < 768;
// }
//
//
