import { useContext, useEffect, useState } from 'react';
import {autorun}                                from "mobx";
import RootStoreContext                         from "../model/RootStoreContext";
import type {IGameEnvironment}                       from '../model/gameEnvironment/GameEnvironment';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";

interface useGameEnvironmentResult{
    isLoaded        : boolean;
    gameEnvironment : IGameEnvironment | null
}

export const useGameEnvironment = () => {

    const rootStore             = useContext(RootStoreContext);
    const publishNotification   = usePublishNotification();
    const {gameEnv} = useParams<{gameEnv: string}>();

    const [result, setResult] = useState<useGameEnvironmentResult>({
      isLoaded: false,
      gameEnvironment: null})

    useEffect(() => {
        autorun(() => {
            setResult({isLoaded:false, gameEnvironment: null})

            if(gameEnv) {
                rootStore.gameEnvironmentStore.getGameEnvironment(parseInt(gameEnv))
                    .then((gameEnvironment) => {
                      setResult({isLoaded:true, gameEnvironment: gameEnvironment})
                    })
                    .catch(e => publishNotification({
                        notificationType: NotificationType.Error,
                        title:            "Error loading game environment",
                        message:          `${e.message}`,
                        footer:           "Please try refreshing"
                    }))
            } else {
              setResult({isLoaded:true, gameEnvironment: rootStore.gameEnvironmentStore.addGameEnvironment()})
            }
        })
    }, [rootStore.gameEnvironmentStore, publishNotification, gameEnv]);

    return result;
};
