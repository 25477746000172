import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {IEntityVersion} from '../model/entity/EntityVersion';

interface useEntitiesResult{
    isLoaded : boolean;
    entityVersions : IEntityVersion[]
}

export const useEntityVersions = (refreshToggle: boolean) => {

  const rootStore = useContext(RootStoreContext)
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useEntitiesResult>({isLoaded: false, entityVersions: []})

  useEffect(() => {
    if (rootStore.categoryStore.currentCategory != null && rootStore.kindVersionStore.currentKindVersion != null) {
      setResult({ isLoaded: false, entityVersions: [] })

      rootStore.entityVersionStore.loadCurrentEntityVersionsForKindAndGameEnvironment()
        .then(() => {
          setResult({ isLoaded: true, entityVersions: rootStore.entityVersionStore.entityVersions })
        })
        .catch((e: any) => {
          publishNotification({
            notificationType: NotificationType.Error,
            title: "Error loading entities",
            message: `${e.message}`,
            footer: "Please try refreshing"
          })
        })
    }
  }, [rootStore.categoryStore, rootStore.entityVersionStore, publishNotification,rootStore.gameEnvironmentStore.currentGameEnvironment, rootStore.kindVersionStore.currentKindVersion, refreshToggle]);

  return result;
}
