import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import {GenerateId} from "../Utils";
import { KindVersionSummary } from '../kind/KindVersionSummary';

export const Category = types.model(
    "Category",
    {
            id: types.optional(types.identifierNumber, GenerateId),
            parent: types.maybeNull(types.reference(types.late((): IAnyModelType => Category))),
            name: "",
            description: "",
            comment: types.maybeNull(types.string),
            versionIdCur: 0,
            versionIdPrev: types.maybeNull(types.number),
            createdAt: "",
            originalAuthor: "",
            updatedAt: "",
            lastAuthor: "",
            lastActionId: 0,
            lastActionName: "",
            lastComment: types.maybeNull(types.string),
            status: "",
            kinds: types.array(KindVersionSummary)
    })
    .views(self => ({
            isNewCategory(){
                return self.versionIdCur === 0;
            }
    }))
    .actions(self => ({
            setName(name: string) {
                    self.name = name;
            },
            setDescription(name: string) {
                    self.description = name;
            },
            setComment(name: string) {
                    self.comment = name;
            }
    }))

export interface ICategory extends Instance<typeof Category> {}
