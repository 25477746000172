import React, {FC} from 'react';
import Navbar                       from 'react-bootstrap/Navbar';
import Button                       from 'react-bootstrap/Button';
import {IGameEnvironment}           from '../../../model/gameEnvironment/GameEnvironment';
import { ContentBoxNavBar } from '../../components/content-box/ContentBox';
import { Container } from 'react-bootstrap';

interface  PropsType {
    gameEnvironment: IGameEnvironment,
    handleSaveGameEnvironment: () => void,
    handleDeleteGameEnvironment: () => void,
    geEdited: boolean
}

export const GameEnvironmentNav : FC<PropsType> = (props) => {
    return (
        <ContentBoxNavBar variant="dark" expand="lg">
          <Container>
            <Navbar.Brand>
              {props.gameEnvironment.isNewGameEnvironment() ? "New Game Environment" : "Game Environment"}
            </Navbar.Brand>
            <Navbar.Toggle/>
            <Navbar.Collapse  id="kindNav" className="justify-content-end">
              {(props.geEdited || props.gameEnvironment.isNewGameEnvironment()) &&
                <Button variant="success" data-value="save" onClick={() => props.handleSaveGameEnvironment()} data-testid="btnSaveGameEnvironment">
                    <i className="fas fa-save"/>
                    {
                        props.gameEnvironment.isNewGameEnvironment() ? "Save Game Environment" : "Update Game Environment"
                    }
                </Button>
              }
               {!props.gameEnvironment.isNewGameEnvironment() &&
                <Button variant="danger" data-value="delete" onClick={() => props.handleDeleteGameEnvironment()} data-testid="btnDeleteGameEnvironment"><i className="fas fa-trash-alt"/> Delete Game Environment</Button>
               }
           </Navbar.Collapse>
          </Container>
        </ContentBoxNavBar>
    )
};
