import {useContext, useEffect, useState} from "react";
import RootStoreContext                         from "../model/RootStoreContext";
import type {IKindVersion} from "../model/kind/KindVersion";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";

export interface useSpecificationsForKindsResult{
    isLoaded            : boolean;
    kinds    : IKindVersion[] | null
}

export const useKindsForEntitySelection = () => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();
    const [result, setResult] = useState<useSpecificationsForKindsResult>({
        isLoaded: false,
        kinds: []
    });

    useEffect(() => {
            setResult({isLoaded: false, kinds:[]})

            rootStore.kindsForEntitySelectionStore.loadEntitySelectionKinds()
                .then(() => {
                    setResult({isLoaded: true, kinds:rootStore.kindsForEntitySelectionStore.getKinds()})
                })
                .catch(e => {
                    setResult({isLoaded: false, kinds: []});
                    publishNotification({
                            notificationType: NotificationType.Error,
                            title: "Error loading kinds for entity selections",
                            message: `${e.message}`,
                            footer: "Please try refreshing"
                        }
                    )
                })
    }, [rootStore.kindsForEntitySelectionStore, publishNotification, rootStore.gameVersionStore.currentGameVersion]);

    return result;
};
