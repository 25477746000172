import React, {FC, PropsWithChildren, useEffect} from 'react';
import styles from './DragAndDrop.module.sass'

export const DragAndDropClassNames = {
  Draggable : styles.draggable,
  Dragging : styles.dragging
}

interface PropsType {
    draggableClassname?: string,
    draggingClassname?: string,
    handleDrop: (droppedElementId: number, afterElementId: number | null) => void
}

export const DragAndDrop : FC<PropsWithChildren<PropsType>> = (props) => {

    let afterElement: HTMLElement | null    = null;

  const draggableClassname = props.draggableClassname ?? DragAndDropClassNames.Draggable;
  const draggingClassname = props.draggingClassname ?? DragAndDropClassNames.Dragging;

  useEffect(() =>
    {
        const selectedContainer : HTMLElement | null    = document.querySelector(".draggable-container");

        if(selectedContainer != null) {

            selectedContainer
                .addEventListener('dragover', (e) => {
                    e.preventDefault();
                    handleDragOver(e, selectedContainer);
                });

            selectedContainer
                .querySelectorAll(`.${draggableClassname}`)
                .forEach(draggable => {
                    draggable.addEventListener('dragstart', () => handleDragStart(draggable));
                    draggable.addEventListener('dragend', () => handleDragEnd(draggable))
                });
        }
    })

    const handleDragOver = (e : any, container : HTMLElement) => {
        e.preventDefault();

        const draggable = document.querySelector(`.${draggingClassname}`);
        afterElement    = getDragAfterElement(container, e.clientY);

        if (afterElement == null && draggable)
            container.appendChild(draggable);

        else if(draggable)
            container.insertBefore(draggable, afterElement);

    };

    const handleDragEnd = (element : Element) => {
        element.classList.remove(draggingClassname);
        props.handleDrop(parseInt(element.id), afterElement ? parseInt(afterElement.id) : null);
    };

    const handleDragStart = (element: Element) => {
        element.classList.add(draggingClassname)
    };

    const getDragAfterElement = (container: any, y: number) => {

        const draggableElements = [...container.querySelectorAll(`.${draggableClassname}:not(.${draggingClassname})`)];

        return draggableElements.reduce((closest, child) => {

            const box       = child.getBoundingClientRect();
            const offset    = y - box.top - box.height / 2;

            if (offset < 0 && offset > closest.offset)
                return { offset: offset, element: child };
            else
                return closest

        }, { offset: Number.NEGATIVE_INFINITY }).element
    }

    return <div className="draggable-container">{props.children}</div>;
}
