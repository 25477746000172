import React, {FC, useContext}  from 'react';
import {observer}               from 'mobx-react-lite';
import {Link}                   from 'react-router-dom';
import Card                     from 'react-bootstrap/cjs/Card';
import Badge                    from 'react-bootstrap/cjs/Badge';
import {ClaimType}              from "@yakoffice/publisher-types";
import {useGetCategoryLink, useGetKindsLink} from '../../routes';
import RootStoreContext         from "../../../model/RootStoreContext";
import {ICategory}              from "../../../model/category/Category";
import {camelToTitle}           from '../../../utilities';
import { YoCard }               from '../../components/yo-card/YoCard';
import styles                   from './categoryCard.module.sass';


interface PropsType {
    category    : ICategory;
}


export const CategoryCard : FC<PropsType> = observer((props) => {

    const rootStore         = useContext(RootStoreContext);
    const getCategoryLink   = useGetCategoryLink();
    const getKindsLink      = useGetKindsLink();

    return (
      <YoCard className={`${props.category.status} ${styles.categoryCard}`} data-testid="yoCardCategory">
        <div className={styles.categoryCardInside} />
        {props.category.status === "archived" && <Card.Header className={`${props.category.status}`}>{props.category.status}</Card.Header>}
        {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory) &&
         <div className="card-settings d-block d-md-none">
           <Link to={getCategoryLink({categoryId: props.category.id})}><i className="fas fa-cog"/></Link>
         </div>
        }
        <Card.Body>
          <Link to={getKindsLink({categoryId: props.category.id})}>
            <Card.Title>
              {camelToTitle(props.category.name)}
            </Card.Title>
            <Card.Text>{props.category.description}</Card.Text>
            <Card.Subtitle className="text-muted">
              {props.category.kinds.length > 0 && <Badge pill bg="primary">{props.category.kinds.length}</Badge>}
            </Card.Subtitle>
          </Link>
        </Card.Body>
        {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory) &&
         <Card.Footer className="d-none d-md-block" data-testid="lnkCategoryCardSettings">
           <Link to={getCategoryLink({categoryId: props.category.id})}><i className="fas fa-cog"/> Settings</Link>
         </Card.Footer>
        }
      </YoCard>
    )
});
