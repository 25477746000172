import React, { FC, useContext, useState } from 'react';
import { GameVersionSelector } from './GameVersionSelector';
import { GameEnvironmentSelector } from './GameEnvironmentSelector';
import RootStoreContext from '../../../model/RootStoreContext';
import Button from 'react-bootstrap/Button';
import {ButtonGroup} from 'react-bootstrap';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';

interface PropTypes{
  variant? : string
  gameEnvironments : IGameEnvironment[]
  disabled?: boolean
  disableCopyToSame?: boolean
  handleCopy: (targetGameVersion: IGameVersion, targetGameEnvironment : IGameEnvironment) => void;
}

export const CopyToGameVersionAndEnvironmentButtonGroup : FC<PropTypes> = ({variant, gameEnvironments, disabled, disableCopyToSame, handleCopy}) => {
  const rootStore = useContext(RootStoreContext);
  const [diffGameVersion, setDiffGameVersion] = useState(rootStore.gameVersionStore.getCurrentGameVersion())
  const [diffGameEnvironment, setDiffGameEnvironment] = useState(gameEnvironments[0])

  return <ButtonGroup>
    <GameVersionSelector    as={ButtonGroup}
                            selectedGameVersion={diffGameVersion}
                            handleGameVersionChange={gv => setDiffGameVersion(gv)}
                            data-testid="ddlCopyToGameVersion"/>
    <GameEnvironmentSelector as={ButtonGroup}
                             selectedGameEnvironment={diffGameEnvironment}
                             gameEnvironmentFilter={ge => gameEnvironments.indexOf(ge) > -1}
                             handleGameEnvironmentChange={ge => setDiffGameEnvironment(ge)}
                             data-testid="ddlCopyToGameEnvironment"/>

    <Button id="btnCopy" variant={variant ?? "outline-primary"} className="border-start" data-testid="btnCopyToVersionAndEnvironment"
            onClick={() => handleCopy && handleCopy(diffGameVersion, diffGameEnvironment)}
            disabled={disabled || (disableCopyToSame && diffGameVersion === rootStore.gameVersionStore.getCurrentGameVersion() && diffGameEnvironment === rootStore.gameEnvironmentStore.getCurrentGameEnvironment())}>
      <i className="fas fa-copy" /> Copy
    </Button>
  </ButtonGroup>
}
