import {useEffect}                  from "react";
import { useParams } from "react-router-dom";
import { useRootStore } from '../../../loaders/useRootStore';

export const useSetGameEnvironmentFromParam = () => {

  const rootStore = useRootStore();
  const { gameEnv } = useParams<{ gameEnv: string }>();

  useEffect(() => {
    const gameEnvAsInt = parseInt(gameEnv ?? "");
    const currentGameEnv = isNaN(gameEnvAsInt)
      // NB.  gameEnv not set so default to first one
      ? rootStore.gameEnvironmentStore.gameEnvironments.find(() => true)
      // NB.  gameVersionId has been set
      : rootStore.gameEnvironmentStore.gameEnvironments.find(ge => ge.id === gameEnvAsInt)

    if (currentGameEnv)
      rootStore.gameEnvironmentStore.setCurrentGameEnvironment(currentGameEnv.id);
  }, [rootStore.gameEnvironmentStore, gameEnv]);
};
