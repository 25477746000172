import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {Entity} from "./Entity";
import { useEntityVersionWithKindVersion } from '../../../loaders/useEntityVersionWithKindVersion';
import { useRootStore } from '../../../loaders/useRootStore';

export const EntityContainer : FC = observer(() => {

    const rootStore = useRootStore();
    const entityVersionResult = useEntityVersionWithKindVersion();

    return (
        <div id="experiment">
          {entityVersionResult.isLoaded &&
          entityVersionResult.entityVersion != null &&
          entityVersionResult.kindVersion != null
            ? <Entity project={rootStore.projectStore.getCurrentProject()}
                       gameVersion={rootStore.gameVersionStore.getCurrentGameVersion()}
                      category={rootStore.categoryStore.getCurrentCategory()}
                      gameEnvironment={rootStore.gameEnvironmentStore.getCurrentGameEnvironment()}
                      currentKindVersion={rootStore.kindVersionStore.getCurrentKindVersion()}
                      entityVersion={entityVersionResult.entityVersion}
                      entityVersionKindVersion={entityVersionResult.kindVersion}
                      specificationKindVersions={rootStore.specificationsForKindsStore.getKinds()}
                      gameEnvironments={rootStore.gameEnvironmentStore.gameEnvironments}
            />
            : <CustomSpinner spinnerText={'Loading entity...'} />
          }
        </div>
    )
})
