import React, {FC}        from "react";
import { runInAction }    from 'mobx';
import { observer }       from "mobx-react-lite";
import {EntityStatus}     from "@yakoffice/publisher-types";
import Form               from "react-bootstrap/Form";
import DropdownButton     from "react-bootstrap/DropdownButton";
import { ICheckedEntity } from '../entities/Entities';


interface PropsType {
    variant               : string,
    statuses              : string[],
    checkedEntityVersions : ICheckedEntity[]

}


export const EntityStatusToggleDropDownButton : FC<PropsType> = observer((props) => {

    const handleStatusToggle = (status: EntityStatus) => {

      runInAction(() => {
        const index = props.statuses.indexOf(status)

        if (index >= 0) {
          props.statuses.splice(index, 1);
          props.checkedEntityVersions
            .filter(checkedEntity => checkedEntity.checked && !props.statuses.includes(checkedEntity.entityVersion.entity.status))
            .forEach(checkedEntity => {checkedEntity.checked = false})
        }
        else
          props.statuses.push(status);
      })
    }

    return <DropdownButton
              variant={props.variant}
              id="bg-nested-dropdown"
              title={<span><i className="fas fa-sort-amount-down" /> Filter </span>}
              bsPrefix={`fw-bold btn btn-${props.variant}`}>
                <div className="ms-3">
                  {[EntityStatus.Draft, EntityStatus.Published, EntityStatus.Stopped, EntityStatus.Archived].map(status => {
                      return <Form.Check key={`toggle-${status}`} type="checkbox" className="text-capitalize fw-normal" label={status} checked={props.statuses.includes(status)} onChange={() => handleStatusToggle(status)} />
                  })}
                </div>
            </DropdownButton>
})
