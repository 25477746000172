import React, {FC}      from 'react';
import { Link }         from 'react-router-dom';
import Col              from 'react-bootstrap/Col';
import Card             from 'react-bootstrap/Card';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { YoCard }       from '../../components/yo-card/YoCard';


interface PropsType{
  gameVersions    : IGameVersion[],
  filterArchived  : boolean;
}


export const GameVersionCard : FC<PropsType> = (props) => {

  return  <React.Fragment>
    {props.gameVersions
      .filter(gv => !props.filterArchived || gv.status !== "archived")
      .map(gv => {
        return  <Col xl="2" lg="3" md="3" sm="6" xs="12" key={gv.id} className="mb-3">
          <YoCard className={`${gv.isArchived() && "archived"}`}  data-testid="yoCardGameVersions">
            <Card.Body>
              <Link to={gv.id.toString()}>
                <Card.Title>{gv.name}</Card.Title>
                <Card.Text>{gv.description}</Card.Text>
                <Card.Text className={`status ${gv.status}`}><i className="fas fa-circle" /> {gv.status}</Card.Text>
              </Link>
            </Card.Body>
          </YoCard>
        </Col>
      })}
  </React.Fragment>
};
