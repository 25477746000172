import {destroy, detach, flow, Instance, types} from "mobx-state-tree";
import {ISelection, Selection}  from "./Selection";
import {ISelectionVm} from "@yakoffice/publisher-types";
import {MapToSelectionModel} from "./SelectionStore";
import {EntitySelectionApiGateway} from "../../api/requests/selections/EntitySelectionApiGateway";
import {observable} from "mobx";


export const EntitySelectionStore = types.model(
    "EntitySelectionStore",
    {
        apiGateway          : EntitySelectionApiGateway
    })
    .views(self => ({

    }))
    .actions(self => {
        // NB.  This local array of detached selections is required to ensure there are no types.reference lookup key clashes due to having the
        //  a selection with the same id loaded in the selectionStore and this store
        const entitySelections : ISelection[] = observable([]);
        const loadSelections = flow(function*() {
            try {
                const selectionVms = (yield self.apiGateway.getSelections()) as ISelectionVm[];

                const detachedSelections = selectionVms.map(vm => detach(Selection.create(MapToSelectionModel(vm))));
                entitySelections.splice(0, entitySelections.length, ...detachedSelections)

            } catch (e:any) {
                throw new Error(`Failed to load entity selections.  Error message: ${e.message}`);
            }
        });
        const getSelections = () => {
            return entitySelections
        };
        const clearStore = () => {
            entitySelections.forEach(k => destroy(k))
        };

        return {
            loadSelections,
            getSelections,
            clearStore
        }

    });

export interface IEntitySelectionStore extends Instance<typeof EntitySelectionStore> {}
