import React, {FC, ReactElement} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToolTip from "react-bootstrap/Tooltip";

interface  PropTypes{
    value : string | null | undefined
    lengthToAbbreviate?: number
    abbreviation?: string | ReactElement
}

export const AbbreviatedString : FC<PropTypes> = ({value, lengthToAbbreviate = 18, abbreviation = '...'}) => {

    return !value || value.length <= lengthToAbbreviate
        ? <span>{value}</span>
        :  <OverlayTrigger key={`overlay-any`} placement="right" overlay={<ToolTip id="button-tooltip">{value}</ToolTip>}>
                <span className="font-italic">{value.substring(0,lengthToAbbreviate)}{abbreviation}</span>
            </OverlayTrigger>
}
