import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import Pagination from "react-bootstrap/cjs/Pagination";

interface PropTypes{
    pageSize: number
    activePageNumber: number
    itemsCount: number
    handleSetPageNumber: (pageNumber: number) => void
}

export const YakofficePagination : FC<PropTypes> = observer(({pageSize, activePageNumber, itemsCount, handleSetPageNumber}) => {

    const numberOfPages = Math.ceil(itemsCount / pageSize);

    return (
            <Pagination size="sm">
                <Pagination.First onClick={() => handleSetPageNumber(1)}/>
                <Pagination.Prev onClick={() => handleSetPageNumber(activePageNumber - 1)} disabled={activePageNumber-1 < 1}/>
                {activePageNumber > 3 && <Pagination.Ellipsis />}
                {activePageNumber > 2 && <Pagination.Item key={2} onClick={() => handleSetPageNumber(activePageNumber - 2)}>{activePageNumber-2}</Pagination.Item>}
                {activePageNumber > 1 && <Pagination.Item key={3} onClick={() => handleSetPageNumber(activePageNumber - 1)}>{activePageNumber-1}</Pagination.Item>}
                <Pagination.Item key={4} active={true}>{activePageNumber}</Pagination.Item>
                {activePageNumber < numberOfPages && <Pagination.Item key={5} onClick={() => handleSetPageNumber(activePageNumber + 1)}>{activePageNumber+1}</Pagination.Item>}
                {activePageNumber < numberOfPages - 1  && <Pagination.Item key={6} onClick={() => handleSetPageNumber(activePageNumber + 2)}>{activePageNumber+2}</Pagination.Item>}
                {activePageNumber < numberOfPages - 2 && <Pagination.Ellipsis />}
                <Pagination.Next onClick={() => handleSetPageNumber(activePageNumber + 1)} disabled={activePageNumber + 1 > numberOfPages}/>
                <Pagination.Last onClick={() => handleSetPageNumber(numberOfPages)}/>
            </Pagination>
    )
})

