import React, { FC } from 'react';
import {observer} from "mobx-react-lite";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from "react-bootstrap/Dropdown";
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { useRootStore } from '../../../loaders/useRootStore';
import { useGetCategoriesLink } from '../../routes';
import { useNavigate } from 'react-router-dom';
import styles from './GameVersionSelector.module.sass';
import { ClaimType } from '@yakoffice/publisher-types';

export const getGameVersionStatusClass = (gameVersion: IGameVersion) => {
  switch (gameVersion.status) {
    case "live":
      return "danger";
    case "inactive":
      return "default"
    case "unreleased":
      return "warning";
    default:
      return "info";
  }
}

interface PropsType{
  [key: string]: any,
  selectedGameVersion?: IGameVersion,
  handleGameVersionChange? : (targetGameVersion : IGameVersion) => void,
}

export const GameVersionSelector : FC<PropsType> = observer(({selectedGameVersion, handleGameVersionChange, className, ...props}) => {
  const rootStore = useRootStore();
  const navigate = useNavigate()
  const getCategoriesLink = useGetCategoriesLink();

  const onGameVersionChange = (targetGameVersion: IGameVersion) =>
    handleGameVersionChange
      ? handleGameVersionChange(targetGameVersion)
      : navigate(getCategoriesLink({ gameVersionId: targetGameVersion.id }))

  const selectedGameVersionOrDefault = selectedGameVersion ?? rootStore.gameVersionStore.getCurrentGameVersion();

  return rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.ViewAllGameVersions)
    ? <DropdownButton
        align="end"
        title={selectedGameVersionOrDefault.name}
        variant={getGameVersionStatusClass(selectedGameVersionOrDefault)}
        className={`${styles.versionSelector} ${className ?? ""}`}
        data-testid={'gameVersionSelectorDropdownButton'}
        {...props}>
        {rootStore.gameVersionStore.gameVersions
          .filter(gv => !gv.isArchived())
          .map(gameVersion => {
            return <Dropdown.Item key={gameVersion.id} as="button" onClick={() => onGameVersionChange(gameVersion)} data-testid={`gameVersionSelectorButton${gameVersion.name}`}>
            <i className={`fas fa-circle text-${getGameVersionStatusClass(gameVersion)} me-1`} /> {gameVersion.name}
            </Dropdown.Item>
          })}
      </DropdownButton>
    : null
})

