import { Instance, types } from 'mobx-state-tree';
import { GenerateId } from '../Utils';
import { ISelectionOption, SelectionOption } from './SelectionOption';


export const Selection = types.model(
  'Selection',
  {
    id:             types.optional(types.identifierNumber, GenerateId),
    name:           '',
    description:    '',
    archived:       false,
    googleSheetId:  types.maybeNull(types.string),
    options:        types.array(SelectionOption),
    createdAt:      '',
    originalAuthor: '',
    updatedAt:      '',
    lastAuthor:     '',
    isNew:          false

  })
  .views(self => ({
           isNewSelection() {
             return self.isNew;
           }
         })
  )
  .actions(self => ({
    setName(name: string) {
      self.name = name;
    },
    setDescription(description: string) {
      self.description = description;
    },
    setArchived(archived: boolean) {
      self.archived = archived;
    },
    setGoogleSheetId(googleSheetId: string | null) {
      self.googleSheetId = googleSheetId;
    },
    addOption() {
      self.options.push(SelectionOption.create({
                                                 name:  '',
                                                 value: ''
                                               }));
    },
    removeOption(selectionOption: ISelectionOption) {
      self.options.splice(self.options.indexOf(selectionOption), 1);
    }
  }));

export interface ISelection extends Instance<typeof Selection> {
}
