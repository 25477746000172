import React, { FC }        from 'react';
import {observer}                     from 'mobx-react-lite';
import {isStateTreeNode, onSnapshot}  from "mobx-state-tree";
import Container                      from 'react-bootstrap/Container';
import {Link}                         from 'react-router-dom';
import {ViewHeader}                   from '@yakoffice/view-header';
import {useGetGameVersionsLink}       from '../../routes';
import {IGameVersion}                 from '../../../model/gameVersion/GameVersion';
import {useGameVersionController}     from '../../../controllers/useGameVersionController';
import {GameVersionHighlights}        from './GameVersionHighlights';
import {GameVersionNav}               from './GameVersionNav';
import {GameVersionMainInfo}          from './GameVersionMainInfo';
import {ContentBox, ContentBoxBody, ContentBoxHeader} from '../../components/content-box/ContentBox';
import {usePrompt} from "@yakoffice/shared-components";

interface PropsType {
  gameVersion : IGameVersion
}

export const GameVersion : FC<PropsType> = observer(({gameVersion}) => {

  const controller          = useGameVersionController(gameVersion);
  const getGameVersionsLink = useGetGameVersionsLink();
  const [edited, setEdited] = usePrompt("You have unsaved changes. Are you sure you want to leave?", false);

  if (isStateTreeNode(gameVersion))
    onSnapshot(gameVersion, () => {
      setEdited(true);
    });

  return <div id="gameVersion">
    <Container>
      <ViewHeader title={`${gameVersion.name != null && gameVersion.name.length > 0
                            ? gameVersion.name
                            : "(New Game Version Name)"}`}
                  breadcrumbs={<span><Link to={getGameVersionsLink()}>Game Versions</Link></span>}
      />
    </Container>
    <Container>
      <ContentBox>
        <ContentBoxHeader>
          <GameVersionNav
            gameVersion={gameVersion}
            geEdited={edited}
            handleSaveGameVersion={() => controller.handleSaveGameVersion(() => setEdited(false))}
            handleDeleteGameVersion={() => controller.handleDeleteGameVersion(() => setEdited(false))}
            handleBranchGameVersion={() => controller.handleBranchGameVersion(() => setEdited(false))}
            handleStatusChange={status => controller.handleStatusChange(status, () => setEdited(false))}
          />
        </ContentBoxHeader>
        <ContentBoxBody>
          {!gameVersion.isNewGameVersion() && <GameVersionHighlights gameVersion={gameVersion}/>}
          <GameVersionMainInfo gameVersion={gameVersion}/>
        </ContentBoxBody>
      </ContentBox>
    </Container>
  </div>
});
