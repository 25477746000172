import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import RootStoreContext from '../../../model/RootStoreContext';
import { useSelections } from './useSelections';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { ISelection } from '../../../model/selections/Selection';
import { useMultiSelect } from '../../components/use-multi-select/useMultiSelect';
import { useSelectionsController } from '../../../controllers/useSelectionsController';
import { SelectionsList } from './SelectionsList';
import { useGetSelectionsLink } from '../../routes';
import { Checked } from '../common/Checked';
import { autorun } from 'mobx';

export const Selections: FC = observer(() => {

  const navigate = useNavigate();
  const rootStore = useContext(RootStoreContext);

  const [showArchived, setShowArchived] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(true);

  const selectionsResult = useSelections(showArchived, refreshFlag);
  const getSelectionsLink = useGetSelectionsLink();
  const controller = useSelectionsController();

  const [checkAllSelections, setCheckAllSelections] = useState(false);
  const [checkedSelections, setCheckedSelections] = useState<Checked<ISelection>[]>(selectionsResult.selections.map(s => ({ checked: checkAllSelections, value: s })));

  const [checkAllGameVersions, setCheckAllGameVersions] = useState(false);
  const [checkedGameVersions, setCheckedGameVersions] = useState<Checked<IGameVersion>[]>(rootStore.gameVersionStore.gameVersions.map(gv => ({ checked: checkAllGameVersions, value: gv })));

  useEffect(() => {
              autorun(() => {
                setCheckAllSelections(false);
                setCheckedSelections(selectionsResult.selections.map(s => ({ checked: false, value: s })));
              });
            }
    , [selectionsResult, rootStore.selectionStore.currentSelection]);

  useEffect(() => {
              setCheckAllGameVersions(false);
              setCheckedGameVersions(rootStore.gameVersionStore.gameVersions.map(gv => ({ checked: false, value: gv })));
            }
    , [rootStore.gameVersionStore.gameVersions]);

  const multiSelectSelections = useMultiSelect<Checked<ISelection>>({
                                                                      items:      checkedSelections,
                                                                      selectItem: (item, selected) => {
                                                                        item.checked = selected;
                                                                        setCheckedSelections(prevState => prevState.map(p => p));
                                                                      }
                                                                    });

  const multiSelectGameVersions = useMultiSelect<Checked<IGameVersion>>({
                                                                          items:      checkedGameVersions,
                                                                          selectItem: (item, selected) => {
                                                                            item.checked = selected;
                                                                            setCheckedGameVersions(prevState => prevState.map(p => p));
                                                                          }
                                                                        });

  const handleSelectionCheckboxToggle = (checkedSelection: Checked<ISelection>, checked: boolean) => {
    multiSelectSelections.handleItemSelection(checkedSelection, checked);
  };

  const handleCheckAllSelections = () => {
    const newState = !checkAllSelections;
    multiSelectSelections.handleSelectAll(newState);
    setCheckAllSelections(newState);
  };

  const handleShowArchived = (archived: boolean, redirect?: boolean) => {
    setShowArchived(archived);
    if (redirect)
      navigate(getSelectionsLink());
  };

  const handleSelectGameVersion = (checked: boolean, gameVersion: Checked<IGameVersion>) => {
    multiSelectGameVersions.handleItemSelection(gameVersion, checked);
  };

  const onGameVersionChanged = (targetGv: IGameVersion) => navigate(getSelectionsLink({ gameVersionId: targetGv.id }));

  const copySelections = () => {
    controller.copySelectionsPrompt(
      checkedSelections,
      checkedGameVersions,
      handleSelectGameVersion
    );
  };

  return (
    selectionsResult.isLoaded ?
    <SelectionsList
      onGameVersionChanged={onGameVersionChanged}
      handleShowArchived={handleShowArchived}
      showArchived={showArchived}
      handleCheckAllSelections={handleCheckAllSelections}
      copySelections={copySelections}
      checkedSelections={checkedSelections}
      handleSelectionCheckboxToggle={handleSelectionCheckboxToggle}
      setRefreshFlag={setRefreshFlag}
      refreshFlag={refreshFlag}
    />
                              :
    <CustomSpinner spinnerText={'Loading selections'} />
  )
    ;
});
