import React, {FC} from 'react';
import { NavbarProps } from 'react-bootstrap/Navbar';
import { Navbar } from 'react-bootstrap';
import styles from './ContentBox.module.sass'

export const ContentBox : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {
  return <div {...props} className={`${styles.contentBox} ${className ?? ""}`}>{props.children}</div>
}

export const ContentBoxHeader : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {
  return <div {...props} className={`${styles.contentBoxHeader} ${className ?? ""}`}>{props.children}</div>
}

export const ContentBoxBody : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {
  return <div {...props} className={`${styles.contentBoxBody} ${className ?? ""}`}>{props.children}</div>
}

export const ContentBoxNavBar : FC<NavbarProps> = ({className, ...props}) => {
  return <Navbar {...props} className={`${styles.contentBoxNavBar} ${className ?? ""}`}>{props.children}</Navbar>
}
