import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {IKindVersion} from "../../../model/kind/KindVersion";
import FieldLayout from "../../components/inputs/fieldLayout";
import {IEntityProperty} from "../../../model/entity/EntityProperty";
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {EntityPropertyFactory} from "../entity/EntityPropertyFactory";
import { FeatureHeader } from '@yakoffice/shared-components';


interface PropsType {
    specificationProperties : IEntityProperty[];
    specificationKinds : IKindVersion[];
    handleRemoveSpecificationProperty: (property: IEntityProperty) => Promise<void>
}

export const Specifications : FC<PropsType> = observer((props) => {

    const findSpecificationProperty = (specificationKindId: number, specificationPropertyId: number) => {
        const specificationKind = props.specificationKinds.find(k => k.kind.id === specificationKindId);

        if (specificationKind)
            return specificationKind.properties.find(kp => kp.id === specificationPropertyId);

        return undefined;
    };

    return (
        <React.Fragment>
            <FeatureHeader size="sm" className="mb-2"><i className="fas fa-building"/> Specification Properties</FeatureHeader>
            {props.specificationProperties.map(ep => {
                    const specificationKindProperty = findSpecificationProperty(ep.kindPropertyKindId, ep.kindPropertyId);
                    return specificationKindProperty &&
                        <div key={ep.kindPropertyId}>
                            <FieldLayout label={specificationKindProperty.key}
                                         labelForId={specificationKindProperty.id.toString()}
                                         description={specificationKindProperty.description}
                                         required={specificationKindProperty.isRequired()}>
                                <Row >
                                    <Col xs="10" md="10" lg="10">
                                        <EntityPropertyFactory kindProperty={specificationKindProperty} getPropertyValue={() => ep.value} setPropertyValue={ep.setValue}/>
                                    </Col>
                                    <Col xs="2" md="2" lg="2" className="text-end">
                                        <Button size="sm" onClick={() => props.handleRemoveSpecificationProperty(ep)}>X</Button>
                                    </Col>
                                </Row>
                            </FieldLayout>
                        </div>
                }
            )}
        </React.Fragment>
    );
});
