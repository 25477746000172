import React, {ChangeEvent, useContext} from "react";
import Button                   from 'react-bootstrap/Button';
import { useNavigate }           from "react-router-dom";
import {CustomSpinner}          from '@yakoffice/custom-spinner';
import {useShowModal}           from '@yakoffice/custom-modal';
import RootStoreContext         from "../model/RootStoreContext";
import {FormattedErrorMessage}  from '../presentation/views/common/Utils';
import type {IGameVersion}           from '../model/gameVersion/GameVersion';
import {useGetGameVersionsLink} from '../presentation/routes';
import {Observer} from 'mobx-react-lite';
import FieldLayout from '../presentation/components/inputs/fieldLayout';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/cjs/Col';


export interface IGameVersionController{
  handleSaveGameVersion : (onSuccessCallback: () => void) => void,
  handleDeleteGameVersion : (onSuccessCallback: () => void) => void,
  handleBranchGameVersion : (onSuccessCallback: () => void) => void,
  handleStatusChange : (status: string, onSuccessCallback: () => void,) => void
}


export const useGameVersionController = (gameVersion: IGameVersion) => {

  // Context Hooks
  const showModal               = useShowModal();
  const rootStore               = useContext(RootStoreContext);
  const navigate                 = useNavigate();
  const getGameVersionsLink     = useGetGameVersionsLink();

  // Handlers

  const showModalSpinner = (title: string, body: string) => {
    showModal({ show: true, title: title, body: <CustomSpinner spinnerText={body} position={'relative'} />, canClose: false })
  };
  const saveGameVersion = (onSuccessCallback: () => void) => {

    const confirmSaveGameVersion = () => {

      showModal(
        {
          show:     true,
          title:    "Save Game Version",
          body:     <CustomSpinner spinnerText={`Saving Game Version`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.gameVersionStore.saveGameVersion(gameVersion)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect();
        })
        .catch(e => {
          showModal({
                      show: true,
                      title: 'Error saving game version',
                      body: <FormattedErrorMessage errorMessage={e.message} />,
                      canClose: true,
                    });
        });
    };

    showModal(
      {
        show:     true,
        title:    "Save Game Version",
        body:     "Are you sure you want to save this game version?",
        action:   <Button variant="success" onClick={() => confirmSaveGameVersion()} data-testid="btnModalConfirmSave">OK</Button>,
        canClose: true
      }
    );
  };

  const deleteGameVersion = (onSuccessCallback: () => void) => {
    const confirmDeleteGameVersion = () => {

      showModal(
        {
          show:     true,
          title:    "Delete Game Version",
          body:     <CustomSpinner spinnerText={`Deleting Game Version`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.gameVersionStore.deleteGameVersion(gameVersion)
        .then( async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect();
        })
        .catch(e => {
          showModal({
                      show: true,
                      title: 'Error deleting game version',
                      body: <FormattedErrorMessage errorMessage={e.message} />,
                      canClose: true,
                    });
        });
    };

    showModal(
      {
        show:     true,
        title:    "Delete Game Version",
        body:     "Are you sure you want to delete this game version?",
        action:   <Button variant="success" onClick={() => confirmDeleteGameVersion()} data-testid="btnModalConfirmDelete">OK</Button>,
        canClose: true
      }
    );
  };

  const branchGameVersion = (onSuccessCallback: () => void) => {
    const branchedGameVersion = gameVersion.branchGameVersion();

    const handleBranchNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      branchedGameVersion.setName(e.target.value)
    };
    const handleBranchDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
      branchedGameVersion.setDescription(e.target.value)
    };
    const handleBranchConfigApiPublishingUrlVersionIdChange = (e: ChangeEvent<HTMLInputElement>) => {
      branchedGameVersion.setConfigApiPublishingUrlVersionId(e.target.value)
    };

    const handleBranchGameVersion = async () => {
      try {
        showModalSpinner("Branching Game Version", `Branching game version ${branchedGameVersion.name}....`);
        await rootStore.gameVersionStore.branchGameVersion(gameVersion.id, branchedGameVersion);
        onSuccessCallback();
        await hideSpinnerAndRedirect();
      } catch (e:any) {
        showModal({
                    show: true,
                    title: "Error Branching Game Version",
                    body: `Could not branch game version ${branchedGameVersion.name}. Error message: ${e.message}`,
                    canClose: true
                  })
      }
    };

    showModal(
      {
        show: true,
        title: "Branch Game Version",
        body: <Observer>{() => <>
          <Col md="6">
            <FieldLayout label="Name:">
              <Form.Control type="text" id="name" value={branchedGameVersion.name} onChange={handleBranchNameChange} placeholder="Please enter game version name..." data-testid="txtBranchGameVersionName"/>
            </FieldLayout>
          </Col>
          <Col md="6">
            <FieldLayout label="Description:">
              <Form.Control type="text" id="description" value={branchedGameVersion.description} onChange={handleBranchDescriptionChange} data-testid="txtBranchGameVersionDescription" />
            </FieldLayout>
          </Col>
          <Col md="12">
            <FieldLayout label="Config Api Publishing Url Version:">
              <Form.Control type="text" id="version" value={branchedGameVersion.configApiPublishingUrlVersionId} onChange={handleBranchConfigApiPublishingUrlVersionIdChange} data-testid="txtBranchGameVersionVersion" />
            </FieldLayout>
          </Col>
        </>}</Observer>,
        action: <Button variant="success" onClick={handleBranchGameVersion}  data-testid="btnSaveBranchGameVersion">Branch Game Version</Button>,
        canClose: true
      }
    );
  }

  const hideSpinnerAndRedirect = async () => {
    showModal({body: "", canClose: false, title: '', show: false});
    navigate(getGameVersionsLink());
  };

  const statusChange = (status: string, onSuccessCallback: () => void) => {

    const statusChange = () => {
      showModal(
        {
          show: true,
          title: "Update Game Version",
          body: <CustomSpinner spinnerText={`Changing Game Version status to ${status}`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.gameVersionStore
        .updateGameVersionStatus(gameVersion, status)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
                      show: true,
                      title: "Error Changing Game Version Status",
                      body: `Could not change the Game Version status to ${status}. Error message: ${e.message}`,
                      canClose: true
                    })
        })
    };

    showModal(
      {
        show: true,
        title: "Update Game Version",
        body: `You are about to set this game version's status to ${status}. Are you sure you want to do this?`,
        action: <Button variant="success" onClick={() => statusChange()} data-testid="btnModalChangeStatus">OK</Button>,
        canClose: true
      }
    );
  }

  const controller : IGameVersionController = {
    handleSaveGameVersion       : saveGameVersion,
    handleDeleteGameVersion     : deleteGameVersion,
    handleBranchGameVersion     : branchGameVersion,
    handleStatusChange          : statusChange
  };

  return controller;
};
