import {observer}                               from "mobx-react-lite";
import React, { FC } from 'react';
import Col                                      from 'react-bootstrap/Col';
import Form                                     from 'react-bootstrap/Form';
import Button                                   from 'react-bootstrap/Button';
import {
  IValidationTypeVm, KindPropertyType,
  ValidationType, ValidationValueType
} from '@yakoffice/publisher-types';
import {equalsIgnoreCase}                       from "../../../utilities";
import {IValidationRule}                        from "../../../model/kind/ValidationRule";
import {IntegerInput}                           from "../common/IntegerInput";
import {BooleanInput}                           from "../common/BooleanInput";
import {DecimalInput}                           from "../common/DecimalInput";
import {HexadecimalInput}                       from "../common/HexadecimalInput";
import {RegexInput}                             from "../common/RegexInput";
import { DateTimeInput } from "../common/DateTimeInput";
import { ValidationReferenceType } from '@yakoffice/publisher-types';
import { IKindProperty } from '../../../model/kind/KindProperty';
import { Row } from 'react-bootstrap';

interface IPropsType {
    handleRemoveValidationRule: (vr: IValidationRule) => void;
    validationRule: IValidationRule;
    validationTypes: IValidationTypeVm[];
    otherProperties: IKindProperty[];
    propertyType: string;
}

export const KindPropertyAddValidationRule : FC<IPropsType> = observer((props) => {

  const removeValidationRule = () => {
    props.handleRemoveValidationRule(props.validationRule);
  }

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const validationType = (ValidationType as any)[event.target.value];
    props.validationRule.setType(validationType);
  }

  const handleValueChange = (value: string | null) => {
    props.validationRule.setValue(value);
  }

  const handleSelectValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.validationRule.setValue(event.target.value);
  }

  const formatPropertyValue = (value: string | null) => value != null ? value : "";

  const renderValueInput = () => {
    const validationType = props.validationTypes.find(vt => equalsIgnoreCase(vt.name, props.validationRule.type));

    if (validationType) {

      if(equalsIgnoreCase(ValidationReferenceType.ValidationRuleValue, validationType.requirementType.referenceType))
      {
        if (equalsIgnoreCase(ValidationValueType.Boolean, validationType.requirementType.validationValueType))
          return typeBoolean();

        if (equalsIgnoreCase(ValidationValueType.Integer, validationType.requirementType.validationValueType))
          return typeInteger();

        if (equalsIgnoreCase(ValidationValueType.Regex, validationType.requirementType.validationValueType))
          return typeRegex();

        if (equalsIgnoreCase(ValidationValueType.PropertyType, validationType.requirementType.validationValueType)) {
          if (equalsIgnoreCase(KindPropertyType.Boolean, props.propertyType))
            return typeBoolean();
          if (equalsIgnoreCase(KindPropertyType.Integer, props.propertyType))
            return typeInteger();
          if (equalsIgnoreCase(KindPropertyType.Decimal, props.propertyType))
            return typeDecimal();
          if (equalsIgnoreCase(KindPropertyType.Hexadecimal, props.propertyType))
            return typeHexadecimal();
          if (equalsIgnoreCase(KindPropertyType.DateTime, props.propertyType))
            return typeDateTime();
        }
      }

      if(equalsIgnoreCase(ValidationReferenceType.ReferencedPropertyValue, validationType.requirementType.referenceType)){
        if (equalsIgnoreCase(ValidationValueType.Boolean, validationType.requirementType.validationValueType))
          return typePropertyKey(KindPropertyType.Boolean);

        if (equalsIgnoreCase(ValidationValueType.Integer, validationType.requirementType.validationValueType))
          return typePropertyKey(KindPropertyType.Integer);

        if (equalsIgnoreCase(ValidationValueType.String, validationType.requirementType.validationValueType))
          return typePropertyKey(KindPropertyType.String);

        return typePropertyKey(props.propertyType);
      }
    }
    return null;
  }

  const typeBoolean = () =>
      <BooleanInput id={"value" + props.validationRule.id} value={formatPropertyValue(props.validationRule.value)}
                    noSelectionText={"Select value..."} handleOnChange={handleValueChange} data-testid="inputValidationRuleValue" />

  const typeInteger = () =>
      <IntegerInput id={"value" + props.validationRule.id} value={formatPropertyValue(props.validationRule.value)}
                    handleOnChange={handleValueChange} data-testid="inputValidationRuleValue"/>

  const typeDecimal = () =>
      <DecimalInput id={"value" + props.validationRule.id} value={formatPropertyValue(props.validationRule.value)}
                    handleOnChange={handleValueChange} data-testid="inputValidationRuleValue"/>

  const typeHexadecimal = () =>
      <HexadecimalInput id={"value" + props.validationRule.id} value={formatPropertyValue(props.validationRule.value)}
                        handleOnChange={handleValueChange} data-testid="inputValidationRuleValue"/>

  const typeDateTime = () =>
      <DateTimeInput id={"value" + props.validationRule.id} value={formatPropertyValue(props.validationRule.value)}
                     handleOnChange={handleValueChange} data-testid="inputValidationRuleValue"/>

  const typeRegex = () =>
      <RegexInput id={"value" + props.validationRule.id} value={formatPropertyValue(props.validationRule.value)}
                  handleOnChange={handleValueChange} data-testid="inputValidationRuleValue"/>

  const typePropertyKey = (kindPropertyType: string) =>
      <Form.Select id={"value" + props.validationRule.id} onChange={handleSelectValueChange}
                    value={formatPropertyValue(props.validationRule.value)} required data-testid="inputValidationRuleValue">
        <option value="">Select property...</option>
        {props.otherProperties.filter(p => equalsIgnoreCase(p.type, kindPropertyType)).map(property => <option key={property.key} value={property.key}>{property.key}</option>)}
      </Form.Select>

  return (
    <Row>
      <Col xs="5" lg="5">
        <Form.Group className="mb-3">
          <Form.Select id="type" value={props.validationRule.type} onChange={handleTypeChange} data-testid="ddlValidationRuleType">
            {props.validationTypes.map(vt => <option key={vt.name} value={vt.name}>{vt.name}</option>)}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col xs="5" lg="6">
        {props.validationRule.type &&
        <Form.Group className="mb-3">
          {renderValueInput()}
        </Form.Group>
        }
      </Col>
      <Col xs="2" lg="1" className="text-end">
        <Button variant="danger" onClick={removeValidationRule}>X</Button>
      </Col>
    </Row>
  )

})


