import React, { FC, useState } from 'react';
import {observer} from "mobx-react-lite";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {useSetGameEnvironmentFromParam} from "../common/useSetGameEnvironmentFromParam";
import {useExperiment} from "../../../loaders/useExperiment";
import {useExperimentKinds} from "../../../loaders/useExperimentKinds";
import {Experiment} from "./Experiment";
import {useSpecificationsForExperiments} from "../../../loaders/useSpecificationsForExperiments";
import {useEntitySelections} from "../../../loaders/useEntitySelections";
import { useFindExperiments } from '../../../loaders/useFindExperiments';
import { ExperimentStatus } from '@yakoffice/publisher-types';
import { IExperimentSearchParams } from '../../../api/requests/ExperimentApiGateway';
import {useSpecificationsForKinds} from "../../../loaders/useSpecificationsForKinds";


export const ExperimentContainer : FC = observer(() => {

  // Required
  useSetGameEnvironmentFromParam();
  const [refreshToggle, setRefreshToggle] = useState(true);
  const experimentResult = useExperiment(refreshToggle)
  const [searchParams] = useState<IExperimentSearchParams>({  statuses: [ExperimentStatus.Published] })
  const publishedExperimentsResult = useFindExperiments(searchParams);

  // Eager re-load in case other user has changed them
  const experimentKindsResult = useExperimentKinds()
  const specificationsForExperimentsResult = useSpecificationsForExperiments();
  const entitySelectionResult = useEntitySelections()
  const specificationsForKindsResult = useSpecificationsForKinds();

  return (
    <div id="experiment">
      {!experimentResult.isLoaded
      || experimentResult.experiment == null
      || !publishedExperimentsResult.isLoaded
      || !specificationsForExperimentsResult.isLoaded
      || !specificationsForKindsResult.isLoaded
      || !entitySelectionResult.isLoaded
        ? <CustomSpinner spinnerText={'Loading experiment and specifications...'} />
        : <Experiment experiment={experimentResult.experiment}
                      publishedExperiments={publishedExperimentsResult.experiments}
                      experimentKindsResult={experimentKindsResult}
                      specificationsForExperiments={specificationsForExperimentsResult.specificationKinds}
                      specificationsForKinds={specificationsForKindsResult.specificationKinds ?? []}
                      refreshToggle={refreshToggle}
                      setRefreshToggle={setRefreshToggle}
       />

      }
    </div>
  )
})
