import React, { FC, useEffect, useState } from 'react';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { EntityVersionTable } from './EntityVersionTable';
import { Checked } from '../common/Checked';
import { IEntityVersionVm } from '@yakoffice/publisher-types';
import { useMultiSelect } from '../../components/use-multi-select/useMultiSelect';
import { Container } from 'react-bootstrap';

interface PropTypes {
  className?: string,
  entitiesTitle? : string
  entityVersions  : IEntityVersionVm[]
  allowSelection? : boolean,
  selectedEntityButtonName? : string,
  handleSelectedEntities? : (entityVms: IEntityVersionVm[]) => void
}

export const GroupByKindEntityVersionContent : FC<PropTypes> = ({className, entitiesTitle, entityVersions, allowSelection, selectedEntityButtonName, handleSelectedEntities}) => {

  const [checkAll, setCheckAll] = useState(false);
  const [checkedEntities, setCheckedEntities] = useState<Checked<IEntityVersionVm>[]>(entityVersions.map(e => ({checked: checkAll,value: e})))
  const multiSelect = useMultiSelect<Checked<IEntityVersionVm>>({
    items: checkedEntities,
    selectItem: (item, selected) => {
      item.checked = selected;
      setCheckedEntities(prevState => prevState.map(p => p));
    }
  });

  useEffect(() => {
      setCheckAll(false);
      setCheckedEntities(entityVersions.map(e => ({ checked: false, value: e })))
    }
    ,[entityVersions])

  const handleCheckChange = (checkedEntity: Checked<IEntityVersionVm>, checked: boolean) => {
    multiSelect.handleItemSelection(checkedEntity, checked)
  }

  const handleCheckAll = () => {
    const newState = !checkAll;
    multiSelect.handleSelectAll(newState);
    setCheckAll(newState);
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <ContentBoxNavBar variant="dark" expand="lg">
          <Container>
            <Navbar.Brand className="text-white"><i className="fas fa-file" />{entitiesTitle ?? "Entities"}
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className={`justify-content-end`}>
              {allowSelection && entityVersions.length > 0 &&
                <Button variant="success"
                        onClick={() => handleSelectedEntities && handleSelectedEntities(checkedEntities.filter(c => c.checked).map(c => c.value))}
                        disabled={!checkedEntities.find(ce => ce.checked)}
                        className="me-2"
                        data-testid="btnSelectedEntities"
                >
                  <i className="fas fa-save" /> {selectedEntityButtonName}
                </Button>
              }
            </Navbar.Collapse>
          </Container>
        </ContentBoxNavBar>
      </ContentBoxHeader>
      <ContentBoxBody>
        <EntityVersionTable className={className}
                            checkedEntityVersions={checkedEntities}
                            displayCheckBoxes={!!allowSelection}
                            checkAll={checkAll}
                            handleToggleCheckAll={handleCheckAll}
                            handleCheckChanged={handleCheckChange}
        />
      </ContentBoxBody>
    </ContentBox>
  )
}
