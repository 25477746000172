import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {IKindVersion} from "../../../model/kind/KindVersion";
import {SpecificationsSelector} from "./SpecificationsSelector";
import {IKindProperty} from "../../../model/kind/KindProperty";
import {IEntityProperty} from "../../../model/entity/EntityProperty";
import {Specifications} from "./Specifications";
import { HorizontalDivider } from '../../components/dividers/HorizontalDivider';

interface PropsType {
    specificationProperties : IEntityProperty[];
    specificationKindVersions : IKindVersion[];
    handleAddSpecificationProperty: (specificationKind: IKindVersion, specificationProperty: IKindProperty) => Promise<void>;
    handleRemoveSpecificationProperty: (property: IEntityProperty) => Promise<void>
}

export const SpecificationsEditor : FC<PropsType> = observer((props) => {

    return (
        <React.Fragment>
            <div>
                <SpecificationsSelector specificationKinds={props.specificationKindVersions} handleAddSpecificationProperty={props.handleAddSpecificationProperty}/>
            </div>
            <HorizontalDivider />
            {props.specificationProperties.length > 0 &&
            <div>
                <Specifications specificationProperties={props.specificationProperties} specificationKinds={props.specificationKindVersions} handleRemoveSpecificationProperty={props.handleRemoveSpecificationProperty} />
            </div>
            }
        </React.Fragment>

    );
});
