import React, { FC } from 'react';
import { useEntitySelections } from '../../../loaders/useEntitySelections';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { Route, Routes } from 'react-router';
import { Entities } from '../entities/Entities';
import { EntityContainer } from '../entity/EntityContainer';
import { useSetGameEnvironmentFromParam } from '../common/useSetGameEnvironmentFromParam';
import {PubRouteItems} from "../../routes";
import {joinRoute} from "@yakoffice/shared-components";

export const KindGameEnvironmentIndex : FC = () => {

  useSetGameEnvironmentFromParam();
  const entitySelectionResult = useEntitySelections()

  return !entitySelectionResult.isLoaded
    ? <CustomSpinner spinnerText="Loading entity selections..." />
    : <Routes>

      <Route path={PubRouteItems.Entities} element={<Entities />}/>

      <Route path={joinRoute(PubRouteItems.Entities, PubRouteItems.AddEntity)} element={<EntityContainer />} />
      <Route path={joinRoute(PubRouteItems.Entities, PubRouteItems.EntityIdParam)} element={<EntityContainer />} />
      <Route path={joinRoute(PubRouteItems.Entities, PubRouteItems.EntityIdParam, PubRouteItems.EntityVersions, PubRouteItems.EntityVersionIdParam)} element={<EntityContainer />} />

    </Routes>

}
