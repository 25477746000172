import { useEffect, useState } from 'react';
import { IEntityVersion } from '../model/entity/EntityVersion';
import { IKindVersion } from '../model/kind/KindVersion';
import { useEntityVersion } from './useEntityVersion';
import { useKindVersionForEntityVersion } from './useKindVersionForEntityVersion';

interface useEntityVersionWithKindVersionResult{
  isLoaded : boolean;
  entityVersion : IEntityVersion | null
  kindVersion : IKindVersion | null
}

export const useEntityVersionWithKindVersion = () => {

  const entityVersionResult = useEntityVersion();
  const kindVersionResult = useKindVersionForEntityVersion(entityVersionResult.entityVersion)

  const [result, setResult] = useState<useEntityVersionWithKindVersionResult>(() => ({
    isLoaded: false,
    entityVersion: null,
    kindVersion: null
  }));

  useEffect(() => {
    setResult({ isLoaded: false, entityVersion: null, kindVersion: null })

    if(entityVersionResult.isLoaded &&
      entityVersionResult.entityVersion != null &&
      kindVersionResult.isLoaded &&
      kindVersionResult.kindVersion != null)
      setResult({ isLoaded: true, entityVersion: entityVersionResult.entityVersion, kindVersion: kindVersionResult.kindVersion })
  }, [entityVersionResult, kindVersionResult]);

  return result;
};
