import {flow, Instance, types}              from "mobx-state-tree";
import { IGameVersionDto, IGameVersionVm, ISearchResultVm } from '@yakoffice/publisher-types';
import {formatError}                        from '../Utils';
import { GameVersion, IGameVersion } from './GameVersion';
import { GameVersionApiGateway, IGameVersionSearchParams } from '../../api/requests/GameVersionApiGateway';

export const  GameVersionStore = types.model(
    "GameVersionStore",
    {
        apiGateway          : GameVersionApiGateway,
        gameVersions        : types.array(GameVersion),
        currentGameVersion  : types.maybeNull(types.reference(GameVersion)),
        isLoading           : false,
    })
    .views(self => ({
        getCurrentGameVersion() : IGameVersion{
            if(self.currentGameVersion)
                return self.currentGameVersion;

            throw new Error("The current game version has not been set")
        }
    }))
    .actions(self => ({
      clearCurrentGameVersion() {
        self.currentGameVersion = null;
      },
      setCurrentGameVersion(gameVersionId: number) {

        const selectedGE = self.gameVersions.find(ge => ge.id === gameVersionId);

        if (selectedGE) {
          self.currentGameVersion = null;
          self.currentGameVersion = selectedGE;
        } else {
          throw new Error(`Game version with id ${gameVersionId} does not exist`)
        }
      },
      addGameVersion() {
        const gameVersion = GameVersion.create({ isNew: true });
        self.gameVersions.push(gameVersion);
        return gameVersion as IGameVersion;
      },
      loadGameVersions: flow(function* (searchParams: IGameVersionSearchParams) {
        self.isLoading = true;

        try {
          const gameVersionVms = yield self.apiGateway.getGameVersions(searchParams);

          const gameVersions: IGameVersion[] = gameVersionVms.map((gv: IGameVersionVm) => GameVersion.create(MapVmToModel(gv)));
          self.gameVersions.replace(gameVersions.sort((v1, v2) => v2.name.localeCompare(v1.name, undefined, {numeric: true, sensitivity: 'base'})));
          self.isLoading = false;
        } catch (e:any) {
          throw new Error(`Failed to load game versions: ${e.message}`);
        }
      }),
      getGameVersion: flow(function* (gameVersionId: number) {
        self.isLoading = true;

        try {
          const gameVersionVm = yield self.apiGateway.getGameVersion(gameVersionId);
          const gameVersion = GameVersion.create(MapVmToModel(gameVersionVm));

          const indexOf = self.gameVersions.findIndex(gv => gv.id === gameVersion.id);
          if (indexOf === -1)
            self.gameVersions.push(gameVersion);
          else
            self.gameVersions.splice(indexOf, 1, gameVersion);

          self.isLoading = false;
          return gameVersion as IGameVersion;
        } catch (e:any) {
          throw new Error(`Failed to load game version: ${e.message}`);
        }
      }),

      saveGameVersion: flow(function* (gameVersion: IGameVersion) {
        try {
          const gameVersionDto = MapToDto(gameVersion);

          if (gameVersion.isNewGameVersion())
            yield self.apiGateway.saveGameVersion(gameVersionDto); //flagged for same name
          else
            yield self.apiGateway.updateGameVersion(gameVersion.id, gameVersionDto)

        } catch (e) {
          throw formatError(e);
        }
      }),
      updateGameVersionStatus: flow(function* (gameVersion: IGameVersion, status) {
        try {
          yield self.apiGateway.updateGameVersionStatus(gameVersion.id, status)
        } catch (e) {
          throw formatError(e);
        }
      }),
      branchGameVersion: flow(function* (gameVersionId: number, branchedGameVersion: IGameVersion) {
        try {
          const branchedGameVersionDto = MapToDto(branchedGameVersion);

          yield self.apiGateway.branchGameVersion(gameVersionId, branchedGameVersionDto);

        } catch (e) {
          throw formatError(e);
        }
      }),
      deleteGameVersion: flow(function* (gameVersion: IGameVersion) {
        try {
          yield self.apiGateway.deleteGameVersion(gameVersion.id);
        } catch (e) {
          throw formatError(e);
        }
      }),
      searchInGameVersion: flow(function* (gameVersion: IGameVersion, searchValue: string, searchLimit: number) {
        try {
          const searchResults = yield self.apiGateway.searchInGameVersion(gameVersion.id, searchValue, searchLimit);
          return searchResults as ISearchResultVm[];
        } catch (e) {
          throw formatError(e);
        }
      }),
    }));



const MapVmToModel = (vm: IGameVersionVm) => {
    return {
        id: vm.id,
        status: vm.status,
        name: vm.name,
        description: vm.description,
        configApiPublishingUrlVersionId: vm.configApiPublishingUrlVersionId,
        createdAt: vm.createdAt,
        createdBy: vm.createdBy,
        updatedAt: vm.updatedAt,
        updatedBy: vm.updatedBy
    }
}

const MapToDto = (gameVersion: IGameVersion): IGameVersionDto => {
    return {
      name: gameVersion.name,
      description: gameVersion.description,
      configApiPublishingUrlVersionId: gameVersion.configApiPublishingUrlVersionId
    }
};


export interface IGameVersionStore extends Instance<typeof GameVersionStore> {}
