import { useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useRootStore } from '../../../loaders/useRootStore';

export const useSetProjectFromParam = () => {

  const rootStore = useRootStore();
  const {projectId} = useParams<{projectId: string}>()

  useEffect(() => {
      rootStore.projectStore.setCurrentProject(parseInt(projectId));
  }, [rootStore.projectStore, projectId]);
};
