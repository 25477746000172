import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import type {IKindVersion} from "../model/kind/KindVersion";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {IKindVersionSearchParams} from "../api/requests/kinds/kindVersionApiGateway";
import {KindStatus} from "@yakoffice/publisher-types";

export interface useExperimentKindsResult{
    isLoaded            : boolean;
    kinds    : IKindVersion[]
}

export const  useExperimentKinds = () => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();
    const [result, setResult] = useState<useExperimentKindsResult>({
      isLoaded: false,
      kinds: []})

    useEffect(() => {
          setResult({isLoaded: false, kinds: []})

            const searchParams : IKindVersionSearchParams = {
                isSpecificationForKinds: false,
                status: KindStatus.Live
            }
            rootStore.kindVersionStore.findAllCurrentKindVersions(searchParams)
                .then((kinds) => {
                  setResult({isLoaded: true, kinds: kinds})
                })
                .catch(e => publishNotification({
                    notificationType: NotificationType.Error,
                    title: "Error loading experiment kinds",
                    message: `${e.message}`,
                    footer: "Please try refreshing"
                }))
    }, [rootStore.kindVersionStore, publishNotification, rootStore.gameVersionStore.currentGameVersion]);

    return result;
};
