import React, {FC, useContext} from "react";
import {Link} from "react-router-dom";
import { observer } from "mobx-react-lite";
import {ClaimType} from "@yakoffice/publisher-types";
import RootStoreContext from "../../../model/RootStoreContext";
import { useGetCategoriesLink, useGetCategoryLink, useGetKindsLink } from '../../routes';


export const KindHeaderLinks : FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const getCategoriesLink = useGetCategoriesLink()
    const getCategoryLink = useGetCategoryLink()
    const getKindsLink = useGetKindsLink()

    return <React.Fragment>
        <Link to={getCategoriesLink()}>Categories</Link>
        {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory)
            ? <Link to={getCategoryLink()}>{rootStore.categoryStore.getCurrentCategory().name}</Link>
            : <span>{rootStore.categoryStore.getCurrentCategory().name} / </span>
        }
        <Link to={getKindsLink()}>Kinds</Link>
        </React.Fragment>
    ;
})
