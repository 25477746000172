import React,
{ ChangeEvent, FC }     from 'react';
import Form             from "react-bootstrap/Form";
import moment           from 'moment';
import { InputGroup }   from 'react-bootstrap';


interface PropsType {
    id              : string;
    value           : string | null;
    handleOnChange  : (dateString: string | null) => void;
    dataTestId?     : string
}


export const DateTimeInput : FC<PropsType> = (props) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {

    const dateTimeString            = moment(e.target.value).format( "YYYY-MM-DDTHH:mm:ss.SSS");
    const dateTimeStringUtcFormat = dateTimeString + "Z"

    props.handleOnChange(dateTimeStringUtcFormat ? dateTimeStringUtcFormat : null);
  }

  return (
    <InputGroup>
      <InputGroup.Text className="cursor-pointer" onClick={() => props.handleOnChange(null)}>X</InputGroup.Text>
      <Form.Control
        id={props.id}
        type="datetime-local"
        value={props.value?.substring(0, props.value.length-1)}
        onChange={handleChange}
        data-testid={props.dataTestId}
      />
    </InputGroup>
  )
}
