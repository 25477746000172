import {useEffect, useState} from "react";
import {useExperimentEntities} from "../../../../loaders/useExperimentEntities";
import {IKindVersion} from "../../../../model/kind/KindVersion";
import {IEntityVersion} from "../../../../model/entity/EntityVersion";
import {IKindProperty} from "../../../../model/kind/KindProperty";
import {IExperimentProperty} from "../../../../model/experiment/ExperimentProperty";

export const useExperimentProperty = (property: IExperimentProperty, kinds : IKindVersion[], specificationsForKinds: IKindVersion[]) => {

    const experimentEntitiesResult = useExperimentEntities(property);
    const [kind, setKind] = useState<IKindVersion | null>(null);
    const [entity, setEntity] = useState<IEntityVersion | null>(null);
    const [kindProperty, setKindProperty] = useState<IKindProperty | null>(null);

    useEffect(() =>
    {
        if(property.hasValidKindId())
            setKind(kinds.find(k => k.kind.id === property.kindId) || null)
    }, [property, property.kindId, kinds])

    useEffect(() =>
    {
        if(experimentEntitiesResult.isLoaded && property.hasValidEntityId())
            setEntity(experimentEntitiesResult.entityVersions.find(e => e.entity.id === property.entityId) || null)
    }, [property, experimentEntitiesResult.isLoaded, experimentEntitiesResult.entityVersions, property.entityId])

    useEffect(() => {
      if (kind != null && property.hasValidKindPropertyId())
        {
          const kindProperty = !property.isSpecificationProperty()
            ? kind.properties.find(kp => kp.id === property.kindPropertyId)
            : specificationsForKinds.find(k => k.kind.id === property.kindPropertyKindId)?.getProperties().find(kp => kp.id === property.kindPropertyId)
          setKindProperty(kindProperty || null)
        }
    }, [kind, property, property.entityPropertyId, specificationsForKinds])

    return {property, experimentEntitiesResult, kind, entity, kindProperty};
};
