import React, {FC} from 'react';
import moment               from 'moment';
import {compareDateToNow}   from '../../../utilities';
import {IEntityProperty} from "../../../model/entity/EntityProperty";
import { Link } from 'react-router-dom';
import { IEntityVersion } from '../../../model/entity/EntityVersion';

interface PropTypes{
    entityProperties  : IEntityProperty[],
    entityLink        : (entityVersion: IEntityVersion) => string,
    entityVersion     : IEntityVersion,
}

export const SpecificationSchedulingStatus : FC<PropTypes> = ({entityProperties, entityLink, entityVersion}) => {

  const startTime           = entityProperties.find( ({kindPropertyKey}) => kindPropertyKey === "startTime");
  const startTimeTypeLocal  = entityProperties.find( ({kindPropertyKey}) => kindPropertyKey === "startTimeLocal");
  const endTime             = entityProperties.find( ({kindPropertyKey}) => kindPropertyKey === "endTime");
  const endTimeLocal        = entityProperties.find( ({kindPropertyKey}) => kindPropertyKey === "endTimeLocal");
  let scheduledStatus     = <code/>;


    //Start time no end time
    if(startTime && startTime.value && !endTime) {
        if(compareDateToNow(startTime.value, !!startTimeTypeLocal && startTimeTypeLocal.value === ("true" || true)))
            // start time < now = continuous
            scheduledStatus = <code className="text-success">Continuous</code>;
        else // start time > now = scheduled
            scheduledStatus = <code className="text-info">Scheduled</code>;
    }

    //Start time with end time
    if(startTime && startTime.value && endTime && endTime.value) {
        if(compareDateToNow(startTime.value, !!startTimeTypeLocal && startTimeTypeLocal.value === ("true" || true))) {

            // start time < now && start time < end time = live
            if(Date.parse(startTime.value) < Date.parse(endTime.value))
                scheduledStatus = <code className="text-info">Live</code>;

            // start time < now && start time > end time = expired
            if(Date.parse(startTime.value) > Date.parse(endTime.value))
                scheduledStatus = <code className="text-danger">Expired</code>;
        } else {

            // start time > now && start time < end time = live
            if(Date.parse(startTime.value) < Date.parse(endTime.value))
                scheduledStatus = <code className="text-info">Scheduled</code>;

            // start time > now && start time > end time = expired
            if(Date.parse(startTime.value) > Date.parse(endTime.value))
                scheduledStatus = <code className="text-danger">Expired</code>;
        }

        if(compareDateToNow(endTime.value, !!endTimeLocal && endTimeLocal.value === ("true" || true))) {

            // end time < now > start time = expired
            if(Date.parse(endTime.value) > Date.parse(startTime.value))
                scheduledStatus = <code className="text-danger">Expired</code>;

            // end time < now < start time = expired
            if(Date.parse(endTime.value) < Date.parse(startTime.value))
                scheduledStatus = <code className="text-danger">Expired</code>;

        } else {

            // end time > now < star time = expired
            if(Date.parse(endTime.value) < Date.parse(startTime.value))
                scheduledStatus = <code className="text-danger">Expired</code>;

            // end time > now > star time = live
            // if(Date.parse(endTime.value) > Date.parse(startTime.value))
            //     scheduledStatus = <code className="text-success">Live</code>;
        }
    }

    //End time now start time
    if(endTime && endTime.value && !startTime) {

        // end time < now = expired
        if(compareDateToNow(endTime.value, !!endTimeLocal && endTimeLocal.value === ("true" || true)))
            scheduledStatus = <code className="text-danger">Expired</code>;

        else // end time > now = live
            scheduledStatus = <code className="text-success">Live</code>;
    }

    return (
      <Link to={entityLink(entityVersion)}>
        <ul className="list-unstyled">
            <li>{scheduledStatus}</li>

            {startTime && startTime.value &&
             <li>
                {startTime.kindPropertyKey}: <strong> {moment.utc(startTime.value).format("ddd, D MMM YYYY, HH:mm")}
                  {startTimeTypeLocal && startTimeTypeLocal.value === "true" ?
                   <code> (Local)</code> :
                   <code> (UTC)</code>
                  }
                </strong>
             </li>
            }
            {endTime && endTime.value &&
             <li>
                 {endTime.kindPropertyKey}: <strong> {moment.utc(endTime.value).format("ddd, D MMM YYYY, HH:mm")}
                  {endTimeLocal && endTimeLocal.value === "true" ?
                   <code> (Local)</code> :
                   <code> (UTC)</code>
                  }
                </strong>
             </li>
            }
        </ul>
      </Link>
    );
};
