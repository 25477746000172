import React, { FC } from 'react';
import Container from 'react-bootstrap/cjs/Container';
import { ExperimentVmTable } from './ExperimentVmTable';
import { IDistributionDiffVm} from '@yakoffice/publisher-types';
import { useGameEnvironmentCssClass } from '../common/useGameEnvironmentCssClass';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';
import { GroupByKindEntityVersionContent } from './GroupByKindEntityVersionContent';
import { GroupedByKindModifiedEntityVersionsContent } from './GroupedByKindModifiedEntityVersionsContent';

interface PropTypes {
  distributionDiff: IDistributionDiffVm
  gameEnvironment: IGameEnvironment
}

export const DiffListView : FC<PropTypes> = ({distributionDiff,gameEnvironment}) => {
  const cssClass = useGameEnvironmentCssClass(gameEnvironment);

  return <Container className="listView">
    {
      [...new Set(distributionDiff.addedEntities.map(ev => ev.kindVersionSummary.name))].map(kn => (
        <GroupByKindEntityVersionContent key={kn}
                                         entitiesTitle={`Added to ${kn}`}
                                         className={cssClass.name}
                                         allowSelection={false}
                                         entityVersions={distributionDiff.addedEntities.filter(e => e.kindVersionSummary.name === kn)}
        />
      ))
    }
    {
      [...new Set(distributionDiff.deletedEntities.map(ev => ev.kindVersionSummary.name))].map(kn => (
        <GroupByKindEntityVersionContent key={kn}
                                         entitiesTitle={`Deleted from ${kn}`}
                                         className={cssClass.name}
                                         allowSelection={false}
                                         entityVersions={distributionDiff.deletedEntities.filter(e => e.kindVersionSummary.name === kn)}
        />
      ))
    }
    {
      [...new Set(distributionDiff.modifiedEntities.map(e => e.kindName))].map(kn => (
        <GroupedByKindModifiedEntityVersionsContent key={kn}
                                                    title={`Modified in ${kn}`}
                                                    className={cssClass.name}
                                                    modifiedEntities={distributionDiff.modifiedEntities.filter(e => e.kindName === kn)}
                                                    allowSelection={false} />
      ))
    }
    {distributionDiff.addedExperiments.length > 0 &&
      <ExperimentVmTable className={cssClass.name} experiments={distributionDiff.addedExperiments} title="Added Experiments" />
    }
    {distributionDiff.deletedExperiments.length > 0 &&
    <ExperimentVmTable className={cssClass.name} experiments={distributionDiff.deletedExperiments} title="Deleted Experiments" />
    }
  </Container>;
}
