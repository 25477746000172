import React, { FC } from 'react';
import {observer}         from 'mobx-react-lite';
import {CustomSpinner}    from '@yakoffice/custom-spinner';
import {useGameVersions}  from '../../../loaders/useGameVersions';
import {GameVersions}     from './GameVersions';

export const GameVersionsContainer : FC = observer(() => {

  const gameVersionsResult = useGameVersions();

  return gameVersionsResult.isLoaded && gameVersionsResult.gameVersions != null
         ? <GameVersions gameVersions={gameVersionsResult.gameVersions}/>
         : <CustomSpinner spinnerText={'Refreshing game versions...'}/>
});
