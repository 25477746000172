import {Instance, types} from "mobx-state-tree";
import {ValidationType as ValidationTypeVm} from "@yakoffice/publisher-types";
import { ValidationRequirementType } from './ValidationRequirementType';

export const ValidationType = types.model(
    "ValidationType",
    {
        name: types.enumeration<ValidationTypeVm>("name", Object.values(ValidationTypeVm)),
        requirementType: ValidationRequirementType
    })

export interface IValidationType extends Instance<typeof ValidationType> {}


