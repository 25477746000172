import {Instance, types} from "mobx-state-tree";


export const PropertyUpdatingStatus = types.model(
    "PropertyUpdatingStatus",
    {
        updating: types.boolean,
        updated: types.boolean
    });

export interface IPropertyUpdatingStatus extends Instance<typeof PropertyUpdatingStatus> {}