import {flow}                       from "mobx";
import {getRoot, Instance, types} from "mobx-state-tree"
import {pub_api}                    from "../../index";
import { EntityStatus, IEntityVersionDiffVm, IEntityVersionVm } from '@yakoffice/publisher-types';
import type {IEntityVersionDto}                 from '@yakoffice/publisher-types';
import {ApiRequest} from "../ApiRequest";
import {RootStore} from "../../../model/RootStore";

export interface IEntityVersionSearchParams{
    entityId?: number | number[]
    name?: string,
    gameVersionId?: number,
    gameEnvironmentId?: number
    kindId?: number
    kindName?: string,
    status?:string | string[]
    hasPropertyWithKeys?: string[]
    distributionId?: number,
    onlyPropertiesWithBValues?: boolean
}

export const EntityVersionApiGateway = types.model(
    "EntityApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
      getRootUrl(kindId?: number): string {
        const routeKindId = kindId ?? getRoot<typeof RootStore>(self).kindVersionStore.getCurrentKindVersion().kind.id;
        return `${pub_api}/kinds/${routeKindId}/entities/`;
      },
      getFindAllUrl(gameVersionId?: number) : string{
        const project = getRoot<typeof RootStore>(self).projectStore.getCurrentProject();
        const routeGameVersionId = gameVersionId ?? getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion().id;
        return `${pub_api}/${project.id}/versions/${routeGameVersionId}/entities/versions/current`;
      },
      getDistributedRootUrl(distributionIdOrLatest: string): string {
        const gameVersion = getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion();
        const gameEnv = getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment();
        return `${pub_api}/versions/${gameVersion.id}/game-environments/${gameEnv.id}/distributions/${distributionIdOrLatest}/entities`;
      }
    }))
    .actions(self => ({
      // Todo:  Move to EntityApiGateway??
      createEntity: flow(function* (kindId: number, entityDto: IEntityVersionDto) {
        return yield self.apiRequest.post<IEntityVersionVm>(self.getRootUrl(kindId), entityDto)
      }),
      updateEntityStatus: flow(function* (kindId: number, entityId: number, status: EntityStatus) {
        return (yield self.apiRequest.put<IEntityVersionVm[]>(`${self.getRootUrl(kindId)}status/${status}?id=${entityId}`, null))[0];
      }),
      updateEntityStatuses: flow(function* (entityIds: number[], status: EntityStatus) {
        return yield self.apiRequest.put<IEntityVersionVm[]>(`${self.getRootUrl()}status/${status}?id=${entityIds.join('&id=')}`, null)
      }),
      moveEntity: flow(function* (entityId: number, gameEnvironmentId: number) {
        return yield self.apiRequest.put<IEntityVersionVm[]>(`${self.getRootUrl()}${entityId}/move/${gameEnvironmentId}`, null)
      }),
      deleteEntity: flow(function* (entityId: number) {
        yield self.apiRequest.delete(`${self.getRootUrl()}${entityId}`)
      }),

      // EntityVersion
      createEntityVersion: flow(function* (kindId: number, entityId: number, entityDto: IEntityVersionDto) {
        return yield self.apiRequest.post<IEntityVersionVm>(`${self.getRootUrl(kindId)}${entityId}/versions`, entityDto)
      }),
      getCurrentVersionForEntity: flow(function* (entityId: number) {
        return yield self.apiRequest.get<IEntityVersionVm>(self.getRootUrl() + `${entityId}/versions/current`)
      }),
      getVersionForEntity: flow(function* (entityId: number, version: number) {
        return yield self.apiRequest.get<IEntityVersionVm>(self.getRootUrl() + `${entityId}/versions/${version}`)
      }),
      getCurrentEntityVersionsForCurrentKind: flow(function* (searchParams: IEntityVersionSearchParams) {
        const queryString = buildEntityVersionSearchQueryString(searchParams);
        return yield self.apiRequest.get<IEntityVersionVm[]>(`${self.getRootUrl()}versions/current?${queryString}`)
      }),
      countCurrentEntityVersionsForCurrentKind: flow(function* (searchParams: IEntityVersionSearchParams) {
        const queryString = buildEntityVersionSearchQueryString(searchParams);
        return yield self.apiRequest.get<number>(`${self.getRootUrl()}versions/current/count?${queryString}`)
      }),
      getCurrentEntityVersions: flow(function* (searchParams: IEntityVersionSearchParams) {
        const queryString = buildEntityVersionSearchQueryString(searchParams);
        return yield self.apiRequest.get<IEntityVersionVm[]>(`${self.getFindAllUrl(searchParams.gameVersionId)}?${queryString}`)
      }),
      getDiffBetweenCurrentEntityVersions: flow(function* (entityId: number, entityVersion: number, diffEntityId: number, diffEntityVersion: number) {
        return yield self.apiRequest.get<IEntityVersionDiffVm>(`${pub_api}/entities/${entityId}/versions/${entityVersion}/differences/entities/${diffEntityId}/versions/${diffEntityVersion}`)
      }),

      // Distributions
      loadPublishedEntityVersions: flow(function* () {
        return yield self.apiRequest.get<IEntityVersionVm[]>(self.getDistributedRootUrl("published"));
      }),
      findDistributedEntityVersions: flow(function* (distributionId: number, searchParams: IEntityVersionSearchParams) {
        let queryString = "";
        if (searchParams.onlyPropertiesWithBValues)
          queryString += `onlyPropertiesWithBValues=${searchParams.onlyPropertiesWithBValues}&`;

        return yield self.apiRequest.get<IEntityVersionVm[]>(`${self.getDistributedRootUrl(distributionId.toString())}?${queryString}`);
      }),

      downloadEntitiesAsJSON: flow(function* (entityIds: number[]) {
        return yield self.apiRequest.get<IEntityVersionVm[]>(`${self.getRootUrl()}versions/current/distribution-json?id=${entityIds.join('&id=')}`)
      })
    }))

export function buildEntityVersionSearchQueryString(searchParams: IEntityVersionSearchParams) {

  let queryString = "";
  if (searchParams.entityId) {
    if (Array.isArray(searchParams.entityId))
      searchParams.entityId.forEach(id => {
        queryString += `id=${id}&`;
      })
    else
      queryString += `id=${searchParams.entityId}&`;
  }
  if (searchParams.name)
    queryString += `name=${searchParams.name}&`;
  if (searchParams.gameEnvironmentId)
    queryString += `game-environment-id=${searchParams.gameEnvironmentId}&`;
  if (searchParams.kindId)
    queryString += `kindId=${searchParams.kindId}&`;
  if (searchParams.kindName)
    queryString += `kind-name=${searchParams.kindName}&`;
  if (searchParams.status) {
    if (Array.isArray(searchParams.status))
      searchParams.status.forEach(status => {
        queryString += `status=${status}&`;
      })
    else
      queryString += `status=${searchParams.status}&`;
  }
  if (searchParams.hasPropertyWithKeys)
    searchParams.hasPropertyWithKeys.forEach(key => queryString += `hasPropertyWithKey=${key}&`);

  return queryString;
}

export interface IEntityApiGateway extends Instance<typeof EntityVersionApiGateway> {}
