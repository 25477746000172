import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import type {IKindVersion} from "../model/kind/KindVersion";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";

export interface useSpecificationsForKindsResult{
    isLoaded            : boolean;
    specificationKinds    : IKindVersion[] | null
}

export const useSpecificationsForKinds = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const [result, setResult] = useState<useSpecificationsForKindsResult>({ isLoaded: false, specificationKinds: [] })

  useEffect(() => {
    setResult({ isLoaded: false, specificationKinds: [] })

    rootStore.specificationsForKindsStore.loadSpecifications()
      .then(() => {
        setResult({ isLoaded: true, specificationKinds: rootStore.specificationsForKindsStore.getKinds() })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading specifications kinds",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.specificationsForKindsStore, publishNotification, rootStore.gameVersionStore.currentGameVersion ]);

  return result;
};
