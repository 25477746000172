import { useEffect, useState } from 'react';
import { useRootStore } from './useRootStore';
import { NotificationType, usePublishNotification } from '@yakoffice/notification-handler';

export interface GoogleSheetExistsResult{
  isLoaded : boolean
  spreadSheetExists: boolean,
  sheetExists: boolean
}

export const useGoogleSheetExists = () => {
  const rootStore = useRootStore();
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<GoogleSheetExistsResult>({isLoaded: false, spreadSheetExists: false, sheetExists: false})

    useEffect(() => {
      const kindVersion = rootStore.kindVersionStore.currentKindVersion

      if (kindVersion != null) {
        setResult({ isLoaded: false, spreadSheetExists: false, sheetExists: false })

        if (kindVersion.kind.googleSheetId == null)
          setResult({ isLoaded: true, spreadSheetExists: false, sheetExists: false })
        else {
          rootStore.kindVersionStore.sheetExists(kindVersion, rootStore.gameEnvironmentStore.getCurrentGameEnvironment())
            .then(sheetExists => {
              setResult({ isLoaded: true, spreadSheetExists: true, sheetExists: sheetExists })
            })
            .catch((e: any) => {
              publishNotification({
                notificationType: NotificationType.Error,
                title: "Error loading google sheet status",
                message: `${e.message}`,
                footer: "Please try refreshing"
              })
            })
        }
      }
    }, [rootStore.kindVersionStore, rootStore.gameEnvironmentStore, publishNotification, rootStore.gameEnvironmentStore.currentGameEnvironment, rootStore.kindVersionStore.currentKindVersion, rootStore.kindVersionStore.currentKindVersion?.kind])

  return result;
}
