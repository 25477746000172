import React, {FC, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import Row                              from 'react-bootstrap/Row';
import Col                              from 'react-bootstrap/Col';
import Form                             from 'react-bootstrap/Form';
import {IExperimentProperty} from "../../../model/experiment/ExperimentProperty";
import {FormatPropertyValue} from "../common/PropertyValueDisplay";
import RootStoreContext from "../../../model/RootStoreContext";
import { YoFormBox, YoFormBoxBody } from '../../components/yo-form-box/YoFormBox';
import { IVariant } from '../../../model/experiment/Variant';

interface PropsType {
    variants: IVariant[]
    property: IExperimentProperty
}

export const ExperimentReadonlyProperty : FC<PropsType> = observer(({variants, property}) => {

    const rootStore = useContext(RootStoreContext);

    return (
        <YoFormBox>
            <YoFormBoxBody>
                <Row>
                    <Col md="12">
                        <Form.Group className="mb-3">
                            <Form.Label>Kind</Form.Label>
                            <Form.Control type="text" value={property.kindName} disabled={true} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Entity</Form.Label>
                            <Form.Control type="text" value={property.entityName} disabled={true} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Property</Form.Label>
                            <Form.Control type="text" value={property.kindPropertyKey} disabled={true} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Values</Form.Label>
                            <Row className="mt-2">
                              <Col md={3} className="text-end align-self-center">Control:</Col>
                              <Col md={9} className="ps-4">{FormatPropertyValue(rootStore, property.kindPropertyType, property.kindPropertySelectionId, property.kindPropertyEntitySelectionKindId, property.entityPropertyValue)}</Col>
                            </Row>
                            {property.values.map(variantValue =>
                            <Row className="mt-2" key={variantValue.id}>
                              <Col md={3} className="text-end align-self-center">{variants.find(v => v.id === variantValue.variantId)?.name}:</Col>
                              <Col md={9}><Form.Control type="text" value={FormatPropertyValue(rootStore, property.kindPropertyType, property.kindPropertySelectionId, property.kindPropertyEntitySelectionKindId, variantValue.value) || ""} disabled={true} /></Col>
                            </Row>
                            )}

                        </Form.Group>
                    </Col>

                </Row>
            </YoFormBoxBody>
        </YoFormBox>
    )
})

