import {Instance, types, getRoot} from "mobx-state-tree"
import {pub_api} from "../../index";
import {
  IKindVersionDto,
  IKindVersionPatchDto,
  IKindVersionPropertyPatchDto, IKindVm
} from '@yakoffice/publisher-types';
import type {IKindVersionVm} from "@yakoffice/publisher-types";
import {flow} from "mobx";
import {RootStore} from "../../../model/RootStore";
import {ApiRequest} from "../ApiRequest";
import { buildEntityVersionSearchQueryString } from '../entities/entityVersionApiGateway';

export interface IKindVersionSearchParams{
    isSpecificationForKinds?: boolean,
    isSpecificationForExperiments?: boolean,
    hasPropertyUsingSelectionId?: number,
    status?: string,
    gameVersionId?:number,
    name?:string
}

export const KindVersionApiGateway = types.model(
    "KindVersionApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl() : string{
          const category = getRoot<typeof RootStore>(self).categoryStore.getCurrentCategory();
            return `${pub_api}/categories/${category.id}/kinds/`;
        },
      getSheetRootUrl(kindId: number, gameEnvironmentId? : number) : string{
        const routeGameEnvironmentId = gameEnvironmentId ?? getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment().id;
        return `${pub_api}/kinds/${kindId}/game-environments/${routeGameEnvironmentId}/sheet`;
      },
        getFindAllUrl(gameVersionId?: number) : string{
          const project = getRoot<typeof RootStore>(self).projectStore.getCurrentProject();
          const routeGameVersionId = gameVersionId ?? getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion().id;
          return `${pub_api}/${project.id}/versions/${routeGameVersionId}/kinds/versions/current`;
        }
    }))
    .actions(self => ({
      // Kind
      createKind: flow(function* (kindDto: IKindVersionDto) {
        return yield self.apiRequest.post<IKindVersionVm>(self.getRootUrl(), kindDto)
      }),
      createSheet: flow(function* (kindId: number, entityStatuses: string[]) {
        const querystring = buildEntityVersionSearchQueryString({status: entityStatuses})
        return yield self.apiRequest.post<IKindVm>(`${self.getSheetRootUrl(kindId)}?${querystring}`, null)
      }),
      sheetExists: flow(function* (kindId: number, gameEnvironmentId: number) {
        return yield self.apiRequest.get<IKindVm>(`${self.getSheetRootUrl(kindId, gameEnvironmentId)}/exists`)
      }),
      refreshSheet: flow(function* (kindId: number, entityStatuses: string[]) {
        const querystring = buildEntityVersionSearchQueryString({status: entityStatuses})
        return yield self.apiRequest.put<null>(`${self.getSheetRootUrl(kindId)}?${querystring}`, null)
      }),
      uploadSheet: flow(function* (kindId: number) {
        return yield self.apiRequest.put<string[]>(`${self.getSheetRootUrl(kindId)}/upload`, null)
      }),
      deleteSheet: flow(function* (kindId: number) {
        return yield self.apiRequest.delete(`${self.getSheetRootUrl(kindId)}`)
      }),
      archiveKind: flow(function* (kindId: number) {
        return yield self.apiRequest.put<IKindVersionVm>(`${self.getRootUrl()}${kindId}/status/archived`, null)
      }),
      unArchiveKind: flow(function* (kindId: number) {
        return yield self.apiRequest.put<IKindVersionVm>(`${self.getRootUrl()}${kindId}/status/live`, null)
      }),
      moveKind: flow(function* (kindId: number, categoryId: number) {
        return yield self.apiRequest.put<IKindVersionVm>(`${self.getRootUrl()}${kindId}/move/${categoryId}`, null)
      }),
      deleteKind: flow(function* (kindId: number) {
        yield self.apiRequest.delete(`${self.getRootUrl()}${kindId}`)
      }),

      // KindVersion
      createKindVersion: flow(function* (kindId: number, kindDto: IKindVersionDto) {
        return yield self.apiRequest.post<IKindVersionVm>(`${self.getRootUrl()}${kindId}/versions`, kindDto)
      }),
      patchCurrentKindVersion: flow(function* (kindId: number, kindPatchDto: IKindVersionPatchDto) {
        return yield self.apiRequest.patch<null>(`${self.getRootUrl()}${kindId}/versions/current`, kindPatchDto)
      }),
      patchCurrentKindVersionProperty: flow(function* (kindId: number, propertyId: number, kindPropertyPatchDto: IKindVersionPropertyPatchDto) {
        return yield self.apiRequest.patch<null>(`${self.getRootUrl()}${kindId}/versions/current/properties/${propertyId}`, kindPropertyPatchDto)
      }),
      getCurrentVersionForKind: flow(function* (kindId: number) {
        return yield self.apiRequest.get<IKindVersionVm>(self.getRootUrl() + `${kindId}/versions/current`)
      }),
      getVersionForKind: flow(function* (kindId: number, version: number) {
        return yield self.apiRequest.get<IKindVersionVm>(self.getRootUrl() + `${kindId}/versions/${version}`)
      }),
      getCurrentKindVersionsForCurrentCategory: flow(function* (searchParams: IKindVersionSearchParams) {
        const queryString = buildSearchQueryString(searchParams);
        return yield self.apiRequest.get<IKindVersionVm[]>(`${self.getRootUrl()}versions/current?${queryString}`)
      }),
      // NOTE:  This function does not use the categories route so is not filtered by category
      getCurrentKindVersions: flow(function* (searchParams: IKindVersionSearchParams) {
        const queryString = buildSearchQueryString(searchParams);
        return yield self.apiRequest.get<IKindVersionVm[]>(`${self.getFindAllUrl(searchParams.gameVersionId)}?${queryString}`)
      })
    }))

function buildSearchQueryString(searchParams: IKindVersionSearchParams) {
  let queryString = "";
  if (searchParams.isSpecificationForKinds !== undefined)
    queryString += `is-specification-for-kinds=${searchParams.isSpecificationForKinds}&`;
  if (searchParams.isSpecificationForExperiments !== undefined)
    queryString += `is-specification-for-experiments=${searchParams.isSpecificationForExperiments}&`;
  if (searchParams.hasPropertyUsingSelectionId !== undefined)
    queryString += `has-property-using-selection-id=${searchParams.hasPropertyUsingSelectionId}&`;
  if (searchParams.status)
    queryString += `status=${searchParams.status}&`;
  if (searchParams.name)
    queryString += `name=${searchParams.name}&`;
  return queryString;
}

export interface IKindApiGateway extends Instance<typeof KindVersionApiGateway> {}
