import React, {ChangeEvent, FC} from "react";
import {observer}               from "mobx-react-lite";
import {ICategory}              from "../../../model/category/Category";
import Col                      from 'react-bootstrap/Col';
import Row                      from 'react-bootstrap/Row';
import Form                     from "react-bootstrap/Form";
import { LabelHeader } from '@yakoffice/shared-components';


interface PropsType {
    category : ICategory
}


export const CategoryMainInfo : FC<PropsType> = observer((props) => {

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.category.setName(e.target.value);
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.category.setDescription(e.target.value);
    };

    return <Row>
        <Col sm="6">
            <Form.Group>
                <Form.Label>
                    <LabelHeader><i className="fas fa-signature"/>Name</LabelHeader>
                </Form.Label>
                <Form.Control type="text" placeholder="" onChange={handleNameChange} value={props.category != null ? props.category.name : ""} required data-testid="txtCategoryName" />
            </Form.Group>
        </Col>
        <Col sm="6">
            <Form.Group>
                <Form.Label>
                    <LabelHeader><i className="fas fa-align-left"/>Description</LabelHeader>
                </Form.Label>
                <Form.Control type="text" placeholder="" onChange={handleDescriptionChange} value={props.category != null ? props.category.description : ""} required data-testid="txtCategoryDescription"/>
                <Form.Text className="text-muted">
                    Use this field to add more information about the category.
                </Form.Text>
            </Form.Group>
        </Col>
    </Row>
});
