import React, {FC, useState} from "react";
import {observer} from "mobx-react-lite";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {IEntityVersionVm } from '@yakoffice/publisher-types';
import Alert from 'react-bootstrap/cjs/Alert';
import {ContentBox, ContentBoxBody, ContentBoxHeader, ContentBoxNavBar} from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import {CollapsePanel} from '@yakoffice/shared-components';
import {YoListGroup, YoListGroupItem} from '../../components/yo-list-group/YoListGroup';
import { Container, NavLink } from 'react-bootstrap';
import { GroupByKindEntityVersionContent } from './GroupByKindEntityVersionContent';
import Button from 'react-bootstrap/Button';
import {useDistributionsController} from '../../../controllers/useDistributionsController';

interface PropTypes {
    className?: string,
    entityVersions  : IEntityVersionVm[]
    entitiesTitle? : string
    allowSelection?: boolean
    selectedEntityButtonName? : string,
    handleSelectedEntities? : (entityVms: IEntityVersionVm[]) => void,
}

export const GroupedByKindEntityVersions : FC<PropTypes> = observer(({className, entityVersions, entitiesTitle, allowSelection, selectedEntityButtonName, handleSelectedEntities}) => {

  const [kindNameVersionsMap] = useState([...new Set(entityVersions.map(ev => ev.kindVersionSummary.name))].map(kn => ({
      kindName: kn,
      versions: [...new Set(entityVersions.filter(ev => ev.kindVersionSummary.name === kn).map(ev => `v${ev.kindVersionSummary.version}`))]
    }))
    .sort((k1, k2) => k1.kindName.localeCompare(k2.kindName)))
  const [kindName, setKindName] = useState(kindNameVersionsMap.length > 0 ? kindNameVersionsMap[0].kindName : null)

  const [kindIdVersionsMap] = useState([...new Set(entityVersions.map(ev => ev.kindVersionSummary.kindId))].map(kId => ({
    kindId: kId})).sort((a, b) => a.kindId - b.kindId));


  return <>
    <Row>
      <Col>
        {kindNameVersionsMap.find(knMap => knMap.versions.length > 1) &&
        <Alert variant="warning"><strong>Warning: </strong> Some entities are using different versions of the same kind</Alert>
        }
      </Col>
    </Row>
    <Row>
      <Col sm="4" lg="3">
        <ContentBox>
          <ContentBoxHeader>
            <ContentBoxNavBar variant="dark" expand="lg">
              <Container>
                <Navbar.Brand className="text-white">
                  <i className="fas fa-folder" />Kinds
                </Navbar.Brand>
              </Container>
            </ContentBoxNavBar>
          </ContentBoxHeader>
          <ContentBoxBody>
            <CollapsePanel>
              <YoListGroup className={`mt-3`}>
                {kindNameVersionsMap
                  .map(knMap =>
                    <YoListGroupItem key={knMap.kindName} active={knMap.kindName === kindName}>
                      <NavLink bsPrefix="text-link" eventKey={knMap.kindName} onClick={() => setKindName(knMap.kindName)}  data-testid={`pill${knMap.kindName}`}>
                        {knMap.kindName} [{knMap.versions.join(", ")}]
                        ({entityVersions.filter(ev => ev.kindVersionSummary.name === knMap.kindName).length})
                        {knMap.versions.length > 1 && <i className="fas fa-exclamation-triangle ms-2 text-warning" />}
                      </NavLink>
                    </YoListGroupItem>
                  )}
              </YoListGroup>
            </CollapsePanel>
          </ContentBoxBody>
        </ContentBox>
      </Col>
      <Col sm="8" lg="9">
        <GroupByKindEntityVersionContent entitiesTitle={entitiesTitle}
                                         className={className}
                                         allowSelection={allowSelection}
                                         selectedEntityButtonName={selectedEntityButtonName}
                                         entityVersions={entityVersions.filter(e => e.kindVersionSummary.name === kindName)}
                                         handleSelectedEntities={handleSelectedEntities}/>
      </Col>
    </Row>
  </>
})
