import React, {ChangeEvent, FC} from "react";
import {observer} from "mobx-react-lite";
import {IKindProperty} from "../../../model/kind/KindProperty";
import Form from "react-bootstrap/Form";
import {IKindVersion} from "../../../model/kind/KindVersion";

interface PropTypes{
    kindProperty : IKindProperty,
    entitySelectionKindVersions: IKindVersion[]
}

export const KindPropertyEntitySelectionInput : FC<PropTypes> = observer(({kindProperty, entitySelectionKindVersions}) => {

  const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const kindId = parseFloat(e.target.value);
    kindProperty.setEntitySelection(kindId, null);
  }

  return <Form.Group>
    <Form.Label>Kind</Form.Label>
    <Form.Select id="selections"
                 value={kindProperty.entitySelectionKindId ? kindProperty.entitySelectionKindId : "default"}
                 onChange={handleSelectionChange}
                 data-testid="ddlKindPropertyEntitySelectionKind">
      <option value="default" disabled hidden>Select a kind...</option>
      {entitySelectionKindVersions.map(k =>
        <option key={k.kind.id} value={k.kind.id}>{k.name}</option>
      )}
    </Form.Select>
  </Form.Group>
})
