import React, {FC, useContext, useState} from "react";
import {observer}               from "mobx-react-lite";
import {IKindVersion}                  from "../../../model/kind/KindVersion";
import Col                      from 'react-bootstrap/Col';
import Row                      from 'react-bootstrap/Row';
import Button                   from "react-bootstrap/Button";
import {KindAddProperty}          from "./kindAddProperty";
import RootStoreContext         from "../../../model/RootStoreContext";
import {IKindProperty}          from "../../../model/kind/KindProperty";
import {IKindController}        from "../../../controllers/useKindController";
import { DragAndDrop, DragAndDropClassNames } from '../common/DragAndDrop';
import { Nav, Tab } from 'react-bootstrap';
import { YoPillNavLink } from '../../components/yo-pills/YoPills';
import { EditPropertyHoldingPanel } from '../../components/edit-property-holding-panel/EditPropertyHoldingPanel';

interface PropsType {
    kindVersion                        : IKindVersion,
    controller                  : IKindController
}

export const KindCustomProperties : FC<PropsType> = observer((props) => {

  const rootStore = useContext(RootStoreContext);
  const [activeProperty, setActiveProperty] = useState(props.kindVersion.properties.length > 0 ? props.kindVersion.properties[0] : null)

    const handleAddProperty = () => {
        setActiveProperty(props.kindVersion.addProperty());
    };

    const handleDeleteProperty = (property: IKindProperty) => {
        if(activeProperty === property)
            setActiveProperty(null)

        props.kindVersion.deleteProperty(property)
    }

    return <Tab.Container id="kindCustomProperties" activeKey={activeProperty ? activeProperty.id : "selectPropertyPaneKey"}>
      <Row xs="12" sm="4">
        <Col xs="12" sm="4">
          <Nav variant="pills" className="flex-column mt-2">
            <DragAndDrop handleDrop={props.kindVersion.reorderProperties}>
              {props.kindVersion.properties.map((p: IKindProperty) => (
                <Nav.Item key={p.id} id={p.id.toString()} draggable={true} className={DragAndDropClassNames.Draggable}>
                  <YoPillNavLink eventKey={p.id.toString()} onClick={() => setActiveProperty(p)} data-testid={`btnKindPropertyPill${p.key}`}>
                    {p.key ? p.key : "(New Property Name)"} <i className="fas fa-ellipsis-v float-end" />
                  </YoPillNavLink>
                </Nav.Item>
                  )
              )}
            </DragAndDrop>
          </Nav>
          <div className="d-grid gap-2">
            <Button variant="outline-primary" className="mt-3 mb-5" onClick={handleAddProperty} data-testid="btnAddNewProperty">
              <i className="fas fa-plus-circle"/> Add new property
            </Button>
          </div>
        </Col>
        <Col xs="12" sm="8">
          <Tab.Content>
            <Tab.Pane eventKey="selectPropertyPaneKey">
              <EditPropertyHoldingPanel />
            </Tab.Pane>
            {props.kindVersion.properties.map(p => (
              <Tab.Pane key={p.id} eventKey={p.id} data-testid="tabKindCustomProperty">
                <KindAddProperty property={p}
                                 handleUpdatePropertyDescription={props.controller.handleUpdatePropertyDescription}
                                 selections={rootStore.selectionStore.selections}
                                 entitySelectionKinds={rootStore.kindsForEntitySelectionStore.getKinds()}
                                 deleteProperty={handleDeleteProperty}/>
              </Tab.Pane>
            ))
            }
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  });
