
import React from "react";
import Button from "react-bootstrap/Button";
import {useShowModal} from '@yakoffice/custom-modal';
import { EntityStatus, IEntityVersionDiffVm, IEntityVersionVm } from '@yakoffice/publisher-types';
import { NotificationType, usePublishNotification } from '@yakoffice/notification-handler';
import { useRootStore } from '../loaders/useRootStore';
import { IEntityVersion } from '../model/entity/EntityVersion';
import { IGameVersion } from '../model/gameVersion/GameVersion';
import { useShowModalSpinner } from '@yakoffice/custom-modal';
import { IGameEnvironment } from '../model/gameEnvironment/GameEnvironment';

export interface IDistributionDiffController{
  handleMergeEntityDiffs : (targetGameVersion: IGameVersion, entityVersionDiffs : IEntityVersionDiffVm[], successCallback? : () => void) => void;
  handleStopEntities : (entities: IEntityVersionVm[], successCallback? : () => void) => void
  handleCopyPublishEntities : (originGameVersion: IGameVersion, targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment, entityVersionVms : IEntityVersionVm[], successCallback? : () => void) => void;
}

export const useDistributionDiffController = () => {

  // Context Hooks
  const showModal = useShowModal();
  const showModalSpinner = useShowModalSpinner();
  const rootStore = useRootStore();
  const publishNotification = usePublishNotification();

  // Handlers
  const handleMergeEntityDiffs = (targetGameVersion: IGameVersion, entityVersionDiffs: IEntityVersionDiffVm[], successCallback: () => void = () => {/**/}) => {

    const mergeModifiedProperties = async () => {
      showModalSpinner("Merge Modified Properties", "Finding entities to update...");

      const entityVersions = await rootStore.entityVersionStore.findAllCurrentEntityVersions({gameVersionId: targetGameVersion.id, entityId: entityVersionDiffs.map(d => d.entityId) })

      let count = 1;
      for (const entityVersionDiff of entityVersionDiffs) {
        try {
          showModalSpinner("Merging Entities", `Merging ${count++} of ${entityVersionDiffs.length} entities`)

          const toEntityVersion = entityVersions.find(ev => ev.entity.id === entityVersionDiff.entityId);

          if (!toEntityVersion) {
            publishNotification({
              notificationType: NotificationType.Error,
              title: `Error updating entity ${entityVersionDiff.name}`,
              message: "Could not find entity"
            })
            continue;
          }


          for (const propertyDiff of entityVersionDiff.modifiedProperties) {
            const toProperty = toEntityVersion.properties.find(evp => evp.kindPropertyKey === propertyDiff.kindPropertyKey);
            if (!toProperty) {
              publishNotification({
                notificationType: NotificationType.Error,
                title: `Error updating entity ${entityVersionDiff.name}`,
                message: `Could not find property ${propertyDiff.kindPropertyKey}`
              })
              continue;
            }

            toProperty.setValue(propertyDiff.diffValue)
          }

          await rootStore.entityVersionStore.saveEntityVersion(toEntityVersion, false);

        } catch (e:any) {
          publishNotification({
            notificationType: NotificationType.Error,
            title: `Error updating entity ${entityVersionDiff.name}`,
            message: e.message,
          })
        }
      }

      showModal(
        {
          show: true,
          title: "Entities Merged",
          body: <div>All done!</div>,
          canClose: true
        }
      );
      successCallback()
    }

    showModal(
      {
        show: true,
        title: "Merge Modified Properties",
        body: "You are about to merge modified properties.  Are you sure you want to do this?",
        action: <Button variant="success" onClick={mergeModifiedProperties} data-testid="btnConfirmMergeEntities">Merge</Button>,
        canClose: true
      }
    );
  }

  const handleStopEntities = (entityVms: IEntityVersionVm[], successCallback: () => void = () =>{ /* do nothing*/  }) => {

    const stopEntities = async () => {
      let count = 1;
      for (const entityVm of entityVms) {
        try {
          showModalSpinner("Stopping Entities", `Stopping ${count++} of ${entityVms.length} entities`);
          await rootStore.entityVersionStore.updateEntityStatusById(entityVm.kindVersionSummary.kindId, entityVm.entity.id, EntityStatus.Stopped);
        } catch (e:any) {
          publishNotification({
            notificationType: NotificationType.Error,
            title: `Error stopping entity ${entityVm.name}`,
            message: e.message,
          })
        }
      }

      showModal(
        {
          show: true,
          title: "Entities Stopped",
          body: <div>All done!</div>,
          canClose: true
        }
      );
      successCallback()
    }

    showModal(
      {
        show: true,
        title: "Stopping Entities",
        body: "You are about to stop entities.  Are you sure you want to do this?",
        action: <Button variant="success" onClick={stopEntities} data-testid="btnConfirmStopEntities">Stop</Button>,
        canClose: true
      }
    );

  }

  // NOTE:  In the entityController and entitiesController there are different implementations depending on what has changed (i.e. GV and/or GE)
  // I have decided to keep a single implementation here which is less performant but easier to understand.  For right or wrong....
  const handleCopyPublishEntities = (originGameVersion: IGameVersion, targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment, entityVersionVms: IEntityVersionVm[], successCallback: () => void = () => {/* do nothing*/}) => {

    const copyPublishEntities = async () => {
      let count = 1;
      for (const kindName of new Set(entityVersionVms.map(ev => ev.kindVersionSummary.name))) {
        showModalSpinner("Copy/Publish Entities", `Copying entities for Kind ${kindName}...`)

        const kindVersion = (await rootStore.kindVersionStore.findAllCurrentKindVersions({
          gameVersionId: targetGameVersion.id,
          name: kindName
        })).find(() => true);
        if (kindVersion == null) {
          showModal({
            show: true,
            title: "Error Copying/Publishing entity",
            body: `Could not find Kind (${kindName}) in ${targetGameEnvironment.name} (${targetGameVersion.name})`,
            canClose: true
          });
          return;
        }

        const kindEntityVersionVms = entityVersionVms.filter(ev => ev.kindVersionSummary.name === kindName);
        const copiedEntities: IEntityVersion[] = await rootStore.entityVersionStore.copyEntityVmsToGameVersionAndEnvironment(kindEntityVersionVms, originGameVersion, targetGameVersion, targetGameEnvironment, kindVersion);

        for (const copiedVersion of copiedEntities) {
          try {
            showModalSpinner("Copy/Publish Entities", `Copying/Publishing ${count++} of ${entityVersionVms.length} entities`);
            copiedVersion.setComment(`Copied by ${rootStore.authStore.currentAuthUser.displayName}`);
            await rootStore.entityVersionStore.saveEntityVersion(copiedVersion, false);
            await rootStore.entityVersionStore.updateEntityStatus(copiedVersion, EntityStatus.Published);

          } catch
            (e : any) {
            publishNotification({
              notificationType: NotificationType.Error,
              title: `Error Copying/Publishing entity ${copiedVersion.name}`,
              message: e.message,
            })
          }
        }
      }

      showModal(
        {
          show: true,
          title: "Entities Copied/Published",
          body: <div>All done!</div>,
          canClose: true
        }
      );
      successCallback()
    }

    showModal(
      {
        show: true,
        title: "Copy/Publish Entities",
        body: "You are about to Copy, if entity doesn't exist, and Publish entities.  Are you sure you want to do this?",
        action: <Button variant="success" onClick={copyPublishEntities}
                        data-testid="btnConfirmCopyEntities">Copy/Publish</Button>,
        canClose: true
      }
    );

  }

  const controller: IDistributionDiffController = {
    handleMergeEntityDiffs,
    handleStopEntities,
    handleCopyPublishEntities
  };

  return controller;
};
