import React, { FC, useState }       from 'react';
import {observer}                               from "mobx-react-lite";
import {isStateTreeNode, onSnapshot}            from "mobx-state-tree";
import {Link}                                   from 'react-router-dom';
import Container                                from 'react-bootstrap/Container';
import Navbar                                   from 'react-bootstrap/Navbar';
import {ICategory}                              from "../../../model/category/Category";
import {CategoryButtons}                        from "./CategoryButtons";
import {CategoryHighlights}                     from "./CategoryHighlights";
import {CategoryMainInfo}                       from "./CategoryMainInfo";
import {useCategoryController}                  from "../../../controllers/useCategoryController";
import {ViewHeader}                             from '@yakoffice/view-header';
import {PubRouteItems} from '../../routes';
import { GameVersionSelector }                  from '../common/GameVersionSelector';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import { Badge } from 'react-bootstrap';
import {joinRoute, usePrompt} from "@yakoffice/shared-components";

interface PropsType {
    category : ICategory;
}

export const Category : FC<PropsType> = observer((props) => {

  const controller = useCategoryController({ category: props.category});
  const [preventRender, setPreventRender] = useState(false);
  const [edited, setEdited] = usePrompt("You have unsaved changes. Are you sure you want to leave?", false);

  if (isStateTreeNode(props.category))
    onSnapshot(props.category, () => {
      setEdited(true)
    });

  const newCategoryText = () => {
    if(props.category.parent?.id)
      return "New Sub Category"
    else
      return "New Category"
  }

  return preventRender ? <div/> : (
    <div id="category">
      <Container>
        <ViewHeader
          title={`${props.category.name != null && props.category.name.length > 0 ? props.category.name : newCategoryText()} `}
          customAction={<GameVersionSelector />}
          breadcrumbs={<span><Link to={joinRoute("..",PubRouteItems.Categories)}>Categories</Link></span>}
        />
      </Container>

      <Container>
        <ContentBox>
          <ContentBoxHeader>
            <ContentBoxNavBar bg="" variant="dark" expand="lg" className={`${props.category.status}`}>
              <Container>
                <Navbar.Brand>
                  {props.category.isNewCategory() ? "New Category" : "Category Version"}
                </Navbar.Brand>
                {!props.category.isNewCategory() &&
                 <Badge bg="light" text="dark" className="ms-0 me-auto">{props.category.versionIdCur}</Badge>
                }
                <Navbar.Toggle/>
                <Navbar.Collapse id="categoryNav" className="justify-content-end">
                  <CategoryButtons category={props.category}
                                   categoryEdited={edited}
                                   handleArchiveCategory={() => controller.handleArchiveCategory(() => {
                                     setEdited(false)
                                     setPreventRender(true)
                                   })}
                                   handleUnarchiveCategory={() => controller.handleUnarchiveCategory(() => {
                                     setEdited(false)
                                     setPreventRender(true)
                                   })}
                                   handleSaveCategory={() => controller.handleSaveCategory(() => {
                                     setEdited(false)
                                     setPreventRender(true)
                                   })}
                                   handleDeleteCategory={() => controller.handleDeleteCategory(() => {
                                     setEdited(false)
                                     setPreventRender(true)
                                     })} />
                </Navbar.Collapse>
              </Container>
            </ContentBoxNavBar>
          </ContentBoxHeader>
          <ContentBoxBody>
            {!props.category.isNewCategory() && <CategoryHighlights category={props.category} /> }
            <CategoryMainInfo category={props.category} />
          </ContentBoxBody>
        </ContentBox>
      </Container>
    </div>
  )
});
