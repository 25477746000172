import Container from 'react-bootstrap/Container';
import { ViewHeader } from '@yakoffice/view-header';
import { GameVersionSelector } from '../common/GameVersionSelector';
import { Link, NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContentBox, ContentBoxBody, ContentBoxHeader, ContentBoxNavBar } from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import { CollapsePanel } from '@yakoffice/shared-components';
import { SelectionsStatus } from './SelectionsStatus';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { YoListGroup, YoListGroupItem } from '../../components/yo-list-group/YoListGroup';
import { Route, Routes } from 'react-router';
import { PubRouteItems, useGetAddSelectionLink, useGetSelectionLink } from '../../routes';
import { Selection } from '../selection/Selection';
import { SelectionLanding } from '../selection/SelectionLanding';
import React, { FC, useContext } from 'react';
import RootStoreContext from '../../../model/RootStoreContext';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { observer } from 'mobx-react-lite';
import { Checked } from '../common/Checked';
import { ISelection } from '../../../model/selections/Selection';

interface PropTypes {
  onGameVersionChanged: (targetGv: IGameVersion) => void;
  handleShowArchived: (archived: boolean, redirect?: boolean) => void;
  showArchived: boolean;
  handleCheckAllSelections: () => void;
  copySelections: () => void;
  checkedSelections: Checked<ISelection>[];
  handleSelectionCheckboxToggle: (checkedSelection: Checked<ISelection>, checked: boolean) => void;
  setRefreshFlag: (refreshFlag: boolean) => void;
  refreshFlag: boolean;

}

export const SelectionsList: FC<PropTypes> = observer((props: PropTypes) => {

  const getAddSelectionLink = useGetAddSelectionLink();
  const getSelectionLink = useGetSelectionLink();
  const rootStore = useContext(RootStoreContext);


  return (
    <div>
      <Container>
        <ViewHeader title='Selections'
                    customAction={
                      <>
                        <GameVersionSelector handleGameVersionChange={props.onGameVersionChanged} />
                        <Link
                          to={getAddSelectionLink()}
                          className='btn btn-primary'
                          data-testid='btnAddSelection'>
                          <i className='fas fa-plus' /> New selection
                        </Link>
                      </>
                    }
        />
      </Container>
      <Container>
         <Row>
           <Col sm='4' lg='3'>
             <ContentBox>
               <ContentBoxHeader>
                 <ContentBoxNavBar variant='dark' expand='lg'>
                   <Container>
                     <Navbar.Brand className='text-white'>Selection Names</Navbar.Brand>
                   </Container>
                 </ContentBoxNavBar>
               </ContentBoxHeader>
               <ContentBoxBody>

                 <CollapsePanel>
                   <SelectionsStatus showArchived={props.handleShowArchived} archived={props.showArchived} />

                   <div className={'alert alert-dark'}>
                     <Row>
                       <Col>
                         <Form.Check type='checkbox' className={'mt-2'} id={'select-all'} onChange={props.handleCheckAllSelections} label='Select All' data-testid={`checkAll`}/>
                       </Col>
                       <Col>
                         <Button variant='primary' className={'float-end'} onClick={props.copySelections} disabled={props.checkedSelections.filter(s => s.checked).length === 0} data-testid={'copyAll'}>Copy</Button>
                       </Col>
                     </Row>
                   </div>

                   <YoListGroup className='scrollableList'>
                     {props.checkedSelections && props.checkedSelections.map(selection => {

                       return (
                         <div key={selection.value.id}>

                           <YoListGroupItem active={rootStore.selectionStore.currentSelection ? selection.value.id === rootStore.selectionStore.currentSelection.id : false}>
                             <Row>
                               <Col sm={1}>
                                 <input type='checkbox' checked={selection.checked} onChange={(event) => props.handleSelectionCheckboxToggle(selection, event.target.checked)}  />
                               </Col>
                               <Col sm={10}>
                                 <NavLink to={getSelectionLink({ selectionId: selection.value.id })} data-testid='pillSelection'>
                                   {selection.value.name}
                                 </NavLink>
                               </Col>
                             </Row>
                           </YoListGroupItem>
                         </div>
                       );
                     })}
                   </YoListGroup>
                 </CollapsePanel>
               </ContentBoxBody>
             </ContentBox>
           </Col>
           <Col sm='8' lg='9'>
             <Routes>
               <Route path={PubRouteItems.AddSelection} element={<Selection showArchived={props.handleShowArchived} refreshSelections={props.setRefreshFlag} selectionsRefreshStatus={props.refreshFlag} />} />
               <Route path={PubRouteItems.SelectionIdParam} element={<Selection showArchived={props.handleShowArchived} refreshSelections={props.setRefreshFlag} selectionsRefreshStatus={props.refreshFlag} />} />
               <Route path={''} element={<SelectionLanding />} />
             </Routes>
           </Col>
         </Row>
      </Container>
    </div>
  )
});
