import React, {FC}              from 'react';
import {observer}               from 'mobx-react-lite';
import Col                      from 'react-bootstrap/Col';
import {ICategory}              from '../../../model/category/Category';
import {CategoryCard}           from './CategoryCard';


interface PropsType {
  childCategories: ICategory[];
  filterArchived: boolean;
}


export const SubCategories : FC<PropsType> = observer((props) => {

  return (
    <>
      {props.childCategories.filter(k => !props.filterArchived || k.status !== "archived").map(childCategory => {
        return (
          <Col xl="2" lg="4" md="6" sm="6" xs="12" key={childCategory.id} className="mb-3">
            <CategoryCard category={childCategory} {...props} />
          </Col>
        )
      })}
    </>
  )
})
