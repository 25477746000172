import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Container from 'react-bootstrap/Container';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { ExperimentHightlights } from './ExperimentHighlights';
import Navbar from 'react-bootstrap/Navbar';
import { ExperimentButtons } from './ExperimentButtons';
import { useExperimentController } from '../../../controllers/useExperimentController';
import { IExperiment } from '../../../model/experiment/Experiment';
import { isStateTreeNode, onSnapshot } from 'mobx-state-tree';
import Alert from 'react-bootstrap/Alert';
import { Col, Row } from 'react-bootstrap';
import { SpecificationsEditor } from '../common/SpecificationsEditor';
import { ExperimentDefaultProperties } from './ExperimentDefaultProperties';
import { IKindVersion } from '../../../model/kind/KindVersion';
import { IKindProperty } from '../../../model/kind/KindProperty';
import { IEntityProperty } from '../../../model/entity/EntityProperty';
import { ExperimentCustomProperties } from './ExperimentCustomProperties';
import { useExperimentKindsResult } from '../../../loaders/useExperimentKinds';
import { ViewHeader } from '@yakoffice/view-header';
import { ReadonlyEntitySpecificationProperties } from '../entity/ReadonlyEntitySpecificationProperties';
import { Link, useNavigate } from 'react-router-dom';
import { useGetExperimentsLink } from '../../routes';
import { GameVersionSelector } from '../common/GameVersionSelector';
import { GameEnvironmentSelector } from '../common/GameEnvironmentSelector';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import { YoFormBox, YoFormBoxBody } from '../../components/yo-form-box/YoFormBox';
import { FeatureHeader, usePrompt } from '@yakoffice/shared-components';
import { RowDivider } from '../../components/dividers/RowDivider';
import { ExperimentBounds } from './ExperimentBounds';
import { ExperimentVariants } from './ExperimentVariants';


interface PropTypes {
  experiment: IExperiment;
  publishedExperiments: IExperiment[];
  experimentKindsResult: useExperimentKindsResult;
  specificationsForExperiments: IKindVersion[] | null;
  specificationsForKinds: IKindVersion[];
  refreshToggle: boolean;
  setRefreshToggle: (refreshToggle: boolean) => void;
}

export const Experiment: FC<PropTypes> = observer(({ experiment, publishedExperiments, experimentKindsResult, specificationsForExperiments, specificationsForKinds , refreshToggle, setRefreshToggle}) => {

  const navigate = useNavigate();
  const controller = useExperimentController(experiment);
  const getExperimentsLink = useGetExperimentsLink();
  const [experimentEdited, setExperimentEdited] = usePrompt('You have unsaved changes. Are you sure you want to leave?', false);

  if (isStateTreeNode(experiment))// So can pass in mock....
    onSnapshot(experiment, () => setExperimentEdited(true));

  const handleSaveExperiment = async (andPublish?: boolean, andCreateSheet?: boolean) => {
    await controller.handleSaveExperiment(!!andPublish, !!andCreateSheet, () => {
      setExperimentEdited(false);
    });
  };

  const handleAddSpecification = async (specificationKind: IKindVersion, specification: IKindProperty) => {
    await controller.handleAddSpecification(specificationKind, specification);
  };

  const handleRemoveSpecification = async (specification: IEntityProperty) => {
    await controller.handleRemoveSpecification(specification);
  };

  const onGameVersionChanged = (targetGv: IGameVersion) => navigate(getExperimentsLink({ gameVersionId: targetGv.id }));

  const onGameEnvironmentChanged = (targetGe: IGameEnvironment) => navigate(getExperimentsLink({ gameEnvironmentId: targetGe.id }));

  const handleCreateSheet = async () => {
    await controller.handleCreateSheet( () => {
      setExperimentEdited(false);
    });
  };

  const handleDeleteSheet = async () => {
    await controller.handleDeleteSheet( () => {
      setExperimentEdited(false);
    });
  };

  const handleUploadSheet = async () => {
    await controller.handleUploadSheet( () => {
      setExperimentEdited(false);
      setRefreshToggle(!refreshToggle);
    });
    // controller.handleUploadSheet(() => setRefreshToggle(!refreshToggle));
  };

  return (
    <>
      <Container>
        <ViewHeader title={`${experiment.name || '(New Experiment Name)'}`}
                    customAction={
                      <>
                        <GameVersionSelector handleGameVersionChange={onGameVersionChanged} />
                        <GameEnvironmentSelector handleGameEnvironmentChange={onGameEnvironmentChanged} />
                      </>
                    }
                    breadcrumbs={
                      <span>
                                    <Link to={getExperimentsLink()}>Experiments</Link>
                                </span>} />
      </Container>
      <Container>
        <ContentBox>
          <ContentBoxHeader>
            <ContentBoxNavBar variant='dark' expand='lg'>
              <Container>
                <Navbar.Brand>
                  {experiment.isNewExperiment() ? 'New Experiment' : 'Experiment'}
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse id='experimentNav' className='justify-content-end mt-2'>
                  <ExperimentButtons
                    experiment={experiment}
                    experimentEdited={experimentEdited}
                    handleSaveExperiment={handleSaveExperiment}
                    handlePublishExperiment={controller.handlePublishExperiment}
                    handleApplyExperiment={controller.handleApplyExperiment}
                    handleStopExperiment={controller.handleStopExperiment}
                    handleArchiveExperiment={controller.handleArchiveExperiment}
                    handleUnarchiveExperiment={controller.handleUnarchiveExperiment}
                    handleDeleteExperiment={controller.handleDeleteExperiment}
                    handleCopyExperiment={controller.handleCopyExperiment}
                    handleCreateSheet={handleCreateSheet}
                    handleViewSheet={controller.handleViewSheet}
                    handleDeleteSheet={handleDeleteSheet}
                    handleRefreshSheet={controller.handleRefreshSheet}
                    handleUploadSheet={handleUploadSheet}
                  />
                </Navbar.Collapse>
              </Container>
            </ContentBoxNavBar>
          </ContentBoxHeader>
          <ContentBoxBody>
            {!experiment.isNewExperiment() && <ExperimentHightlights experiment={experiment} />}
            <Row>
              <Col className='col-lg-8 col-md-6 col-sm-12'>
                {experiment.inAnyDistribution &&
                 <Alert variant='warning'>
                   <strong>Warning:</strong> This experiment has been distributed so it is no longer
                   editable
                 </Alert>
                }
                {!experiment.inAnyDistribution && experiment.requiresUpdating &&
                 <Alert variant='warning'>
                   <strong>Warning:</strong> This experiment has out of date specification properties.
                   You may need to re-add them before publishing.
                 </Alert>
                }
                <ExperimentDefaultProperties experiment={experiment} />
                <RowDivider />
                <ExperimentBounds experiment={experiment} publishedExperiments={publishedExperiments} />
                <RowDivider />
                <ExperimentVariants experiment={experiment} disabled={experiment.inAnyDistribution} />
                <RowDivider />
                <Row>
                  <Col>
                    <FeatureHeader size='sm'><i className='fas fa-home' />Properties</FeatureHeader>
                  </Col>
                </Row>
                {experimentKindsResult.isLoaded
                 ? <ExperimentCustomProperties experiment={experiment}
                                               experimentKinds={experimentKindsResult.kinds}
                                               specificationsForKinds={specificationsForKinds}/>
                 : <CustomSpinner spinnerText={'Loading properties...'} />
                }
              </Col>
              <Col xs='12' md='6' lg='4'>
                <YoFormBox>
                  <YoFormBoxBody>
                    {specificationsForExperiments &&
                     (experiment.inAnyDistribution
                      ? <ReadonlyEntitySpecificationProperties
                        specificationProperties={experiment.specifications}
                        specificationKinds={specificationsForExperiments} />
                      : <SpecificationsEditor
                        specificationKindVersions={specificationsForExperiments}
                        specificationProperties={experiment.specifications}
                        handleAddSpecificationProperty={handleAddSpecification}
                        handleRemoveSpecificationProperty={handleRemoveSpecification} />)
                    }
                  </YoFormBoxBody>
                </YoFormBox>
              </Col>
            </Row>
          </ContentBoxBody>
        </ContentBox>
      </Container>
    </>
  );
});
