import React, {FC} from 'react';
import { NavbarProps } from 'react-bootstrap/Navbar';
import { Navbar } from 'react-bootstrap';
import styles from './YoFormBox.module.sass'

export const YoFormBox : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {
  return <div {...props} className={`${styles.formBox} ${className ?? ""}`}>{props.children}</div>
}

export const YoFormBoxHeader : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {
  return <div {...props} className={`${styles.formBoxHeader} ${className ?? ""}`}>{props.children}</div>
}

export const YoFormBoxBody : FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({className, ...props}) => {
  return <div {...props} className={`${styles.formBoxBody} ${className ?? ""}`}>{props.children}</div>
}

export const YoFormBoxClose : FC<NavbarProps> = ({className, ...props}) => {
  return <Navbar {...props} className={`${styles.formBoxClose} ${className ?? ""}`}>{props.children}</Navbar>
}
