import React, { FC, useContext, useEffect, useState } from 'react';
import {Link}                             from 'react-router-dom';
import {observer}                         from 'mobx-react-lite';
import Container                          from 'react-bootstrap/Container';
import Row                                from 'react-bootstrap/Row';
import Col                                from 'react-bootstrap/Col';
import {ClaimType}                        from "@yakoffice/publisher-types";
import {ViewHeader}                       from '@yakoffice/view-header';
import {PubRouteItems} from '../../routes';
import RootStoreContext                   from "../../../model/RootStoreContext";
import {IKindVersion}                     from '../../../model/kind/KindVersion';
import {ICategory}                        from '../../../model/category/Category';
import {FilterButton}                     from "../common/FilterButton";
import {SubCategories}                    from '../categories/SubCategories';
import {KindCard}                         from "./KindCard";
import {AddCategoryButton}                from '../categories/AddCategoryButton';
import {AddKindButton}                    from './AddKindButton';
import { GameVersionSelector }            from '../common/GameVersionSelector';
import Nav                                from 'react-bootstrap/Nav';
import { IGameVersion }                   from '../../../model/gameVersion/GameVersion';
import { useKindsController }             from '../../../controllers/useKindsController';
import { sortByString }                   from '@yakoffice/yakoffice-firebase';
import {joinRoute} from "@yakoffice/shared-components";

interface PropsType {
    subCategories   : ICategory[],
    kindVersions   : IKindVersion[]
}


export const Kinds : FC<PropsType> = observer((props) => {

    const rootStore       = useContext(RootStoreContext);
    const controller = useKindsController()
    const [filterArchived, setFilterArchived] = useState(true);

    useEffect(() => rootStore.kindVersionStore.clearCurrentKindVersion());

    const numberOfFilteredItems = () => {
        return rootStore.kindVersionStore.kindVersions.filter(c => c.kind.status === "archived").length + props.subCategories.filter(sc => sc.status === "archived").length
    }

    const backLink = () => !rootStore.categoryStore.getCurrentCategory().parent
      ? <Link to={joinRoute("..","..",PubRouteItems.Categories)}><i className="fas fa-arrow-alt-circle-left"/></Link>
      : <Link to={joinRoute("..","..",PubRouteItems.Categories, rootStore.categoryStore.getCurrentCategory().parent?.id.toString() ?? "0", PubRouteItems.Kinds)}><i className="fas fa-arrow-alt-circle-left"/></Link>

    const onGameVersionChanged = (targetGv: IGameVersion) => controller.handleGameVersionChange(rootStore.categoryStore.getCurrentCategory(), targetGv)

    return (
        rootStore.categoryStore.currentCategory &&
        <div id="kinds">

            <Container>
                <ViewHeader title={`${rootStore.categoryStore.getCurrentCategory().name} ${rootStore.categoryStore.getCurrentCategory().parent ? '- Kinds' : ""}`}
                            customAction={<GameVersionSelector handleGameVersionChange={onGameVersionChanged} />}
                            breadcrumbs={
                                <span>
                                    <Link to={joinRoute("..","..",PubRouteItems.Categories)}>Categories</Link>
                                    {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory)
                                     ? <Link to={joinRoute("..","..",PubRouteItems.Categories, rootStore.categoryStore.getCurrentCategory().id.toString())}>
                                        {rootStore.categoryStore.getCurrentCategory().name}
                                    </Link>
                                     : <span>{rootStore.categoryStore.getCurrentCategory().name}</span>
                                    }
                                </span>
                            }
                            backLink={backLink()}
                />
            </Container>

            <Container>
              <Nav>
                  {!rootStore.categoryStore.getCurrentCategory().parent &&
                    rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory) &&
                      <Nav.Item>
                        <AddCategoryButton isSubCategory={true}/>
                      </Nav.Item>
                  }
                  {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind) &&
                   <Nav.Item>
                     <AddKindButton />
                   </Nav.Item>
                  }
                <Nav.Item className="ms-auto me-0">
                  <FilterButton filterText="Archived"
                                filterOn={filterArchived}
                                numberOfFilteredItems={numberOfFilteredItems()}
                                onClick={() => setFilterArchived(!filterArchived)} />
                  </Nav.Item>
                </Nav>

                <Row className="mt-5">
                  {!rootStore.categoryStore.getCurrentCategory().parent &&
                   <SubCategories childCategories={props.subCategories} filterArchived={filterArchived} {...props} />
                  }
                  {props.kindVersions
                      .filter(k => !filterArchived || k.kind.status !== "archived")
                      .sort(sortByString("name"))
                      .map((k) => (
                          <Col xl="2" lg="4" md="6" sm="6" xs="12" key={k.kind.id} className="mb-3 mt-2">
                              <KindCard kindVersion={k} />
                          </Col>)
                      )
                  }
                </Row>
            </Container>
        </div>
    )
})

