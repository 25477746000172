import React from 'react';
import type {IRootStore} from "./RootStore";
import {RootStore} from "./RootStore";
import {KindPropertyTypeStore} from "./kind/KindPropertyTypeStore";
import {KindVersionStore} from "./kind/KindVersionStore";
import {SelectionStore} from "./selections/SelectionStore";
import {KindVersionApiGateway} from "../api/requests/kinds/kindVersionApiGateway";
import {ProjectStore} from "./project/ProjectStore";
import {ProjectApiGateway} from "../api/requests/projects/projectApiGateway";
import {ApiRequest} from "../api/requests/ApiRequest";
import {GameEnvironmentStore} from "./gameEnvironment/GameEnvironmentStore";
import {GameEnvironmentApiGateway} from "../api/requests/gameEnv/GameEnvironmentApiGateway";
import {CategoryStore} from "./category/CategoryStore";
import {CategoryApiGateway} from "../api/requests/categories/CategoryApiGateway";
import {KindPropertyTypeApiGateway} from "../api/requests/kinds/kindPropertyTypeApiGateway";
import {SelectionApiGateway} from "../api/requests/selections/selectionApiGateway";
import {EntityVersionApiGateway} from "../api/requests/entities/entityVersionApiGateway";
import {
    KindsForEntitySelectionStore,
    SpecificationsForExperimentsStore,
    SpecificationsForKindsStore
} from "./kind/SpecificationKindStore";
import {AuthUser} from "./auth/AuthUser";
import {DistributionApiGateway} from "../api/requests/distribution/DistributionApiGateway";
import {DistributionStore} from "./distributions/DistributionStore";
import {AuthStore} from "./auth/AuthStore";
import {bearerTokenProvider} from "@yakoffice/yakoffice-firebase";
import {ExperimentStore} from "./experiment/ExperimentStore";
import {ExperimentApiGateway} from "../api/requests/ExperimentApiGateway";
import {EntitySelectionApiGateway} from "../api/requests/selections/EntitySelectionApiGateway";
import {EntitySelectionStore} from "./selections/EntitySelectionStore";
import { EntityVersionStore } from './entity/EntitytVersionStore';
import { GameVersionStore } from './gameVersion/GameVersionStore';
import { GameVersionApiGateway } from '../api/requests/GameVersionApiGateway';

export const rootStore = RootStore.create(
    {
            authStore: AuthStore.create({currentAuthUser: AuthUser.create()}),
            // @ts-ignore: Don't know why
            projectStore: ProjectStore.create({apiGateway: ProjectApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            categoryStore: CategoryStore.create({apiGateway: CategoryApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            gameVersionStore: GameVersionStore.create({apiGateway: GameVersionApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            gameEnvironmentStore: GameEnvironmentStore.create({apiGateway: GameEnvironmentApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            kindVersionStore: KindVersionStore.create({apiGateway: KindVersionApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            entityVersionStore: EntityVersionStore.create({apiGateway: EntityVersionApiGateway.create({apiRequest: ApiRequest.create()})}),
            kindPropertyTypeStore: KindPropertyTypeStore.create({apiGateway: KindPropertyTypeApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            selectionStore: SelectionStore.create({apiGateway: SelectionApiGateway.create({apiRequest: ApiRequest.create()})}),
            entitySelectionStore: EntitySelectionStore.create({apiGateway: EntitySelectionApiGateway.create({apiRequest: ApiRequest.create()})}),
            specificationsForKindsStore: SpecificationsForKindsStore.create({apiGateway: KindVersionApiGateway.create({apiRequest: ApiRequest.create()})}),
            specificationsForExperimentsStore: SpecificationsForExperimentsStore.create({apiGateway: KindVersionApiGateway.create({apiRequest: ApiRequest.create()})}),
            kindsForEntitySelectionStore: KindsForEntitySelectionStore.create({apiGateway: KindVersionApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            distributionStore: DistributionStore.create({apiGateway: DistributionApiGateway.create({apiRequest: ApiRequest.create()})}),
            // @ts-ignore: Don't know why
            experimentStore: ExperimentStore.create({apiGateway: ExperimentApiGateway.create({apiRequest: ApiRequest.create()})}),
    },
    {
            bearerTokenProvider : bearerTokenProvider
    });

const RootStoreContext = React.createContext<IRootStore>(rootStore);

export const RootStoreProvider = RootStoreContext.Provider;
export const RootStoreConsumer = RootStoreContext.Consumer;

export default RootStoreContext;
