import React, { FC, useContext, useState } from 'react';
import { ClaimType } from '@yakoffice/publisher-types';
import RootStoreContext from '../../../model/RootStoreContext';
import Button from 'react-bootstrap/Button';
import {ButtonGroup} from 'react-bootstrap';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';
import {GameVersionMultiSelector} from "../common/GameVersionMultiSelector";
import {GameEnvironmentMultiSelector} from "../common/GameEnvironmentMultiSelector";

interface PropTypes{
  buttonVariant? : string
  gameEnvironments?: IGameEnvironment[]
  disableCompareButtonOverride?: boolean
  handleCompareEntityVersion?: (diffGameVersion: IGameVersion, diffGameEnvironment : IGameEnvironment) => void;
  disableCopyButtonOverride?: boolean
  disableCopyToSame?: boolean
  handleCopyEntityVersion?: (targetGameVersions: IGameVersion[], targetGameEnvironments : IGameEnvironment[]) => void;
}

export const EntityCompareCopyButtonGroup : FC<PropTypes> = (
  {
    buttonVariant,
    gameEnvironments,
    disableCompareButtonOverride,
    handleCompareEntityVersion,
    disableCopyButtonOverride,
    disableCopyToSame,
    handleCopyEntityVersion
  }) => {
  const rootStore = useContext(RootStoreContext);
  const [diffGameVersions, setDiffGameVersions] = useState<IGameVersion[]>([])
  const [diffGameEnvironments, setDiffGameEnvironments] = useState<IGameEnvironment[]>([])

  return <ButtonGroup className="flex-column flex-lg-row">
    <GameVersionMultiSelector as={ButtonGroup}
                              selectedGameVersions={diffGameVersions}
                              handleGameVersionSelected={gv => setDiffGameVersions(state => [...state, gv])}
                              handleGameVersionUnselected={unselectedGv => setDiffGameVersions(state => state.filter(gv => gv !== unselectedGv))}
                              data-testid={"ddlCopyEntityGv"}/>
    <GameEnvironmentMultiSelector as={ButtonGroup}
                                  gameEnvironments={gameEnvironments}
                                  selectedGameEnvironments={diffGameEnvironments}
                                  handleGameEnvironmentSelected={ge => setDiffGameEnvironments(state => [...state, ge])}
                                  handleGameEnvironmentUnselected={unselectedGe => setDiffGameEnvironments(state => state.filter(ge => ge !== unselectedGe))}
                                  data-testid={"ddlCopyEntityGe"}/>
    {handleCompareEntityVersion &&
      <Button id="btnCompare"
              variant={buttonVariant ?? "light"}
              onClick={() => handleCompareEntityVersion(diffGameVersions[0], diffGameEnvironments[0])}
              disabled={disableCompareButtonOverride || (diffGameVersions.length !== 1 || diffGameEnvironments.length !== 1)}>
        <i className="fas fa-exchange-alt"/> Compare
      </Button>
    }
    {handleCopyEntityVersion &&
      <Button id="btnCopy"
              variant={buttonVariant ?? "light"}
              className="border-start"
              onClick={() => handleCopyEntityVersion(diffGameVersions, diffGameEnvironments)}
              disabled={disableCopyButtonOverride ||
                (diffGameVersions.length === 0 || diffGameEnvironments.length === 0 || diffGameEnvironments.find(ge => !rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity, ge)) != null) ||
                (disableCopyToSame && diffGameVersions.includes(rootStore.gameVersionStore.getCurrentGameVersion()) && diffGameEnvironments.includes(rootStore.gameEnvironmentStore.getCurrentGameEnvironment()))}
              data-testid="btnCopyEntityVersion">
        <i className="fas fa-copy"/> Copy
      </Button>
    }
  </ButtonGroup>
}
