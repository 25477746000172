import React, { FC } from 'react';
import { formatISODateString } from '../common/Utils';
import { IEntityVersionDiffVm } from '@yakoffice/publisher-types';
import { IconLeftDiff } from './IconLeftDiff';
import { IconRightDiff } from './IconRightDiff';
import { YoPublisherTable, YoTableClassNames } from '../../components/yo-publisher-table/YoPublisherTable';
import styles from './ModifiedEntityTable.module.sass'
import { Form } from 'react-bootstrap';
import { useGetEntityVersionLink } from '../../routes';
import {useNavigate} from 'react-router-dom';

interface ModifiedEntityTablePropTypes{
  className?: string,
  displayCheckbox: boolean,
  checked?: boolean,
  entityVersionDiff : IEntityVersionDiffVm
  handleCheckedChange?: (checked: boolean) => void;
}

export const ModifiedEntityTable : FC<ModifiedEntityTablePropTypes>= ({className, entityVersionDiff, displayCheckbox, checked, handleCheckedChange}) => {

  const getEntityVersionLink = useGetEntityVersionLink()
  const navigate = useNavigate();

  const redirectToEntityVersion = () => {
    navigate(getEntityVersionLink({
      categoryId: entityVersionDiff.kindCategoryId,
      kindId: entityVersionDiff.kindId,
      entityId: entityVersionDiff.entityId,
      version: entityVersionDiff.version}))
  }

  return <div className={styles.mnh0}>
    <YoPublisherTable variant={className} responsive className={`${YoTableClassNames.MinWidth100}`} key={entityVersionDiff.entityId} data-testid="tblModifiedEntity">
      <thead className="table-dark">
      <tr key="h1">
        <th colSpan={displayCheckbox? 3: 4} className="cursor-pointer" onClick={() => redirectToEntityVersion()}>
          <IconLeftDiff/><span className="text-decoration-underline" >{entityVersionDiff.name}</span> : <span className="fw-normal ms-2">{entityVersionDiff.description}</span>
        </th>
        {displayCheckbox &&
        <th className="text-end">
          <Form.Check checked={checked} onChange={() => handleCheckedChange && handleCheckedChange(!checked)} className="me-2" data-testid="cbEntity"/>
        </th>
        }
      </tr>
      {(entityVersionDiff.name !== entityVersionDiff.diffName || entityVersionDiff.description !== entityVersionDiff.diffDescription) &&
      <tr key="h2">
        <th colSpan={4}>
          {entityVersionDiff.name !== entityVersionDiff.diffName &&
          <><IconRightDiff className="me-2" /> Name: <span
            className="fw-normal me-4">{` ${entityVersionDiff.diffName}`}</span></>}
          {entityVersionDiff.description !== entityVersionDiff.diffDescription &&
          <><IconRightDiff className="me-2" /> Description: <span
            className="fw-normal">{` ${entityVersionDiff.diffDescription}`}</span></>}
        </th>
      </tr>
      }
      {entityVersionDiff.kindName !== entityVersionDiff.diffKindName &&
      <tr key="h3">
        <th colSpan={1}><IconLeftDiff /> Name: <span className="fw-normal">{entityVersionDiff.kindName}</span>
        </th>
        <th colSpan={3}><IconRightDiff /> Kind Name: <span
          className="fw-normal">{entityVersionDiff.diffKindName}</span></th>
      </tr>
      }
      {entityVersionDiff.addedProperties.length === 0 &&
      entityVersionDiff.modifiedProperties.length === 0 &&
      entityVersionDiff.deletedProperties.length === 0
        ?<tr key="h4">
          <td align="center" colSpan={4}>No properties have been modified</td>
        </tr>
        :<tr  key="h5">
          <th>Property Key</th>
          <th><IconLeftDiff /></th>
          <th><IconRightDiff /></th>
          <th>Action</th>
        </tr>
      }
      </thead>
      <tbody>
      {entityVersionDiff.addedProperties.map((ap) => {
        return (
          <tr key={`added${ap.kindPropertyId}`} data-testid={`trAdded${ap.kindPropertyKey}`}>
            <td>{ap.kindPropertyKey}</td>
            <td data-testid="tdOrigin">{formatISODateString(ap.value) || ap.value}</td>
            <td data-testid="tdDiff"/>
            <td><span className="text-success">Added</span></td>
          </tr>
        )
      })}
      {entityVersionDiff.modifiedProperties.map((ap) => {
        return (
          <tr key={`modded${ap.kindPropertyId}`} data-testid={`trModified${ap.kindPropertyKey}`}>
            <td>{ap.kindPropertyKey}</td>
            <td data-testid="tdOrigin">{formatISODateString(ap.value) || ap.value}</td>
            <td data-testid="tdDiff">{formatISODateString(ap.diffValue) || ap.diffValue}</td>
            <td><span className="text-warning">Modified</span></td>
          </tr>
        )
      })}
      {entityVersionDiff.deletedProperties.map((ap) => {
        return (
          <tr key={`deleted${ap.kindPropertyId}`} data-testid={`trMissing${ap.kindPropertyKey}`}>
            <td>{ap.kindPropertyKey}</td>
            <td data-testid="tdOrigin"/>
            <td data-testid="tdDiff">{formatISODateString(ap.value) || ap.value}</td>
            <td><span className="text-danger">Missing</span></td>
          </tr>
        )
      })}
      </tbody>
    </YoPublisherTable>
  </div>
}
