import React, {FC} from 'react';
import {observer}                   from 'mobx-react-lite';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { formatISODateStringAsLocal } from '../common/Utils';
import {IDistribution} from "../../../model/distributions/Distribution";
import { InfoBox, InfoBoxHeader, InfoBoxBody } from '@yakoffice/info-box';
import { CollapsePanel } from '@yakoffice/shared-components';
import { AbbreviatedString } from '../../components/abbreviated-string/AbbreviatedString';
import JiraIssueLinks from './JiraIssueLinks';
import styles from './DistributionHighlights.module.sass'

interface PropsType {
    distribution  : IDistribution;
}

export const DistributionHighlights : FC<PropsType> = observer(({distribution}) => {

  return (
    <CollapsePanel>
      <Row>
        <Col md="6" lg="2">
          <InfoBox className={styles.infoBox}>
            <InfoBoxHeader><i className="fas fa-code-branch" /> Version</InfoBoxHeader>
            <InfoBoxBody>{distribution.distributionVersion}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col md="6" lg="3">
          <InfoBox className={styles.infoBox}>
            <InfoBoxHeader><i className="fas fa-calendar-alt" /> Distributed On</InfoBoxHeader>
            <InfoBoxBody>{formatISODateStringAsLocal(distribution.updatedAt)}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col md="6" lg="2">
          <InfoBox className={styles.infoBox}>
            <InfoBoxHeader><i className="fas fa-calendar-alt" /> Distributed By</InfoBoxHeader>
            <InfoBoxBody>{distribution.lastAuthor}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col md="6" lg="2">
          <InfoBox className={styles.infoBox}>
            <InfoBoxHeader><i className="fas  fa-comment" /> Comment</InfoBoxHeader>
            <InfoBoxBody>"<AbbreviatedString value={distribution.comment} lengthToAbbreviate={30}/>"</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col md="6" lg="3">
          <InfoBox className={styles.infoBox}>
            <InfoBoxHeader><i className="fas  fa-comment" /> Jira Issues</InfoBoxHeader>
            <InfoBoxBody><JiraIssueLinks className="text-info" delimiter=", " jiraIssueIds={distribution.jiraIssueIds}/></InfoBoxBody>
          </InfoBox>
        </Col>
      </Row>
    </CollapsePanel>
  )
})
