import React, {FC}          from 'react';
import {observer}           from 'mobx-react-lite';
import {CustomSpinner}      from '@yakoffice/custom-spinner';
import {GameVersion}        from './GameVersion';
import {useGameVersion}     from '../../../loaders/useGameVersion';
import { useSetGameVersionFromParam } from '../common/useSetGameVersionFromParam';


export const GameVersionContainer : FC = observer(() => {

  useSetGameVersionFromParam();
  const gameVersionResult = useGameVersion();

  return (gameVersionResult.isLoaded && gameVersionResult.gameVersion
    ? <GameVersion gameVersion={gameVersionResult.gameVersion} />
    : <CustomSpinner spinnerText={'Loading game version'}/>)
});
