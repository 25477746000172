import React, {ChangeEvent, FC} from 'react';
import {observer} from "mobx-react-lite";
import {useLocalObservable} from "mobx-react-lite";
import { runInAction } from 'mobx';

interface LocalStore {
    timer : number | null
    value : string;
}

interface WithDelayedChangeHandlers{
    handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void
    handleOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

interface DelayedChangeProps {
    children(eventHandlers: WithDelayedChangeHandlers): JSX.Element;
    delayedOnChange: (value: string) => void;
    waitInterval: number
}

export const WithDelayedChange : FC<DelayedChangeProps> = observer((props : DelayedChangeProps) => {

  const localStore: LocalStore = useLocalObservable(() => ({
    timer: null,
    value: ""
  }))

  const handleDelayedOnChange = () => {
    props.delayedOnChange(localStore.value);
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (localStore.timer != null)
      window.clearTimeout(localStore.timer);

    runInAction(() => {
      if(localStore.value !== e.target.value)
      {
        localStore.value = e.target.value;
        localStore.timer = window.setTimeout(handleDelayedOnChange, props.waitInterval);
      }
    })
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (localStore.timer != null) {
        window.clearTimeout(localStore.timer);
        localStore.timer = null;
      }

      handleDelayedOnChange();
    }
  }

  return props.children({ handleOnKeyDown: handleOnKeyDown, handleOnChange: handleOnChange });
})
