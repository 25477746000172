import React, {FC}      from 'react';
import Row              from 'react-bootstrap/Row';
import Col              from 'react-bootstrap/Col';
import Form             from 'react-bootstrap/Form';
import {StringInput}    from '../common/StringInput';
import {observer}       from "mobx-react-lite";
import {IGameVersion}   from '../../../model/gameVersion/GameVersion';
import { GameVersionVersionInformation } from './GameVersionVersionInformation';
import { RowDivider } from '../../components/dividers/RowDivider';
import { FormGroupHeader, LabelHeader } from '@yakoffice/shared-components';


interface PropsType{
  gameVersion: IGameVersion
}


export const GameVersionMainInfo : FC<PropsType> = observer(({gameVersion} : PropsType) => {

  const handleNameChange = (value: string) => {
    gameVersion.setName(value);
  };

  const handleDescriptionChange = (value: string) => {
    gameVersion.setDescription(value);
  };

  const handleUrlChange = (value: string) => {
    gameVersion.setConfigApiPublishingUrlVersionId(value);
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs="12" sm="6">
          <Form.Group>
            <Form.Label><LabelHeader><i className="fas fa-signature"/> Name</LabelHeader></Form.Label>
            <StringInput id='geName' value={gameVersion.name} handleOnChange={handleNameChange}  data-testid="txtGameVersionName" />
          </Form.Group>
        </Col>

        <Col xs="12" sm="6">
          <Form.Group>
            <Form.Label><LabelHeader><i className="fas fa-align-left"/> Description</LabelHeader></Form.Label>
            <StringInput id='geDescription' value={gameVersion.description} handleOnChange={handleDescriptionChange} data-testid="txtGameVersionDescription"  />
          </Form.Group>
        </Col>
      </Row>
      <RowDivider />
      <Row>
        <Col md="12">
          <FormGroupHeader><i className="fas fa-server" /> Distribution Settings</FormGroupHeader>
          <Form.Group>
            <Form.Label>Config Api Publishing Url Version  <GameVersionVersionInformation /></Form.Label>
            <StringInput id='geUrl' value={gameVersion.configApiPublishingUrlVersionId} handleOnChange={handleUrlChange} data-testid="txtGameVersionPubUrlVersion" />
          </Form.Group>
        </Col>
      </Row>
    </React.Fragment>
  )
});
