
export enum ClaimType{
        Role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
        EditCategory = "http://yakuto.com/2020/02/identity/claims/editcategoryinproject",
        EditKind = "http://yakuto.com/2020/02/identity/claims/editkindinproject",
        EditGameVersion = "http://yakuto.com/2020/02/identity/claims/editgameversioninproject",
        ViewAllGameVersions = "http://yakuto.com/2020/02/identity/claims/viewallgameversionsinproject",
        EditGameEnvironment = "http://yakuto.com/2020/02/identity/claims/editgameenvironmentinproject",
        EditSelection = "http://yakuto.com/2020/02/identity/claims/editselectioninproject",
        EditEntity = "http://yakuto.com/2020/02/identity/claims/editentityingameenvironment",
        MoveEntity = "http://yakuto.com/2020/02/identity/claims/moveentityingameenvironment",
        DistributeEntities = "http://yakuto.com/2020/02/identity/claims/distributeentitiesingameenvironment",
        EditExperiments = "http://yakuto.com/2020/02/identity/claims/editexperimentingameenvironment",
}
