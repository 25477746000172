import React    from 'react';
import App      from './presentation/views/app';
import {rootStore, RootStoreProvider} from "./model/RootStoreContext";
import {createRoot} from "react-dom/client";
import {initYakofficeFirebaseApp} from "@yakoffice/yakoffice-firebase";

initYakofficeFirebaseApp();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <RootStoreProvider value={rootStore}>
        <App />
    </RootStoreProvider>
  </React.StrictMode>
);

