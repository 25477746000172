import React, {FC, useContext}  from 'react';
import {observer}               from 'mobx-react-lite';
import Container                from 'react-bootstrap/Container';
import Navbar                   from 'react-bootstrap/Navbar';
import Nav                      from 'react-bootstrap/Nav';
import {NavLink}                from 'react-router-dom';
import { ClaimType }            from '@yakoffice/publisher-types';
import {PublisherCategoriesNav} from './categoriesNav';
import RootStoreContext         from "../../../model/RootStoreContext";
import {
  useGetCategoriesLink,
  useGetCalendarLink,
  useGetDistributionsLink,
  useGetExperimentsLink,
  useGetGameEnvironmentsLink,
  useGetSelectionsLink,
  useGetGameVersionsLink,
} from '../../routes';
import {
  ProjectLogo,
  ProjectLogoPlaceholder
}                               from '@yakoffice/shared-components';
import { SearchBox }            from '../../views/search/SearchBox';
import styles                   from './publisher-nav.module.sass';



export const PublisherNav : FC = observer(() => {

  const rootStore               = useContext(RootStoreContext);
  const getGameEnvironmentsLink = useGetGameEnvironmentsLink();
  const getCategoriesLink       = useGetCategoriesLink();
  const getExperimentsLink      = useGetExperimentsLink();
  const getSelectionsLink       = useGetSelectionsLink()
  const getDistributionsLink    = useGetDistributionsLink()
  const getCalendarLink         = useGetCalendarLink()
  const getGameVersionsLink     = useGetGameVersionsLink();

  const adminEnvironmentsNav = () => {
    return (rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditGameEnvironment) &&
            <Nav.Item>
              <NavLink to={getGameEnvironmentsLink()} className="nav-link"><i className="fas fa-play-circle"/> Game Environments</NavLink>
            </Nav.Item>
    )
  }

  const calendarNav = () => {
      return (
        <Nav.Item>
          <NavLink to={getCalendarLink()} className="nav-link"><i className="far fa-calendar-alt"/> Calendar</NavLink>
        </Nav.Item>
      )
  }

  const distributionsNav = () => {
    return (
      <Nav.Item>
        <NavLink to={getDistributionsLink()} className="nav-link"><i className="fas fa-database" /> Distributions</NavLink>
      </Nav.Item>
    )
  }

  const experimentsNav = () => {
    return (
      <Nav.Item>
        <NavLink to={getExperimentsLink()} className="nav-link"><i className="fas fa-flask" /> Experiments</NavLink>
      </Nav.Item>
    )
  }

  const gameVersionsNav = () => {
    return (rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditGameVersion) &&
            <Nav.Item>
              <NavLink end to={getGameVersionsLink()} className="nav-link"><i className="fas fa-code-branch" /> Game Versions</NavLink>
            </Nav.Item>
    )
  }

  const selectionsNav = () => {
    return (
      rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditSelection) &&
      <Nav.Item>
        <NavLink to={getSelectionsLink()} className="nav-link"><i className="fas fa-check-double"/> Selections</NavLink>
      </Nav.Item>
    )
  }

  const projectClass = () => {
    return rootStore.projectStore.getCurrentProject().name.replace(/\s/g, '');
  }

  return (
    <Navbar className={`${styles.publisherNav} ${projectClass()}`} expand="md" collapseOnSelect={true}>
      <Container>
        <Navbar.Brand href={getCategoriesLink()}>
          {rootStore.projectStore.currentProject?.imageUrl
           ? <React.Fragment>Publisher <ProjectLogo height={65} src={require(`../../assets/images/logos/${rootStore.projectStore.getCurrentProject().imageUrl}`)} alt={rootStore.projectStore.getCurrentProject().name} className=""/></React.Fragment>
           : <React.Fragment><ProjectLogoPlaceholder /> {rootStore.projectStore.getCurrentProject().name}</React.Fragment>
          }
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="publisherNavInner" />
        <Navbar.Collapse id="publisherNavInner" className={styles.publisherNavInner} >
          <Nav>
            <div>
              <Nav.Item>
                <div className="nav-link"><SearchBox /></div>
              </Nav.Item>
            </div>
            <div className={styles.scrollWrapper}>
              {distributionsNav()}
              {experimentsNav()}
              <PublisherCategoriesNav/>
              {calendarNav()}
              {selectionsNav()}
              {adminEnvironmentsNav()}
              {gameVersionsNav()}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
});


export default PublisherNav;
