import React, {FC} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import Container from 'react-bootstrap/Container';
import {IGameEnvironment} from '../../../model/gameEnvironment/GameEnvironment';
import {IDistribution} from "../../../model/distributions/Distribution";
import {useGameEnvironmentCssClass} from "../common/useGameEnvironmentCssClass";
import {GameEnvironmentSelector} from "../common/GameEnvironmentSelector";
import {useDistributionsController} from "../../../controllers/useDistributionsController";
import {DistributionsNav} from "./DistributionsNav";
import {DistributionsTable} from "./DistributionsTable";
import {ViewHeader} from '@yakoffice/view-header';
import {useNavigate} from 'react-router-dom';
import {useDistributionsSearch} from "./useDistributionsSearch";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import {YakofficePageSize} from "../../components/yakoffice-pagesize/YakofficePageSize";
import {YakofficePagination} from "../../components/yakoffice-pagination/YakofficePagination";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import {runInAction} from 'mobx';
import {useGetDistributionsLink} from '../../routes';
import {GameVersionSelector} from '../common/GameVersionSelector';
import {IGameVersion} from '../../../model/gameVersion/GameVersion';


export const Distributions: FC = observer(() => {

  // Context
  const cssClass = useGameEnvironmentCssClass();
  const navigate = useNavigate();
  const {query, distributionsResult, distributionsCountResult, setPageSize, setPageNumber, setHideFailed} = useDistributionsSearch({
                                                                                                                                     defaultPageSize: 25,
                                                                                                                                     hideFailed:      true
                                                                                                                                   });
  const controller = useDistributionsController();
  const getDistributionsLink = useGetDistributionsLink();

  // State
  const checkedDistributions = useLocalObservable<IDistribution[]>(() => []);

  const handleChangePageNumber = (pageNumber: number) => {
    runInAction(() => checkedDistributions.length = 0);
    setPageNumber(pageNumber);
  }

  const handleDistribute = () => {
    // To cause a refresh of distributions
    controller.handleDistribute(() => {setHideFailed(query.hideFailed || true)})
  }

  const handleDownloadAsJson = () => {
    // To cause a refresh of distributions
    controller.handleDownloadAsJson(() => {setHideFailed(query.hideFailed || true)})
  }

  const handleCopyDistribution = (toGameEnvironment: IGameEnvironment) => {
    controller.handleCopyDistribution(checkedDistributions.length === 1 ? checkedDistributions[0] : null, toGameEnvironment,)
  }

  const handleDistributionCheckedToggle = (distribution: IDistribution) => {
    if (checkedDistributions.includes(distribution))
      runInAction(() => checkedDistributions.splice(checkedDistributions.indexOf(distribution), 1));
    else
      runInAction(() => checkedDistributions.push(distribution));
  }

  const onGameVersionChanged = (targetGv: IGameVersion) => navigate(getDistributionsLink({gameVersionId: targetGv.id}))
  const onGameEnvironmentChanged = (targetGe: IGameEnvironment) => navigate(getDistributionsLink({gameEnvironmentId: targetGe.id}))

  return (
    <div id="distributions" className={cssClass.name}>
      <Container>
        <ViewHeader title="Distributions" customAction={<>
          <GameVersionSelector handleGameVersionChange={onGameVersionChanged}/>
          <GameEnvironmentSelector handleGameEnvironmentChange={onGameEnvironmentChanged}/>
        </>
        }/>
      </Container>
      <Container>
        <Row>
          <Col xs="12" md="12" xl="8">
            <DistributionsNav
              hasDistributions={distributionsResult.distributions != null && distributionsResult.distributions.length > 0}
              checkedDistributions={checkedDistributions}
              handleDistribute={handleDistribute}
              handleCopyDistribution={handleCopyDistribution}
              handleDownloadAsJson={handleDownloadAsJson}
            />
          </Col>
          <Col xs="12" sm="12" md="12" xl="4" className="d-flex justify-content-end">
            {query.pageSize &&
             <span className="me-2">
                          <YakofficePageSize pageSize={query.pageSize} handleSetPageSize={setPageSize}/>
                        </span>
            }
            {(query.pageSize && query.pageNumber && !!distributionsCountResult.count) &&
             <YakofficePagination pageSize={query.pageSize}
                                  activePageNumber={query.pageNumber}
                                  itemsCount={distributionsCountResult.count}
                                  handleSetPageNumber={handleChangePageNumber}/>
            }
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <DistributionsTable
          statusHeader={(
            <DropdownButton variant={cssClass.name}
                            as={ButtonGroup}
                            id="bg-nested-dropdown"
                            title="Status">
              <div className="ms-3">
                <Form.Check key={`toggle`}
                            type="checkbox"
                            className="text-capitalize fw-normal"
                            label="hide failed"
                            onChange={() => setHideFailed(!query.hideFailed)}
                            checked={query.hideFailed}/>
              </div>
            </DropdownButton>
          )}
          distributionsResult={distributionsResult}
          checkedDistributions={checkedDistributions}
          handleDistributionCheckedToggle={handleDistributionCheckedToggle}
        />
      </Container>
    </div>
  )
})
