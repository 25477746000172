import React, { FC } from 'react';
import styles from './EditPropertyHoldingPanel.module.sass'


export const EditPropertyHoldingPanel : FC = () => {

  return <div className={`d-none d-lg-block text-center ${styles.panel}`}>
    <div className={styles.panelContent}>
      <div className={styles.panelTitle}>Select a property</div>
      <i className="fas fa-hand-point-left "/>
    </div>
  </div>
}
