import React, {FC, useContext}  from "react";
import {observer}               from "mobx-react-lite";
import Button                   from 'react-bootstrap/Button';
import DropdownButton           from 'react-bootstrap/DropdownButton';
import Dropdown                 from 'react-bootstrap/Dropdown';
import {ClaimType, EntityStatus}              from "@yakoffice/publisher-types";
import RootStoreContext         from "../../../model/RootStoreContext";
import type {IGameEnvironment}       from "../../../model/gameEnvironment/GameEnvironment";
import type {IEntityVersion}                from "../../../model/entity/EntityVersion";


interface PropsType {
    entityVersion : IEntityVersion;
    gameEnvironments: IGameEnvironment[];
    currentGameEnvironment: IGameEnvironment;
    handleStopEntity: () => void;
    handlePublishEntity: () => void;
    handleArchiveEntity: () => void;
    handleUnarchiveEntity: () => void;
    handleDeleteEntity: () => void;
    handleMoveEntity: (ge : IGameEnvironment) => void;
}

export const  EntityButtons : FC<PropsType> = observer((props) => {

  const rootStore = useContext(RootStoreContext);

  return props.entityVersion.isNewEntity()
    ? <span/>
    :<>
      {rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.MoveEntity, props.currentGameEnvironment) &&
      props.gameEnvironments
        .filter(env => env !== props.currentGameEnvironment)
        .find(env => rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.MoveEntity, env)) &&
      <DropdownButton id="move" title={<span><i className="fas fa-file-import" /> Move</span>}
                      variant="light">
        {
          props.gameEnvironments
            .filter(env => env !== props.currentGameEnvironment)
            .filter(env => rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.MoveEntity, env))
            .map((env) => {
              return <Dropdown.Item key={env.id} onClick={() => props.handleMoveEntity(env)}>{env.name}</Dropdown.Item>
            })
        }
      </DropdownButton>
      }

      <DropdownButton id="statusChange" title={<span className="text-capitalize"><i
        className="fas fa-eye" /> Change Status</span>}
                      variant="light" data-testid="btnStatusDropdownEntity">
        {props.entityVersion.entity.status !== EntityStatus.Published ?
          <Dropdown.Item onClick={props.handlePublishEntity} data-testid="btnStatusPublishEntity">Publish</Dropdown.Item> :
          <Dropdown.Item onClick={props.handleStopEntity} data-testid="btnStatusStopEntity">Stop</Dropdown.Item>
        }
        {props.entityVersion.entity.status !== EntityStatus.Archived ?
          <Dropdown.Item onClick={props.handleArchiveEntity} data-testid="btnStatusArchiveEntity">Archive</Dropdown.Item> :
          <Dropdown.Item onClick={props.handleUnarchiveEntity} data-testid="btnStatusUnarchiveEntity">Unarchive</Dropdown.Item>
        }
      </DropdownButton>

      {!props.entityVersion.entity.inAnyDistribution &&
      <Button variant="danger" onClick={props.handleDeleteEntity} data-value="deleteEntity">
        <i className="fas fa-trash-alt" /> Delete
      </Button>
      }
    </>
});
