import {Instance, types}    from "mobx-state-tree";
import {GenerateId}         from "../Utils";

export const GameEnvironment = types.model(
    "GameEnvironment",
    {
            id:                          types.optional(types.identifierNumber, GenerateId),
            name:                        "",
            description:                 "",
            configApiPublishingUrl:      "",
            createdAt:                   "",
            originalAuthor:              "",
            updatedAt:                   "",
            lastAuthor:                  "",
            slackNotificationWebhookUrl: "",
            isNew:                       false
    })
    .views( self => ({
        isNewGameEnvironment(){
            return self.isNew;
        },
    }))
    .actions(self => ({
        setName(name: string){
            self.name = name;
        },
        setDescription(description: string){
            self.description = description;
        },
        setConfigApiPublishingUrl(configApiPublishingUrl: string){
            self.configApiPublishingUrl = configApiPublishingUrl;
        },
        setSlackNotificationWebhookUrl(slackNotificationWebhookUrl: string) {
          self.slackNotificationWebhookUrl = slackNotificationWebhookUrl;
        }
    }));

export interface IGameEnvironment extends Instance<typeof GameEnvironment> {}
