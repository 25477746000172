import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import type {ISelection} from "../model/selections/Selection";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";


interface useSelectionsResult{
    isLoaded            : boolean;
    selections    : ISelection[] | null
}

export const useSelections = () => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();
    const {projectId, gameVersionId} = useParams<{projectId: string, gameVersionId: string}>();

    const [result, setResult] = useState<useSelectionsResult>({isLoaded: false, selections: []})

    useEffect(() => {
            setResult({isLoaded: false, selections: []})

            rootStore.selectionStore.loadSelections(false)
                .then(() => {
                  setResult({isLoaded: true, selections: rootStore.selectionStore.selections})
                })
                .catch(e => publishNotification({
                    notificationType: NotificationType.Error,
                    title: "Error loading selections",
                    message: `${e.message}`,
                    footer: "Please try refreshing"
                }))
    }, [rootStore.selectionStore, publishNotification, projectId, gameVersionId]);

    return result;
};
