import {Instance, types} from "mobx-state-tree"
import {pub_api} from "../../index";
import type {IProjectVm} from "@yakoffice/publisher-types";
import {flow} from "mobx";
import {ApiRequest} from "../ApiRequest";

export const ProjectApiGateway = types.model(
    "ProjectApiGateway",
    {
        apiRequest: ApiRequest
    })
    .actions(self => ({
        getProjects: flow(function* () {
            return yield self.apiRequest.get<IProjectVm[]>(getRootUrl())
        }),
    }))

const getRootUrl = () : string => {
    return `${pub_api}/projects`;
}

export interface IProjectApiGateway extends Instance<typeof ProjectApiGateway> {}
