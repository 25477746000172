import React, {FC}                      from 'react';
import {observer}                       from 'mobx-react-lite';
import {CustomSpinner}                  from '@yakoffice/custom-spinner';
import {useGameEnvironments}            from "../../../loaders/useGameEnvironments";
import {GameEnvironments}               from "./GameEnvironments";

export const GameEnvironmentsContainer : FC = observer(() => {

    const gameEnvironmentsResult = useGameEnvironments();

    return gameEnvironmentsResult.isLoaded && gameEnvironmentsResult.gameEnvironments != null
            ? <GameEnvironments gameEnvironments={gameEnvironmentsResult.gameEnvironments} />
            : <CustomSpinner spinnerText={'Refreshing game environments...'}/>

});
