import {getRoot, Instance, types} from "mobx-state-tree";
import type {IGameEnvironment} from "../gameEnvironment/GameEnvironment";
import {RootStore} from "../RootStore";
import type {IProject} from "../project/Project";
import {ClaimType} from "@yakoffice/publisher-types";
import {Claim} from "./Claim";

export const AuthUser = types.model(
    "AuthUser",
    {
        email: "",
        displayName: types.maybeNull(types.string),
        photoURL: types.maybeNull(types.string),
        claims: types.array(Claim)
    })
    .views(self => ({
        hasClaim(type: string, value: string) : boolean {
            return self.claims.find(claim => claim.type === type && claim.value === value) != null;
        },
    }))
    .views(self => ({
        isInRole(roleName: string): boolean{
            return self.hasClaim(ClaimType.Role, roleName);
        },
    }))
    .views(self => ({
        isViewer() : boolean {
            return self.isInRole("Viewer")
        },
        isSystemAdmin() : boolean {
            return self.isInRole("Administrator")
        },
    }))
    .views(self => ({
        hasGameEnvironmentClaim(claimType: ClaimType, gameEnvironment?: IGameEnvironment): boolean {
            if(self.isSystemAdmin())
                return true;

            if(gameEnvironment == null)
                gameEnvironment = getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment();

            return self.hasClaim(claimType, gameEnvironment.id.toString())
        },
        hasProjectClaim(claimType: ClaimType, project?: IProject): boolean {
            if(self.isSystemAdmin())
                return true;

            if(project == null)
                project = getRoot<typeof RootStore>(self).projectStore.getCurrentProject();

            return self.hasClaim(claimType, project.id.toString())
        },

    }))

export interface IAuthUser extends Instance<typeof AuthUser> {}
