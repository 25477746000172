import React, {FC}                  from 'react';
import {observer}                   from 'mobx-react-lite';
import { ContentBox, ContentBoxBody } from '../../components/content-box/ContentBox';
import styles from './SelectionLanding.module.sass'

export const SelectionLanding : FC = observer((props) => {
  return (
    <ContentBox className={styles.selectionLanding}>
      <ContentBoxBody className={styles.selectionsFormBody}>
        <div className="row">
          <div className="col-sm-6 d-none d-sm-block">
            <i className="fas fa-long-arrow-alt-left" />
            <h4>Click a selection on the left</h4>
          </div>
          <div className="col-sm-6 d-none d-sm-block">
            <i className="fas fa-long-arrow-alt-up" />
            <h4>Or create a new selection</h4>
          </div>
          <div className="col-xs-12 d-block d-sm-none text-center w-100">
            <i className="fas fa-long-arrow-alt-up" />
            <h4>Click or add a selection</h4>
          </div>
        </div>
      </ContentBoxBody>
    </ContentBox>
  )

});


