import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import type {ICategory} from "../model/category/Category";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from 'react-router-dom';

interface useCategoriesResult{
    isLoaded : boolean;
    categories    : ICategory[] | null
}

export const useCategories = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const {projectId, gameVersionId} = useParams<{projectId: string, gameVersionId: string}>();

  const [result, setResult] = useState<useCategoriesResult>({ isLoaded: false, categories: [] });

  useEffect(() => {
    setResult({isLoaded: false, categories: []})

    rootStore.categoryStore.loadCategories()
      .then(() => {
        setResult({
          isLoaded: true,
          categories: rootStore.categoryStore.categories
        })

      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading categories",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))

    return () => {
      rootStore.categoryStore.clearCategories();
    }
  }, [rootStore.categoryStore, publishNotification, projectId, gameVersionId]);

  return result;
};
