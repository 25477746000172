import React, { FC, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import RootStoreContext from '../../../model/RootStoreContext';
import { IExperiment } from '../../../model/experiment/Experiment';
import { ExperimentProperty } from './ExperimentProperty';
import { DragAndDrop, DragAndDropClassNames } from '../common/DragAndDrop';
import { IExperimentProperty } from '../../../model/experiment/ExperimentProperty';
import { IKindVersion } from '../../../model/kind/KindVersion';
import { ExperimentReadonlyProperty } from './ExperimentReadonlyProperty';
import { Alert, Nav, Tab } from 'react-bootstrap';
import { YoPillNavLink } from '../../components/yo-pills/YoPills';
import { YoListGroup } from '../../components/yo-list-group/YoListGroup';
import {Observer} from "mobx-react-lite";

interface PropsType {
  experiment: IExperiment;
  experimentKinds: IKindVersion[];
  specificationsForKinds: IKindVersion[];
}

export const ExperimentCustomProperties: FC<PropsType> = observer(({experiment, experimentKinds, specificationsForKinds}) => {

  const rootStore = useContext(RootStoreContext);
  const [activeProperty, setActiveProperty] = useState(experiment.properties.length > 0 ? experiment.properties[0] : null);

  const handleAddProperty = () => {
    setActiveProperty(experiment.addProperty());
  };

  const handleAddPropertyFromSelectedEntity = (property: IExperimentProperty) => {
    setActiveProperty(experiment.addPropertyFromSelectedEntity(property));
  };


  const handleRemoveProperty = (property: IExperimentProperty) => {
    if (activeProperty === property)
      setActiveProperty(null);

    experiment.removeProperty(property);
  };

  return <Tab.Container id='kindCustomProperties' activeKey={activeProperty ? activeProperty.id : 'selectPropertyPaneKey'}>
    <Row>
      <Col xs='12' sm='4' className='mb-5'>
        {!experiment.inAnyDistribution &&
         <div className='d-grid gap-2'>
           <Button variant='outline-primary' className='mt-3' onClick={handleAddProperty} data-testid='btnAddNewProperty'>
             <i className='fas fa-plus-circle' /> Add new property
           </Button>
         </div>
        }
        <YoListGroup className='scrollableList mt-3'>
          <DragAndDrop handleDrop={experiment.reorderProperties}>
            {experiment.properties.map((p: IExperimentProperty) => (<Observer key={p.id}>{() =>
                <Nav.Item key={p.id} id={p.id.toString()} draggable={true} className={DragAndDropClassNames.Draggable}>
                  <YoPillNavLink eventKey={p.id.toString()} onClick={() => setActiveProperty(p)}
                                 data-testid={`btnExperimentPropertyPill${p.kindPropertyKey}`} style={{marginBottom: "5px", padding: "10px 15px", borderRadius: "5px"}}>
                    {p.kindName ? p.displayString() : '(New Property)'} <i className='fas fa-ellipsis-v float-end mt-1'/>
                  </YoPillNavLink>
                </Nav.Item>
              }</Observer>
                                             )
            )}
          </DragAndDrop>
        </YoListGroup>
      </Col>
      <Col xs='12' sm='8'>
        <div data-testid='tabExperimentCustomProperty'>
          {activeProperty == null ? <Alert variant={'warning'}>No property selected</Alert> :
           experiment.inAnyDistribution
           ? <ExperimentReadonlyProperty variants={experiment.variants} property={activeProperty} />
           : <ExperimentProperty key={activeProperty.id}
                                 property={activeProperty}
                                 variants={experiment.variants}
                                 kinds={experimentKinds}
                                 selections={rootStore.selectionStore.selections}
                                 specificationsForKinds={specificationsForKinds}
                                 removeProperty={handleRemoveProperty}
                                 addAnother={handleAddPropertyFromSelectedEntity} />
          }
        </div>
      </Col>
    </Row>
  </Tab.Container>;
});
