import React, {FC}          from "react";
import Row                  from 'react-bootstrap/Row';
import Col                  from 'react-bootstrap/Col';
import {ICategory}          from "../../../model/category/Category";
import { formatISODateStringAsLocal } from '../common/Utils';
import { observer } from 'mobx-react-lite';
import { InfoBox, InfoBoxHeader, InfoBoxBody } from '@yakoffice/info-box';
import { CollapsePanel } from '@yakoffice/shared-components';
import { AbbreviatedString } from '../../components/abbreviated-string/AbbreviatedString';

interface PropsType {
    category : ICategory,
}


export const CategoryHighlights : FC<PropsType> = observer((props) => {

  return (
    <CollapsePanel>
      <Row>
        <Col sm="4">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-calendar-alt" /> Last updated</InfoBoxHeader>
            <InfoBoxBody>{formatISODateStringAsLocal(props.category.updatedAt)}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="4">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-user" /> Updated by</InfoBoxHeader>
            <InfoBoxBody>{props.category.lastAuthor}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="4">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-comment" /> Last comment</InfoBoxHeader>
            <InfoBoxBody>"<AbbreviatedString value={props.category.lastComment} lengthToAbbreviate={40}/>"</InfoBoxBody>
          </InfoBox>
        </Col>
      </Row>
    </CollapsePanel>
  )
});
