import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { onSnapshot } from 'mobx-state-tree';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ISelectionOption } from '../../../model/selections/SelectionOption';
import { useSelectionController } from '../../../controllers/useSelectionController';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { useSelection } from './useSelection';
import { SelectionOption } from './SelectionOption';
import { UsedInKind } from './UsedInKind';
import Row from 'react-bootstrap/cjs/Row';
import { SelectionButtons } from './SelectionButtons';
import { HorizontalDivider } from '../../components/dividers/HorizontalDivider';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader
} from '../../components/content-box/ContentBox';

interface PropsType {
  showArchived: (archived: boolean) => void;
  refreshSelections: (refresh: boolean) => void;
  selectionsRefreshStatus: boolean;
}


export const Selection: FC<PropsType> = observer(({ showArchived, refreshSelections, selectionsRefreshStatus }) => {

  const [edited, setEdited] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(true);
  const selectionResult = useSelection(refreshFlag);
  const controller = useSelectionController();

  useEffect(() => {
    if (selectionResult.isLoaded && selectionResult.selection != null) {
      onSnapshot(selectionResult.selection, () => {
        setEdited(true);
      });
    } else {
      setEdited(false);
    }
  }, [selectionResult]);

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    selectionResult.selection && selectionResult.selection.setName(e.target.value);
  };

  const updateDescription = (e: ChangeEvent<HTMLInputElement>) => {
    selectionResult.selection && selectionResult.selection.setDescription(e.target.value);
  };

  const addOption = () => {
    selectionResult.selection && selectionResult.selection.addOption();
  };

  const removeOption = (option: ISelectionOption) => {
    selectionResult.selection && selectionResult.selection.removeOption(option);
  };

  const saveSelection = () => {
    selectionResult.selection && controller.handleSaveSelection(selectionResult.selection, () => {
      setRefreshFlag(!refreshFlag);
      refreshSelections(!selectionsRefreshStatus);
    });
  };

  const deleteSelection = () => {
    selectionResult.selection && controller.handleDeleteSelection(selectionResult.selection);
  };

  const archiveSelection = (archive: boolean) => {
    selectionResult.selection && controller.handleArchiveSelection(selectionResult.selection, archive, () => {
      showArchived(archive);
    });
  };

  const handleCreateSheet = () => {
    selectionResult.selection && controller.handleCreateSheet(selectionResult.selection, () => {
      setEdited(false);
    });
  };

  const handleViewSheet = () => {
    selectionResult.selection && controller.handleViewSheet(selectionResult.selection);
  };

  const handleRefreshSheet = () => {
    selectionResult.selection && controller.handleRefreshSheet(selectionResult.selection);
  };

  const handleUploadSheet = () => {
    selectionResult.selection && controller.handleUploadSheet(selectionResult.selection, () => {
      setRefreshFlag(!refreshFlag);
    });
  };

  const handleDeleteSheet = () => {
    selectionResult.selection && controller.handleDeleteSheet(selectionResult.selection, () => {
      setEdited(false);
    });
  };

  return !selectionResult.isLoaded || !selectionResult.selection
         ? <CustomSpinner spinnerText={'Loading selection'} />
         : <ContentBox>
           <ContentBoxHeader>
             <SelectionButtons
               selection={selectionResult.selection}
               isEditedSelection={edited}
               saveSelection={saveSelection}
               archiveSelection={archiveSelection}
               deleteSelection={deleteSelection}
               handleCreateSheet={handleCreateSheet}
               handleViewSheet={handleViewSheet}
               handleRefreshSheet={handleRefreshSheet}
               handleUploadSheet={handleUploadSheet}
               handleDeleteSheet={handleDeleteSheet}
             />
           </ContentBoxHeader>
           <ContentBoxBody>
             <Row>
               <Col md='9'>
                 <div>
                   <Row>
                     <Col md='6'>
                       <Form.Group>
                         <Form.Label>Selection Name</Form.Label>
                         <Form.Control type='text'
                                       key={`name-${selectionResult.selection.id}`}
                                       id={`name-${selectionResult.selection.id}`}
                                       placeholder={selectionResult.selection.name}
                                       defaultValue={selectionResult.selection.name}
                                       onChange={updateName}
                                       data-testid='txtSelectionName' />
                       </Form.Group>
                     </Col>

                     <Col md='6'>
                       <Form.Group>
                         <Form.Label>Description</Form.Label>
                         <Form.Control type='text'
                                       key={`desc-${selectionResult.selection.id}`}
                                       id={`desc-${selectionResult.selection.id}`}
                                       placeholder={selectionResult.selection.description}
                                       defaultValue={selectionResult.selection.description}
                                       onChange={updateDescription}
                                       data-testid='txtSelectionDescription' />
                       </Form.Group>
                     </Col>
                   </Row>
                   <HorizontalDivider />
                   {selectionResult.selection.options && selectionResult.selection.options.map(option => {
                     return <React.Fragment key={option.id}>
                       <SelectionOption option={option} removeOption={removeOption} />
                       <HorizontalDivider />
                     </React.Fragment>;
                   })}
                   <div className='mt-3 d-grid gap-2'>
                     <Button variant='outline-success' onClick={addOption} data-testid='btnAddOption'>
                       <i className='fas fa-plus' /> Add new option
                     </Button>
                   </div>
                 </div>
               </Col>
               <Col md='3'>
                 <UsedInKind selection={selectionResult.selection} />
               </Col>
             </Row>
           </ContentBoxBody>
         </ContentBox>;

});
