import React, { FC, useState } from 'react';
import {observer}                                       from 'mobx-react-lite';
import Tab                                              from "react-bootstrap/Tab";
import {IGameEnvironment}                               from "../../../model/gameEnvironment/GameEnvironment";
import {useDistributionDiff}                            from "./useDistributionDiff";
import {CustomSpinner}                                  from '@yakoffice/custom-spinner';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { DiffGroupedView } from './DiffGroupedView';
import { IEntityVersionDiffVm, IEntityVersionVm } from '@yakoffice/publisher-types';
import { useDistributionDiffController } from '../../../controllers/useDistributionDiffController';
import styles from './DistributionDiff.module.sass';
import { DistributionOverlayHeader } from './DistributionOverlayHeader';
import { IconLeftDiff } from './IconLeftDiff';
import { IconRightDiff } from './IconRightDiff';
import { Nav, TabContainer } from 'react-bootstrap';
import { DiffListView } from './DiffListView';
import { DistributionId } from '@yakoffice/publisher-types';

interface PropsType {
    gameVersion : IGameVersion,
    gameEnvironment : IGameEnvironment,
    distributionId: DistributionId,
    diffGameVersion: IGameVersion,
    diffGameEnvironment: IGameEnvironment,
    diffDistributionId : DistributionId
}

const groupedViewKey = "diffGroupedView";
const listViewKey = "diffListView"

export const DistributionDiff : FC<PropsType> = observer((props) => {

  const controller = useDistributionDiffController();
  const [refreshToggle, setRefreshToggle] = useState(true);
  const diffResult = useDistributionDiff(props.gameVersion, props.gameEnvironment, props.distributionId, props.diffGameVersion, props.diffGameEnvironment, props.diffDistributionId, refreshToggle)
  const [activeKey, setActiveKey] = useState(groupedViewKey)

  const refreshResult = () => setRefreshToggle(toggle => !toggle);

  const handleMergeEntityDiffs = (modifiedEntities: IEntityVersionDiffVm[]) => {
    if (modifiedEntities.length > 0)
      controller.handleMergeEntityDiffs(props.gameVersion, modifiedEntities, () => refreshResult());
  }

  const handleStopEntities = (entities: IEntityVersionVm[]) => {
    if (entities.length > 0)
      controller.handleStopEntities(entities, () => refreshResult());
  }

  const handleCopyPublishEntities = (entities: IEntityVersionVm[]) => {
    if (entities.length > 0)
      controller.handleCopyPublishEntities(props.diffGameVersion, props.gameVersion, props.gameEnvironment, entities, () => refreshResult());
  }

  const distributionLabel = props.distributionId !== "published" ? `${props.distributionId} in ` : "";
  const diffDistributionLabel = props.diffDistributionId !== "published" ? `${props.diffDistributionId} in ` : "";

  const title = <span>
                  <IconLeftDiff />{`${distributionLabel}${props.gameEnvironment.name} (${props.gameVersion.name})`}
                  <i className="fas fa-exchange-alt me-4 ms-4" />
                  <span className="fw-light">{`${diffDistributionLabel}${props.diffGameEnvironment.name} (${props.diffGameVersion.name})`}</span>
                  <IconRightDiff />
                </span>;

  return <TabContainer id="diffViewsTabContainer" activeKey={activeKey} transition={false}>
        <DistributionOverlayHeader title={title}
                                   navOptions={<Nav className={styles.tabs}>
                                                 <Nav.Item>
                                                   <Nav.Link eventKey={groupedViewKey} onClick={() => setActiveKey(groupedViewKey)}><i className="fas fa-sitemap" /></Nav.Link>
                                                 </Nav.Item>
                                                 <Nav.Item>
                                                   <Nav.Link eventKey={listViewKey} onClick={() => setActiveKey(listViewKey)}><i className="fas fa-list" /></Nav.Link>
                                                 </Nav.Item>
                                               </Nav>}
        />
    {!diffResult.isLoaded || diffResult.distributionDiff == null
      ?<CustomSpinner spinnerText="Loading diff..." />
      :<Tab.Content>
        <Tab.Pane eventKey={groupedViewKey}>
          <DiffGroupedView distributionDiff={diffResult.distributionDiff}
                           gameEnvironment={props.gameEnvironment}
                           allowActions={props.distributionId === "published"}
                           handleMergeEntityDiffs={handleMergeEntityDiffs}
                           handleStopEntities={handleStopEntities}
                           handleCopyPublishEntities={handleCopyPublishEntities}
          />
        </Tab.Pane>
        <Tab.Pane eventKey={listViewKey}>
          <DiffListView distributionDiff={diffResult.distributionDiff}
                        gameEnvironment={props.gameEnvironment}
          />
        </Tab.Pane>
      </Tab.Content>
    }
    </TabContainer>
});
