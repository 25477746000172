import React, {FC, useState} from "react";
import {IKindVersion} from "../../../model/kind/KindVersion";
import Form from "react-bootstrap/Form";
import FieldLayout from "../../components/inputs/fieldLayout";
import {IKindProperty} from "../../../model/kind/KindProperty";
import { observer } from 'mobx-react-lite';
import { FeatureHeader } from '@yakoffice/shared-components';

interface PropsType {
    specificationKinds : IKindVersion[]
    handleAddSpecificationProperty : (specificationKind: IKindVersion, specificationProperty: IKindProperty) => void
}

export const SpecificationsSelector : FC<PropsType> = observer((props) => {

  // It's easier to use state than an observable here for local property
  const [activeSpecificationKind, setActiveSpecificationKind] = useState<IKindVersion | undefined>(undefined);

  const handleSpecificationKindChange = (kindId: string) => {
    if (kindId === "")
      setActiveSpecificationKind(undefined);
    else
      setActiveSpecificationKind(props.specificationKinds.find(k => k.kind.id === parseInt(kindId)));
  };

  const handleSpecificationPropertyChange = (kindPropertyId: string) => {
    if (kindPropertyId === "")
      return;
    else {
      const specificationProperty = activeSpecificationKind && activeSpecificationKind.properties.find(kp => kp.id === parseInt(kindPropertyId));
      if (activeSpecificationKind && specificationProperty)
        props.handleAddSpecificationProperty(activeSpecificationKind, specificationProperty);
    }
  };

  return (
    <React.Fragment>
      <FieldLayout label={<FeatureHeader size="sm"><i className="fas fa-bullseye"/> Specifications</FeatureHeader>}>
        <Form.Select onChange={e => handleSpecificationKindChange(e.target.value)} data-testid="ddlSpecifications">
          <option value="">Select specification kind...</option>
          {props.specificationKinds.map((k: IKindVersion) => {
            return <option key={k.kind.id} value={k.kind.id}>{k.name}</option>
          })}
        </Form.Select>
      </FieldLayout>
      {activeSpecificationKind &&
        <FieldLayout label={"Select specification property"}>
          <Form.Select onChange={e => handleSpecificationPropertyChange(e.target.value)} data-testid={`ddlSpecification${activeSpecificationKind.name}`}>
            <option value="">Select...</option>
            {activeSpecificationKind.properties.map(kp => {
              return <option key={kp.id} value={kp.id}>{kp.key}</option>
            })}
          </Form.Select>
        </FieldLayout>
      }
    </React.Fragment>
  );
});
