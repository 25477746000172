import {applySnapshot, getSnapshot, Instance, types} from 'mobx-state-tree';
import {EntityProperty, IEntityProperty}        from './EntityProperty';
import { GenerateId, GenerateIdString } from '../Utils';
import type {IGameEnvironment} from "../gameEnvironment/GameEnvironment";
import type {IKindVersion} from "../kind/KindVersion";
import type {IKindProperty} from "../kind/KindProperty";
import { Entity } from './Entity';
import { KindVersionSummary } from '../kind/KindVersionSummary';
import { mapKindVersionToKindVersionSummary } from './EntitytVersionStore';
import { EntityStatus } from '@yakoffice/publisher-types';

export const  EntityVersion = types.model(
    "Entity",
  {
    id: types.optional(types.identifier, GenerateIdString),
    version: 0,
    entity: types.frozen(Entity),
    kindVersionSummary: types.frozen(KindVersionSummary),

    name: '',
    description: '',
    comment: types.maybeNull(types.string),

    properties: types.array(EntityProperty),

    inLatestDistribution: false,
    inAnyDistribution: false,

    createdAt: "",
    createdBy: "",

    specificationPropertiesUpdated: false,
    edited: false
  })
  .views(self => ({
    // getCurrentKindVersion(): IKindVersion {
    //     return self.currentKindVersion;
    // },
    getProperties(): IEntityProperty[] {
      return self.properties;
    },
    getKindProperties(): IEntityProperty[] {
      return self.properties.filter(property => property.kindPropertyKindId === self.kindVersionSummary.kindId)
    },
    getSpecificationProperties(): IEntityProperty[] {
      return self.properties.filter(property => property.kindPropertyKindId !== self.kindVersionSummary.kindId)
    },
    isNewEntity() {
      return self.version === 0;
    },
    isCurrentVersionOfEntity() {
      return self.version === self.entity.versionCurrent;
    },
    isOnOldVersionOfKind() {
      return self.kindVersionSummary.version !== self.kindVersionSummary.kindVersionCurrent;
    }
  }))
  .views(self => ({
    requiresSaving() {
      return self.isNewEntity() || self.specificationPropertiesUpdated || self.edited
    },
    getSchedulingSpecificationProperties(): IEntityProperty[] {
      return self.getSpecificationProperties().filter(p => ["startTime", "startTimeLocal", "endTime", "endTimeLocal"].includes(p.kindPropertyKey));
    },
  }))
  .actions( self => ({
        // setCurrentKindVersion(kind: IKindVersion) {
        //     self.currentKindVersion = kind;
        // },
        setEntityId(entityId: number){
          self.entity = getSnapshot(Entity.create({
            ...self.entity,
            id: entityId
          }));
        },
        setName(name: string) {
          self.name = name;
        },
        setDescription(description: string) {
          self.description = description;
        },
        setComment(comment: string) {
          self.comment = comment;
        },
        setEdited(edited: boolean) {
          self.edited = edited;
        },
        addProperty(kindProperty: IKindProperty) {
          self.properties.push(EntityProperty.create({
            kindPropertyId: kindProperty.id,
            kindPropertyKindId: self.kindVersionSummary.kindId,
            kindPropertyKey: kindProperty.key,
            value: kindProperty.default
          }));
        },
        addSpecificationProperty(specificationKindVersion: IKindVersion, specificationProperty: IKindProperty) {
          self.properties.push(EntityProperty.create({
            kindPropertyId: specificationProperty.id,
            kindPropertyKindId: specificationKindVersion.kind.id,
            kindPropertyKey: specificationProperty.key,
            value: specificationProperty.default
          }));
        },
        removeProperty(property: IEntityProperty) {
          self.properties.splice(self.properties.indexOf(property), 1);
        },
        copyEntityVersion(targetGameEnvironment: IGameEnvironment) {
          const snapshot = getSnapshot(self);
          return EntityVersion.create({
            ...snapshot,
            id: GenerateIdString(),
            version: 0,
            entity: getSnapshot(Entity.create({
              id: GenerateId(),
              status: EntityStatus.Draft,
              gameEnvironmentId: targetGameEnvironment.id
            })),
            kindVersionSummary: self.kindVersionSummary
          });
        },
        updateFrom(entityVersion: IEntityVersion) {
          const snapshot = getSnapshot(entityVersion);
          applySnapshot(self,
            {
              ...snapshot,
              id: self.id,
              version: self.version,
              entity: self.entity
            });
        },
        updateKindVersionAndProperties(desiredKindVersion: IKindVersion) {
          if(self.kindVersionSummary.kindId === desiredKindVersion.kind.id && self.kindVersionSummary.version === desiredKindVersion.version)
            return;

          const updatedProperties: IEntityProperty[] = [];

          // Properties will have different ids due to versioning so match on:
          // * PreviousId:  Will only work if updating from previous version
          // * Key:  Best effort
          desiredKindVersion.properties.forEach(desiredKindProperty => {
            const matchingEntityProperty = self.getKindProperties().find(ep => ep.kindPropertyId === desiredKindProperty.previousId || ep.kindPropertyKey === desiredKindProperty.key);
            if (matchingEntityProperty) {
              matchingEntityProperty.setKindPropertyId(desiredKindProperty.id);
              matchingEntityProperty.setKindPropertyKindId(desiredKindVersion.kind.id);
              matchingEntityProperty.setKindPropertyKey(desiredKindProperty.key);
              updatedProperties.push(matchingEntityProperty);
            } else {
              // New property or a property with an updated key more than one version later
              updatedProperties.push(EntityProperty.create({
                kindPropertyKindId: desiredKindVersion.kind.id,
                kindPropertyId: desiredKindProperty.id,
                kindPropertyKey: desiredKindProperty.key,
                value: desiredKindProperty.default
              }));
            }
          });

          // Only include specification properties if desired kind version allows them and they don't clash with kind properties
          if (desiredKindVersion.canUseSpecifications)
            updatedProperties.push(...self.getSpecificationProperties().filter(sp => !updatedProperties.find(up => sp.kindPropertyKey === up.kindPropertyKey)))

          self.properties.replace(updatedProperties);

          self.kindVersionSummary = getSnapshot(KindVersionSummary.create(mapKindVersionToKindVersionSummary(desiredKindVersion)));
          self.edited = true;
        },
        updateSpecificationPropertiesToCurrentSpecificationKindVersion(specificationKindVersions: IKindVersion[]) {
          const updatedProperties: IEntityProperty[] = self.getKindProperties();

          if (self.kindVersionSummary.canUseSpecifications) {
            self.getSpecificationProperties().forEach(specificationEntityProperty => {
              const specificationKindVersion = specificationKindVersions.find(specificationKind => specificationKind.kind.id === specificationEntityProperty.kindPropertyKindId)
              if (specificationKindVersion) {
                // Properties could have different ids due to versioning so match on:
                // * Id:  If specification kind version hasn't been updated
                // * PreviousId:  Will only work if updating from previous version
                // * Key:  Best effort
                const specificationKindProperty = specificationKindVersion.properties.find(specificationKindProperty =>
                  specificationEntityProperty.kindPropertyId === specificationKindProperty.id ||
                  specificationEntityProperty.kindPropertyId === specificationKindProperty.previousId ||
                  specificationEntityProperty.kindPropertyKey === specificationKindProperty.key)
                if (specificationKindProperty) {
                  // If ids are different then must be on old version
                  if (specificationEntityProperty.kindPropertyId !== specificationKindProperty.id) {
                    self.specificationPropertiesUpdated = true;
                    specificationEntityProperty.setKindPropertyId(specificationKindProperty.id)
                    specificationEntityProperty.setKindPropertyKindId(specificationKindVersion.kind.id);
                    specificationEntityProperty.setKindPropertyKey(specificationKindProperty.key)
                  }

                  if (!updatedProperties.find(existingProperty => existingProperty.kindPropertyKey === specificationEntityProperty.kindPropertyKey))
                    updatedProperties.push(specificationEntityProperty);
                  else
                    self.specificationPropertiesUpdated = true;  // Specification property removed due to key clash with kind property

                } else {
                  // Specification Kind property has been deleted or changed key and more than one version old
                  self.specificationPropertiesUpdated = true;
                }
              } else {
                // Specification Kind has been deleted
                self.specificationPropertiesUpdated = true;
              }
            });
          }

          self.properties.replace(updatedProperties);
        }
      }
    )
  );

export interface IEntityVersion extends Instance<typeof EntityVersion> {}
