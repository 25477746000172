import React, { FC, useState } from 'react';
import {observer}                           from 'mobx-react-lite';
import {CustomSpinner}                      from '@yakoffice/custom-spinner';
import {useGameEnvironments}                from "../../../loaders/useGameEnvironments";
import { useGameVersions } from '../../../loaders/useGameVersions';
import { useSetProjectFromParam } from '../common/useSetProjectFromParam';
import Project from './project';
import { GameVersionStatus } from '@yakoffice/publisher-types';

export const ProjectContainer : FC = observer(() => {

  useSetProjectFromParam();
  const [gameVersionSearchCriteria] = useState({status: [GameVersionStatus.Unreleased, GameVersionStatus.Live, GameVersionStatus.Inactive]})
  const loadGameVersionsResult = useGameVersions(gameVersionSearchCriteria);  // NB. required for <PublisherNav> and setting default gameVersion
  const loadGameEnvironmentsResult = useGameEnvironments();  // NB. required for <PublisherNav> and setting default gameEnvironment

  return loadGameVersionsResult.isLoaded && loadGameEnvironmentsResult.isLoaded
    ? <Project />
    : <CustomSpinner spinnerText="Loading game versions and environments" />
});

export default ProjectContainer;
