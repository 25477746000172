import React, { FC } from 'react';
import Tab from 'react-bootstrap/Tab';
import { YoTabBadge } from '../../components/yo-tab-badge/YoTabBadge';
import Container from 'react-bootstrap/cjs/Container';
import { Alert } from 'react-bootstrap';
import Tabs from 'react-bootstrap/cjs/Tabs';
import Badge from 'react-bootstrap/cjs/Badge';
import { GroupedByKindEntityVersions } from './GroupedByKindEntityVersions';
import { GroupedByKindModifiedEntityVersions } from './GroupedByKindModifiedEntityVersions';
import { ExperimentVmTable } from './ExperimentVmTable';
import { YoTabs } from '../../components/yo-tabs/YoTabs';
import { IDistributionDiffVm, IEntityVersionDiffVm, IEntityVersionVm } from '@yakoffice/publisher-types';
import { useGameEnvironmentCssClass } from '../common/useGameEnvironmentCssClass';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';
import styles from './DiffGroupedView.module.sass';

interface PropTypes {
  distributionDiff: IDistributionDiffVm
  gameEnvironment: IGameEnvironment
  allowActions: boolean
  handleMergeEntityDiffs : (modifiedEntities: IEntityVersionDiffVm[]) => void
  handleStopEntities: (entities: IEntityVersionVm[]) => void
  handleCopyPublishEntities: (entities: IEntityVersionVm[]) => void
}

export const DiffGroupedView : FC<PropTypes> = ({distributionDiff,gameEnvironment, allowActions,handleMergeEntityDiffs, handleStopEntities, handleCopyPublishEntities}) =>
{
  const cssClass = useGameEnvironmentCssClass(gameEnvironment);

  return <YoTabs id="diffGroupedTabs">
    <Tab eventKey="entitiesTab"
         className={`${styles.diffCompareTab}`}
         title={<span data-testid="tabEntities">
                     <YoTabBadge bg="secondary">
                       <i className="fas fa-caret-up me-2" />
                       {distributionDiff.addedEntities.length + distributionDiff.modifiedEntities.length + distributionDiff.deletedEntities.length}
                     </YoTabBadge> Entities</span>}>

      {(distributionDiff.addedEntities.length === 0 && distributionDiff.modifiedEntities.length === 0 && distributionDiff.deletedEntities.length === 0)
        ? <Container>
          <Alert variant={'success'} className="text-center">No differences between entities</Alert>
        </Container>


        : <Container>
          <Tabs id="entitiesDiff" variant="pills">
            {distributionDiff.addedEntities.length > 0 &&
            <Tab eventKey="addedEntities"
                 title={<span className="h6" data-testid="tabAddedEntities"><i className="fas fa-plus" /> Added <Badge bg="primary">{distributionDiff.addedEntities.length}</Badge></span>}>
              <div data-testid="tabContentAddedEntities">
                <GroupedByKindEntityVersions className={cssClass.name}
                                             entityVersions={distributionDiff.addedEntities}
                                             entitiesTitle="Added Entities"
                                             allowSelection={allowActions}
                                             selectedEntityButtonName="Stop Entities"
                                             handleSelectedEntities={handleStopEntities}
                />
              </div>
            </Tab>
            }
            {distributionDiff.deletedEntities.length > 0 &&
            <Tab eventKey="deletedEntities"
                 title={<span className="h6" data-testid="tabDeletedEntities"><i className="fas fa-minus" /> Deleted <Badge bg="primary">{distributionDiff.deletedEntities.length}</Badge></span>}>
              <div data-testid="tabContentDeletedEntities">
                <GroupedByKindEntityVersions className={cssClass.name}
                                             entityVersions={distributionDiff.deletedEntities}
                                             entitiesTitle="Deleted Entities"
                                             allowSelection={allowActions}
                                             selectedEntityButtonName="Copy/Publish Entities"
                                             handleSelectedEntities={handleCopyPublishEntities}/>
              </div>
            </Tab>
            }
            {distributionDiff.modifiedEntities.length > 0 &&
            <Tab eventKey="modifiedEntities" title={<span className="h6" data-testid="tabModifiedEntities"><i className="fas fa-edit" /> Modified <Badge bg="primary">{distributionDiff.modifiedEntities.length}</Badge></span>}>
              <div data-testid="tabContentModifiedEntities">
                <GroupedByKindModifiedEntityVersions className={cssClass.name}
                                                     modifiedEntities={distributionDiff.modifiedEntities}
                                                     allowSelection={allowActions}
                                                     handleMergeChanges={handleMergeEntityDiffs}
                />
              </div>
            </Tab>
            }
          </Tabs>
        </Container>
      }
    </Tab>

    <Tab eventKey="experimentsTab"
         className={`${styles.diffCompareTab}`}
         title={<span className="align-content-m h6" data-testid="tabExperiments"><YoTabBadge bg="secondary">
                   <i className="fas fa-caret-up me-2" />{distributionDiff.addedExperiments.length + distributionDiff.deletedExperiments.length}</YoTabBadge> Experiments</span>}>
      {(distributionDiff.addedExperiments.length === 0 && distributionDiff.deletedExperiments.length === 0)
        ? <Container>
          <Alert variant={'success'} className="text-center">No differences between experiments</Alert>
        </Container>
        : <Container>
          <Tabs id="experimentsDiff" variant="pills">
            {distributionDiff.addedExperiments.length > 0 &&
            <Tab eventKey="addedExperiments"
                 title={<span className="h6" data-testid="tabAddedExperiments"><i className="fas fa-plus" /> Added <Badge bg="primary">{distributionDiff.addedExperiments.length}</Badge></span>}>
              <Container>
                {distributionDiff.addedExperiments.length > 0 ? <ExperimentVmTable className={cssClass.name} experiments={distributionDiff.addedExperiments} title="Added Experiments" />
                  : <div>No additional experiments</div>
                }
              </Container>
            </Tab>
            }
            {distributionDiff.deletedExperiments.length > 0 &&
            <Tab eventKey="deletedExperiments"
                 title={<span className="h6" data-testid="tabDeletedExperiments"><i className="fas fa-minus" /> Deleted <Badge bg="primary">{distributionDiff.deletedExperiments.length}</Badge></span>}>
              <Container>
                {distributionDiff.deletedExperiments.length > 0 ? <ExperimentVmTable className={cssClass.name} experiments={distributionDiff.deletedExperiments} title="Deleted Experiments"/>
                  : <div>All experiments are present</div>
                }
              </Container>
            </Tab>
            }
          </Tabs>
        </Container>
      }
    </Tab>
  </YoTabs>
}
