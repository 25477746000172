import {IDistribution} from "../../../model/distributions/Distribution";
import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../../../model/RootStoreContext";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from 'react-router-dom';

export interface useDistributionResult{
    isLoaded : boolean;
    distribution : IDistribution | null
}

export const useDistribution = () => {
  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const {distributionId} = useParams() as {distributionId: string};

  const [result, setResult] = useState<useDistributionResult>({ isLoaded: false, distribution: null })

  useEffect(() => {
    setResult({ isLoaded: false, distribution: null })
      rootStore.distributionStore.getDistribution(parseInt(distributionId))
        .then(distribution => {
          setResult({ isLoaded: true, distribution: distribution })
        })
        .catch(e => publishNotification({
          notificationType: NotificationType.Error,
          title: "Error loading distribution",
          message: `${e.message}`,
          footer: "Please try refreshing"
        }))
  }, [distributionId,rootStore.distributionStore, publishNotification, rootStore.gameVersionStore.currentGameVersion, rootStore.gameEnvironmentStore.currentGameEnvironment]);

  return result;
};
