import React, {FC} from "react";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    value : string;
    handleOnChange: (e: string) => void;
}

export const RegexInput : FC<PropsType> = (props) => {
    return <Form.Control type="text"
                  id={props.id}
                  placeholder="Enter Regex"
                  onChange={e => props.handleOnChange(e.target.value)}
                  value={props.value}
    />
}