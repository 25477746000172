import React, {FC} from "react";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    value : string;
    noSelectionText: string;
    handleOnChange: (value : string) => void;
}

export const BooleanInput : FC<PropsType> = ({ id, value, noSelectionText, handleOnChange, ...props}) => {

    return  <Form.Select id={id} value={value?.toLowerCase()} onChange={e => handleOnChange(e.target.value)} {...props}>
                <option value="">{noSelectionText}</option>
                <option>true</option>
                <option>false</option>
            </Form.Select>
};
