import React, {FC, PropsWithChildren} from 'react';

interface PropTypes {
  flexColClassName? : string;
}

export const TdFlexColumn : FC<PropsWithChildren<PropTypes>> = ({flexColClassName = "", children}) => {

  // Hack:  td must have fixed height if child div height:100% is to fill it  so give it an artificial height as a workaround
  return <td style={{"height":"1px"}}>
    <div className={`d-flex h-100 flex-column ${flexColClassName}`}>
      {children}
    </div>
  </td>
}
