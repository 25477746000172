import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {IExperimentVm} from "@yakoffice/publisher-types";
import { formatISODateStringAsLocal } from '../common/Utils';
import {useGameEnvironmentCssClass} from "../common/useGameEnvironmentCssClass";
import {ContentBox, ContentBoxBody, ContentBoxHeader, ContentBoxNavBar} from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import {YoPublisherTable, YoTableClassNames} from '../../components/yo-publisher-table/YoPublisherTable';
import { Container } from 'react-bootstrap';


interface PropsType {
    className?: string,
    experiments : IExperimentVm[],
    title: string,
}


export const ExperimentVmTable : FC<PropsType> = observer((props) => {

    const cssClass = useGameEnvironmentCssClass();

    return (
      <ContentBox>
        <ContentBoxHeader>
          <ContentBoxNavBar variant="dark"  expand="lg">
            <Container>
              <Navbar.Brand className="text-white"><i className="fas fa-flask"/>{props.title}</Navbar.Brand>
            </Container>
          </ContentBoxNavBar>
        </ContentBoxHeader>
        <ContentBoxBody>
        <YoPublisherTable responsive variant={props.className ? props.className : cssClass.name} className={YoTableClassNames.MinWidth100}>
            <thead className="table-dark">
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>Comment</th>
              <th>Last Updated</th>
              <th>Last Author</th>
            </tr>
            </thead>
            <tbody>
            {props.experiments.map(experiment => (
              <tr key={experiment.id}>
                <td>{experiment.id}</td>
                <td>{experiment.name}</td>
                <td className="max-width">{experiment.description}</td>
                <td className="max-width">{experiment.comment}</td>
                <td>{formatISODateStringAsLocal(experiment.updatedAt)}</td>
                <td>{experiment.lastAuthor}</td>
              </tr>
            ))
            }
            </tbody>
        </YoPublisherTable>
        </ContentBoxBody>
      </ContentBox>
    )
})
