import React, {useContext}                          from 'react';
import { useNavigate }                               from 'react-router-dom';
import Button                                       from 'react-bootstrap/Button';
import {NotificationType, usePublishNotification}   from '@yakoffice/notification-handler';
import {ModalState, useShowModal, useShowModalAsync} from '@yakoffice/custom-modal';
import {CustomSpinner}                              from '@yakoffice/custom-spinner';
import { EntityStatus }                             from '@yakoffice/publisher-types';
import RootStoreContext                             from '../model/RootStoreContext';
import {IGameEnvironment}                           from '../model/gameEnvironment/GameEnvironment';
import {IEntityVersion}                             from '../model/entity/EntityVersion';
import { IGameVersion }                             from '../model/gameVersion/GameVersion';
import { IKindVersion }                             from '../model/kind/KindVersion';
import { useGetCategoriesLink, useGetEntitiesLink } from '../presentation/routes';
import { useHideModal, useShowModalSpinner }        from '@yakoffice/custom-modal';


export interface IEntitiesController {
    handleCopyEntities      : (entities: IEntityVersion[], targetGameVersions: IGameVersion[], targetGameEnvironments : IGameEnvironment[]) => void;
    handleDownloadAsJson    : (entityIds: number[]) => void;
    batchStatusUpdate       : (entities: IEntityVersion[], transitionStatus: EntityStatus, completedCallback: () => void) => void;
    batchUpdate             : (entities: IEntityVersion[], completedCallback: () => void) => void;
    handleGameVersionChange : (currentKindVersion: IKindVersion, desiredGameVersion : IGameVersion) => void;
    handleCreateSheet       : (statuses: string[]) => void;
    handleViewSheet         : () => void;
    handleUploadSheet       : (completedCallback: () => void) => void;
    handleRefreshSheet      : (statuses: string[]) => void;
    handleDeleteSheet       : () => void;
}


export const useEntitiesController = () => {

  // Context Hooks
  const showModalAsync        = useShowModalAsync();
  const showModal             = useShowModal();
  const showModalSpinner      = useShowModalSpinner();
  const hideModal             = useHideModal();
  const rootStore             = useContext(RootStoreContext);
  const publishNotification   = usePublishNotification()
  const navigate              = useNavigate();
  const getEntitiesLink       = useGetEntitiesLink();
  const getCategoriesLink     = useGetCategoriesLink();


  const batchUpdate = (entities: IEntityVersion[], completedCallback: () => void) => {

    showModal(
      {
        show: true,
        title: "Update Entities",
        body: `Are you sure you want to update these entities?`,
        action: <Button variant="primary" onClick={() => updateEntities(entities, completedCallback)}>OK</Button>,
        canClose: true
      }
    );
  }

  const updateEntities = async (entities: IEntityVersion[], completedCallback: () => void) => {
    let count = 1;
    for (const entity of entities) {
      try {
        showModal(
          {
            show: true,
            title: "Update Entities",
            body: <CustomSpinner spinnerText={`Updating ${count++} of ${entities.length} entities`}
                                 position={'relative'} />,
            canClose: false
          }
        );
        entity.updateKindVersionAndProperties(rootStore.kindVersionStore.getCurrentKindVersion());
        entity.updateSpecificationPropertiesToCurrentSpecificationKindVersion(rootStore.specificationsForKindsStore.getKinds());
        await rootStore.entityVersionStore.saveEntityVersion(entity, false);
      } catch (e:any) {
        publishNotification({
          notificationType: NotificationType.Error,
          title: `Error updating entity ${entity.name}`,
          message: e.message,
        })
      }
    }

    showModal(
      {
        show: true,
        title: "Update Entities",
        body: <div>All done!</div>,
        canClose: true
      }
    );
    completedCallback()
  }

  const saveCopiedEntities = async (entityVersions: IEntityVersion[], targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment, targetKindVersion: IKindVersion, andPublish: boolean) => {
    let count = 1;
    for (const entityVersion of entityVersions) {
      try {
        showModalSpinner(
          "Copying/Updating Entities",
          `Copying/Updating ${count++} of ${entityVersions.length} entities to ${targetGameEnvironment.name} (${targetGameVersion.name})`)
        entityVersion.setComment(`Copied by ${rootStore.authStore.currentAuthUser.displayName}`);
        await rootStore.entityVersionStore.saveEntityVersion(entityVersion, andPublish);
      } catch (e:any) {
        publishNotification({
          notificationType: NotificationType.Error,
          title: `Error ${entityVersion.isNewEntity() ? "creating" : "overwriting"} entity ${entityVersion.name} in ${targetGameEnvironment.name} (${targetGameVersion.name})`,
          message: e.message,
        })
      }
    }
  }

  const confirmAndSaveCopiedEntities = async (copiedEntities: IEntityVersion[], targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment, targetKindVersion: IKindVersion) => {
    const newEntities = copiedEntities.filter(e => e.isNewEntity());
    const updatedEntities = copiedEntities.filter(e => !e.isNewEntity())

    const result = await showModalAsync<boolean>(setResult => (
      {
        show: true,
        title: "Copy Entities",
        body: <div>
          {newEntities.length > 0 &&
          <div key={"createdEntitiesDivKey"} data-testid="divCreatedEntities">
            The following entities will be created in {targetGameEnvironment.name} ({targetGameVersion.name}):
            <ul>{newEntities.map(entity => {return <li key={entity.id}>{entity.name}</li>})}</ul>
          </div>
          }
          {updatedEntities.length > 0 &&
          <div key={"updatedEntitiesDivKey"} data-testid="divUpdatedEntities">
            The following entities already exist in {targetGameEnvironment.name} ({targetGameVersion.name}) and will be
            overwritten:
            <ul>{updatedEntities.map(entity => {return <li key={entity.id}>{entity.name}</li>})}</ul>
          </div>
          }
        </div>,
        action: <div><Button variant="success" className="me-2" onClick={() => setResult(false)} data-testid="btnModalConfirm">Copy</Button><Button variant="success" onClick={() => setResult( true)}>Copy and Publish</Button></div>,
        canClose: true
      })
    );

    if(result === ModalState.Closed)
      return;

    await saveCopiedEntities(copiedEntities, targetGameVersion, targetGameEnvironment, targetKindVersion, result)
  }

  const handleCopyToDifferentGameEnvironment = async (entities: IEntityVersion[], targetGameEnvironment: IGameEnvironment) => {
    showModalSpinner("Copy Entities", `Checking ${targetGameEnvironment.name} for existing entities`)

    const copiedEntities: IEntityVersion[] = await rootStore.entityVersionStore.copyEntitiesToGameEnvironment(entities, targetGameEnvironment)
    await confirmAndSaveCopiedEntities(copiedEntities, rootStore.gameVersionStore.getCurrentGameVersion(), targetGameEnvironment, rootStore.kindVersionStore.getCurrentKindVersion())
  }

  const handleCopyToDifferentGameVersionAndEnvironment = async (entities: IEntityVersion[], targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment) => {
    showModalSpinner("Copy Entities", `Checking ${targetGameEnvironment.name} (${targetGameVersion.name}) for existing entities`)

    const kindName = rootStore.kindVersionStore.getCurrentKindVersion().name;
    const targetKindVersion = (await rootStore.kindVersionStore.findAllCurrentKindVersions({gameVersionId: targetGameVersion.id, name: kindName})).find(() => true);
    if (targetKindVersion == null) {
      await showModalAsync(_ => ({
        show: true,
        title: "Error Copying Entity Versions",
        body: `Could not copy entity entities.  There is no current kind version with name (${kindName}) in game version (${targetGameVersion.name})`,
        canClose: true
      }))
      return;
    }

    const copiedEntities = await rootStore.entityVersionStore.copyEntitiesToGameVersionAndEnvironment(entities, rootStore.gameVersionStore.getCurrentGameVersion(), targetGameVersion, targetGameEnvironment, targetKindVersion);

    await confirmAndSaveCopiedEntities(copiedEntities, targetGameVersion, targetGameEnvironment, targetKindVersion)
  }

  const handleCopyEntities = async (entities: IEntityVersion[], targetGameVersions: IGameVersion[], targetGameEnvironments: IGameEnvironment[]) => {
    for (const targetGameVersion of targetGameVersions) {
      for (const targetGameEnvironment of targetGameEnvironments) {
        await copyEntities(entities, targetGameVersion, targetGameEnvironment);
      }
    }
    showModal(
      {
        show: true,
        title: "Copy Entities",
        body: <div>All done!</div>,
        canClose: true,
      }
    );
  }

  const copyEntities = async (entities: IEntityVersion[], targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment) => {
    try {
      const result = await showModalAsync<boolean>(setResult => (
        {
          show: true,
          title: "Copy Entities",
          body: `Are you sure you want to copy these entities to ${targetGameEnvironment.name} (${targetGameVersion.name})?`,
          action: <Button variant="success" className="me-2" onClick={() => {setResult(true)}} data-testid="btnModalSave">OK</Button>,
          canClose: true
        })
      );

      if(result === ModalState.Closed || !result)
        return;

      if(targetGameVersion.id === rootStore.gameVersionStore.getCurrentGameVersion().id && targetGameEnvironment.id !== rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id)
        await handleCopyToDifferentGameEnvironment(entities, targetGameEnvironment)
      else
        await handleCopyToDifferentGameVersionAndEnvironment(entities, targetGameVersion, targetGameEnvironment);

    } catch (e:any) {
      await showModalAsync(_ => (
        {
          show: true,
          title: "Error Checking Existing Entities",
          body: `Error message: ${e.message}`,
          canClose: true
        })
      )
    }
  }

  const batchStatusUpdate = (entities: IEntityVersion[], status: EntityStatus, completedCallback: () => void) => {
    showModal(
      {
        show: true,
        title: "Update entity status",
        body: `Are you sure you want to change these entities' status to ${status}?`,
        action: <Button variant="primary"
                        onClick={async () => updateCheckedEntityStatuses(entities, status, completedCallback)}>OK</Button>,
        canClose: true
      }
    );
  }

  const updateCheckedEntityStatuses = async (entities: IEntityVersion[], status: EntityStatus, completedCallback: () => void) => {
    let count = 1;
    for (const entity of entities) {
      try {
        showModal(
          {
            show: true,
            title: "Update entity status",
            body: <CustomSpinner spinnerText={`Updating ${count++} of ${entities.length} entities`}
                                 position={'relative'} />,
            canClose: false
          }
        );
        await rootStore.entityVersionStore.updateEntityStatus(entity, status);
      } catch (e:any) {
        publishNotification({
          notificationType: NotificationType.Error,
          title: `Error updating status for entity ${entity.name}`,
          message: e.message,
        })
      }
    }

    showModal(
      {
        show: true,
        title: "Update entity status",
        body: <div>All done!</div>,
        canClose: true
      }
    );
    completedCallback();
  }

  const handleGameVersionChange = async (currentKindVersion: IKindVersion, desiredGameVersion: IGameVersion) => {
    try {
      showModal(
        {
          show: true,
          title: "Searching Kinds",
          body: <CustomSpinner
            spinnerText={`Searching for kind with the same name in game version ${desiredGameVersion.name}...`}
            position={'relative'} />,
          canClose: false
        }
      );
      const kinds = await rootStore.kindVersionStore.findAllCurrentKindVersions({
        name: currentKindVersion.name,
        gameVersionId: desiredGameVersion.id
      })

      navigate(kinds && kinds.length > 0
        ? getEntitiesLink({
          gameVersionId: desiredGameVersion.id,
          categoryId: kinds[0].kind.categoryId,
          kindId: kinds[0].kind.id
        })
        : getCategoriesLink({ gameVersionId: desiredGameVersion.id }));

      hideModal();
    } catch (e:any) {
      showModal({
        show: true,
        title: "Error Searching Kinds",
        body: `Error message: ${e.message}`,
        canClose: true
      })
    }
  }

  const handleViewSheet = () => {
    const spreadSheetId = rootStore.kindVersionStore.getCurrentKindVersion().kind.googleSheetId;
    const sheetId = rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id;
    spreadSheetId != null && window.open(`https://docs.google.com/spreadsheets/d/${spreadSheetId}/edit#gid=${sheetId}`, "_blank");
  }

  const handleCreateSheet = (entityStatuses: string[]) => {
    const createGoogleSheet = async () => {
      try {
        showModalSpinner("Create Google Sheet", "Creating...")
        await rootStore.kindVersionStore.createSheet(rootStore.kindVersionStore.getCurrentKindVersion(), entityStatuses)
        showModal(
          {
            show: true,
            title: "Create Google Sheet",
            body: <div>Google sheet created.</div>,
            action: <Button variant="primary" onClick={handleViewSheet}>Open Sheet</Button>,
            canClose: true,
            closeButtonText: "Close"
          }
        );
      } catch (e:any) {
        showModal({
          show: true,
          title: "Error Creating Sheet",
          body: `Error message: ${e.message}`,
          canClose: true
        })
      }
    }

    showModal(
      {
        show: true,
        title: "Create Google Sheet",
        body: `Are you sure you want to create a google sheet?`,
        action: <Button variant="primary" onClick={async () => createGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleUploadSheet = (completedCallback: () => void) => {
    const createGoogleSheet = async () => {
      try {
        showModalSpinner("Upload Google Sheet", "Uploading...")
        const messages : string[]  = await rootStore.kindVersionStore.uploadSheet(rootStore.kindVersionStore.getCurrentKindVersion())

        showModal(
          {
            show: true,
            title: "Upload Google Sheet",
            body: <div>
              Upload completed:
              <ul>
                {messages.map((m, i) => <li key={i}>{m}</li>)}
              </ul>
            </div>,
            action: <Button variant="primary" onClick={handleViewSheet}>Open Sheet</Button>,
            canClose: true,
            closeButtonText: "Close"
          }
        );
        completedCallback();
      } catch (e:any) {
        showModal({
          show: true,
          title: "Error Uploading Sheet",
          body: `Error message: ${e.message}`,
          canClose: true
        })
      }
    }

    showModal(
      {
        show: true,
        title: "Upload Google Sheet",
        body: `Are you sure you want to upload the google sheet?`,
        action: <Button variant="primary" onClick={async () => createGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleRefreshSheet = (entityStatuses: string[]) => {
    const createGoogleSheet = async () => {
      try {
        showModalSpinner("Refresh Google Sheet", "Refreshing...")
        await rootStore.kindVersionStore.refreshSheet(rootStore.kindVersionStore.getCurrentKindVersion(), entityStatuses)
        showModal(
          {
            show: true,
            title: "Refresh Google Sheet",
            body: <div>Google sheet refreshed.</div>,
            action: <Button variant="primary" onClick={handleViewSheet}>Open Sheet</Button>,
            canClose: true,
            closeButtonText: "Close"
          }
        );
      } catch (e:any) {
        showModal({
          show: true,
          title: "Error Refreshing Sheet",
          body: `Error message: ${e.message}`,
          canClose: true
        })
      }
    }

    showModal(
      {
        show: true,
        title: "Regenerate Google Sheet",
        body: `Are you sure you want to refresh the google sheet?`,
        action: <Button variant="primary" onClick={async () => createGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleDeleteSheet = () => {
    const deleteGoogleSheet = async () => {
      try {
        showModalSpinner("Delete Google Sheet", "Uploading...")
        await rootStore.kindVersionStore.deleteSheet(rootStore.kindVersionStore.getCurrentKindVersion())
        showModal(
          {
            show: true,
            title: "Delete Google Sheet",
            body: <div>Google sheet deleted.</div>,
            canClose: true
          }
        );
      } catch (e:any) {
        showModal({
          show: true,
          title: "Error Deleting Sheet",
          body: `Error message: ${e.message}`,
          canClose: true
        })
      }
    }

    showModal(
      {
        show: true,
        title: "Delete Google Sheet",
        body: `Are you sure you want to delete the google sheet?  It will delete worksheets for all game environments `,
        action: <Button variant="primary" onClick={async () => deleteGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleDownloadAsJson = async (entityIds: number[]) => {

    const downloadJson = (json: any) => {
      const jsonString  = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
      const link        = document.createElement("a");
      link.href         = jsonString;
      const timeStamp   = Date.now();
      link.download     = `entitiesAsJson-${timeStamp}.json`;
      link.click();
    }

    const fetchEntitiesAsJson = async () => {
      try {
        showModalSpinner("Download entities as JSON", "Generating JSON")
        const json = await rootStore.entityVersionStore.downloadEntitiesAsJSON(entityIds);
        showModal(
          {
            show: true,
            title: "Download Entities as JSON",
            body: `JSON ready. Click the button below to download.`,
            action: <Button variant="success" className="me-2" onClick={() => downloadJson(json)} data-testid="btnModalSave"><i className="fas fa-file-download"/> Download</Button>,
            canClose: true
          });
      } catch (e:any) {
        showModal({
                    show: true,
                    title: "Error generating JSON file",
                    body: `Error message: ${e.message}`,
                    canClose: true
                  })
      }
    }

    showModal(
      {
        show: true,
        title: "Download Entities as JSON",
        body: `A JSON file will be generated with the selected entities and downloaded.`,
        action: <Button variant="success" className="me-2" onClick={fetchEntitiesAsJson} data-testid="btnModalSave">OK</Button>,
        canClose: true
      }
    );
  }



  const controller: IEntitiesController = {
    handleCopyEntities,
    batchStatusUpdate,
    batchUpdate,
    handleGameVersionChange,
    handleCreateSheet,
    handleViewSheet,
    handleUploadSheet,
    handleRefreshSheet,
    handleDeleteSheet,
    handleDownloadAsJson
  };

  return controller;
}
