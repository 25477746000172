import React,
{FC, ReactElement, useContext}  from "react";
import { observer }             from 'mobx-react-lite';
import {IKindProperty}          from "../../../model/kind/KindProperty";
import RootStoreContext         from "../../../model/RootStoreContext";
import {KindPropertyType}       from "@yakoffice/publisher-types";
import {StringInput}            from "../common/StringInput";
import {BooleanInput}           from "../common/BooleanInput";
import {IntegerInput}           from "../common/IntegerInput";
import {DecimalInput}           from "../common/DecimalInput";
import {HexadecimalInput}       from "../common/HexadecimalInput";
import {DateTimeInput}          from "../common/DateTimeInput";
import {SelectionInput}         from "../common/SelectionInput";


interface PropsType {
    kindProperty    : IKindProperty;
    getPropertyValue: () => string | null;
    setPropertyValue: (value : string | null) => void;
    variantName?: string;
}


export const EntityPropertyFactory : FC<PropsType> = observer((props: PropsType) => {

  const rootStore = useContext(RootStoreContext);

  const handleValueChange = (value: string | null) => {
    props.setPropertyValue(value)
  };

  const formatPropertyValue = (value: string | null) => value != null ? value : "";

  const propertyInputMap = new Map<string, () => ReactElement>();

  const variantName = () => props.variantName !== undefined ? props.variantName : "";

  propertyInputMap.set(KindPropertyType.Boolean, () => {
    return <BooleanInput id={props.kindProperty.id.toString()} handleOnChange={handleValueChange}
                         noSelectionText="Select an option..."
                         value={formatPropertyValue(props.getPropertyValue())}
                         data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
    />
  });

  propertyInputMap.set(KindPropertyType.String, () => {
    return <StringInput id={props.kindProperty.id.toString()} handleOnChange={handleValueChange}
                        value={formatPropertyValue(props.getPropertyValue())}
                        data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
    />
  });

  propertyInputMap.set(KindPropertyType.Integer, () => {
    return <IntegerInput id={props.kindProperty.id.toString()} handleOnChange={handleValueChange}
                         value={formatPropertyValue(props.getPropertyValue())}
                         data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
    />
  });

  propertyInputMap.set(KindPropertyType.Decimal, () => {
    return <DecimalInput id={props.kindProperty.id.toString()} handleOnChange={handleValueChange}
                         value={formatPropertyValue(props.getPropertyValue())}
                         data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
    />
  });

  propertyInputMap.set(KindPropertyType.Hexadecimal, () => {
    return <HexadecimalInput id={props.kindProperty.id.toString()}
                             handleOnChange={handleValueChange}
                             value={formatPropertyValue(props.getPropertyValue())}
                             data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
    />
  });

  propertyInputMap.set(KindPropertyType.DateTime, () => {
    return <DateTimeInput id={props.kindProperty.id.toString()} handleOnChange={handleValueChange}
                          value={formatPropertyValue(props.getPropertyValue())}
                          dataTestId={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
    />
  });

  propertyInputMap.set(KindPropertyType.Selection, () => {
    const selection = rootStore.selectionStore.selections.find(s => s.id === props.kindProperty.selectionId);
    return <SelectionInput id={props.kindProperty.id.toString()}
                           defaultText={"Select a type..."}
                           value={formatPropertyValue(props.getPropertyValue())} selection={selection}
                           data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
                           handleOnChange={handleValueChange}
    />
  });

  propertyInputMap.set(KindPropertyType.EntitySelection, () => {
    const selection = rootStore.entitySelectionStore.getSelections().find(s => s.id === props.kindProperty.entitySelectionKindId);
    return <SelectionInput id={props.kindProperty.id.toString()}
                           defaultText={"Select an entity..."}
                           value={formatPropertyValue(props.getPropertyValue())} selection={selection}
                           data-testid={`entityPropertyInput${props.kindProperty.key}${variantName()}`}
                           handleOnChange={handleValueChange}
    />
  });

  const elementFunction = propertyInputMap.get(props.kindProperty.type);

  return elementFunction ? elementFunction() : <span/>
});
