import React, {FC, useContext}  from 'react';
import {Link}       from 'react-router-dom';
import {observer}               from 'mobx-react-lite';
import RootStoreContext         from "../../../model/RootStoreContext";
import {ClaimType}              from "@yakoffice/publisher-types";
import {IKindVersion}                  from "../../../model/kind/KindVersion";
import {camelToTitle}           from '../../../utilities';
import Card from 'react-bootstrap/cjs/Card';
import { useGetEntitiesLink, useGetKindLink } from '../../routes';
import { YoCard } from '../../components/yo-card/YoCard';
import styles from './kindCard.module.sass'

interface PropsType {
    kindVersion       : IKindVersion;
}


export const KindCard : FC<PropsType> = observer((props) => {

    const rootStore = useContext(RootStoreContext);
    const getKindLink = useGetKindLink()
    const getEntitiesLink = useGetEntitiesLink()

    const kindEntitiesLink = () => {
        if(!props.kindVersion.isSpecificationForKinds)
            return getEntitiesLink({kindId: props.kindVersion.kind.id});
        else if(rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind))
            return getKindLink({kindId: props.kindVersion.kind.id});
        else
            return "#";
    };

    return (
      <YoCard className={`${props.kindVersion.kind.status} ${styles.kindCard}`} data-testid="yoCardKind">
        {props.kindVersion.kind.status === "archived" && <Card.Header className={`${props.kindVersion.kind.status}`}>{props.kindVersion.kind.status}</Card.Header>}

        {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind) &&
         <div className="card-settings d-block d-md-none">
           <Link
             to={getKindLink({kindId: props.kindVersion.kind.id})}>
             <i className="fas fa-cog"/>
           </Link>
         </div>
        }

        <Card.Body data-value={props.kindVersion.kind.id} onClick={() => {kindEntitiesLink()}}>
          <Link to={kindEntitiesLink()}>
            <Card.Title data-value={props.kindVersion.kind.id} onClick={() => {kindEntitiesLink()}}>{camelToTitle(props.kindVersion.name)}</Card.Title>
            <Card.Text data-value={props.kindVersion.kind.id} onClick={() => {kindEntitiesLink()}} className="mb-0">{props.kindVersion.description}</Card.Text>
          </Link>
        </Card.Body>
        {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind) &&
          <Card.Footer className="d-none d-md-block" data-testid="lnkKindCardSettings">
            <Link to={getKindLink({kindId: props.kindVersion.kind.id})}><i className="fas fa-cog"/> Settings</Link>
          </Card.Footer>
        }
      </YoCard>
    )
});

