import {observer} from "mobx-react-lite";
import React, {FC} from "react";
import Button, { ButtonProps } from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

export interface GoogleSheetsManagerDropDownProps extends ButtonProps {
  googleSheetExists: boolean
  handleCreateSheet: () => void
  handleViewSheet: () => void
  handleUploadSheet: () => void
  handleRefreshSheet: () => void
  handleDeleteSheet: () => void
}

const GoogleSheetsManagerDropDown : FC<GoogleSheetsManagerDropDownProps> = observer(({variant, style, ...props}) => {
    return (
      !props.googleSheetExists
        ?<Button onClick={() => props.handleCreateSheet()} variant={variant}>
          <i className="fas fa-table" /> Create Google Sheet
        </Button>
        :<DropdownButton id="googleSheetActions"
                         title={<React.Fragment><i className="fas fa-table" /><span> Google sheets</span></React.Fragment>}
                         style={style}
                          variant={variant}>
          <Dropdown.Item as="button" onClick={() => props.handleViewSheet()}>View Sheet</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => props.handleUploadSheet()}>Upload from Sheet</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => props.handleRefreshSheet()}>Refresh Sheet</Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => props.handleDeleteSheet()}>Delete Spreadsheet</Dropdown.Item>
        </DropdownButton>
    )
})

export default GoogleSheetsManagerDropDown;
