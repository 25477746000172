import React, {FC, useState } from 'react';
import {observer}             from "mobx-react-lite";
import { Dropdown} from 'react-bootstrap';
import Form                   from 'react-bootstrap/Form';
import { WithDelayedChange }  from '../common/WithDelayedChange';
import { useGetSearchResult } from '../../../loaders/useGetSearchResult';
import { SearchResult }       from './SearchResult';
import sass                   from './SearchBox.module.sass';

export const SearchBox : FC = observer(() => {

  const [showMenu, setShowMenu] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const searchResult = useGetSearchResult(searchValue);

  const handleSearchValueUpdate = (value: string) => {
    setSearchValue(value);
    setShowMenu(true);
  }

  function handleEscapingOrClickingOffMenu() {
    setShowMenu(false)
  }

  return (
    <div className={sass.searchBox}>
      <WithDelayedChange waitInterval={500} delayedOnChange={handleSearchValueUpdate}>{eventHandlers =>
          <Form.Control
            type="text"
            placeholder="Search..."
            onChange={eventHandlers.handleOnChange}
            className=""
        />}
      </WithDelayedChange>
      {searchResult.isLoaded &&
      <Dropdown className={sass.searchResults} onToggle={handleEscapingOrClickingOffMenu} show={showMenu}>
        <Dropdown.Menu className={sass.dropdownMenu} show={searchResult.isLoaded}>
          {searchResult.searchResults.length === 0
            ? <div key="notFound" className={sass.menuItem}>{`No results found for ${searchValue}...`}</div>
            : searchResult.searchResults.map(searchResult =>
              <div className={sass.menuItem} key={`${searchResult.categoryId}-${searchResult.kindId}-${searchResult.gameEnvironmentId}-${searchResult.entityId}`}>
                <SearchResult searchResult={searchResult} onClick={handleEscapingOrClickingOffMenu} />
              </div>
            )}
        </Dropdown.Menu>
      </Dropdown>
      }
    </div>
  )
})
