import React, {useContext, useRef, useState} from 'react';
import Button from "react-bootstrap/Button";
import RootStoreContext from "../model/RootStoreContext";
import {IKindVersion} from "../model/kind/KindVersion";
import {CustomSpinner} from '@yakoffice/custom-spinner';
import CustomCommentForm from "../presentation/components/custom-comment-form/customCommentForm";
import {FormattedErrorMessage} from "../presentation/views/common/Utils";
import {IKindProperty} from "../model/kind/KindProperty";
import {useShowModal} from '@yakoffice/custom-modal';
import {useNavigate} from "react-router-dom";
import {ICategory} from "../model/category/Category";
import {useGetCategoriesLink, useGetKindLink, useGetKindsLink} from '../presentation/routes';
import {IGameVersion} from '../model/gameVersion/GameVersion';
import {useHideModal} from '@yakoffice/custom-modal';
import {useParams} from "react-router-dom";
import Form from 'react-bootstrap/Form';


export interface IKindController {
  kind: IKindVersion;
  handleMoveKind: (category: ICategory, onSuccessCallback: () => void, onFailureCallback: () => void) => void;
  handleArchiveKind: (onSuccessCallback: () => void) => void;
  handleUnarchiveKind: (onSuccessCallback: () => void) => void;
  handleSaveKind: (onSuccessCallback: () => void) => void;
  handleCopyKind: (onSuccessCallback: () => void) => void;
  handleDeleteKind: (onSuccessCallback: () => void) => void;
  handleUpdateDescription: (description: string) => void
  handleUpdateHexColour: (hexColour: string) => void
  handleUpdatePropertyDescription: (property: IKindProperty, description: string) => void
  handleGameVersionChange: (currentKindVersion: IKindVersion, desiredGameVersion: IGameVersion) => void
}


export const useKindController = (kindVersion: IKindVersion) => {

  // Context Hooks
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const currentComment = useRef(comment);
  const getKindsLink = useGetKindsLink()
  const getKindLink = useGetKindLink();
  const getCategoriesLink = useGetCategoriesLink()
  const {categoryId} = useParams() as { categoryId: string };

  // Handlers
  const handleMoveKind = (category: ICategory, onSuccessCallback: () => void, onFailureCallback: () => void) => {
    const moveKind = () => {
      showModal(
        {
          show:     true,
          title:    "Move Kind",
          body:     <CustomSpinner spinnerText={`Moving kind`} position={'relative'}/>,
          canClose: false
        }
      );

      kindVersion.setComment(`Moved by ${rootStore.authStore.currentAuthUser.displayName}`);

      rootStore.kindVersionStore
        .moveKind(kindVersion, category)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          kindVersion.setComment("");
          onFailureCallback();
          showModal({
                      show:     true,
                      title:    "Error Moving Kind",
                      body:     `Could not move kind. Error message: ${e.message}`,
                      canClose: true
                    })
        })
    };

    showModal(
      {
        show:     true,
        title:    "Move Kind",
        body:     "You are about to move this kindVersion. Are you sure you want to do this?",
        action:   <Button variant="success" onClick={() => moveKind()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleArchiveKind = (onSuccessCallback: () => void) => {
    const archiveKind = () => {
      showModal(
        {
          show:     true,
          title:    "Archive Kind",
          body:     <CustomSpinner spinnerText={`Archiving kind`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.kindVersionStore
        .archiveKind(kindVersion)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    "Error Changing Kind Status",
                      body:     `Could not archive entity. Error message: ${e.message}`,
                      canClose: true
                    })
        })
    };

    showModal(
      {
        show:     true,
        title:    "Archive Kind",
        body:     "You are about to archive this kindVersion. Are you sure you want to do this?",
        action:   <Button variant="success" onClick={() => archiveKind()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleUnarchiveKind = (onSuccessCallback: () => void) => {
    const unarchiveKind = () => {

      showModal(
        {
          show:     true,
          title:    "Unarchive Kind",
          body:     <CustomSpinner spinnerText={`Unarchiving Kind`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.kindVersionStore
        .unArchiveKind(kindVersion)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    "Error Changing Kind Status",
                      body:     `Could not unarchive entity. Error message: ${e.message}`,
                      canClose: true
                    })
        })
    };

    showModal(
      {
        show:     true,
        title:    "Unarchive Kind",
        body:     "You are about to unarchive this entity. Are you sure you want to do this?",
        action:   <Button variant="success" onClick={() => unarchiveKind()}>OK</Button>,
        canClose: true
      }
    );
  }

  const handleSaveKind = (onSuccessCallback: () => void) => {
    const updateComment = (comment: string) => {
      setComment(comment)
      currentComment.current = comment;
    };

    const _saveKind = () => {
      showModal(
        {
          show:     true,
          title:    "Saving Kind",
          body:     <CustomSpinner spinnerText={`Saving kind...`} position={'relative'}/>,
          canClose: false
        }
      );
      kindVersion.setComment(currentComment.current);

      rootStore.kindVersionStore
        .saveKindVersion(kindVersion)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    "Error saving/updating kindVersion",
                      body:     <FormattedErrorMessage errorMessage={e.message}/>,
                      canClose: true
                    })
        })

    };

    showModal(
      {
        show:     true,
        title:    "Save Kind",
        body:     <CustomCommentForm defaultValue={comment} handler={updateComment}/>,
        action:   <Button variant="success" onClick={() => _saveKind()} data-testid="btnModalConfirmSave">OK</Button>,
        canClose: true
      }
    );
  }
  const handleCopyKind = (onSuccessCallback: () => void) => {
    const updateName = (name: string) => {
      kindVersion.setName(name);
    };

    const updateComment = (comment: string) => {
      kindVersion.setComment(comment);
    };

    const newNameDefaultValue = `Copy of ${kindVersion.name}`;
    const newCommentDefaultValue = `Copied from ${kindVersion.name}`;

    kindVersion.resetCurrentVersion();
    kindVersion.setName(newNameDefaultValue);
    kindVersion.setComment(newCommentDefaultValue);

    const _copyKind = () => {

      showModal(
        {
          show:     true,
          title:    "Copy Kind",
          body:     <CustomSpinner spinnerText={`Copying kind...`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.kindVersionStore
        .saveKindVersion(kindVersion)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    "Error copying kind",
                      body:     <FormattedErrorMessage errorMessage={e.message}/>,
                      canClose: true
                    })
        })
    };

    showModal(
      {
        show:     true,
        title:    "Copy Kind",
        body: <Form.Group controlId="formGroupCustomComment" className="mb-3">
                <Form.Label>Enter a name for the new kind</Form.Label>
                  <Form.Control type="text"
                    placeholder=""
                    defaultValue={newNameDefaultValue}
                    onChange={(e) => updateName(e.target.value)}
                    maxLength={255}
                    data-testid="txtCustomComment"
                  />
                  <Form.Label className="mt-3">Please enter a comment</Form.Label>
                  <Form.Control type="text"
                              placeholder=""
                              defaultValue={newCommentDefaultValue}
                              onChange={(e) => updateComment(e.target.value)}
                              maxLength={255}
                              data-testid="txtCustomComment"
                  />
            </Form.Group>,
        action:   <Button variant="success" onClick={() => _copyKind()} data-testid="btnModalConfirmSave">OK</Button>,
        canClose: true
      }
    );
  }

  const handleUpdateDescription = (description: string) => {
    kindVersion.setDescription(description)
      .then(async () => {
        // do nothing
      })
      .catch(e => {
        showModal({
                    show:     true,
                    title:    "Error updating kindVersion description",
                    body:     <FormattedErrorMessage errorMessage={e.message}/>,
                    canClose: true
                  })
      })
  };

  const handleUpdateHexColour = (hexColour: string) => {
    kindVersion.setHexColour(hexColour)
      .then(async () => {
        // do nothing
      })
      .catch(e => {
        showModal({
                    show:     true,
                    title:    "Error updating kind hexColour",
                    body:     <FormattedErrorMessage errorMessage={e.message}/>,
                    canClose: true
                  })
      })
  };

  const handleUpdatePropertyDescription = (property: IKindProperty, description: string) => {
    property.setDescription(description)
      .then(async () => {
        // do nothing
      })
      .catch(e => {
        showModal({
                    show:     true,
                    title:    "Error updating kindVersion property description",
                    body:     <FormattedErrorMessage errorMessage={e.message}/>,
                    canClose: true
                  })
      })
  };

  const handleDeleteKind = (onSuccessCallback: () => void) => {
    const deleteKind = () => {

      if (!kindVersion)
        return;

      showModal(
        {
          show:     true,
          title:    "Delete Kind",
          body:     <CustomSpinner spinnerText={`Deleting kind`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.kindVersionStore.deleteKind(kindVersion)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    "Error deleting kindVersion",
                      body:     `Error message: ${e.message}`,
                      canClose: true
                    })
        })
    };

    showModal(
      {
        show:     true,
        title:    "Delete Kind",
        body:     "You are about to DELETE this kind. This is a permanent action! Are you sure you want to delete this kindVersion?",
        action:   <Button variant="success" onClick={() => deleteKind()}>OK</Button>,
        canClose: true
      }
    );
  };

  const hideSpinnerAndRedirect = async () => {

    await rootStore.categoryStore.getCategory(parseInt(categoryId));

    showModal({show: false, title: "", body: "", canClose: false});
    navigate(getKindsLink())

  };

  const handleGameVersionChange = async (currentKindVersion: IKindVersion, desiredGameVersion: IGameVersion) => {
    try {
      showModal(
        {
          show:     true,
          title:    "Searching Kinds",
          body:     <CustomSpinner spinnerText={`Searching for kind with the same name in game version ${desiredGameVersion.name}...`} position={'relative'}/>,
          canClose: false
        }
      );
      const kinds = await rootStore.kindVersionStore.findAllCurrentKindVersions({name: currentKindVersion.name, gameVersionId: desiredGameVersion.id})

      navigate(kinds && kinds.length > 0
               ? getKindLink({gameVersionId: desiredGameVersion.id, categoryId: kinds[0].kind.categoryId, kindId: kinds[0].kind.id})
               : getCategoriesLink({gameVersionId: desiredGameVersion.id}));

      hideModal();
    }
    catch (e: any) {
      showModal({
                  show:     true,
                  title:    "Error Searching Kinds",
                  body:     `Error message: ${e.message}`,
                  canClose: true
                })
    }
  }

  const controller: IKindController = {
    kind: kindVersion,
    handleMoveKind,
    handleArchiveKind,
    handleUnarchiveKind,
    handleSaveKind,
    handleCopyKind,
    handleDeleteKind,
    handleUpdateDescription,
    handleUpdateHexColour,
    handleUpdatePropertyDescription,
    handleGameVersionChange
  };

  return controller;
}
