import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import type {IKindVersion} from "../../../model/kind/KindVersion";
import FieldLayout from "../../components/inputs/fieldLayout";
import type {IEntityProperty} from "../../../model/entity/EntityProperty";
import {ReadonlyEntityPropertyFactory} from "./ReadonlyEntityPropertyFactory";
import Form from "react-bootstrap/Form";
import {formatISODateString} from "../common/Utils";
import { FeatureHeader } from '@yakoffice/shared-components';


interface PropsType {
    specificationProperties : IEntityProperty[];
    specificationKinds : IKindVersion[];
}

export const ReadonlyEntitySpecificationProperties : FC<PropsType> = observer((props) => {

  const findSpecificationProperty = (specificationKindId: number, specificationPropertyKey: string) => {
    const specificationKind = props.specificationKinds.find(k => k.kind.id === specificationKindId);
    if (specificationKind)
      return specificationKind.properties.find(kp => kp.key === specificationPropertyKey);

    return undefined;
  };

  return (
    <React.Fragment>
      <FeatureHeader size="sm" className="mb-2"><i className="fas fa-building"/> Specifications</FeatureHeader>
      {props.specificationProperties.map(ep => {
          const specificationKindProperty = findSpecificationProperty(ep.kindPropertyKindId, ep.kindPropertyKey);
          if (specificationKindProperty)
            return <div key={ep.kindPropertyId}>
              <FieldLayout label={specificationKindProperty.key}
                           labelForId={specificationKindProperty.id.toString()}
                           description={specificationKindProperty.description}
                           required={specificationKindProperty.isRequired()}>
                <ReadonlyEntityPropertyFactory kindProperty={specificationKindProperty}
                                               getPropertyValue={() => ep.value}/>
              </FieldLayout>
            </div>;
          else
            return <div key={ep.kindPropertyId}>
              <FieldLayout label={ep.kindPropertyKey}
                           labelForId={ep.kindPropertyId.toString()}
                           description={""}
                           required={false}>
                <Form.Control type="text" id={ep.kindPropertyId.toString()}
                              value={formatISODateString(ep.value) || ep.value || ""} disabled={true}/>
              </FieldLayout>
            </div>
        }
      )}
    </React.Fragment>
  );
});
