import {Instance, types}        from "mobx-state-tree";
import {GenerateId}             from "../Utils";


export const SelectionOption = types.model(
    "SelectionOption",
    {
        id              : types.optional(types.identifierNumber, GenerateId),
        name            : "",
        value           : "",
        createdAt       : "",
        author          : ""
    })
    .actions( self => ({
            setName(name: string){
                    self.name = name;
            },
            setValue(value: string) {
                    self.value = value;
            },
    }));


export interface ISelectionOption extends Instance<typeof SelectionOption> {}