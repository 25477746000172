import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {useSpecificationsForExperiments} from "../../../loaders/useSpecificationsForExperiments";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {Experiments} from "./Experiments";
import {useSetGameEnvironmentFromParam} from '../common/useSetGameEnvironmentFromParam';
import {useEntitySelections} from "../../../loaders/useEntitySelections";

export const ExperimentsContainer : FC = observer(() => {

  useSetGameEnvironmentFromParam();
  const specificationsForExperimentsResult = useSpecificationsForExperiments();
  const entitySelectionResult = useEntitySelections()

  return specificationsForExperimentsResult.isLoaded && entitySelectionResult.isLoaded
    ? <Experiments/>
    : <CustomSpinner spinnerText={'Loading experiment specifications...'}/>
})
