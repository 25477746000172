import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { ExperimentStatus } from '@yakoffice/publisher-types';
import { IExperiment } from '../../../model/experiment/Experiment';
import Navbar from 'react-bootstrap/Navbar';
import { CopyToGameVersionAndEnvironmentButtonGroup } from '../common/CopyToGameVersionAndEnvironmentButtonGroup';
import { useEditableGameEnvironments } from '../common/useEditableGameEnvironments';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';
import { IVariant } from '../../../model/experiment/Variant';
import GoogleSheetsManagerDropDown from '../../components/googlesheets-manager-dropdown/googlesheetsManagerDropDown';


interface PropsType {
  experiment: IExperiment;
  experimentEdited: boolean;
  handleSaveExperiment: (andPublish?: boolean, andCreateGoogleSheet?: boolean) => void;
  handleStopExperiment: () => void;
  handlePublishExperiment: () => void;
  handleApplyExperiment: (variant: IVariant) => void;
  handleArchiveExperiment: () => void;
  handleUnarchiveExperiment: () => void;
  handleDeleteExperiment: () => void;
  handleCopyExperiment: (targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment) => void;
  handleCreateSheet: () => void;
  handleViewSheet: () => void;
  handleDeleteSheet: () => void;
  handleRefreshSheet: () => void;
  handleUploadSheet: () => void;
}

const statusToggleClasses: any = {
  published: 'text-success',
  draft:     'text-info',
  stopped:   'text-danger',
  archived:  'text-warning'
};


export const ExperimentButtons: FC<PropsType> = observer((props) => {

  const editableGameEnvironments = useEditableGameEnvironments();

  if (!props.experiment.isNewExperiment() && props.experiment.requiresUpdating)
    return (
      <React.Fragment>
        <Navbar.Text>
          <span className='text-warning'><i className='fas fa-exclamation-triangle' /> Outdated specification properties</span>
        </Navbar.Text>
        <Button variant='success' className='pulse' onClick={() => props.handleSaveExperiment()}>
          <i className='fas fa-save' /> Update</Button>
      </React.Fragment>
    );

  if ((props.experiment.isNewExperiment() || props.experimentEdited) && !props.experiment.inAnyDistribution)
    return (
      <React.Fragment>
        <Button variant='success' className='pulse'
                onClick={() => props.handleSaveExperiment()} data-testid='btnSaveExperiment'>
          <i className='fas fa-save' /> Save
        </Button>
        <Button variant='success' className='pulse' onClick={() => props.handleSaveExperiment(false, true)} data-testid='btnSaveExperimentAndCreateSheet'>
          <i className='fas fa-save' /> Save <strong>and {props.experiment.isNewExperiment() ? 'Create' : 'Update'} Sheet</strong>
        </Button>
        <Button variant='success' className='pulse' onClick={() => props.handleSaveExperiment(true)} data-testid='btnSaveAndPublishExperiment'>
          <i className='fas fa-save' />Save <strong>and Publish</strong>
        </Button>
      </React.Fragment>
    );

  const applyOptions = props.experiment.variants.map(v => <Dropdown.Item key={'apply' + v.id} onClick={() => props.handleApplyExperiment(v)}>Apply "{v.name}"</Dropdown.Item>);
  const publishOption = <Dropdown.Item key='publish' onClick={() => props.handlePublishExperiment()}>Publish</Dropdown.Item>;
  const stopOption = <Dropdown.Item key='stop' onClick={() => props.handleStopExperiment()}>Stop</Dropdown.Item>;
  const archiveOption = <Dropdown.Item key='archive' onClick={() => props.handleArchiveExperiment()}>Archive</Dropdown.Item>;
  const unarchiveOption = <Dropdown.Item key='unarchive' onClick={() => props.handleUnarchiveExperiment()}>Unarchive</Dropdown.Item>;
  const statusOptions = new Map([
                                  [ExperimentStatus.Draft, [publishOption, archiveOption]],
                                  [ExperimentStatus.Published, [stopOption, archiveOption]],
                                  [ExperimentStatus.Applied, [archiveOption]],
                                  [ExperimentStatus.Stopped, [publishOption, archiveOption]],
                                  [ExperimentStatus.Archived, [publishOption, unarchiveOption]]
                                ]);

  return (
    <React.Fragment>
      <React.Fragment>
        {!props.experiment.isNewExperiment() &&
         <CopyToGameVersionAndEnvironmentButtonGroup variant='light'
                                                     gameEnvironments={editableGameEnvironments}
                                                     handleCopy={(gv, ge) => props.handleCopyExperiment(gv, ge)} />
        }

        <GoogleSheetsManagerDropDown
          variant='light'
          googleSheetExists={!!props.experiment.googleSheetId}
          handleCreateSheet={props.handleCreateSheet}
          handleViewSheet={props.handleViewSheet}
          handleRefreshSheet={props.handleRefreshSheet}
          handleUploadSheet={props.handleUploadSheet}
          handleDeleteSheet={props.handleDeleteSheet}
        />

        <DropdownButton id='statusChange' variant='light' title={
          <span className='text-capitalize'><i className={`fas fa-eye ${statusToggleClasses[props.experiment.status]}`} /> {props.experiment.status}</span>}>
          {props.experiment.status === ExperimentStatus.Published && props.experiment.inAnyDistribution && applyOptions}
          {props.experiment.status && statusOptions.get(props.experiment.status as ExperimentStatus)?.map(o => o)}
        </DropdownButton>
      </React.Fragment>

      {!props.experiment.isNewExperiment() && !props.experiment.inAnyDistribution &&
       <Button variant='danger' onClick={() => props.handleDeleteExperiment()}>
         <i className='fas fa-trash-alt' /> Delete
       </Button>
      }
    </React.Fragment>
  );
});
