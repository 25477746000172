import { useContext }   from 'react';
import RootStoreContext from "../model/RootStoreContext";
import { useParams }    from 'react-router-dom';


export const useSubCategories = () => {

  const rootStore     = useContext(RootStoreContext);
  const {categoryId}  = useParams<{categoryId: string}>()

  return rootStore.categoryStore.categories.filter(category => category.parent?.id === parseInt(categoryId));
};
