import { useContext, useEffect, useState } from 'react';
import type {IAuthUser} from "../model/auth/AuthUser";
import axios, {AxiosResponse} from "axios";
import {pub_api} from "../api";
import type {IResponse} from "../api/requests/IResponse";
import type {IClaimVm} from "@yakoffice/publisher-types";
import RootStoreContext from "../model/RootStoreContext";
import {useAuthUser} from "@yakoffice/yakoffice-firebase";

interface WithAuthorisedUserResult{
    hasAuthorised : boolean;
    authorisedUser : IAuthUser | null
}


// Publisher has more granular claims than the other apps.  We retrieve them from the API so that the code
// for generating them from the Yakoffice Firestore roles is only in one place.
export const usePublisherAuthorisedUser = () => {

    const authUser = useAuthUser();
    const [result, setResult] = useState<WithAuthorisedUserResult>({hasAuthorised : false, authorisedUser: null })
    const rootStore = useContext(RootStoreContext)

    useEffect(() => {
      setResult({hasAuthorised: false, authorisedUser: null})
      if (!authUser?.firebaseUser)
        return;

      const email = authUser.firebaseUser.email;
      const displayName = authUser.firebaseUser.displayName;
      const photoURL = authUser.firebaseUser.photoURL;

      authUser.firebaseUser.getIdToken().then(token => {
        axios
          .get<IResponse<IClaimVm[]>>(`${pub_api}/claims`, {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + token
            }
          })
          .then((res: AxiosResponse<IResponse<IClaimVm[]>>) => {
            if (res.status === 200 && email != null) {
              rootStore.authStore.setCurrentAuthUser(email, displayName, photoURL, res.data.data);
              setResult({hasAuthorised: true, authorisedUser: rootStore.authStore.currentAuthUser})
            } else {
              console.log(`Authorisation failed.  Status code: ${res.status}, Status text: ${res.statusText}`);
              setResult({hasAuthorised: true, authorisedUser: null})
            }
          })
          .catch((error: any) => {
            console.error(error);
            setResult({hasAuthorised: true, authorisedUser: null})
          });
      })
    }, [rootStore.authStore,authUser?.firebaseUser]);

    return result;
}
