import React, {FC} from "react";
import { observer } from "mobx-react-lite";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {ExperimentStatus} from "@yakoffice/publisher-types";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";

interface PropsType {
    variant : string,
    statuses : ExperimentStatus[],
    handleStatusToggle: (status : ExperimentStatus) => void
}

export const ExperimentStatusToggleDropDownButton : FC<PropsType> = observer((props) => {

    return (
        <DropdownButton variant={props.variant}
                        as={ButtonGroup}
                        align="end"
                        id="bg-nested-dropdown"
                        title={"Status"}
                        bsPrefix={`fw-bold btn btn-${props.variant}`}>
            <div className={"ms-3"}>
                {Object.values(ExperimentStatus).map(status => {
                    return <Form.Check key={`toggle-${status}`} type="checkbox"
                                       className="text-capitalize fw-normal" label={status}
                                       checked={props.statuses.includes(status)}
                                       onChange={() => props.handleStatusToggle(status)}/>
                })}
            </div>
        </DropdownButton>
    )
})
