import {useEffect, useState} from "react";

interface PropTypes<T>{
    items: T[],
    selectItem: (item: T, selected: boolean) => void
}

export const useMultiSelect = <T,>({items, selectItem} : PropTypes<T>) => {

    const [isShiftDown, setIsShiftDown] = useState(false);
    const [lastSelectedItem, setLastSelectedItem] = useState<T | null>(null)

    useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === "Shift" && isShiftDown)
                setIsShiftDown(false);
        }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.shiftKey && !isShiftDown)
                setIsShiftDown(true);
        }

        document.addEventListener("keyup", handleKeyUp,  false);
        document.addEventListener("keydown", handleKeyDown, false);

        return () => {
            document.removeEventListener("keyup", handleKeyUp);
            document.removeEventListener("keydown", handleKeyDown);
        }
    })

    const handleItemSelection = (item: T, selected: boolean) => {
        if(lastSelectedItem != null && isShiftDown){
            const lastSelectedIndex = items.indexOf(lastSelectedItem);
            const currentSelectedIndex = items.indexOf(item);
            items.slice(
                Math.min(lastSelectedIndex, currentSelectedIndex),
                Math.max(lastSelectedIndex, currentSelectedIndex) + 1)
                .forEach(i => selectItem(i, selected))
        }else {
            selectItem(item, selected)
        }

        setLastSelectedItem(item);
    }

    const handleSelectAll = (selected: boolean) => {
        items.forEach(i => selectItem(i, selected))
    }

    return {
        handleItemSelection,
        handleSelectAll
    }
}