import {useContext, useEffect, useState} from "react";
import RootStoreContext                         from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {ISelection} from "../model/selections/Selection";
import { useParams } from 'react-router-dom';

interface useEntitySelectionsResult{
    isLoaded : boolean
    selections : ISelection[]
}

export const  useEntitySelections = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const { gameEnv } = useParams<{ gameEnv: string }>();

  const [result, setResult] = useState<useEntitySelectionsResult>(() => ({ isLoaded: false, selections: [] }));

  // Note:  This should be dependent on rootStore.currentGameEnvironment but this introduces issues due to useEffects
  // running bottom to top.  What happens is that useEffects further down the chain will end up running twice when
  // rootoStore.currentGameEnvironment changes (i.e once before they are removed from the tree and once when they are added back in the tree)
  // Making this dependent on the param means that it will run before those further down the tree,   that are dependent on
  // rootStore.currentGameEnvironment, which will remove them from the tree before their useEffects run. See Entities.tsx
  useEffect(() => {
    setResult({ isLoaded: false, selections: [] })
    rootStore.entitySelectionStore.loadSelections()
      .then(() => {
        setResult({ isLoaded: true, selections: rootStore.entitySelectionStore.getSelections() })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error finding entity selections",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.entitySelectionStore, publishNotification, rootStore.gameVersionStore.currentGameVersion, gameEnv]);

  return result;
};
