import {Instance, types} from "mobx-state-tree";
import { ValidationReferenceType } from '@yakoffice/publisher-types';
import { ValidationValueType } from '@yakoffice/publisher-types';

export const ValidationRequirementType = types.model(
  "ValidationRequirementType",
  {
    referenceType: types.enumeration<ValidationReferenceType>("name", Object.values(ValidationReferenceType)),
    validationValueType: types.enumeration<ValidationValueType>("name", Object.values(ValidationValueType)),
  })

export interface IValidationRequirementType extends Instance<typeof ValidationRequirementType> {}
