import React, {FC} from "react";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    value : string;
    step?: string;
    handleOnChange: (e: string) => void;
    disabled? : boolean
}

export const DecimalInput : FC<PropsType> = ({ id, value, step, handleOnChange, disabled, ...props}) => {
  return <Form.Control type="number"
                       id={id}
                       placeholder="Enter Decimal"
                       value={value}
                       onChange={e => handleOnChange(e.target.value)}
                       step={step || "any"}
                       disabled={disabled || false}
                       {...props} />
};
