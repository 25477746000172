import React, { ChangeEvent, FC, ReactElement } from 'react';
import {observer} from 'mobx-react-lite';
import Row                              from 'react-bootstrap/Row';
import Col                              from 'react-bootstrap/Col';
import Form                             from 'react-bootstrap/Form';
import Button                           from 'react-bootstrap/Button';
import {IKindProperty}                  from "../../../model/kind/KindProperty";
import {IValidationRule}                from "../../../model/kind/ValidationRule";
import {ISelection}                     from "../../../model/selections/Selection";
import {KindPropertyType}               from '@yakoffice/publisher-types';
import {KindPropertyAddValidationRule}    from "./kindPropertyAddValidationRule";
import {StringInput}                    from "../common/StringInput";
import {IntegerInput}                   from "../common/IntegerInput";
import {DecimalInput}                   from "../common/DecimalInput";
import {BooleanInput}                   from "../common/BooleanInput";
import {HexadecimalInput}               from "../common/HexadecimalInput";
import { DateTimeInput }                from "../common/DateTimeInput";
import {KindPropertySelectionInput}     from "./kindPropertySelectionInput"
import {WithDelayedChange} from "../common/WithDelayedChange";
import {PropertyUpdatingIconDisplay} from "../common/PropertyUpdatingIconDisplay";
import {KindPropertyEntitySelectionInput} from "./KindPropertyEntitySelectionInput";
import {IKindVersion} from "../../../model/kind/KindVersion";
import { RowDivider } from '../../components/dividers/RowDivider';
import { YoFormBox, YoFormBoxBody } from '../../components/yo-form-box/YoFormBox';

interface IPropsType {
    deleteProperty  : (property: IKindProperty) => void;
    property        : IKindProperty;
    selections      : ISelection[];
    entitySelectionKinds      : IKindVersion[];
    handleUpdatePropertyDescription: (property: IKindProperty, description: string) => void
}

interface IInputTypeMethods {
    [key: string]   : any;
}

export const KindAddProperty : FC<IPropsType> = observer((props) => {

  const handleKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.property.setKey(e.target.value);
  }

  const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const propertyType = (KindPropertyType as any)[e.target.value];
    props.property.setType(propertyType);
  }

  const handleDefaultChange = (value: string | null) => {
    props.property.setDefault(value);
  }

  const getOtherProperties = () => {
    return props.property.getOtherProperties()
  }

  const addValidationRule = () => {
    props.property.addValidationRule();
  }

  const handleDeleteValidationRule = (vr: IValidationRule) => {
    props.property.deleteValidationRule(vr)
  }

  const getDefaultValue = () => {
    return props.property.default ? props.property.default : "";
  }

  const defaultProperty = (formControl: ReactElement) =>
    <Form.Group>
      <Form.Label>Default property value</Form.Label>
      {formControl}
    </Form.Group>


  const renderTypeInput = () => {

    const inputProps = {
      id: `prop${props.property.id}-default`,
      value: getDefaultValue(),
      handleOnChange: handleDefaultChange
    };

    const inputTypeMethods = {} as IInputTypeMethods;
    inputTypeMethods[KindPropertyType.Boolean] = defaultProperty(<BooleanInput {...inputProps} noSelectionText="Select default value..." data-testid="inputDefaultValue" />);
    inputTypeMethods[KindPropertyType.Integer] = defaultProperty(<IntegerInput {...inputProps} data-testid="inputDefaultValue"/>);
    inputTypeMethods[KindPropertyType.Decimal] = defaultProperty(<DecimalInput {...inputProps} data-testid="inputDefaultValue"/>);
    inputTypeMethods[KindPropertyType.Hexadecimal] = defaultProperty(<HexadecimalInput {...inputProps} data-testid="inputDefaultValue"/>);
    inputTypeMethods[KindPropertyType.DateTime] = defaultProperty(<DateTimeInput {...inputProps} dataTestId="inputDefaultValue"/>);
    inputTypeMethods[KindPropertyType.String] = defaultProperty(<StringInput {...inputProps} data-testid="inputDefaultValue"/>);
    inputTypeMethods[KindPropertyType.Selection] = <KindPropertySelectionInput selections={props.selections} property={props.property} />;
    inputTypeMethods[KindPropertyType.EntitySelection] = <KindPropertyEntitySelectionInput kindProperty={props.property} entitySelectionKindVersions={props.entitySelectionKinds} />;

    if (props.property.type)
      return inputTypeMethods[props.property.type];
    else
      return null;
  }


  return (
    <YoFormBox>
      <YoFormBoxBody>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Property Key</Form.Label>
              <Form.Control type="text" id={`propertyKey-${props.property.id}`} defaultValue={props.property.key}
                            onChange={handleKeyChange}
                            data-testid="txtPropertyKey"/>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property type</Form.Label>
              <Form.Select id={`propertyType-${props.property.id}`} onChange={handleTypeChange}
                            defaultValue={props.property.type}
                            data-testid="ddlPropertyType">
                <option value="default" disabled hidden>Select a type...</option>
                {Object.values(KindPropertyType).map(type =>
                  <option key={type}>{type}</option>
                )}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <div>
                <PropertyUpdatingIconDisplay descriptionUpdating={props.property.descriptionStatus.updating}
                                             descriptionUpdated={props.property.descriptionStatus.updated}
                                             updatingTootip={"Updating property description..."}
                                             updatedTooltip={<>Property description updated<br />(version not
                                               changed)</>} />
                <Form.Label>Property Description</Form.Label>
              </div>
              <WithDelayedChange waitInterval={props.property.isNew ? 0 : 1000}
                                 delayedOnChange={value => props.handleUpdatePropertyDescription(props.property, value)}>{eventHandlers =>
                <Form.Control type="text" id={`propertyDescription-${props.property.id}`}
                              defaultValue={props.property.description}
                              onChange={eventHandlers.handleOnChange}
                              data-testid="txtPropertyDescription"/>
              }</WithDelayedChange>
            </Form.Group>

            {props.property.type && renderTypeInput()}

          </Col>
        </Row>
        <RowDivider />

        {props.property.type &&
        <React.Fragment>
          <Row>
            <Col sm="12" className="mt-2 mb-3">
              <strong>Validation Rules</strong>
            </Col>
            <Col sm="12">
              {props.property.validationRules.map(vr =>
                <KindPropertyAddValidationRule
                  key={vr.id}
                  handleRemoveValidationRule={handleDeleteValidationRule}
                  validationRule={vr}
                  validationTypes={props.property.validationTypes}
                  otherProperties={getOtherProperties()}
                  propertyType={props.property.type}
                />
              )}
            </Col>
            <Col sm="12">
              <Button variant="outline-primary" onClick={addValidationRule} data-testid="btnAddValidationRule">
                <i className="fas fa-plus-circle" /> Add new validation rule
              </Button>
            </Col>
          </Row>
          <RowDivider />
          <Row>
            <Col sm="12" className="mt-2">
              <Button variant="danger" onClick={() => props.deleteProperty(props.property)} data-testid="btnDeleteProperty"><i
                className="fas fa-trash" /> Delete property</Button>
            </Col>
          </Row>
        </React.Fragment>
        }
      </YoFormBoxBody>
    </YoFormBox>
  )
})


