import {flow}                       from "mobx";
import {getRoot, Instance, types} from "mobx-state-tree"
import {pub_api}                    from "../index";
import {ApiRequest} from "./ApiRequest";
import {RootStore} from "../../model/RootStore";
import { ExperimentStatus as SearchExperimentStatus, IExperimentVm, ISelectionVm } from '@yakoffice/publisher-types';
import type {IPaginationSearchParams} from "./IPaginationSearchParams";
import { IExperimentDto } from '@yakoffice/publisher-types';

export interface IExperimentSearchParams extends IPaginationSearchParams{
    statuses?: SearchExperimentStatus[],
    gameEnvironmentId?: number
    distributionId?: number
    nameLike?: string
}

export enum ExperimentStatus {
    Archive = "archive",
    Draft = "draft",
    Publish = "publish",
    Apply = "apply",
    Stop = "stop"
}

export const ExperimentApiGateway = types.model(
    "ExperimentApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl(gameVersionId?: number, gameEnvironmentId?: number) : string{
          const rootStore = getRoot<typeof RootStore>(self);
          const project =  rootStore.projectStore.getCurrentProject();
          const routeGVId = gameVersionId ?? rootStore.gameVersionStore.getCurrentGameVersion().id;
          const routeGEId = gameEnvironmentId ?? rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id;
            return `${pub_api}/${project.id}/versions/${routeGVId}/game-environments/${routeGEId}/experiments/`;
        },
        getSheetRootUrl(experimentId: number) : string{
          const project = getRoot<typeof RootStore>(self).projectStore.getCurrentProject();
          const gameVersion = getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion();
          return `${pub_api}/${project.id}/versions/${gameVersion.id}/experiments/${experimentId}/sheet`;
        },
    }))
    .actions(self => ({
        getExperiments: flow(function* () {
            return yield self.apiRequest.get<IExperimentVm[]>(self.getRootUrl())
        }),
        getExperiment: flow(function* (experimentId: number) {
            return yield self.apiRequest.get<IExperimentVm>(self.getRootUrl()+experimentId)
        }),
        findExperiments: flow(function* (searchParams : IExperimentSearchParams) {
          const queryString = buildSearchQueryString(searchParams);
          return yield self.apiRequest.get<IExperimentVm[]>(`${self.getRootUrl()}?${queryString}`)
        }),
        countExperiments: flow(function* (searchParams : IExperimentSearchParams) {
          const queryString = buildSearchQueryString(searchParams);
          return yield self.apiRequest.get<number>(`${self.getRootUrl()}count?${queryString}`)
        }),
        saveExperiment: flow(function* (gameVersionId: number, gameEnvironmentId: number, experimentDto : IExperimentDto) {
            return yield self.apiRequest.post<IExperimentVm>(self.getRootUrl(gameVersionId, gameEnvironmentId), experimentDto)
        }),
        updateExperiment: flow(function* (gameVersionId: number, gameEnvironmentId: number, entityId: number, experimentDto : IExperimentDto) {
            return yield self.apiRequest.put<IExperimentVm>(`${self.getRootUrl(gameVersionId, gameEnvironmentId)}${entityId}`, experimentDto)
        }),
        applyExperimentVariantToEntities: flow(function* (experimentId : number, variantId: number) {
          return yield self.apiRequest.put<IExperimentVm[]>(`${self.getRootUrl()}${experimentId}/apply/${variantId}`, null)
        }),
        updateExperimentStatus: flow(function* (experimentId : number, status: ExperimentStatus) {
            return yield self.apiRequest.put<IExperimentVm[]>(`${self.getRootUrl()}${experimentId}/${status}`, null)
        }),
        deleteExperiment: flow(function* (experimentId: number) {
            yield self.apiRequest.delete(`${self.getRootUrl()}${experimentId}`)
        }),
        createSheet: flow(function* (experimentId: number) {
          return yield self.apiRequest.post<IExperimentVm>(`${self.getSheetRootUrl(experimentId)}`, null)
        }),
        refreshSheet: flow(function* (experimentId: number) {
          yield self.apiRequest.put(`${self.getSheetRootUrl(experimentId)}`, null)
        }),
        uploadSheet: flow(function* (experimentId: number) {
          return yield self.apiRequest.put<ISelectionVm>(`${self.getSheetRootUrl(experimentId)}/upload`, null)
        }),
        deleteSheet: flow(function* (experimentId: number) {
          yield self.apiRequest.delete(`${self.getSheetRootUrl(experimentId)}`)
        }),

    }))

function buildSearchQueryString(searchParams: IExperimentSearchParams) {
    let queryString = "";
    if (searchParams.statuses)
        searchParams.statuses.forEach(status => queryString += `status=${status.toString()}&`);
    if (searchParams.gameEnvironmentId)
        queryString += `game-environment-id=${searchParams.gameEnvironmentId}&`;
    if (searchParams.distributionId)
        queryString += `distribution-id=${searchParams.distributionId}&`;
    if (searchParams.nameLike)
        queryString += `name-like=%${searchParams.nameLike}%&`;
    if (searchParams.pageNumber)
        queryString += `page-number=${searchParams.pageNumber}&`;
    if (searchParams.pageSize)
        queryString += `page-size=${searchParams.pageSize}&`;
    return queryString;
}

export interface IExperimentApiGateway extends Instance<typeof ExperimentApiGateway> {}
