import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IKindProperty } from '../../../model/kind/KindProperty';
import { ISelection } from '../../../model/selections/Selection';
import { IExperimentProperty } from '../../../model/experiment/ExperimentProperty';
import { IKindVersion } from '../../../model/kind/KindVersion';
import { ExperimentKindSelection } from './ExperimentKindSelection';
import { ExperimentEntitySelection } from './ExperimentEntitySelection';
import { IEntityVersion } from '../../../model/entity/EntityVersion';
import Spinner from 'react-bootstrap/Spinner';
import { IEntityProperty } from '../../../model/entity/EntityProperty';
import { ExperimentPropertySelection } from './ExperimentPropertySelection';
import { useExperimentProperty } from './hooks/useExperimentProperty';
import { RowDivider } from '../../components/dividers/RowDivider';
import { YoFormBox, YoFormBoxBody } from '../../components/yo-form-box/YoFormBox';
import { ExperimentVariantValue } from './ExperimentVariantValue';
import { IVariant } from '../../../model/experiment/Variant';
import { FormatPropertyValue } from '../common/PropertyValueDisplay';
import { useRootStore } from '../../../loaders/useRootStore';

interface PropsType {
  variants: IVariant[]
  property: IExperimentProperty
  kinds: IKindVersion[]
  selections: ISelection[]
  specificationsForKinds: IKindVersion[]
  removeProperty: (property: IExperimentProperty) => void
  addAnother: (property: IExperimentProperty) => void
}

// NB This control handles a lot of logic so beware when making changes:
// * Kind missing (i.e. no longer published)
// * Entity missing (i.e. no longer published)
// * Kind property missing (i.e. kind has been updated so no longer current version)
// * Entity property missing (i.e. Entity been updated so no longer current version)
export const ExperimentProperty: FC<PropsType> = observer(({ property, ...props }) => {

  const rootStore = useRootStore();
  const { experimentEntitiesResult, kind, entity, kindProperty } = useExperimentProperty(property, props.kinds, props.specificationsForKinds);

  const handleKindChange = (kindVersion: IKindVersion) => {
    property.setKindDetails(kindVersion.kind.id, kindVersion.name);
  };

  const handleEntityChange = (entityVersion: IEntityVersion) => {
    property.setEntityDetails(entityVersion.entity.id, entityVersion.name);
  };

  const handlePropertyChange = (kindProperty: IKindProperty, entityProperty: IEntityProperty) => {
    property.setPropertyDetails(kindProperty.getKind().kind.id, kindProperty.id, kindProperty.key, entityProperty.id, entityProperty.value);
  };

  return (
    <YoFormBox>
      <YoFormBoxBody>
        <Row>
          <Col md='12'>
            <Form.Group className='mb-3'>
              <Form.Label>Kind</Form.Label>
              <ExperimentKindSelection property={property} kinds={props.kinds} handleKindChange={handleKindChange} />
            </Form.Group>

            {!property.hasValidKindId()
             ? null
             : !experimentEntitiesResult.isLoaded
               ? <>
                 <Spinner animation='border' role='status' size='sm' />
                 <span className='ms-3 spinner-text'>Loading entities for kind...</span>
               </>
               : <>
                 <Form.Group className='mb-3'>
                   <Form.Label>Entity</Form.Label>
                   <ExperimentEntitySelection property={property}
                                              entityVersions={experimentEntitiesResult.entityVersions}
                                              handleEntityChange={handleEntityChange} />
                 </Form.Group>

                 {property.hasValidEntityId() &&
                  <>
                    <Form.Group className='mb-3'>
                      <Form.Label>Property</Form.Label>
                      <ExperimentPropertySelection property={property}
                                                   kind={kind}
                                                   entity={entity}
                                                   specificationsForKinds={props.specificationsForKinds}
                                                   handlePropertyChange={handlePropertyChange} />
                    </Form.Group>

                    {property.hasValidEntityPropertyId() &&
                     <Form.Group className='mb-3'>
                       <Form.Label>Values</Form.Label>
                       <Row className='mt-2'>
                         <Col md={3} className='text-end align-self-center'>Control:</Col>
                         <Col md={9} className='ps-4'>{FormatPropertyValue(rootStore, property.kindPropertyType, property.kindPropertySelectionId, property.kindPropertyEntitySelectionKindId, property.entityPropertyValue)}</Col>
                       </Row>
                       {property.values.map(variantValue =>
                                              <ExperimentVariantValue key={variantValue.id}
                                                                      variants={props.variants}
                                                                      experimentProperty={property}
                                                                      variantValue={variantValue}
                                                                      kindProperty={kindProperty} />
                       )}
                     </Form.Group>
                    }
                  </>
                 }
               </>
            }
          </Col>
        </Row>
        <RowDivider />

        {property &&
         <Row>
           <Col sm='6' className='mt-2'>
             <Button variant='success' onClick={() => props.addAnother(property)}>
               <i className='fas fa-plus' /> Add another property
             </Button>
           </Col>
           <Col sm='6' className='mt-2'>
             <Button variant='danger' className="float-end" onClick={() => props.removeProperty(property)}>
               <i className='fas fa-trash' /> Delete property
             </Button>
           </Col>
         </Row>
        }
      </YoFormBoxBody>
    </YoFormBox>
  );
});

