import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import { useParams } from 'react-router-dom';
import { DistributionDiff } from './DistributionDiff';
import { useRootStore } from '../../../loaders/useRootStore';
import { Alert, Container } from 'react-bootstrap';

export const DistributionDiffContainer : FC = observer(() => {

  const rootStore = useRootStore();
  const {
    gameVersionId,
    diffGameVersionId,
    gameEnv,
    diffGameEnv,
    distributionId,
    diffDistributionId
  } = useParams() as { gameVersionId: string, diffGameVersionId: string, gameEnv: string, diffGameEnv: string, distributionId: string, diffDistributionId: string };

  const gameVersion = rootStore.gameVersionStore.gameVersions.find(gv => gv.id === parseInt(gameVersionId));
  const diffGameVersion = rootStore.gameVersionStore.gameVersions.find(gv => gv.id === parseInt(diffGameVersionId));

  const gameEnvironment = rootStore.gameEnvironmentStore.gameEnvironments.find(gv => gv.id === parseInt(gameEnv));
  const diffGameEnvironment = rootStore.gameEnvironmentStore.gameEnvironments.find(gv => gv.id === parseInt(diffGameEnv));

  if (gameVersion == null || diffGameVersion == null || gameEnvironment == null || diffGameEnvironment == null)
    return <Container><Alert variant="warning" className="text-center">Invalid Diff Request</Alert></Container>

  return <DistributionDiff gameVersion={gameVersion}
                        gameEnvironment={gameEnvironment}
                        distributionId={distributionId === "published" ? distributionId : parseInt(distributionId)}
                        diffGameVersion={diffGameVersion}
                        diffGameEnvironment={diffGameEnvironment}
                        diffDistributionId={diffDistributionId === "published" ? diffDistributionId : parseInt(diffDistributionId)} />
})
