import {Event} from 'react-big-calendar';


export const EventProps = (event: Event, isSelected: boolean) => {

  const backgroundColor = () => {return event.resource.kindVersionSummary.kindHexColour ? event.resource.kindVersionSummary.kindHexColour : '000000'};

  const generatedTextColor = () => {

    const color = (backgroundColor().charAt(0) === '#') ? backgroundColor().substring(1, 7) : backgroundColor();

    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB

    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? '#000000' : '#ffffff';
  }

  const isLocalTime = event.resource.properties.find((p: any) => p.kindPropertyKey === "startTimeLocal" || p.kindPropertyKey === "endTimeLocal");

  const isOnlyStartOrEndTime = () => {

    const hasStartTime  = event.resource.properties.find( (p: any) => p.kindPropertyKey === "startTime")
    const hasEndTime    = event.resource.properties.find( (p: any) => p.kindPropertyKey === "endTime")

    if(hasStartTime && !hasEndTime)
      return "startTimeOnly"
    else if(!hasStartTime && hasEndTime)
      return "endTimeOnly"
    else
      return ""
  }

  if(!isSelected)
    return {
      style: {
        backgroundColor: `#${backgroundColor()}`,
        color: generatedTextColor(),
      },
      className: `${isOnlyStartOrEndTime()} ${isLocalTime && 'localTime'}`
    }
  else
    return {className: `bg-primary text-white ${isLocalTime && 'localTime'} ${isOnlyStartOrEndTime()}`}
}
