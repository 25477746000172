import React, { FC } from 'react';
import Navbar, {NavbarProps} from 'react-bootstrap/Navbar';
import styles from './YoSubNav.module.sass';



export const YoSubNav : FC<NavbarProps> = ({children, ...props}) => {

  return (
    <Navbar {...props} className={styles.yoSubNav}>
      <Navbar.Toggle aria-controls="yoSubNav" />
      <Navbar.Collapse id="yoSubNav">
        {children}
      </Navbar.Collapse>
    </Navbar>
  )
}

