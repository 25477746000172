import React, { FC } from 'react';
import Form     from 'react-bootstrap/Form';

interface PropsType {
  defaultValue?: string;
  handler     : (comment: string) => void;
}

const CustomCommentForm : FC<PropsType> = ({defaultValue, handler}) => {
  return (
    <Form.Group controlId="formGroupCustomComment" className="mb-3">
      <Form.Label>Please enter a comment</Form.Label>
      <Form.Control type="text"
                    placeholder=""
                    defaultValue={defaultValue}
                    onChange={(e) => handler(e.target.value)}
                    maxLength={255}
                    data-testid="txtCustomComment"
      />
    </Form.Group>
  )
}

export default CustomCommentForm
