import { FC } from 'react';
import {ClaimType, ISearchResultVm} from '@yakoffice/publisher-types';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import React from 'react';
import { useGetEntitiesLink, useGetEntityLink, useGetKindLink, useGetKindsLink } from '../../routes';
import sass from './SearchResult.module.sass'
import { useGameEnvironmentCssClass } from '../common/useGameEnvironmentCssClass';
import { useRootStore } from '../../../loaders/useRootStore';
import {Link} from 'react-router-dom';

interface PropTypes{
  searchResult: ISearchResultVm
  onClick: () => void
}

export const SearchResult : FC<PropTypes> = ({searchResult, onClick}) => {

  const rootStore = useRootStore()
  const css = useGameEnvironmentCssClass(rootStore.gameEnvironmentStore.gameEnvironments.find(ge => ge.id === searchResult.gameEnvironmentId))
  const getKindsLink = useGetKindsLink();
  const getKindLink = useGetKindLink();
  const getEntitiesLink = useGetEntitiesLink();
  const getEntityLink = useGetEntityLink();

  return (
    <Breadcrumb listProps={{className: `${sass.searchResult} ${searchResult.gameEnvironmentId && sass[css.name]}`}}>
      <strong className="me-2">{searchResult.entityId ? "Entity" : searchResult.kindId ? "Kind" : "Category"}:</strong>
      <BreadcrumbItem linkAs={Link} linkProps={{ to: getKindsLink({categoryId: searchResult.categoryId}) }} onClick={onClick}>
        {searchResult.categoryName}
      </BreadcrumbItem>

      {searchResult.kindId &&
        (rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind)
          ? <BreadcrumbItem linkAs={Link} linkProps={{to: getKindLink({categoryId: searchResult.categoryId, kindId: searchResult.kindId})}} onClick={onClick} >
              {searchResult.kindName}
              </BreadcrumbItem>
          : <BreadcrumbItem active>{searchResult.kindName}</BreadcrumbItem>
        )
      }

      {searchResult.kindId && !searchResult.kindIsSpecificationForKinds && (searchResult.gameEnvironmentId || rootStore.gameEnvironmentStore.currentGameEnvironment) &&
      <BreadcrumbItem linkAs={Link} linkProps={{to: getEntitiesLink({categoryId: searchResult.categoryId, kindId: searchResult.kindId, gameEnvironmentId: searchResult.gameEnvironmentId ?? undefined})}} onClick={onClick}>
        Entities
      </BreadcrumbItem>
      }

      {searchResult.kindId && searchResult.gameEnvironmentId && searchResult.entityId &&
      <BreadcrumbItem linkAs={Link} linkProps={{to: getEntityLink({categoryId: searchResult.categoryId, kindId: searchResult.kindId, gameEnvironmentId: searchResult.gameEnvironmentId, entityId: searchResult.entityId })}} onClick={onClick}>
        {searchResult.entityName}
      </BreadcrumbItem>
      }
    </Breadcrumb>
  )
}
