import {flow, Instance, types} from "mobx-state-tree";
import {DistributionId, IDistributionDto, IDistributionVm, IEntityVersionVm} from '@yakoffice/publisher-types';
import {formatError} from "../Utils";
import {Distribution, IDistribution} from "./Distribution";
import {DistributionApiGateway, IDistributionSearchParams} from '../../api/requests/distribution/DistributionApiGateway';
import type {IGameEnvironment} from "../gameEnvironment/GameEnvironment";
import {IGameVersion} from '../gameVersion/GameVersion';


export const DistributionStore = types.model(
  "DistributionStore",
  {
    apiGateway:          DistributionApiGateway,
    distributions:       types.array(Distribution),
    currentDistribution: types.maybeNull(types.reference(Distribution)),
    isLoading:           false,
  })
  .views(self => ({
    getCurrentDistribution(): IDistribution {
      if (self.currentDistribution)
        return self.currentDistribution;

      throw new Error("The current distribution has not been set")
    }
  }))
  .actions(self => ({
    clearCurrentDistribution() {
      self.currentDistribution = null;
    },
    setCurrentDistribution(distribution: IDistribution) {
      self.currentDistribution = distribution;
    },
    loadDistributions:      flow(function* (searchParams: IDistributionSearchParams, preventStoreUpdateAndReturnResult?: boolean) {
      self.isLoading = true;

      // To prevent an invalid reference
      self.currentDistribution = null;

      try {
        const distributionVms = (yield self.apiGateway.findDistributions(searchParams)) as IDistributionVm[];

        const distributions = distributionVms.map((vm: IDistributionVm) => Distribution.create(MapToModel(vm)));

        if (preventStoreUpdateAndReturnResult)
          return distributions;
        else
          self.distributions.replace(distributions);

        self.isLoading = false;
      }
      catch (e: any) {
        throw new Error(`Failed to load distributions: ${e.message}`);
      }
    }),
    getDistribution:        flow(function* (distributionId: number) {

      self.isLoading = true;

      try {
        const distributionVm = yield self.apiGateway.getDistribution(distributionId);
        const distribution = Distribution.create(MapToModel(distributionVm));

        const indexOf = self.distributions.findIndex(d => d.id === distribution.id);
        if (indexOf === -1)
          self.distributions.push(distribution);
        else
          self.distributions.splice(indexOf, 1, distribution);

        self.isLoading = false;
        return distribution as IDistribution;
      }
      catch (e: any) {
        throw new Error(`Failed to load distribution: ${e.message}`);
      }
    }),
    countDistributions:     flow(function* (searchParams: IDistributionSearchParams) {
      try {
        return (yield self.apiGateway.countDistributions(searchParams)) as number;
      }
      catch (e: any) {
        throw new Error(`Failed to count distributions: ${e.message}`);
      }
    }),
    getDistributionDiff:    flow(function* (gameVersion: IGameVersion, gameEnvironment: IGameEnvironment, distributionId: DistributionId,
                                            diffGameVersion: IGameVersion, diffGameEnvironment: IGameEnvironment, diffDistributionId: DistributionId) {
      self.isLoading = true;

      try {
        return yield self.apiGateway.getDistributionDiff(gameVersion.id, gameEnvironment.id, distributionId, diffGameVersion.id, diffGameEnvironment.id, diffDistributionId);
      }
      catch (e: any) {
        throw new Error(`Failed to run diff comparison: ${e.message}`);
      }
    }),
    distribute:             flow(function* (distributionDto: IDistributionDto) {
      try {
        yield self.apiGateway.distribute(distributionDto);
      }
      catch (e: any) {
        throw formatError(e);
      }
    }),
    deleteDistribution:     flow(function* (distribution: IDistribution) {

      try {
        yield self.apiGateway.deleteDistribution(distribution.id)
      }
      catch (e: any) {
        throw formatError(e);
      }
    }),
    copyEntities:           flow(function* (gameVersion: IGameVersion, gameEnvironment: IGameEnvironment, distributionId: DistributionId, toGameEnvironment: IGameEnvironment) {

      try {
        return yield self.apiGateway.copyEntities(gameVersion.id, gameEnvironment.id, distributionId, toGameEnvironment.id)
      }
      catch (e: any) {
        throw formatError(e);
      }
    }),
    downloadDistributionAsJSON: flow(function* (searchParams: IDistributionSearchParams) {
      try {
        const a = yield self.apiGateway.downloadEntitiesAsJSON(searchParams);
        console.log(a);
        return a;
      }
      catch (e: any) {
        throw formatError(e);
      }
    })
  }));

const MapToModel = (vm: IDistributionVm) => {
  return {
    id:                  vm.id,
    gameEnvironmentId:   vm.gameEnvironmentId,
    distributionVersion: vm.distributionVersion,
    comment:             vm.comment,
    jiraIssueIds:        vm.jiraIssueIds,
    entityVersionCount:  vm.entityVersionCount,
    experimentCount:     vm.experimentCount,
    succeeded:           vm.succeeded,
    errorMessage:        vm.errorMessage,
    deleted:             vm.deleted,
    createdAt:           vm.createdAt,
    originalAuthor:      vm.originalAuthor,
    updatedAt:           vm.updatedAt,
    lastAuthor:          vm.lastAuthor
  }
}


export interface IDistributionStore extends Instance<typeof DistributionStore> {
}
