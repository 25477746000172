import React, {FC} from 'react';
import {Form} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

interface PropsType {
  archived    : boolean;
  showArchived: (archived: boolean, redirect?: boolean) => void;
}
export const SelectionsStatus : FC<PropsType> = observer((props) => {

  const showArchived = (e: any) => {
    props.showArchived(e.target.value === "true", true);
  }

  return (
          <Form.Select
            id="selectionIsArchived"
            onChange={(e) => {showArchived(e)}}
            className="mb-3 mt-3"
            value={props.archived.toString()}
            data-testid="ddlSelectionStatus"
          >
            <option value="false">In Use</option>
            <option value="true">Archived</option>
          </Form.Select>
  )
})
