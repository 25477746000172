import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";

export const VariantValue = types.model(
  "VariantValue",
  {
    id: types.optional(types.identifierNumber, GenerateId),
    variantId: 0,
    value : types.maybeNull(types.string)

  })
  .views(self => ({

  }))
  .actions(self => ({
    setVariantId(variantId: number) {
      self.variantId = variantId;
    },
    setValue(value: string | null) {
      self.value = value;
    },
  }))

export interface IVariantValue extends Instance<typeof VariantValue> {}
