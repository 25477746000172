import React, {FC} from "react";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    value : string;
    handleOnChange: (e: string) => void;
}

export const StringInput : FC<PropsType> = ({ id, value, handleOnChange, ...props }) => {
  return <Form.Control type="text"
                       id={id}
                       placeholder="Enter String"
                       onChange={e => handleOnChange(e.target.value)}
                       value={value}
                       {...props} />
}
