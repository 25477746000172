import React, { FC } from 'react';
import {observer}                                   from "mobx-react-lite";
import {CustomSpinner}                              from '@yakoffice/custom-spinner';
import { YakofficeHeader } from '@yakoffice/yakoffice-header';
import {useAuthUser} from '@yakoffice/yakoffice-firebase';
import { NoAccess } from './auth/noAccess';
import ProjectContainer from './project/project-container';
import { useProjects } from '../../loaders/useProjects';
import { useParams } from 'react-router-dom';

export const Publisher : FC = observer(() => {

  const useProjectsResult = useProjects();
  const {projectId} = useParams() as {projectId: string};
  const authUser = useAuthUser();

  return (
    <div>
      <YakofficeHeader hostedInPortal={false} hostedInToolId={process.env.REACT_APP_FIREBASE_CONFIG === "localhost" ? "1" : undefined}>

      {!useProjectsResult.isLoaded
        ? <CustomSpinner spinnerText="Loading projects..." />
        :authUser?.isAuthorisedForHostedToolProject(projectId)
          ? <ProjectContainer />
          : <NoAccess />
      }
      </YakofficeHeader>
    </div>
  )
})

