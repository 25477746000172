export enum ValidationType {
    Required = "Required",

    // String
    LengthEqualTo = "LengthEqualTo",
    LengthLessThan = "LengthLessThan",
    LengthGreaterThan = "LengthGreaterThan",
    MatchesRegex = "MatchesRegex",

    // IComparable
    EqualTo = "EqualTo",
    NotEqualTo = "NotEqualTo",
    LessThan = "LessThan",
    LessThanOrEqualTo = "LessThanOrEqualTo",
    GreaterThan = "GreaterThan",
    GreaterThanOrEqualTo = "GreaterThanOrEqualTo",

    // Dependent Property IComparable
    RequiredIfPropertyTrue = "RequiredIfPropertyTrue",
    RequiredIfPropertyFalse = "RequiredIfPropertyFalse",
    EqualToProperty = "EqualToProperty",
    NotEqualToProperty = "NotEqualToProperty",
    LessThanProperty = "LessThanProperty",
    LessThanOrEqualToProperty = "LessThanOrEqualToProperty",
    GreaterThanProperty = "GreaterThanProperty",
    GreaterThanOrEqualToProperty = "GreaterThanOrEqualToProperty",
    LengthEqualToProperty = "LengthEqualToProperty",
    LengthLessThanProperty = "LengthLessThanProperty",
    LengthGreaterThanProperty = "LengthGreaterThanProperty",
    MatchesRegexProperty = "MatchesRegexProperty",
}
