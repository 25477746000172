import {getSnapshot, Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";
import { equalsIgnoreCase } from '../../utilities';
import { GameVersionStatus } from '@yakoffice/publisher-types';

export const GameVersion = types.model(
    "GameVersion",
    {
            id:                          types.optional(types.identifierNumber, GenerateId),
            status:                      GameVersionStatus.Live,
            name:                        "",
            description:                 "",
            configApiPublishingUrlVersionId:      "",
            createdAt:                   "",
            createdBy:                   "",
            updatedAt:                   "",
            updatedBy:                   "",
            isNew:                       false
    })
    .views( self => ({
        isNewGameVersion(){
            return self.isNew;
        },
        isArchived(){
          return equalsIgnoreCase(self.status, GameVersionStatus.Archived);
        }
    }))
    .actions(self => ({
        setName(name: string){
            self.name = name;
        },
        setDescription(description: string){
            self.description = description;
        },
        setConfigApiPublishingUrlVersionId(configApiPublishingUrlVersionId: string){
            self.configApiPublishingUrlVersionId = configApiPublishingUrlVersionId;
        },
        branchGameVersion() {
          const snapshot = getSnapshot(self);

          return GameVersion.create({
            ...snapshot,
            id: GenerateId(),
            name: undefined,
            description: self.description,
            configApiPublishingUrlVersionId: self.configApiPublishingUrlVersionId
          });
        },
    }));

export interface IGameVersion extends Instance<typeof GameVersion> {}
