import React, {ChangeEvent, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Observer} from "mobx-react-lite";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {EntityStatus} from '@yakoffice/publisher-types';
import type {IGameVersion} from '../model/gameVersion/GameVersion';
import type {IKindVersion} from "../model/kind/KindVersion";
import type {IKindProperty} from "../model/kind/KindProperty";
import type {IGameEnvironment} from "../model/gameEnvironment/GameEnvironment";
import type {IEntityVersion} from "../model/entity/EntityVersion";
import type {IEntityProperty} from "../model/entity/EntityProperty";
import {useRootStore} from '../loaders/useRootStore';
import FieldLayout from "../presentation/components/inputs/fieldLayout";
import {FormattedErrorMessage} from "../presentation/views/common/Utils";
import {useGetCategoriesLink, useGetEntitiesLink, useGetEntityLink} from '../presentation/routes';
import {ModifiedEntityTable} from '../presentation/views/distribution/ModifiedEntityTable';
import {useGameEnvironmentCssClass} from '../presentation/views/common/useGameEnvironmentCssClass';
import {IconLeftDiff} from '../presentation/views/distribution/IconLeftDiff';
import {IconRightDiff} from '../presentation/views/distribution/IconRightDiff';
import {ModalState, useHideModal, useShowModal, useShowModalAsync, useShowModalSpinner} from '@yakoffice/custom-modal';


export interface IEntityController{
    entity                            : IEntityVersion;
    handleSaveEntity                  : (andPublish: boolean, onSuccessCallback: () => void) => Promise<void>;
    handlePublishEntity               : () => Promise<void>;
    handleStopEntity                  : () => Promise<void>;
    handleArchiveEntity               : () => Promise<void>;
    handleUnarchiveEntity             : () => Promise<void>;
    handleDeleteEntity                : () => Promise<void>;
    handleUpdateToCurrentKindVersion  : () => void;
    handleCompareEntityVersion        : (diffGameVersion: IGameVersion, diffGameEnvironment : IGameEnvironment) => void;
    handleCopyEntityVersion           : (targetGameVersions: IGameVersion[], targetGameEnvironments : IGameEnvironment[]) => Promise<void>;
    handleMoveEntity                  : (targetGE: IGameEnvironment,onSuccessCallback: () => void, onFailureCallback: () => void) => Promise<void>;
    handleAddSpecificationProperty    : (specificationKind: IKindVersion, specificationProperty: IKindProperty) => Promise<void>;
    handleRemoveSpecificationProperty : (property: IEntityProperty) => Promise<void>
    handleGameVersionChange           : (currentEntityVersion: IEntityVersion, desiredGameVersion : IGameVersion) => void
}


export const useEntityController = (entityVersion  : IEntityVersion) => {

  // Context Hooks
  const showModalAsync          = useShowModalAsync();
  const showModal               = useShowModal();
  const showModalSpinner        = useShowModalSpinner();
  const hideModal               = useHideModal();
  const rootStore               = useRootStore();
  const navigate                = useNavigate()
  const [comment, setComment]   = useState("");
  const currentComment          = useRef(comment);
  const getEntityLink           = useGetEntityLink();
  const getEntitiesLink         = useGetEntitiesLink();
  const getCategoriesLink       = useGetCategoriesLink()
  const css                     = useGameEnvironmentCssClass();

  // Handlers
  const  hideSpinnerAndRedirect = (
    targetGE  : IGameEnvironment  = rootStore.gameEnvironmentStore.getCurrentGameEnvironment(),
    targetGv  : IGameVersion      = rootStore.gameVersionStore.getCurrentGameVersion(),
    categoryId: number            = entityVersion.kindVersionSummary.kindCategoryId,
    kindId    : number            = entityVersion.kindVersionSummary.kindId
  ) => {

    showModal({show: false, title: "", body: "", canClose: false});

    navigate(getEntitiesLink({
      gameVersionId: targetGv.id,
      categoryId: categoryId,
      kindId: kindId,
      gameEnvironmentId: targetGE.id
    }));
  }

  const handleUpdateToCurrentKindVersion = () => {
    entityVersion.updateKindVersionAndProperties(rootStore.kindVersionStore.getCurrentKindVersion());
  }

  const handleSaveEntity = async (andPublish: boolean, onSuccessCallback: () => void) => {

    const handleEntityCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value)
      currentComment.current = e.target.value;
    };

    const saveEntity = async () => {
      try {
        showModalSpinner("Saving Entity Version", "Saving entity version....");
        entityVersion.setComment(currentComment.current);
        await rootStore.entityVersionStore.saveEntityVersion(entityVersion, false);
        onSuccessCallback();
        andPublish && await handlePublishEntity(true);
        !andPublish && hideSpinnerAndRedirect(rootStore.gameEnvironmentStore.getCurrentGameEnvironment());
      } catch (e: any) {
        showModal({
          show: true,
          title: "Error Saving/Updating Entity Version",
          body: <FormattedErrorMessage errorMessage={e.message} />,
          canClose: true
        })
      }
    };

    showModal(
      {
        show: true,
        title: "Save/Update Entity Version",
        body: <Observer>{() => <FieldLayout label="You are about to update this entity. Please enter a comment.">
          <Form.Control type="text" id="comment" defaultValue={comment} onChange={handleEntityCommentChange}
                        maxLength={255} data-testid="txtSaveEntityCustomComment"/>
        </FieldLayout>
        }</Observer>,
        action: <Button variant="success" onClick={saveEntity} data-testid="btnModalConfirmSave">Save/Update Entity</Button>,
        canClose: true
      }
    );
  };

  const handleEntityStatusChange = async (status: string, comment: string, newStatus: EntityStatus, noConfirm?: boolean) => {

    const updateEntityStatus = async () => {
      try {
        showModalSpinner("Changing Entity Status", "Saving entity status....");
        await rootStore.entityVersionStore.updateEntityStatus(entityVersion, newStatus);
        hideSpinnerAndRedirect(rootStore.gameEnvironmentStore.getCurrentGameEnvironment());
      } catch (e: any) {
        showModal({
          show: true,
          title: "Error Changing Entity Status",
          body: `Could not ${newStatus} entity. Error message: ${e.message}`,
          canClose: true
        })
      }
    };

    noConfirm ? await updateEntityStatus() : showModal(
      {
        show: true,
        title: `${status} Entity`,
        body: comment,
        action: <Button variant="success" onClick={updateEntityStatus} data-testid="btnModalChangeStatus">{`${status} Entity`}</Button>,
        canClose: true
      }
    );
  };

  const handlePublishEntity = async (noConfirm?: boolean) => {
    handleEntityStatusChange(
      "Publish",
      "You are about to publish this entity. Once published this entity will be live in this game environment. Are you sure you want to do this?",
      EntityStatus.Published,
      noConfirm && noConfirm);
  };

  const handleStopEntity = async () => {
    handleEntityStatusChange(
      "Stop",
      "You are about to stop this entity. Once stopped this entity will no longer be live in this game environment. Are you sure you want to do this?",
      EntityStatus.Stopped);
  };

  const handleArchiveEntity = async () => {
    handleEntityStatusChange(
      "Archive",
      "You are about to archive this entity. Are you sure you want to do this?",
      EntityStatus.Archived);
  };

  const handleUnarchiveEntity = async () => {
    handleEntityStatusChange(
      "Unarchive",
      "You are about to un-archive this entity. Are you sure you want to do this?",
      EntityStatus.Draft);
  };

  const handleCopyEntityVersion = async (targetGameVersions: IGameVersion[], targetGameEnvironments : IGameEnvironment[]) => {
    for (const targetGameVersion of targetGameVersions) {
      for (const targetGameEnvironment of targetGameEnvironments) {
        await copyEntityVersion(targetGameVersion, targetGameEnvironment);
      }
    }
  }

  const copyEntityVersion = async (targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment) => {
    try {
      if (targetGameVersion.id === rootStore.gameVersionStore.getCurrentGameVersion().id && targetGameEnvironment.id === rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id)
        await handleCopyToSameGameVersionAndEnvironment();
      else if (targetGameVersion.id === rootStore.gameVersionStore.getCurrentGameVersion().id && targetGameEnvironment.id !== rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id)
        await handleCopyToDifferentGameEnvironment(targetGameEnvironment);
      else
        await handleCopyToDifferentGameVersionAndEnvironment(targetGameVersion, targetGameEnvironment);
    } catch (e: any) {
      await showModalAsync(_ => ({
        show: true,
        title: "Error Copying Entity Version",
        body: `Error:  ${e.message}`,
        canClose: true,
      }))
    }
  }

  const handleCopyToSameGameVersionAndEnvironment = async () => {
    const copiedEntity = entityVersion.copyEntityVersion(rootStore.gameEnvironmentStore.getCurrentGameEnvironment());
    copiedEntity.setComment(`Copied by ${rootStore.authStore.currentAuthUser.displayName}`);

    const handleCopyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      copiedEntity.setName(e.target.value)
    };

    const result = await showModalAsync<boolean>(
      setResult => {
        return {
          show: true,
          title: "Copy Entity",
          body: <Observer>{() =>
            <FieldLayout label="You are about to copy this entity version to the same game environment. Please enter a new name:">
              <Form.Control type="text" id="comment" value={copiedEntity.name} onChange={handleCopyNameChange} data-testid="txtModalCopyComment"/>
            </FieldLayout>
          }</Observer>,
          action: <div>
            <Button variant="success" className="me-2" onClick={() => {setResult(false)}} data-testid="btnModalSave">Save</Button>
            <Button variant="success" onClick={() => {setResult(true)}}>Save and Publish</Button>
          </div>,
          canClose: true
        }
      }
    );

    if(result === ModalState.Closed)
      return;

    try {
      showModalSpinner("Copying Entity Version", `Copying entity version ${entityVersion.name}....`);
      await rootStore.entityVersionStore.saveEntityVersion(copiedEntity, result);
      hideModal();
    } catch (e: any) {
      await showModalAsync(_ => ({
        show: true,
        title: "Error Copying Entity Version",
        body: `Could not copy entity version ${copiedEntity.name} to ${rootStore.gameEnvironmentStore.getCurrentGameEnvironment().name} (${rootStore.gameVersionStore.getCurrentGameVersion().name}). Error message: ${e.message}`,
        canClose: true,
      }))
    }
  };

  const handleCopyToDifferentGameEnvironment = async (targetGe: IGameEnvironment) => {
    showModalSpinner("Checking Existing Entity Versions", `Checking for existing entities in ${targetGe.name}....`);

    const copiedEntity = (await rootStore.entityVersionStore.copyEntitiesToGameEnvironment([entityVersion], targetGe))[0];

    await confirmAndSaveCopiedEntity(copiedEntity, rootStore.gameVersionStore.getCurrentGameVersion(), targetGe)
  };

  const handleCopyToDifferentGameVersionAndEnvironment = async (targetGv: IGameVersion, targetGe: IGameEnvironment) => {
    showModalSpinner("Checking Existing Entity Versions", `Checking for existing entities in ${targetGe.name} (${targetGv.name})....`);

    const kindName = rootStore.kindVersionStore.getCurrentKindVersion().name;
    const targetKindVersion = (await rootStore.kindVersionStore.findAllCurrentKindVersions({gameVersionId: targetGv.id, name: kindName})).find(() => true);
    if(targetKindVersion == null) {
      await showModalAsync(_ => ({
        show: true,
        title: "Error Copying Entity Version",
        body: `Could not copy entity version ${entityVersion.name} to ${targetGe.name} (${targetGv.name}).  There is no current kind version with name (${kindName}) in game version (${targetGv.name})`,
        canClose: true,
      }))
      return;
    }

    const copiedEntity = (await rootStore.entityVersionStore.copyEntitiesToGameVersionAndEnvironment([entityVersion], rootStore.gameVersionStore.getCurrentGameVersion(), targetGv, targetGe, targetKindVersion))[0];

    await confirmAndSaveCopiedEntity(copiedEntity, targetGv, targetGe)
  };

  const confirmAndSaveCopiedEntity = async (copiedEntity : IEntityVersion, targetGv : IGameVersion, targetGe: IGameEnvironment) => {

    copiedEntity.setComment(`Copied by ${rootStore.authStore.currentAuthUser.displayName}`);

    const modalResult = await showModalAsync<boolean>(setResult => ({
        show: true,
        title: "Copy Entity Version",
        body: <div>
          <div className={"mt-2"}>
            You are about to copy entity version <strong>{entityVersion.name}</strong> to <strong>{targetGe.name} ({targetGv.name})</strong>. Are you sure you want to do this?
          </div>
          {!copiedEntity.isNewEntity() &&
            <div className="alert alert-warning mb-3 mt-2" role="alert">
              An entity version with this name already exists in <strong>{targetGe.name}</strong>. You will overwrite it.
            </div>}
        </div>,
        action: <div>
          <Button variant="success" className="me-2" onClick={() => setResult(false)} data-testid="btnModalSave">Copy</Button>
          <Button variant="success" onClick={() => setResult(true)}>Copy and Publish</Button></div>,
        canClose: true
      })
    );

    if (modalResult === ModalState.Closed)
      return;

    try {
      showModalSpinner("Copying Entity Version", `Copying entity version ${entityVersion.name}....`);
      await rootStore.entityVersionStore.saveEntityVersion(copiedEntity, modalResult);
      hideModal();
    } catch (e: any) {
      await showModalAsync(_ => ({
        show: true,
        title: "Error Copying Entity Version",
        body: `Could not copy entity version ${copiedEntity.name} to ${targetGe.name} (${targetGv.name}). Error message: ${e.message}`,
        canClose: true,
      }))
    }
  }

  const handleMoveEntity = async (ge: IGameEnvironment, onSuccessCallback: () => void, onFailureCallback: () => void) => {

    const moveEntity = async () => {
      try {
        showModalSpinner("Moving Entity", `Moving entity ${entityVersion.name}...`);
        entityVersion.setComment(`Moved by ${rootStore.authStore.currentAuthUser.displayName}`);
        await rootStore.entityVersionStore.moveEntity(entityVersion, ge);
        onSuccessCallback();
        hideSpinnerAndRedirect(rootStore.gameEnvironmentStore.getCurrentGameEnvironment());
      } catch (e: any) {
        entityVersion.setComment("");
        onFailureCallback();
        showModal({
          show: true,
          title: "Error Moving Entity",
          body: `Could not move entity ${entityVersion.name}. Error message: ${e.message}`,
          canClose: true
        })
      }
    };

    showModal(
      {
        show: true,
        title: "Move Entity",
        body: `You are about to move this entity to the ${ge.name} game environment. Are you sure you want to do this?`,
        action: <Button variant="success" onClick={moveEntity}>Move Entity</Button>,
        canClose: true
      }
    );
  };

  const handleDeleteEntity = async () => {
    const deleteEntity = async () => {
      try {
        showModalSpinner("Deleting Entity", `Deleting entity ${entityVersion.name}....`);
        await rootStore.entityVersionStore.deleteEntity(entityVersion);
        hideSpinnerAndRedirect(rootStore.gameEnvironmentStore.getCurrentGameEnvironment());
      } catch (e: any) {
        showModal({
          show: true,
          title: "Error Deleting Entity",
          body: `Could not delete entity ${entityVersion.name}. Error message: ${e.message}`,
          canClose: true
        })
      }
    };

    showModal(
      {
        show: true,
        title: "Delete Entity",
        body: "You are about to DELETE this entity. This is a permanent action! Are you sure you want to delete this entity?",
        action: <Button variant="success" onClick={deleteEntity}>Delete Entity</Button>,
        canClose: true
      }
    );
  };

  const handleAddSpecificationProperty = async (specificationKind: IKindVersion, specificationProperty: IKindProperty) => {
    if (entityVersion.properties.find(p => p.kindPropertyKey === specificationProperty.key)) {
      showModal({
        show: true,
        title: "Error",
        body: `Cannot add this specification property to the entity because it has the same key as an existing property`,
        canClose: true
      });
      return;
    }

    entityVersion.addSpecificationProperty(specificationKind, specificationProperty);
  };

  const handleRemoveSpecificationProperty = async (property: IEntityProperty) => {
    entityVersion.removeProperty(property);
  };

  const handleCompareEntityVersion = async (diffGameVersion: IGameVersion, diffGameEnvironment : IGameEnvironment) => {
    const modalTitle = "Compare Entities";
    showModalSpinner(modalTitle, `Finding matching entity in ${diffGameVersion.name} (${diffGameEnvironment.name})...`);

    const matchedVersions = await rootStore
      .entityVersionStore
      .findAllCurrentEntityVersions({
        gameVersionId: diffGameVersion.id,
        gameEnvironmentId: diffGameEnvironment.id,
        name: entityVersion.name,
        kindName: entityVersion.kindVersionSummary.name
      });

    if (matchedVersions.length === 0) {
      showModal({
        show: true,
        title: modalTitle,
        body: `Could not find a matching entity in ${diffGameVersion.name} (${diffGameEnvironment.name}).`,
        canClose: true
      });
    } else {
      const entityVersionDiff = await rootStore.entityVersionStore.getDiffBetweenCurrentEntityVersions(entityVersion, matchedVersions[0]);

      if (entityVersionDiff)
        // Show Results
        showModal(
          {
            show: true,
            title: modalTitle,
            body: <>
              <span>
                <IconLeftDiff />
                <strong>{`${rootStore.gameEnvironmentStore.getCurrentGameEnvironment().name} (${rootStore.gameVersionStore.getCurrentGameVersion().name})`}</strong>
                <i className="fas fa-exchange-alt me-4 ms-4" />
                      {`${diffGameEnvironment.name} (${diffGameVersion.name})`}
                      <IconRightDiff />
              </span>
              <ModifiedEntityTable className={css.name} entityVersionDiff={entityVersionDiff} displayCheckbox={false}/>
              </>,
            canClose: true,
            size:"lg"
          }
        );
      else
        showModal({
            show: true,
            title: modalTitle,
            body: <div className="text-center">There are no differences between entities</div>,
            canClose: true,
          }
        );
    }
  }

  const handleGameVersionChange = async (currentEntityVersion: IEntityVersion, desiredGameVersion : IGameVersion) => {
    try {
      showModal(
        {
          show: true,
          title: "Searching Entities",
          body: <CustomSpinner spinnerText={`Searching for entity with the same name in game version ${desiredGameVersion.name}...`} position={'relative'}/>,
          canClose: false
        }
      );
      const entities = await rootStore.entityVersionStore.findAllCurrentEntityVersions({gameVersionId: desiredGameVersion.id, gameEnvironmentId: rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id, name: currentEntityVersion.name});

      hideModal();
      navigate(entities && entities.length > 0
        ? getEntityLink({gameVersionId: desiredGameVersion.id, categoryId: entities[0].kindVersionSummary.kindCategoryId, kindId: entities[0].kindVersionSummary.kindId, entityId: entities[0].entity.id})
        : getCategoriesLink({gameVersionId: desiredGameVersion.id}));
    }catch(e: any){
      showModal({
        show: true,
        title: "Error Searching Entities",
        body: `Error message: ${e.message}`,
        canClose: true
      })
    }
  }


  const controller: IEntityController = {
    entity: entityVersion,
    handleSaveEntity                  : handleSaveEntity,
    handlePublishEntity               : handlePublishEntity,
    handleStopEntity                  : handleStopEntity,
    handleArchiveEntity               : handleArchiveEntity,
    handleUnarchiveEntity             : handleUnarchiveEntity,
    handleDeleteEntity                : handleDeleteEntity,
    handleUpdateToCurrentKindVersion  : handleUpdateToCurrentKindVersion,
    handleCompareEntityVersion        : handleCompareEntityVersion,
    handleCopyEntityVersion           : handleCopyEntityVersion,
    handleMoveEntity                  : handleMoveEntity,
    handleAddSpecificationProperty    : handleAddSpecificationProperty,
    handleRemoveSpecificationProperty : handleRemoveSpecificationProperty,
    handleGameVersionChange
  };

  return controller;
}
