import React, { FC } from 'react';
import { createSliderWithTooltip, Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { RangeProps } from 'rc-slider/lib/Range';

const RangeWithToolTip = createSliderWithTooltip(Range);

export interface PropTypes {
  lowerBound: number,
  upperBound: number,
  displayValueMarks? : boolean
  displayMinMaxMarks? : boolean
  onRangeChange? : (lowerBound : number, upperBound: number) => void
  disabled? : boolean
}

export const UserRange : FC<PropTypes> = ({ lowerBound, upperBound , displayValueMarks, displayMinMaxMarks, onRangeChange,disabled= false}) => {

  const rangeProps : RangeProps = {}
  if(displayValueMarks)
    rangeProps.marks = {[lowerBound]: lowerBound, [upperBound]: upperBound}

  if(displayMinMaxMarks)
    rangeProps.marks = {0: 0, 1: 1, ...rangeProps.marks}

  return <RangeWithToolTip
    min={0}
    max={1}
    step={0.01}
    count={1}
    included={true}
    value={[lowerBound, upperBound]}
    marks={rangeProps.marks}
    tipFormatter={value => value}
    onChange={(value: number[]) => onRangeChange && onRangeChange(value[0], value[1])}
    disabled={disabled}
  />
}
