import {flow}                               from "mobx";
import {Instance, types, getRoot}           from "mobx-state-tree"
import {pub_api}                            from "../../index";
import type {ISelectionVm}                       from "@yakoffice/publisher-types";
import {RootStore}                          from "../../../model/RootStore";
import {ApiRequest}                         from "../ApiRequest";

export const EntitySelectionApiGateway = types.model(
    "EntitySelectionApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl() : string {
          const gameVersion = getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion();
          const gameEnvironment = getRoot<typeof RootStore>(self).gameEnvironmentStore.getCurrentGameEnvironment();
          return `${pub_api}/versions/${gameVersion.id}/entities/entity-selections?game-environment-id=${gameEnvironment.id}`;
        }
    }))
    .actions(self => ({
        getSelections   : flow(function* () {
            return yield self.apiRequest.get<ISelectionVm[]>(self.getRootUrl())
        })
    }));

export interface IEntitySelectionApiGateway extends Instance<typeof EntitySelectionApiGateway> {}
