import React, { FC, ReactElement, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ClaimType } from '@yakoffice/publisher-types';
import RootStoreContext from '../../../model/RootStoreContext';
import { Link } from 'react-router-dom';
import ToolTip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useExperimentsResult } from '../../../loaders/useExperiments';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { AbbreviatedString } from '../../components/abbreviated-string/AbbreviatedString';
import { FormatPropertyValue } from '../common/PropertyValueDisplay';
import { useGetEntityLink, useGetKindLink } from '../../routes';
import { YoPublisherTable, YoTableClassNames } from '../../components/yo-publisher-table/YoPublisherTable';
import { UserRange } from './UserRange';
import { TdFlexColumn } from '../../components/td-flex-column/TdFlexColumn';
import Accordion from 'react-bootstrap/cjs/Accordion';
import { SpecificationProperties } from '../common/SpecificationProperties';
import { StatusLabel } from '../../components/status-label/StatusLabel';

interface PropsType {
  experimentsResult: useExperimentsResult;
  cssClass: string;
  handleCreateExperiment: () => void;
  nameHeader: ReactElement | string;
  statusHeader: ReactElement | string;
}

export const ExperimentsTable: FC<PropsType> = observer((props) => {

  const rootStore = useContext(RootStoreContext);
  const getKindLink = useGetKindLink();
  const getEntityLink = useGetEntityLink();
  const [accoridonActiveKey, setAccordionActiveKey] = useState(0);

  return (
    <YoPublisherTable variant={props.cssClass} responsive className={`${YoTableClassNames.MinWidthContainer}, ${YoTableClassNames.AlignTop}`} data-testid='tblExperiments'>
      <thead className='table-dark'>
      <tr>
        <th className={YoTableClassNames.AlignTop}>{props.nameHeader}</th>
        <th>Experiment User Range</th>
        <th>Variants</th>
        <th>Kind</th>
        <th>Entity</th>
        <th>Property</th>
        <th>Control Value</th>
        <th>Variant Values</th>
        <th>Specifications</th>
        <th>{props.statusHeader}</th>
        <th />
      </tr>
      </thead>
      <tbody>
      {!props.experimentsResult.isLoaded &&
       <tr>
         <td colSpan={42}><CustomSpinner spinnerText='Loading Experiments...' /></td>
       </tr>
      }
      {props.experimentsResult.isLoaded && props.experimentsResult.experiments && props.experimentsResult.experiments.length === 0 &&
       <tr>
         <td colSpan={42} className='text-center'>No Experiments found for this Game Version and Environment with current filters</td>
       </tr>
      }
      {props.experimentsResult.isLoaded && props.experimentsResult.experiments && props.experimentsResult.experiments.length > 0 &&
       props.experimentsResult.experiments.map(experiment => (
         <tr key={experiment.id} className={`${experiment.status}`} data-testid={experiment.name}>
           <td data-testid='tdExperimentName'>
             {rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditExperiments)
              ? <span className='text-info cursor-pointer'>
                                <Link className='w-75 d-inline-block' to={experiment.id.toString()}>{experiment.name}</Link>
                              </span>
              : experiment.name}
             <OverlayTrigger placement='right' overlay={<ToolTip id='button-tooltip'>{experiment.description}</ToolTip>}>
               <span className='font-italic float-end d-inline-block'>  <i className={`fa fa-info-circle`} /></span>
             </OverlayTrigger>
           </td>
           <td style={{ height: '4rem' }} data-testid='tdExperimentBounds'>
             <UserRange lowerBound={experiment.lowerBound} upperBound={experiment.upperBound} displayValueMarks={true} disabled={true} />
           </td>
           <td style={{ height: '4rem' }} data-testid='tdVariants'>
             {/*<div className="h-100 d-flex flex-column justify-content-between">*/}
             {experiment.variants.slice().sort((v1, v2) => v1.id - v2.id).map(variant => <div key={variant.id}>{variant.name}@{variant.allocation}</div>)}
             {/*</div>*/}
           </td>
           <TdFlexColumn flexColClassName='justify-content-around align-items-left'>
             {experiment.properties.map(p =>
                                          <div key={p.id}>
                                            <Link className='text-info' to={getKindLink({ categoryId: p.kindCategoryId, kindId: p.kindId })}>
                                              {p.kindName}
                                            </Link>
                                          </div>
             )}
           </TdFlexColumn>
           <TdFlexColumn flexColClassName='justify-content-around align-items-left'>
             {experiment.properties.map(p =>
                                          <div key={p.id}>
                                            <Link className='text-info' to={getEntityLink({ categoryId: p.kindCategoryId, kindId: p.kindId, entityId: p.entityId })}>
                                              {p.entityName}
                                            </Link>
                                          </div>
             )}
           </TdFlexColumn>
           <TdFlexColumn flexColClassName='justify-content-around align-items-left'>
             {experiment.properties.map(p =>
                                          <div key={p.id}>
                                            {p.kindPropertyKey}
                                          </div>)}
           </TdFlexColumn>
           <TdFlexColumn flexColClassName='justify-content-around align-items-left'>
             {experiment.properties.map(p =>
                                          <div key={p.id}>
                                            <AbbreviatedString value={`${FormatPropertyValue(rootStore, p.kindPropertyType, p.kindPropertySelectionId, p.kindPropertyEntitySelectionKindId, p.entityPropertyValue)}`} />
                                          </div>
             )}
           </TdFlexColumn>
           <TdFlexColumn flexColClassName='align-items-left'>

             <Accordion activeKey={accoridonActiveKey.toString()}>
               <div onClick={() => setAccordionActiveKey(experiment.id === accoridonActiveKey ? 0 : experiment.id)} className="text-info">
                 {experiment.id === accoridonActiveKey ?
                  <div><i className='fas fa-caret-up' /> Hide</div>
                                                       :
                  <div><i className='fas fa-caret-down' /> Show</div>
                 }
               </div>
               <Accordion.Collapse eventKey={experiment.id.toString()}>
                 <div>
                   {experiment.properties.map(p =>
                                                <div key={p.id} className='border-bottom mb-1'>
                                                  {p.values.slice().sort((v1, v2) => v1.variantId - v2.variantId).map(variantValue =>
                                                                                                                        <div key={variantValue.id}>
                                                                                                                          <strong>{experiment.variants.find(v => v.id === variantValue.variantId)?.name}: <AbbreviatedString value={`${FormatPropertyValue(rootStore, p.kindPropertyType, p.kindPropertySelectionId, p.kindPropertyEntitySelectionKindId, variantValue.value)}`} /></strong>
                                                                                                                        </div>
                                                  )}
                                                </div>
                   )}
                 </div>
               </Accordion.Collapse>
             </Accordion>

           </TdFlexColumn>
           <td>
             <SpecificationProperties entityProperties={experiment.specifications} specificationKindVersions={rootStore.specificationsForExperimentsStore.getKinds()} />
           </td>
           <td><StatusLabel status={experiment.status} /></td>
           <td>
             {experiment.inLatestDistribution &&
              <OverlayTrigger key={`overlay-latest`} placement='right' overlay={<ToolTip id='button-tooltip'>In latest distribution</ToolTip>}>
                <i className='fas fa-check-circle text-success' />
              </OverlayTrigger>

             }
             {experiment.inAnyDistribution &&
              <OverlayTrigger key={`overlay-any`} placement='right' overlay={<ToolTip id='button-tooltip'>Has been distributed</ToolTip>}>
                <i className='fas fa-check-circle text-warning' />
              </OverlayTrigger>
             }
           </td>
         </tr>
       ))
      }
      </tbody>
    </YoPublisherTable>
  );
});
