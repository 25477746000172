import React, {useContext}                          from 'react';
import { useNavigate }                               from 'react-router-dom';
import {useShowModal}                               from '@yakoffice/custom-modal';
import {CustomSpinner}                              from '@yakoffice/custom-spinner';
import RootStoreContext                             from '../model/RootStoreContext';
import { useGetCategoriesLink, useGetKindsLink } from '../presentation/routes';
import { IGameVersion } from '../model/gameVersion/GameVersion';
import { useHideModal } from '@yakoffice/custom-modal';
import { ICategory } from '../model/category/Category';


export interface IKindsController{
    handleGameVersionChange : (currentCategory: ICategory, desiredGameVersion : IGameVersion) => void
}


export const useKindsController = () => {

    // Context Hooks
    const showModal           = useShowModal();
    const hideModal           = useHideModal();
    const rootStore           = useContext(RootStoreContext);
    const navigate            = useNavigate();
    const getKindsLink        = useGetKindsLink();
    const getCategoriesLink   = useGetCategoriesLink();


    const handleGameVersionChange = async (currentCategory: ICategory, desiredGameVersion : IGameVersion) => {
      try {
        showModal(
          {
            show: true,
            title: "Searching Categories",
            body: <CustomSpinner spinnerText={`Searching for category with the same name in game version ${desiredGameVersion.name}...`} position={'relative'}/>,
            canClose: false
          }
        );
        const categories = await rootStore.categoryStore.findCategories({name: currentCategory.name, gameVersionId: desiredGameVersion.id})

        hideModal();
        navigate(categories && categories.length > 0
          ? getKindsLink({gameVersionId: desiredGameVersion.id, categoryId:categories[0].id})
          : getCategoriesLink({gameVersionId: desiredGameVersion.id}));
      }catch(e:any){
        showModal({
          show: true,
          title: "Error Searching Categories",
          body: `Error message: ${e.message}`,
          canClose: true
        })
      }
    }

  const controller: IKindsController = {
        handleGameVersionChange
    };

    return controller;
}
