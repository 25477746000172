import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";
import {KindPropertyType} from "@yakoffice/publisher-types";
import { VariantValue } from './VariantValue';
import { IVariant } from './Variant';

export const ExperimentProperty = types.model(
    "ExperimentProperty",
    {
            id: types.optional(types.identifierNumber, GenerateId),
            values: types.array(VariantValue),
            kindCategoryId: 0,
            kindId: 0,
            kindName: "",
            entityName: "",
            entityId: 0,
            entityVersion: 0,
            entityPropertyId: 0,
            entityPropertyValue: types.maybeNull(types.string),
            kindPropertyKindId: 0,
            kindPropertyId: 0,
            kindPropertyKey: "",
            kindPropertyType: types.optional(types.enumeration<KindPropertyType>("type", Object.values(KindPropertyType)), KindPropertyType.Boolean),
            kindPropertySelectionId: types.maybeNull(types.number),
            kindPropertyEntitySelectionKindId: types.maybeNull(types.number),
            isNew: false

    })
    .views(self => ({
            hasValidKindId(){
                return self.kindId > 0;
            },
            hasValidEntityId(){
                return self.entityId > 0;
            },
            hasValidKindPropertyId() {
                return self.kindPropertyId > 0;
            },
            hasValidEntityPropertyId(){
                return self.entityPropertyId > 0;
            },
            isSpecificationProperty(){
              return self.kindPropertyKindId !== self.kindId;
            },
            displayString(){
                    return `${self.kindName || "???"} / ${self.entityName || "?"} / ${self.kindPropertyKey || "?"}`
            }
    }))
    .actions(self => ({
        clearEntity(){
            self.entityId = 0;
            self.entityName = "";
        },
        clearProperty(){
            self.kindPropertyKindId = 0;
            self.kindPropertyId = 0;
            self.kindPropertyKey = "";
            self.entityPropertyId = 0;
        },
        clearValues(){
            self.values.forEach(v => v.value = null);
        },
        addVariantValue(variant: IVariant){
          self.values.push(VariantValue.create({variantId: variant.id}))
        },
        removeVariantValue(variant: IVariant){
          const value = self.values.find(e => e.variantId === variant.id);
          if(value)
            self.values.remove(value)
        }
    }))
    .actions(self => ({
            setKindDetails(id: number, name: string){
                self.kindId = id;
                self.kindName = name;
                self.clearEntity();
                self.clearProperty();
                self.clearValues();
            },
            setEntityDetails(id: number, name: string){
                self.entityId = id;
                self.entityName = name;
                self.clearProperty();
                self.clearValues();
            },
            setPropertyDetails(kindPropertyKindId: number, kindPropertyId: number, kindPropertyKey: string, entityId: number, value: string | null) {
                self.kindPropertyKindId = kindPropertyKindId;
                self.kindPropertyId = kindPropertyId;
                self.kindPropertyKey = kindPropertyKey;
                self.entityPropertyId = entityId;
                self.entityPropertyValue = value;
                self.values.forEach(v => v.setValue(value));
            }
    }))

export interface IExperimentProperty extends Instance<typeof ExperimentProperty> {}
