import React, {FC} from "react";
import moment from "moment";



export const formatISODateStringAsLocal = (dateString: string | null) => {
  if(dateString == null)
    return undefined;

  return !moment(dateString, "Z").isValid() ? undefined : moment(dateString).format('D MMM YYYY, HH:mm')
}

export const formatISODateString = (dateString: string | null) => {

    if(dateString == null || dateString.startsWith("-")) //catches negative values that were being interpreted as date strings
        return undefined;

    return !moment(dateString, "Z").isValid() ? undefined : moment(dateString.substring(0, dateString.length-1)).format('D MMM YYYY, HH:mm')
}

interface FormattedErrorMessageProps {
    errorMessage: string
}

// This is to handle validation errors returned from our API.  The validation framework comma separates the validation errors
export const FormattedErrorMessage : FC<FormattedErrorMessageProps> = ({errorMessage}) => {
    return (
        <ul>
            {
                errorMessage.split(",").map((m, index) => {
                    return <li key={index}> {m} </li>
                })
            }
        </ul>
    )
}
