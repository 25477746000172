import {detach, flow, Instance, types} from "mobx-state-tree";
import type {ICategoryDto}                       from "@yakoffice/publisher-types";
import type {ICategoryVm}                        from "@yakoffice/publisher-types";
import {Category, ICategory}                from "./Category";
import { CategoryApiGateway, ICategorySearchParams } from '../../api/requests/categories/CategoryApiGateway';
import { formatError } from '../Utils';
import { createIdFromIdAndVersion } from '../kind/KindVersionStore';


export const CategoryStore = types.model(
    "CategoryStore",
    {
        apiGateway: CategoryApiGateway,
        categories: types.array(Category),
        currentCategory: types.maybeNull(types.reference(Category)),
        isLoading: false,
    })
    .views(self => ({
        getCurrentCategory() : ICategory {
            if(self.currentCategory)
                return self.currentCategory;
            throw new Error("The current category has not been set")
        },
        getSubCategories(): ICategory[] {

          const childCategories: ICategory[] = [];

          self.categories.forEach(category => {
            if(self.currentCategory)
              if(category.parent)
                if(category.parent.id === self.currentCategory.id)
                  childCategories.push(category)
          })
          return childCategories
        },
    }))
    .actions(self => ({
      clearCurrentCategory() {
        self.currentCategory = null;
      },
      clearCategories() {
        self.categories.clear();
      },
      setCurrentCategory(category: ICategory) {
        self.currentCategory = category;
      },
      addCategory(parentCategoryId?: number) {

        const category = Category.create({ parent: parentCategoryId });

        self.categories.push(category);

        return category as ICategory;
      },
      removeFromStore(category: ICategory){
        detach(category);
      },
      loadCategories: flow(function* () {
        self.isLoading = true;

        // To prevent an invalid reference
        self.currentCategory = null;

        try {
          const categoryVms = yield self.apiGateway.getCategories({});

          const categories = categoryVms.map((vm: ICategoryVm) => Category.create(MapToCategoryModel(vm)));
          // NB.  Detach stale ones to prevent errors if they're still used (ie. by categoriesNav)
          self.categories.forEach(c => detach(c));
          self.categories.replace(categories);
          self.isLoading = false;
        } catch (e: any) {
          throw new Error(`Failed to load categories: ${e.message}`);
        }
      }),
      getCategory: flow(function* (categoryId: number) {

        self.isLoading = true;

        try {
          const categoryVm = yield self.apiGateway.getCategory(categoryId);
          const category = Category.create(MapToCategoryModel(categoryVm));

          const existing = self.categories.find(c => c.id === category.id);
          if (existing) {
            detach(existing)
          }
          self.categories.push(category);

          self.isLoading = false;
          return category as ICategory;
        } catch (e:any) {
          throw new Error(`Failed to load category: ${e.message}`);
        }
      }),
      findCategories: flow(function* (searchParams: ICategorySearchParams) {
        try {
          const categoryVms = yield self.apiGateway.getCategories(searchParams);
          return categoryVms.map((vm: ICategoryVm) => Category.create(MapToCategoryModel(vm)));
        } catch (e:any) {
          throw new Error(`Failed to findcategories: ${e.message}`);
        }
      }),
      saveCategory: flow(function* (category: ICategory) {

        try {
          const dto = MapToDto(category);

          if (category.isNewCategory())
            yield self.apiGateway.saveCategory(dto);
          else
            yield self.apiGateway.updateCategory(category.id, dto)

        } catch (e:any) {
          throw formatError(e);
        }
      }),
      deleteCategory: flow(function* (category: ICategory) {

        try {
          yield self.apiGateway.deleteCategory(category.id)
        } catch (e: any) {
          throw formatError(e);
        }
      }),
      archiveCategory: flow(function* (category: ICategory) {

        try {
          yield self.apiGateway.archiveCategory(category.id)
        } catch (e:any) {
          throw formatError(e);
        }
      }),
      unArchiveCategory: flow(function* (category: ICategory) {

        try {
          yield self.apiGateway.unArchiveCategory(category.id)
        } catch (e: any) {
          throw formatError(e);
        }
      }),
    }));

const MapToCategoryModel = (vm: ICategoryVm) => {

    return {
        id: vm.id,
        parent: vm.parent?.id,
        name: vm.name,
        description: vm.description,
        versionIdCur: vm.versionIdCur,
        versionIdPrev: vm.versionIdPrev,
        createdAt: vm.createdAt,
        originalAuthor: vm.originalAuthor,
        updatedAt: vm.updatedAt,
        lastAuthor: vm.lastAuthor,
        lastActionId: vm.lastActionId,
        lastActionName: vm.lastActionName,
        lastComment: vm.lastComment,
        status: vm.status,
        kinds: vm.kinds.map(k => ({...k, id: createIdFromIdAndVersion(k.kindId, k.version)}))
    }
};

// NB.  This isn't really required because Kind has all KindDto properties but it prevents sending any unnecessary data
// and adds some type checking
const MapToDto = (category: any): ICategoryDto => {

    return {
        name: category.name,
        description: category.description,
        comment: category.comment,
        status: category.status,
        parentId: category.parent?.id,
    }
}

export interface ICategoryStore extends Instance<typeof CategoryStore> {}
