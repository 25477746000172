import React, {FC, useContext}  from 'react';
import {observer}               from 'mobx-react-lite';
import { CSVLink }              from "react-csv";
import {Data, Headers}          from 'react-csv/components/CommonPropTypes';
import RootStoreContext         from '../../../model/RootStoreContext';
import { IEntityVersion }       from '../../../model/entity/EntityVersion';
import {camelToTitle}           from '../../../utilities';
import {ICheckedEntity}         from './Entities';


interface ICSVData {
  [key: string] : string | null;
  name          : string;
  description   : string;
}


interface PropsType {
  checkedEntities : ICheckedEntity[];
  statusStore     : {statusFilters: string[]}
}


export const EntitiesCsv : FC<PropsType> = observer((props) => {

  const rootStore = useContext(RootStoreContext);

  const headers: Headers = [
    // {label: "ID" , key: "id"},
    {label: "Name", key: "name"},
    {label: "Description", key: "description"},
    // {label: "Created At", key: "createdAt"},
    // {label: "Comment", key: "comment"},
    // {label: "Game Environment Id", key: "gameEnvironmentId"},
    // {label: "In Latest Distribution?", key: "inLatestDistribution"},
    // {label: "In Any Distribution?", key: "inAnyDistribution"},
    // {label: "Kind Id", key: "kindId"},
    // {label: "Kind Version", key: "kindVersion"},
    // {label: "Kind Category Id", key: "kindCategoryId"},
    // {label: "Kind Name", key: "kindName"},
    // {label: "Last Action Id", key: "lastActionId"},
    // {label: "Last Action Name", key: "lastActionName"},
    // {label: "Last Author", key: "lastAuthor"},
    // {label: "Last Comment", key: "lastComment"},
    // {label: "Original Author", key: "originalAuthor"},
    // {label: "Status", key: "status"},
    // {label: "Updated At", key: "updatedAt"},
    // {label: "Current Version Id", key: "versionIdCur"},
    // {label: "Previous Version Id", key: "versionIdPrev"},
    // {label: "Version Id Max", key: "versionIdMax"},
    // {label: "Requires Updating", key: "requiresUpdating"},
  ];

  rootStore.kindVersionStore.getCurrentKindVersion().properties.forEach(kindProperty => {
    headers.push({
      label: camelToTitle(kindProperty.key), key: kindProperty.key
    })
  })

  const data: Data = [];

  const pushData = (e: IEntityVersion) => {

    const _data: ICSVData =
      {
        // id: e.id,
        name: e.name,
        description: e.description,
        // createdAt: e.createdAt,
        // comment: e.comment,
        // gameEnvironmentId: e.gameEnvironmentId,
        // inLatestDistribution: e.inLatestDistribution,
        // inAnyDistribution: e.inAnyDistribution,
        // kindId: e.kindId,
        // kindVersion: e.kindVersion,
        // kindCategoryId: e.kindCategoryId,
        // kindName: e.kindName,
        // lastActionId: e.lastActionId,
        // lastActionName: e.lastActionName,
        // lastAuthor: e.lastAuthor,
        // lastComment: e.lastComment,
        // originalAuthor: e.originalAuthor,
        // status: e.status,
        // updatedAt: e.updatedAt,
        // versionIdCur: e.versionIdCur,
        // versionIdPrev: e.versionIdPrev,
        // versionIdMax: e.versionIdMax,
        // requiresUpdating: e.requiresUpdating,
      }

    e.properties.map(property => {
      return _data[property.kindPropertyKey] = property.value
    })

    data.push(_data);
  }

  // if any entities are checked then use those entities
  if(props.checkedEntities.some(checkedEntity => checkedEntity.checked)) {

    //if we have checked entities, check that their status is present in the status store
    props.checkedEntities
      .filter(checkedEntity => checkedEntity.checked)
      .filter(checkedEntity => props.statusStore.statusFilters.includes(checkedEntity.entityVersion.entity.status))
      .forEach(checkedEntity => {
        pushData(checkedEntity.entityVersion);
      })
  }
  //else use only entities that have status' matching those present in the statusStore
  else {
    props.checkedEntities
      .filter(checkedEntity => props.statusStore.statusFilters.includes(checkedEntity.entityVersion.entity.status))
      .forEach(checkedEntity => {
        pushData(checkedEntity.entityVersion);
      })
  }

  return <CSVLink
            data={data}
            headers={headers}
            className="btn btn-outline-primary"
            filename={`${rootStore.kindVersionStore.getCurrentKindVersion().name}.csv`}>
              <i className="fas fa-file-download" /> Download as .csv
          </CSVLink>

})
