import React, { FC } from 'react';
import { ListGroup, ListGroupItemProps, ListGroupProps } from 'react-bootstrap';
import styles from './YoListGroup.module.sass';

export const YoListGroup : FC<ListGroupProps> = ({className, ...props}) => {
  return <ListGroup {...props} className={`${styles.listGroup} ${className ?? ""}`}>{props.children}</ListGroup>
}

export const YoListGroupItem : FC<ListGroupItemProps> = ({className, ...props}) => {

  return <ListGroup.Item {...props} className={`${styles.listGroupItem} ${className ?? ""}`}>{props.children}</ListGroup.Item>
}
