import {CustomSpinner} from '@yakoffice/custom-spinner';
import * as React from "react";
import {FC, PropsWithChildren} from "react";
import {NoAccess} from "@yakoffice/shared-components";
import {usePublisherAuthorisedUser} from "./usePublisherAuthorisedUser";

export const WithPublisherAuthorisation : FC<PropsWithChildren> = (props) => {

  const authUserResult = usePublisherAuthorisedUser();

  if (!authUserResult.hasAuthorised)
    return <CustomSpinner spinnerText="Getting user permissions..."/>

  if (!authUserResult.authorisedUser)
    return <NoAccess/>

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{props.children}</>
}
