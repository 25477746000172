import React, { FC, useContext, useEffect, useState } from 'react';
import {observer}                           from 'mobx-react-lite';
import Container                            from 'react-bootstrap/Container';
import Row                                  from 'react-bootstrap/Row';
import Col                                  from 'react-bootstrap/Col';
import RootStoreContext                     from "../../../model/RootStoreContext";
import {ViewHeader}                         from '@yakoffice/view-header';
import {ClaimType}                          from "@yakoffice/publisher-types";
import {CategoryCard}                       from "./CategoryCard";
import {FilterButton}                       from "../common/FilterButton";
import {AddCategoryButton}                  from './AddCategoryButton';
import { GameVersionSelector }              from '../common/GameVersionSelector';
import { sortByString }                     from '@yakoffice/yakoffice-firebase';


export const Categories : FC = observer(() => {

    const rootStore   = useContext(RootStoreContext)
    const [filterArchived, setFilterArchived] = useState(true);

    useEffect(() => rootStore.categoryStore.clearCurrentCategory())

    return <div id="categories">
        <Container>
            <ViewHeader
              title="All Categories"
              customAction={<GameVersionSelector />}
            />
        </Container>

        <Container>

          <Row className="mb-5">
            <Col>
              {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory) &&
              <AddCategoryButton />
              }
            </Col>
            <Col className="text-end">
              <FilterButton filterText="Archived"
                               filterOn={filterArchived}
                               numberOfFilteredItems={rootStore.categoryStore.categories.filter(c => (c.status === "archived" && !c.parent)).length}
                               onClick={() => setFilterArchived(!filterArchived)} /></Col>
          </Row>
          <Row>
              {rootStore.categoryStore.categories.length === 0
                 ? <Col><div>No categories found</div></Col>
                 : rootStore.categoryStore.categories
                   .filter(category => category.parent == null)
                   .filter(category => !filterArchived || category.status !== "archived")
                   .sort(sortByString("name"))
                   .map((category) => {

                      return <Col xl="2" lg="4" md="6" sm="6" xs="12" key={category.id} className="mb-3">
                          <CategoryCard category={category} />
                      </Col>
                  })
              }
          </Row>
        </Container>
    </div>
})

export default Categories;
