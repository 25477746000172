import React, {FC} from 'react';
import {Link}               from 'react-router-dom';
import {PubRouteItems} from '../../routes';
import {joinRoute} from "@yakoffice/shared-components";

interface PropsType {
  isSubCategory?: boolean;
}

export const AddCategoryButton: FC<PropsType> = (props) => {

  const link = props.isSubCategory
    ? joinRoute("..",PubRouteItems.AddSubCategory)
    : PubRouteItems.AddCategory;

  return (
     <Link to={link} className="btn btn-primary me-1">
       <i className="fas fa-folder-plus text-white me-1"/>{props.isSubCategory ? "New Sub-Category" : "New Category"}
     </Link>
  )
}
