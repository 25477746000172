import {useContext, useEffect, useState}          from "react";
import RootStoreContext                           from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {IKindVersion}                                    from '../model/kind/KindVersion';
import type {IKindVersionSearchParams}                        from '../api/requests/kinds/kindVersionApiGateway';


interface useFindKindsResult{
  isLoaded  : boolean;
  kinds     : IKindVersion[]
}


export const  useFindKinds = (searchParams: IKindVersionSearchParams) => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useFindKindsResult>(() => ({isLoaded: false, kinds: []}));

  useEffect(() => {
    setResult({isLoaded: false, kinds: []})
    rootStore.kindVersionStore.findAllCurrentKindVersions(searchParams)
      .then((kinds) => { setResult({isLoaded: true, kinds: kinds})})
      .catch(e => publishNotification({
                                        notificationType: NotificationType.Error,
                                        title: "Error finding kinds",
                                        message: `${e.message}`,
                                        footer: "Please try refreshing"
                                      }))
  }, [rootStore.kindVersionStore, publishNotification, searchParams]);

  return result;
};
