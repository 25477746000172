import { useEffect} from 'react';
import { useParams } from "react-router-dom";
import { useRootStore } from '../../../loaders/useRootStore';

export const useSetGameVersionFromParam = () => {

  const rootStore = useRootStore();
  const { gameVersionId } = useParams() as { gameVersionId: string };

  useEffect(() => {
    const gameVersionIdAsInt = parseInt(gameVersionId);
    const currentGameVersion = isNaN(gameVersionIdAsInt)
      // NB.  assume versionId is "current" from portal redirection so find first
      ? rootStore.gameVersionStore.gameVersions[0]
      // NB.  versionId has been set
      : rootStore.gameVersionStore.gameVersions.find(gv => gv.id === gameVersionIdAsInt)

    if (currentGameVersion)
      rootStore.gameVersionStore.setCurrentGameVersion(currentGameVersion.id);

  }, [rootStore.gameVersionStore, gameVersionId])
};
