import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../../../model/RootStoreContext";
import {ISelection} from '../../../model/selections/Selection';
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import { useParams } from 'react-router-dom';

interface useSelectionResult{
    isLoaded  : boolean;
    selection : ISelection | null;
}

export const useSelection = (refreshFlag: boolean) => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const {selectionId} = useParams<{selectionId: string}>()

  const [result, setResult] = useState<useSelectionResult>({
    isLoaded: false,
    selection: null,
  })

  useEffect(() => {
    setResult({isLoaded: false, selection: null})

    if (selectionId) {
      rootStore.selectionStore.getSelection(parseInt(selectionId))
        .then((selection) => {
          rootStore.selectionStore.setCurrentSelection(selection.id);
          setResult({
            isLoaded: true,
            selection: selection
          })
        })
        .catch(e => publishNotification({
          notificationType: NotificationType.Error,
          title: "Error loading selection",
          message: `${e.message}`,
          footer: "Please try refreshing"
        }))
    } else {
      setResult({
        isLoaded: true,
        selection: rootStore.selectionStore.addSelection()
      })
    }
    return () => {
      rootStore.selectionStore.clearCurrentSelection();
    }
  }, [rootStore.selectionStore, publishNotification, selectionId, refreshFlag]);

  return result;
};
