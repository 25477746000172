import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import type {IKindVersion} from "../model/kind/KindVersion";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";

interface useKindVersionsResult{
    isLoaded : boolean;
    kindVersions : IKindVersion[]
}

export const useKindVersions = () => {

    const rootStore = useContext(RootStoreContext)
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useKindVersionsResult>({ isLoaded: false, kindVersions: [] })

    useEffect(() => {
            // TODO: This check shouldn't be required but without it it would faile when you navigate from an entities list
            // to a different category
            if (rootStore.categoryStore.currentCategory != null) {
              setResult({ isLoaded: false, kindVersions: [] })
                rootStore.kindVersionStore.loadCurrentKindVersionsForCurrentCategory()
                    .then(() => {
                      setResult({ isLoaded: true, kindVersions: rootStore.kindVersionStore.kindVersions })
                    })
                    .catch((e: any) => {
                        publishNotification({
                            notificationType: NotificationType.Error,
                            title: "Error loading kind versions",
                            message: `${e.message}`,
                            footer: "Please try refreshing"
                        })
                    })
            }
    }, [rootStore.categoryStore, rootStore.kindVersionStore, publishNotification, rootStore.categoryStore.currentCategory]);

    return result;
}
