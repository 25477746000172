import { useEffect, useState } from 'react';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useRootStore } from './useRootStore';
import { IProject } from '../model/project/Project';

interface useProjectsResult{
    isLoaded            : boolean;
    projects    : IProject[] | null
}

export const useProjects = () => {

  const rootStore = useRootStore();
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useProjectsResult>({ isLoaded: false, projects: [] })

  useEffect(() => {
    setResult({ isLoaded: false, projects: [] })

    rootStore.projectStore.loadProjects()
      .then(() => {
        setResult({ isLoaded: true, projects: rootStore.projectStore.projects })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading projects",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.projectStore, publishNotification]);

  return result;
};
