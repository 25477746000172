import React, { useContext, useEffect, useState } from 'react';
import {Link, useNavigate}                         from 'react-router-dom';
import { runInAction }                            from 'mobx';
import { observer }                               from 'mobx-react-lite';
import { useLocalObservable }                     from 'mobx-react-lite';
import {CustomSpinner}                            from '@yakoffice/custom-spinner';
import {ViewHeader}                               from '@yakoffice/view-header';
import {ClaimType, EntityStatus}                  from '@yakoffice/publisher-types';
import Container                                  from 'react-bootstrap/Container';
import RootStoreContext                           from '../../../model/RootStoreContext';
import type {IGameEnvironment}                         from '../../../model/gameEnvironment/GameEnvironment';
import type {IEntityVersion}                           from "../../../model/entity/EntityVersion";
import { IGameVersion }                           from '../../../model/gameVersion/GameVersion';
import {useEntitiesController}                    from '../../../controllers/useEntitiesController';
import {useEntityVersions}                        from '../../../loaders/useEntityVersions';
import { useGoogleSheetExists }                   from '../../../loaders/useGoogleSheetExists';
import {YoSubNav}                                 from '../../components/yo-sub-nav/YoSubNav';
import {GameEnvironmentSelector}                  from '../common/GameEnvironmentSelector';
import {useGameEnvironmentCssClass}               from '../common/useGameEnvironmentCssClass';
import { GameVersionSelector }                    from '../common/GameVersionSelector';
import {EntitiesNav}                              from './EntitiesNav';
import {EntitiesTable}                            from './EntitiesTable';
import {  useGetCategoriesLink,
          useGetCategoryLink,
          useGetEntitiesLink,
          useGetKindLink,
          useGetKindsLink }                       from '../../routes';


export interface ICheckedEntity {
    entityVersion : IEntityVersion;
    checked       : boolean;
}


export const Entities = observer(() => {

  const rootStore               = useContext(RootStoreContext);
  const cssClass                = useGameEnvironmentCssClass();
  const [ refreshToggle,
          setRefreshToggle]     = useState(true);
  const loadEntitiesResult      = useEntityVersions(refreshToggle)
  const googleSheetExistsResult = useGoogleSheetExists()
  const controller              = useEntitiesController();
  const navigate                 = useNavigate();
  const getCategoriesLink       = useGetCategoriesLink()
  const getCategoryLink         = useGetCategoryLink()
  const getKindsLink            = useGetKindsLink()
  const getKindLink             = useGetKindLink()
  const getEntitiesLink         = useGetEntitiesLink()


  const localStore  = useLocalObservable(() => ({ checkedEntities: [] as ICheckedEntity[] }));

  const statusStore = useLocalObservable(() => ({
    statusFilters: [EntityStatus.Draft, EntityStatus.Stopped, EntityStatus.Published] as string[],
  }))


  useEffect(() => {
    runInAction(() => localStore.checkedEntities = loadEntitiesResult.entityVersions.map(entity => ({
      entityVersion: entity,
      checked: false
    })));
  }, [loadEntitiesResult.entityVersions, localStore])

  const handleCopyEntities = (targetGameVersions: IGameVersion[], targetGameEnvironments: IGameEnvironment[]) => {
    controller.handleCopyEntities(localStore.checkedEntities.filter(entity => entity.checked).map(_entity => _entity.entityVersion), targetGameVersions, targetGameEnvironments)
  }

  const handleDownloadAsJson = () => {

    const checkedEntities = localStore.checkedEntities.filter(entity => entity.checked);

    const entitiesToDownload = (checkedEntities.length > 0 ? checkedEntities : localStore.checkedEntities).map(_entity => _entity.entityVersion.entity.id);

    controller.handleDownloadAsJson(entitiesToDownload);
  }

  const handleBatchStatusChange = async (status: EntityStatus) => {
    controller.batchStatusUpdate(
      localStore.checkedEntities.filter(entity => entity.checked).map(_entity => _entity.entityVersion),
      status,
      () => setRefreshToggle(!refreshToggle));
  }

  const handleBatchUpdate = () => {
    controller.batchUpdate(
      localStore.checkedEntities.filter(entity => entity.checked).map(_entity => _entity.entityVersion),
      () => setRefreshToggle(!refreshToggle));
  }

  const handleCreateSheet = () => {
    controller.handleCreateSheet(statusStore.statusFilters);
  }

  const handleRefreshSheet = () => {
    controller.handleRefreshSheet(statusStore.statusFilters);
  }
  const handleUploadSheet = () => {
    controller.handleUploadSheet(() => setRefreshToggle(!refreshToggle));
  }

  const onGameVersionChanged = (targetGv: IGameVersion) => controller.handleGameVersionChange(rootStore.kindVersionStore.getCurrentKindVersion(), targetGv)

  const onGameEnvironmentChanged = (targetGe: IGameEnvironment) => navigate(getEntitiesLink({ gameEnvironmentId: targetGe.id }))


  return (
    rootStore.kindVersionStore.currentKindVersion &&
    <div id="entities" className={cssClass.name}>
      {!loadEntitiesResult.isLoaded || !googleSheetExistsResult.isLoaded
        ?<CustomSpinner spinnerText="Loading Entities..." />
        :<React.Fragment>
          <Container>
            <ViewHeader title={`${rootStore.kindVersionStore.currentKindVersion.name} - Entities`}
                        customAction={
                          <>
                            <GameVersionSelector handleGameVersionChange={onGameVersionChanged} data-testid="ddlGameVersion" />
                            <GameEnvironmentSelector handleGameEnvironmentChange={onGameEnvironmentChanged} data-testid="ddlGameEnvironment" />
                          </>
                        }
                        breadcrumbs={
                          <span>
                              <Link to={getCategoriesLink()}>Categories</Link>
                            {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory)
                              ? <Link to={getCategoryLink()}>{rootStore.categoryStore.getCurrentCategory().name}</Link>
                              : <span>{rootStore.categoryStore.getCurrentCategory().name} / </span>
                            }
                            <Link to={getKindsLink()}>Kinds</Link>
                            {rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind)
                              ?
                              <Link to={getKindLink()}>{rootStore.kindVersionStore.getCurrentKindVersion().name}</Link>
                              : <span>{rootStore.kindVersionStore.getCurrentKindVersion().name} / </span>
                            }
                                         </span>
                        }
            />
          </Container>
          <Container>
            <YoSubNav expand="lg">
              <EntitiesNav
                googleSheetExistsResult={googleSheetExistsResult}
                handleBatchCopy={handleCopyEntities}
                handleBatchStatusUpdate={handleBatchStatusChange}
                handleBatchUpdate={handleBatchUpdate}
                handleCreateSheet={handleCreateSheet}
                handleViewSheet={controller.handleViewSheet}
                handleUploadSheet={handleUploadSheet}
                handleRefreshSheet={handleRefreshSheet}
                handleDeleteSheet={controller.handleDeleteSheet}
                handleDownloadAsJson={handleDownloadAsJson}
                checkedEntityVersions={localStore.checkedEntities}
                statusStore={statusStore}
                singleEntityView={localStore.checkedEntities.filter(e => statusStore.statusFilters.includes(e.entityVersion.entity.status)).length < 2}
              />
            </YoSubNav>
          </Container>
          <Container fluid>
            {<EntitiesTable
                checkedEntityVersions={localStore.checkedEntities.filter(e => statusStore.statusFilters.includes(e.entityVersion.entity.status))}
                cssColorClass={cssClass.name} statusStore={statusStore} />
            }
          </Container>
        </React.Fragment>
      }
    </div>
  )
})
