import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {useSpecificationsForExperiments} from "../../../loaders/useSpecificationsForExperiments";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import {Distributions} from "./Distributions";
import { useSetGameEnvironmentFromParam } from '../common/useSetGameEnvironmentFromParam';
import { Route, Routes } from 'react-router';
import { DistributionContainer } from './DistributionContainer';
import { Distribution } from './Distribution';
import { DistributionDiffContainer } from './DistributionDiffContainer';
import { DistributionExperimentsContainer } from './DistributionExperimentsContainer';
import {joinRoute} from "@yakoffice/shared-components";
import {PubRouteItems} from "../../routes";

export const DistributionsIndex : FC = observer(() => {

  useSetGameEnvironmentFromParam();
  const specificationsForExperimentsResult = useSpecificationsForExperiments();

  return !specificationsForExperimentsResult.isLoaded
    ? <CustomSpinner spinnerText={'Loading experiment specifications...'} />
    : <Routes>
      <Route path={""} element={<Distributions />}/>
      <Route path={PubRouteItems.CurrentDistribution} element={<Distribution title={`Preview of next distribution`} distribution={null} />}/>
      <Route path={PubRouteItems.DistributionIdParam} element={<DistributionContainer />}/>
      <Route path={joinRoute(PubRouteItems.DistributionIdParam, PubRouteItems.Experiments)} element={<DistributionExperimentsContainer />}/>
      <Route path={joinRoute(
        PubRouteItems.DistributionIdParam,
        PubRouteItems.Differences,
        PubRouteItems.GameVersions,
        PubRouteItems.DiffGameVersionIdParam,
        PubRouteItems.GameEnvironments,
        PubRouteItems.DiffGameEnvironmentIdParam,
        PubRouteItems.Distributions,
        PubRouteItems.DiffDistributionIdParam)} element={<DistributionDiffContainer />}/>
    </Routes>

})
