import {Instance, types} from 'mobx-state-tree';

export const Entity = types.model(
    "Entity",
    {
      id:  types.identifierNumber,
      gameEnvironmentId: types.number,

      status: types.string,
      versionCurrent: 0,
      versionPrevious: types.maybeNull(types.number),
      versionMax: 0,

      lastActionId: 0,

      inAnyDistribution: false,

      createdAt: "",
      createdBy: "",
      updatedAt: "",
      updatedBy: ""
    })

export interface IEntity extends Instance<typeof Entity> {}
