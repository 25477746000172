import { useContext, useEffect, useState } from 'react';
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";
import type {IKindVersion} from "../model/kind/KindVersion";
import RootStoreContext from "../model/RootStoreContext";

interface useKindVersionResult{
    isLoaded : boolean;
    kindVersion : IKindVersion | null
}

export const useKindVersion = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const { kindId } = useParams<{kindId: string}>();

  const [result, setResult] = useState<useKindVersionResult>({    isLoaded: false,    kindVersion: null  })

  useEffect(() => {
    // Note:  If you change from one kind to another in a different category using Nav menu then, because useEffects
    // run from bottom to top, this one will run again before the useCategory one which would prevent this from even
    // being rendered
    if(!rootStore.categoryStore.currentCategory)
      return;

    setResult({ isLoaded: false, kindVersion: null })

    if (kindId) {
      rootStore.kindVersionStore.loadCurrentVersionForKind(parseInt(kindId))
        .then(kindVersion => {
          rootStore.kindVersionStore.setCurrentKindVersion(kindVersion);
          setResult({ isLoaded: true, kindVersion: kindVersion })
        })
        .catch(e => {
          publishNotification({
            notificationType: NotificationType.Error,
            title: "Error loading Kind version",
            message: e.message,
            footer: "Please try refreshing"

          });
        })
    } else {
      setResult({ isLoaded: true, kindVersion: rootStore.kindVersionStore.addKindVersion() })
    }

    return () => {
      rootStore.kindVersionStore.clearCurrentKindVersion()
    };

  }, [rootStore.categoryStore, rootStore.categoryStore.currentCategory, rootStore.kindVersionStore, publishNotification, kindId]);

  return result;
};
