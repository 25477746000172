import React, {FC, useContext} from "react";
import {observer} from "mobx-react-lite";
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import {IDistribution} from "../../../model/distributions/Distribution";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import DropdownButton from "react-bootstrap/cjs/DropdownButton";
import RootStoreContext from "../../../model/RootStoreContext";
import {ClaimType} from "@yakoffice/publisher-types";


interface PropsType{
  distribution: IDistribution | null
  handleCopyDistribution : (toGameEnvironment: IGameEnvironment) => void
}

export const CopyDistributionButton : FC<PropsType> = observer(({distribution, handleCopyDistribution}) => {

  const rootStore = useContext(RootStoreContext);

  return <DropdownButton variant="primary" title={<><i className="fas fa-copy"/> Copy</>} >
    {rootStore.gameEnvironmentStore.gameEnvironments
      .filter(ge => distribution || ge !== rootStore.gameEnvironmentStore.getCurrentGameEnvironment())
      .filter(ge => rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity, ge))
      .map(ge => <Dropdown.Item key={ge.id} onClick={() => handleCopyDistribution(ge)}>{distribution?.id ?? "Published"} to {ge.name}</Dropdown.Item>)
    }
  </DropdownButton>
})
