import React, { useContext } from 'react';
import Button from 'react-bootstrap/Button';
import RootStoreContext from '../model/RootStoreContext';
import { ISelection } from '../model/selections/Selection';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { FormattedErrorMessage } from '../presentation/views/common/Utils';
import { useShowModal } from '@yakoffice/custom-modal';
import { useGetSelectionsLink } from '../presentation/routes';
import { useShowModalSpinner } from '@yakoffice/custom-modal';
import { useNavigate } from 'react-router';

export interface ISelectionController {
  handleSaveSelection: (selection: ISelection, onSuccessCallback: () => void) => void,
  handleDeleteSelection: (selection: ISelection) => void,
  handleArchiveSelection: (selection: ISelection, archive: boolean, onSuccessCallback: () => void) => void,
  handleCreateSheet: (selection: ISelection, onSuccessCallback: () => void) => void,
  handleViewSheet: (selection: ISelection) => void,
  handleRefreshSheet: (selection: ISelection) => void,
  handleUploadSheet: (selection: ISelection, onSuccessCallback: () => void) => void,
  handleDeleteSheet: (selection: ISelection, onSuccessCallback: () => void) => void,
}

export const useSelectionController = () => {

  // Context Hooks
  const navigate = useNavigate();
  const showModal = useShowModal();
  const showModalSpinner = useShowModalSpinner();
  const rootStore = useContext(RootStoreContext);
  const getSelectionsLink = useGetSelectionsLink();

  // Handlers
  const redirectToSelections = (archived: boolean) => {
    rootStore.selectionStore.loadSelections(archived);
    navigate(getSelectionsLink(),{replace: true});
  };

  const handleSaveSelection = (selection: ISelection, onSuccessCallback: () => void) => {

    const confirmSaveSelection = () => {

      showModal(
        {
          show:     true,
          title:    'Save Selection',
          body:     <CustomSpinner spinnerText={`Saving Selection`} position={'relative'} />,
          canClose: false
        }
      );

      rootStore.selectionStore.saveSelection(selection)
        .then(() => {
          showModal({ body: '', canClose: false, title: '', show: false });
          if (selection.isNewSelection()) {
            onSuccessCallback();
            redirectToSelections(selection.archived);
          } else
            onSuccessCallback();
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    'Error saving selection',
                      body:     <FormattedErrorMessage errorMessage={e.message} />,
                      canClose: true
                    });
        });
    };

    showModal(
      {
        show:     true,
        title:    'Save Selection',
        body:     'Are you sure you want to save this selection?',
        action:   <Button variant='success' onClick={() => confirmSaveSelection()} data-testid='btnModalConfirmSave'>OK</Button>,
        canClose: true
      }
    );

  };

  const handleDeleteSelection = (selection: ISelection) => {
    const confirmDeleteSelection = () => {

      showModal(
        {
          show:     true,
          title:    'Delete Selection',
          body:     <CustomSpinner spinnerText={`Deleting Selection`} position={'relative'} />,
          canClose: false
        }
      );

      rootStore.selectionStore.deleteSelection(selection)
        .then(() => {
          showModal({ body: '', canClose: false, title: '', show: false });
          redirectToSelections(selection.archived);
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    'Error deleting selection',
                      body:     <FormattedErrorMessage errorMessage={e.message} />,
                      canClose: true
                    });
        });
    };

    showModal(
      {
        show:     true,
        title:    'Delete Selection',
        body:     'Are you sure you want to delete this selection?',
        action:   <Button variant='success' onClick={() => confirmDeleteSelection()} data-testid='btnModalConfirmDelete'>OK</Button>,
        canClose: true
      }
    );
  };

  const handleArchiveSelection = (selection: ISelection, archive: boolean, onSuccessCallback: () => void) => {

    const actionString = archive ? 'Archive' : 'UnArchive';

    const confirmArchiveSelection = () => {


      showModal(
        {
          show:     true,
          title:    `${actionString} Selection`,
          body:     <CustomSpinner spinnerText={`${actionString} Selection`} position={'relative'} />,
          canClose: false
        }
      );

      rootStore.selectionStore.archiveSelection(selection, archive)
        .then(() => {
          showModal({ body: '', canClose: false, title: '', show: false });
          onSuccessCallback();
        })
        .catch(e => {
          showModal({
                      show:     true,
                      title:    `Error ${actionString} selection`,
                      body:     <FormattedErrorMessage errorMessage={e.message} />,
                      canClose: true
                    });
        });
    };

    showModal(
      {
        show:     true,
        title:    `${actionString} Selection`,
        body:     `Are you sure you want to ${actionString} this selection?`,
        action:   <Button variant='success' onClick={() => confirmArchiveSelection()} data-testid='btnModalConfirmArchive'>OK</Button>,
        canClose: true
      }
    );
  };

  const handleCreateSheet = (selection: ISelection, onSuccessCallback: () => void) => {
    const createGoogleSheet = async () => {
      try {
        showModalSpinner('Create Google Sheet', 'Creating...');
        await rootStore.selectionStore.createSheet(selection);
        showModal(
          {
            show:            true,
            title:           'Create Google Sheet',
            body:            <div>Google sheet created.</div>,
            action:          <Button variant='primary' onClick={() => handleViewSheet(selection)}>Open Sheet</Button>,
            canClose:        true,
            closeButtonText: 'Close'
          }
        );
        onSuccessCallback();
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Creating Sheet',
                    body:     <FormattedErrorMessage errorMessage={e.message} />,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Create Google Sheet',
        body:     `Are you sure you want to create a google sheet?`,
        action:   <Button variant='primary' onClick={async () => createGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleViewSheet = (selection: ISelection) => {
    selection.googleSheetId != null && window.open(`https://docs.google.com/spreadsheets/d/${selection.googleSheetId}/edit`, '_blank');
  };

  const handleRefreshSheet = (selection: ISelection) => {
    const createGoogleSheet = async () => {
      try {
        showModalSpinner('Refresh Google Sheet', 'Refreshing...');
        await rootStore.selectionStore.refreshSheet(selection);
        showModal(
          {
            show:            true,
            title:           'Refresh Google Sheet',
            body:            <div>Google sheet refreshed.</div>,
            action:          <Button variant='primary' onClick={() => handleViewSheet(selection)}>Open Sheet</Button>,
            canClose:        true,
            closeButtonText: 'Close'
          }
        );
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Refreshing Sheet',
                    body:     <FormattedErrorMessage errorMessage={e.message} />,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Regenerate Google Sheet',
        body:     `Are you sure you want to refresh the google sheet?`,
        action:   <Button variant='primary' onClick={async () => createGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleUploadSheet = (selection: ISelection, onSuccessCallback: () => void) => {
    const createGoogleSheet = async () => {
      try {
        showModalSpinner('Upload Google Sheet', 'Uploading...');
        await rootStore.selectionStore.uploadSheet(selection);
        showModal(
          {
            show:            true,
            title:           'Upload Google Sheet',
            body:            'Upload complete',
            action:          <Button variant='primary' onClick={() => handleViewSheet(selection)}>Open Sheet</Button>,
            canClose:        true,
            closeButtonText: 'Close'
          }
        );
        onSuccessCallback();
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Uploading Sheet',
                    body:     <FormattedErrorMessage errorMessage={e.message} />,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Upload Google Sheet',
        body:     `Are you sure you want to upload the google sheet?`,
        action:   <Button variant='primary' onClick={async () => createGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleDeleteSheet = (selection: ISelection, onSuccessCallback: () => void) => {
    const deleteGoogleSheet = async () => {
      try {
        showModalSpinner('Delete Google Sheet', 'Uploading...');
        await rootStore.selectionStore.deleteSheet(selection);
        showModal(
          {
            show:     true,
            title:    'Delete Google Sheet',
            body:     <div>Google sheet deleted.</div>,
            canClose: true
          }
        );
        onSuccessCallback();
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Deleting Sheet',
                    body:     <FormattedErrorMessage errorMessage={e.message} />,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Delete Google Sheet',
        body:     `Are you sure you want to delete the google sheet?  It will delete worksheets for all game environments `,
        action:   <Button variant='primary' onClick={async () => deleteGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const controller: ISelectionController = {
    handleSaveSelection,
    handleDeleteSelection,
    handleArchiveSelection,
    handleCreateSheet,
    handleViewSheet,
    handleRefreshSheet,
    handleUploadSheet,
    handleDeleteSheet
  };

  return controller;
};
