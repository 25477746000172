import React, { FC, useEffect, useState } from 'react';
import {observer}             from 'mobx-react-lite';
import {NavLink} from 'react-router-dom';
import Accordion              from 'react-bootstrap/cjs/Accordion';
import {rootStore}            from '../../../model/RootStoreContext';
import {ICategory}            from '../../../model/category/Category';
import {useDoubleClick}       from './useDoubleClick';
import {camelToTitle}         from '../../../utilities';
import {sortByString}         from '@yakoffice/yakoffice-firebase';
import { useGetEntitiesLink, useGetKindLink, useGetKindsLink } from '../../routes';
import { KindStatus }         from '@yakoffice/publisher-types';
import { Button } from 'react-bootstrap';
import styles                 from './categoryAccordian.module.sass'

interface CategoryAccordionPropsType {
  currentCategory : ICategory | null,
  category        : ICategory,
  allCategories   : ICategory[],
  expandAll?      : boolean
}


export const CategoryAccordion : FC<CategoryAccordionPropsType> = observer((props) => {

  const [defaultExpanded, setDefaultExpanded] = useState<ICategory>();
  const [expand, setExpand]                   = useState(!!props.expandAll);
  const [expandChildren, setExpandChildren]   = useState(!!props.expandAll);
  const getKindsLink                          = useGetKindsLink();
  const getKindLink                           = useGetKindLink();
  const getEntitiesLink                       = useGetEntitiesLink();


  useEffect( () => {
    if(props.currentCategory) {
      setDefaultExpanded(props.currentCategory)

      if(props.currentCategory.id === props.category.id)
        setExpand(true);

      if(props.currentCategory.parent?.id === props.category.id) {
        setDefaultExpanded(props.currentCategory.parent)
        setExpand(true);
      }
    }
  }, [props.currentCategory, props.category])


  const handleDoubleClick =  useDoubleClick(() => {
    let toggledExpandChildren = !expandChildren;

    // Note:  If they double click when parent closed but children are open then open
    if(!expand && !toggledExpandChildren)
      toggledExpandChildren = true;

    if(toggledExpandChildren)
      setExpand(true)

    setExpandChildren(toggledExpandChildren)
  })

  useEffect(() => {
    setExpand(!!props.expandAll)
    setExpandChildren(!!props.expandAll)
  }, [props.expandAll])


  const accordionProps = () => {
    return { activeKey: expand ? JSON.stringify(props.category?.id) : "", defaultActiveKey: props.currentCategory ? JSON.stringify(defaultExpanded?.id) : "" }
  }
    return (
      <li className={styles.folder}>

        <Accordion {...accordionProps()}>

          <Button onClick={() => setExpand(!expand)} className={styles.categoryToggle}>
            <i className={`fas ${expand ? "fa-caret-down" : "fa-caret-right"}`} />
          </Button>

          <NavLink className={`${styles.categoryLink} ${props.currentCategory?.parent?.id === props.category.id && "active"}`} to={getKindsLink({categoryId: props.category.id})}
                   onClick={handleDoubleClick}>
            <i className={`fa ${expand ? "fa-folder-open" : "fa-folder"} me-2`} /> {camelToTitle(props.category.name)}
          </NavLink>

          <Accordion.Collapse eventKey={JSON.stringify(props.category.id)} className={""}>
            <ul>

              {props.allCategories.slice().sort(sortByString("name")).map((subCategory: ICategory) => {
                if(subCategory.parent && subCategory.parent.id === props.category.id)
                  return (
                    <CategoryAccordion key={`subCategory-${subCategory.id}`} currentCategory={props.currentCategory} category={subCategory} allCategories={rootStore.categoryStore.categories} expandAll={expandChildren}  />
                  )
                else
                  return null
              })}

              {props.category.kinds.slice().sort(sortByString("name")).filter(kindVersion => kindVersion.kindStatus !== KindStatus.Archived).map(kindVersion => {

                const kindLink = kindVersion.isSpecificationForKinds
                               ? getKindLink({categoryId: props.category.id, kindId: kindVersion.kindId})
                               : getEntitiesLink({categoryId: props.category.id, kindId: kindVersion.kindId})

                return (
                  <li key={`kind-${kindVersion.id}`}>
                    <NavLink to={kindLink} className={styles.categoryLink}>{camelToTitle(kindVersion.name)}</NavLink>
                  </li>
                )
              })}
            </ul>
          </Accordion.Collapse>
        </Accordion>
      </li>
    )
});
