import React, {useContext}      from "react";
import Button                   from 'react-bootstrap/Button';
import RootStoreContext         from "../model/RootStoreContext";
import type {IGameEnvironment}       from '../model/gameEnvironment/GameEnvironment';
import {CustomSpinner}          from '@yakoffice/custom-spinner';
import {FormattedErrorMessage}  from '../presentation/views/common/Utils';
import {useShowModal} from '@yakoffice/custom-modal';
import { useNavigate } from "react-router-dom";
import { useGetGameEnvironmentsLink } from '../presentation/routes';


export interface IGameEnvironmentController{
    handleSaveGameEnvironment : () => void,
    handleDeleteGameEnvironment : () => void
}


export const useGameEnvironmentController = (gameEnvironment     : IGameEnvironment) => {

    // Context Hooks
    const showModal = useShowModal();
    const rootStore = useContext(RootStoreContext);
    const navigate = useNavigate();
    const getGameEnvironmentsLink = useGetGameEnvironmentsLink()

    // Handlers
    const saveGameEnvironment = () => {

        const confirmSaveGameEnvironment = () => {

            showModal(
                {
                    show:     true,
                    title:    "Save Game Environment",
                    body:     <CustomSpinner spinnerText={`Saving Game Environment`} position={'relative'}/>,
                    canClose: false
                }
            );

            rootStore.gameEnvironmentStore.saveGameEnvironment(gameEnvironment)
                .then(async () => {
                    await hideSpinnerAndRedirect();
                })
                .catch(e => {
                    showModal({
                                  show: true,
                                  title: 'Error saving game environment',
                                  body: <FormattedErrorMessage errorMessage={e.message} />,
                                  canClose: true,
                              });
                });
        };

        showModal(
            {
                show:     true,
                title:    "Save Game Environment",
                body:     "Are you sure you want to save this game environment?",
                action:   <Button variant="success" onClick={() => confirmSaveGameEnvironment()} data-testid="btnModalConfirmSave">OK</Button>,
                canClose: true
            }
        );

    };

    const deleteGameEnvironment = () => {
        const confirmDeleteGameEnvironment = () => {

            showModal(
                {
                    show:     true,
                    title:    "Delete Game Environment",
                    body:     <CustomSpinner spinnerText={`Deleting Game Environment`} position={'relative'}/>,
                    canClose: false
                }
            );

            rootStore.gameEnvironmentStore.deleteGameEnvironment(gameEnvironment)
                .then( async () => {
                    await hideSpinnerAndRedirect();
                })
                .catch(e => {
                    showModal({
                          show: true,
                          title: 'Error deleting game environment',
                          body: <FormattedErrorMessage errorMessage={e.message} />,
                          canClose: true,
                      });
                });
        };

        showModal(
            {
                show:     true,
                title:    "Delete Game Environment",
                body:     "Are you sure you want to delete this game environment?",
                action:   <Button variant="success" onClick={() => confirmDeleteGameEnvironment()} data-testid="btnModalConfirmDelete">OK</Button>,
                canClose: true
            }
        );
    };

    const hideSpinnerAndRedirect = async () => {
        showModal({body: "", canClose: false, title: '', show: false});
        navigate(getGameEnvironmentsLink());
    };

  const controller : IGameEnvironmentController = {
        handleSaveGameEnvironment     : saveGameEnvironment,
        handleDeleteGameEnvironment   : deleteGameEnvironment,
    };

    return controller;
};
