import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import { IEntityVersion } from '../model/entity/EntityVersion';
import { useParams } from 'react-router-dom';

interface useEntityVersionResult{
  isLoaded : boolean;
  entityVersion : IEntityVersion | null
}

export const useEntityVersion = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const { entityId, versionId } = useParams<{ entityId: string, versionId: string }>();

  const [result, setResult] = useState<useEntityVersionResult>(() => ({
    isLoaded: false,
    entityVersion: null,
  }));

  useEffect(() => {
    // Note:  If you change gameVersion then, because useEffects run from bottom to top, this one will run again
    // before the useCategory and useKind which would prevent this from even being rendered
    if(!rootStore.kindVersionStore.currentKindVersion || !rootStore.categoryStore.currentCategory)
      return;

    setResult({ isLoaded: false, entityVersion: null });

    const entityVersionLoader = !entityId
      ? rootStore.entityVersionStore.addEntityVersion()
      : !versionId
        ? rootStore.entityVersionStore.loadCurrentVersionForEntity(parseInt(entityId))
        : rootStore.entityVersionStore.loadEntityVersion(parseInt(entityId), parseInt(versionId));

    entityVersionLoader
      .then(entityVersion => {
        if (entityVersion.isCurrentVersionOfEntity())
          entityVersion.updateSpecificationPropertiesToCurrentSpecificationKindVersion(rootStore.specificationsForKindsStore.getKinds());

        rootStore.entityVersionStore.setCurrentEntityVersion(entityVersion);
        setResult({ isLoaded: true, entityVersion: entityVersion })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading entity version",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }));

    return () => {
      rootStore.entityVersionStore.clearCurrentEntityVersion();
    };
  }, [rootStore.entityVersionStore, rootStore.specificationsForKindsStore, publishNotification, rootStore.kindVersionStore.currentKindVersion, rootStore.categoryStore.currentCategory, entityId, versionId]);

  return result;
};
