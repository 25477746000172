import {IEntityProperty} from '../../../model/entity/EntityProperty';

const DayOfTheWeek : {[key: number]: string} = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
}

export const SpecificationPropertiesFormatter = (entityProperty: IEntityProperty) => {

  switch(entityProperty.kindPropertyKey) {
    case "dayOfTheWeek":
      return entityProperty.value && DayOfTheWeek[parseInt(entityProperty.value)]
    default:
      return entityProperty.value;
  }
}
