import React, {useContext} from "react";
import Button from "react-bootstrap/Button";
import RootStoreContext from "../model/RootStoreContext";
import type {ICategory} from "../model/category/Category";
import {CustomSpinner} from '@yakoffice/custom-spinner';
import CustomCommentForm from "../presentation/components/custom-comment-form/customCommentForm";
import {FormattedErrorMessage} from "../presentation/views/common/Utils";
import {useShowModal} from '@yakoffice/custom-modal';
import { useGetCategoriesLink, useGetKindsLink } from '../presentation/routes';
import { useNavigate } from 'react-router-dom';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";


export interface ICategoryController{
    handleArchiveCategory: (onSuccessCallback: () => void) => void;
    handleUnarchiveCategory: (onSuccessCallback: () => void) => void;
    handleSaveCategory: (onSuccessCallback: () => void) => void;
    handleDeleteCategory: (onSuccessCallback: () => void) => void;
}

interface useCategoryControllerProps {
    category          : ICategory;
}


export const useCategoryController = (props: useCategoryControllerProps) => {

  // Context Hooks
  const showModal = useShowModal();
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const getCategoriesLink = useGetCategoriesLink()
  const getKindsLink = useGetKindsLink()
  const publishNotification = usePublishNotification();

  // Handlers
  const handleArchiveCategory = (onSuccessCallback: () => void) => {
    const archiveCategory = () => {
      showModal(
        {
          show: true,
          title: "Archive Category",
          body: <CustomSpinner spinnerText={`Archiving category`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.categoryStore
        .archiveCategory(props.category)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
            show: true,
            title: "Error Changing Category Status",
            body: `Could not archive entity. Error message: ${e.message}`,
            canClose: true
          })
        })
    };

    showModal(
      {
        show: true,
        title: "Archive Category",
        body: "You are about to archive this category. Are you sure you want to do this?",
        action: <Button variant="success" onClick={() => archiveCategory()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleUnarchiveCategory = (onSuccessCallback: () => void) => {
    const unarchiveCategory = () => {

      showModal(
        {
          show: true,
          title: "Unarchive Category",
          body: <CustomSpinner spinnerText={`Unarchiving Category`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.categoryStore
        .unArchiveCategory(props.category)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
            show: true,
            title: "Error Changing Category Status",
            body: `Could not unarchive entity. Error message: ${e.message}`,
            canClose: true
          })
        })
    };

    showModal(
      {
        show: true,
        title: "Unarchive Category",
        body: "You are about to unarchive this entity. Are you sure you want to do this?",
        action: <Button variant="success" onClick={() => unarchiveCategory()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleSaveCategory = (onSuccessCallback: () => void) => {
    const _saveCategory = () => {
      showModal(
        {
          show: true,
          title: "Save Category",
          body: <CustomSpinner spinnerText={`Saving category`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.categoryStore
        .saveCategory(props.category)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
            show: true,
            title: "Error saving/updating category",
            body: <FormattedErrorMessage errorMessage={e.message}/>,
            canClose: true
          })
        })

    };

    showModal(
      {
        show: true,
        title: "Save Category",
        body: <CustomCommentForm handler={comment => props.category.setComment(comment)}/>,
        action: <Button variant="success" onClick={() => _saveCategory()} data-testid="btnModalConfirmSave">OK</Button>,
        canClose: true
      }
    );
  };

  const handleDeleteCategory = (onSuccessCallback: () => void) => {
    const deleteCategory = () => {

      showModal(
        {
          show: true,
          title: "Delete Category",
          body: <CustomSpinner spinnerText={`Deleting category`} position={'relative'}/>,
          canClose: false
        }
      );

      rootStore.categoryStore.deleteCategory(props.category)
        .then(async () => {
          onSuccessCallback();
          await hideSpinnerAndRedirect()
        })
        .catch(e => {
          showModal({
            show: true,
            title: "Error deleting category",
            body: `Error message: ${e.message}`,
            canClose: true
          })
        })
    };

    showModal(
      {
        show: true,
        title: "Delete Category",
        body: "You are about to DELETE this category. This is a permanent action! Are you sure you want to delete this category?",
        action: <Button variant="success" onClick={() => deleteCategory()}
                        data-testid="btnModalConfirmDelete">OK</Button>,
        canClose: true
      }
    );
  };

  const hideSpinnerAndRedirect = async () => {
    showModal({show: false, title: "", body: "", canClose: false});

    const parentCategoryId = props.category.parent?.id;
    rootStore.categoryStore.loadCategories()
      .then(() => {
        if (parentCategoryId)
          navigate(getKindsLink({categoryId: parentCategoryId}));
        else
          navigate(getCategoriesLink())
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading categories",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))

  };

  const controller: ICategoryController = {
    handleArchiveCategory: handleArchiveCategory,
    handleUnarchiveCategory: handleUnarchiveCategory,
    handleSaveCategory: handleSaveCategory,
    handleDeleteCategory: handleDeleteCategory
  };

  return controller
};
