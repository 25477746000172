import {Instance, types} from "mobx-state-tree";

export const Claim = types.model(
    "Claim",
    {
        type:"",
        value:"",
    })
    .views(self => ({
        getValueAsInt() : number{
            return parseInt(self.value);
        },

    }));

export interface IClaim extends Instance<typeof Claim> {}