import React, {FC}    from 'react';
import Navbar         from 'react-bootstrap/Navbar';
import Button         from 'react-bootstrap/Button';
import {IGameVersion} from '../../../model/gameVersion/GameVersion';
import { ContentBoxNavBar } from '../../components/content-box/ContentBox';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { GameVersionStatus } from '@yakoffice/publisher-types';
import { Container } from 'react-bootstrap';


interface  PropsType {
  gameVersion             : IGameVersion,
  handleSaveGameVersion   : () => void,
  handleDeleteGameVersion : () => void,
  handleBranchGameVersion : () => void,
  handleStatusChange      : (status: string) => void,
  geEdited                : boolean
}

export const GameVersionNav : FC<PropsType> = (props) => {
  return (
    <ContentBoxNavBar variant="dark" expand="lg">
      <Container>
        <Navbar.Brand>
          {props.gameVersion.isNewGameVersion() ? "New Game Version" : "Game Version"}
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="gameVersionNav" className="justify-content-end">

          {!props.gameVersion.isNewGameVersion() &&
          <DropdownButton id="statusChange"
                          title={<span className="text-capitalize"><i className="fas fa-eye" /> Change Status</span>}
                          variant="light" data-testid="btnStatusDropdownGameVersion">
            {Object
              .values(GameVersionStatus)
              .filter(s => s !== props.gameVersion.status)
              .map(s => <Dropdown.Item key={s} onClick={() => props.handleStatusChange(s)} className="text-capitalize" data-testid={`btnStatus${s}GameVersion`}>{s}</Dropdown.Item>)}
          </DropdownButton>
          }
          {!props.gameVersion.isNewGameVersion() && !props.geEdited &&
          <Button variant="light" data-value="branch" onClick={() => props.handleBranchGameVersion()} data-testid="btnBranchGameVersion">
            <i className="fas fa-code-branch" />Branch
          </Button>
          }
          {(props.geEdited || props.gameVersion.isNewGameVersion()) &&
          <Button variant="success" data-value="save" onClick={() => props.handleSaveGameVersion()} data-testid="btnSaveGameVersion">
            <i className="fas fa-save" />
            {
              props.gameVersion.isNewGameVersion() ? "Save Game Version" : "Update Game Version"
            }
          </Button>
          }
          {!props.gameVersion.isNewGameVersion() &&
          <Button variant="danger" data-value="delete" onClick={() => props.handleDeleteGameVersion()} data-testid="btnDeleteGameVersion">
            <i className="fas fa-trash-alt" />Delete Game Version</Button>
          }
        </Navbar.Collapse>
      </Container>
    </ContentBoxNavBar>
  )
}
