import React, {FC} from "react";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    value : string;
    handleOnChange: (e: string) => void;
}

export const IntegerInput : FC<PropsType> = ({ id, value, handleOnChange, ...props}) => {
  return <Form.Control type="number"
                       id={id}
                       placeholder="Enter Integer"
                       value={value}
                       onChange={e => handleOnChange(e.target.value)}
                       min="1" step="1"
                       {...props}
  />
}
