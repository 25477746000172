import React, {FC}          from 'react';
import moment               from 'moment';
import Row                  from 'react-bootstrap/Row';
import Col                  from 'react-bootstrap/Col';
import {IGameVersion}       from '../../../model/gameVersion/GameVersion';
import { InfoBox, InfoBoxHeader, InfoBoxBody } from '@yakoffice/info-box';
import { CollapsePanel } from '@yakoffice/shared-components';


interface PropsType{
  gameVersion: IGameVersion
}

const statusClasses: any = {
  live        : "text-danger",
  unreleased  : "text-default",
  inactive    : "text-warning",
  archived    : "text-info"
};


export const GameVersionHighlights : FC<PropsType> = (props) => {
  return (
    <CollapsePanel>

      <Row>
        {/*<Col sm="3">*/}
        {/*  <InfoBox>*/}
        {/*    <InfoBoxHeader><i className="far fa-calendar-alt" /> Created at</InfoBoxHeader>*/}
        {/*    <InfoBoxBody>{moment(props.gameVersion.createdAt).isValid() && moment(props.gameVersion.createdAt).format('Do MMMM YYYY, h:mm')}</InfoBoxBody>*/}
        {/*  </InfoBox>*/}
        {/*</Col>*/}
        {/*<Col sm="3">*/}
        {/*  <InfoBox>*/}
        {/*    <InfoBoxHeader><i className="far fa-user" /> Created by</InfoBoxHeader>*/}
        {/*    <InfoBoxBody>{props.gameVersion.createdBy}</InfoBoxBody>*/}
        {/*  </InfoBox>*/}
        {/*</Col>*/}
        <Col sm="4">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-calendar-alt" /> Updated at</InfoBoxHeader>
            <InfoBoxBody>{moment(props.gameVersion.updatedAt).isValid() && moment(props.gameVersion.updatedAt).format('Do MMMM YYYY, h:mm')}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="4">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-user" /> Updated by</InfoBoxHeader>
            <InfoBoxBody>{props.gameVersion.updatedBy}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="4">
          <InfoBox>
            <InfoBoxHeader><i
              className={`fas fa-eye ${statusClasses[props.gameVersion.status]}`} /> Status</InfoBoxHeader>
            <InfoBoxBody className="text-capitalize" data-testid="txtStatusGameVersion"><strong className={`${statusClasses[props.gameVersion.status]}`}>{`${props.gameVersion.status}`}</strong></InfoBoxBody>
          </InfoBox>
        </Col>
      </Row>
    </CollapsePanel>
  )
};
