import React, { FC, useState } from 'react';
import { formatISODateStringAsLocal } from '../common/Utils';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { observer } from 'mobx-react-lite';
import { IEntityVersion } from '../../../model/entity/EntityVersion';
import { InfoBox, InfoBoxHeader, InfoBoxBody } from '@yakoffice/info-box';
import { CollapsePanel } from '@yakoffice/shared-components';
import { AbbreviatedString } from '../../components/abbreviated-string/AbbreviatedString';

interface PropsType {
  entityVersion: IEntityVersion
}

export const EntityVersionHighlights : FC<PropsType> = observer(({ entityVersion}) => {

  // Cache comment so that the updated comment is not displayed
  const [comment] = useState(entityVersion.comment);

  return (
    <CollapsePanel>
      <Row>
        <Col sm="6" md="3">
          <InfoBox>
            <InfoBoxHeader><i className="fas fa-code-branch" /> On Kind Version</InfoBoxHeader>
            {entityVersion.isOnOldVersionOfKind()
              ? <InfoBoxBody className="text-warning fw-bold">{entityVersion.kindVersionSummary.version} <i
                className="fas fa-exclamation-triangle" /></InfoBoxBody>
              : <InfoBoxBody className="text-success fw-bold">{entityVersion.kindVersionSummary.version} <i
                className="far fa-check-circle" /></InfoBoxBody>
            }
          </InfoBox>
        </Col>
        <Col sm="6" md="3">
          <InfoBox>
            <InfoBoxHeader><i className="fas fa-play-circle" /> Distribution Status</InfoBoxHeader>
            <InfoBoxBody>
              {entityVersion.inLatestDistribution && <i className="fas fa-check-circle text-success" />}
              {!entityVersion.inLatestDistribution && entityVersion.inAnyDistribution &&
              <i className="fas fa-check-circle text-warning" />}
              {!entityVersion.inLatestDistribution && !entityVersion.inAnyDistribution &&
              <i className="fas fa-times-circle text-danger" />}
            </InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="6" md="3">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-calendar-alt" /> Updated On</InfoBoxHeader>
            <InfoBoxBody>{entityVersion.createdAt !== "" && formatISODateStringAsLocal(entityVersion.createdAt)}, {entityVersion.createdBy}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="6" md="3">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-comment" /> Last comment</InfoBoxHeader>
            <InfoBoxBody><AbbreviatedString value={`"${comment}"`} lengthToAbbreviate={30} /></InfoBoxBody>
          </InfoBox>
        </Col>
      </Row>
    </CollapsePanel>
  )
});
