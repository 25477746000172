import Form from "react-bootstrap/Form";
import React, {ChangeEvent, FC} from "react";
import {observer} from "mobx-react-lite";
import {IExperimentProperty} from "../../../model/experiment/ExperimentProperty";
import Alert from "react-bootstrap/Alert";
import {IEntityVersion} from "../../../model/entity/EntityVersion";

interface PropsType {
    property: IExperimentProperty
    entityVersions: IEntityVersion[]
    handleEntityChange: (entity: IEntityVersion) => void
}

export const ExperimentEntitySelection : FC<PropsType> = observer(({property, ...props}) => {

    const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if(e.target.value) {
            const entity = props.entityVersions.find(entity => entity.entity.id === parseInt(e.target.value));
            if (entity)
                props.handleEntityChange(entity)
        }
    }

    const propertyEntityDoesntExistInList = () => !property.isNew && property.hasValidEntityId() && props.entityVersions.find(e => e.entity.id === property.entityId) == null;

    return (
        <>
            {propertyEntityDoesntExistInList() &&
            <Alert variant="warning">
                <strong>Warning:</strong> This Entity is no longer available for experiments
            </Alert>
            }
            <Form.Select  id="experimentEntitiesSelection"
                          value={property.entityId ? property.entityId : ""}
                          onChange={handleSelectionChange}
                          data-testid="ddlExperimentEntitySelect">
                <option value="" className={"text-danger"} disabled={true}>Select Entity...</option>
                {propertyEntityDoesntExistInList() &&
                <option value={property.entityId} disabled={true}>{property.entityName}</option>
                }
                {props.entityVersions.map((entityVersion: IEntityVersion) => {
                    return <option key={entityVersion.entity.id} value={entityVersion.entity.id}>{entityVersion.name}</option>
                })}
            </Form.Select>
        </>
    )
})
