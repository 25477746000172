import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import type {ICategory} from "../model/category/Category";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";
import {useLocation} from 'react-router';

interface useCategoryResult{
    isLoaded : boolean;
    category : ICategory | null;
}

export const useCategory  = () => {

  const rootStore             = useContext(RootStoreContext);
  const publishNotification   = usePublishNotification();
  const { categoryId }        = useParams<{categoryId: string}>();
  const location              = useLocation();

  const [result, setResult] = useState<useCategoryResult>({ isLoaded: false, category: null})


  useEffect(() => {
    setResult({ isLoaded: false, category: null})

    // add sub-category
    if(location.pathname.includes("add-sub-category") && categoryId) {
      const newSubCategory = rootStore.categoryStore.addCategory(parseInt(categoryId));
      rootStore.categoryStore.setCurrentCategory(newSubCategory)
      setResult({ isLoaded: true, category: newSubCategory})
    }
    // load category
    else if (categoryId) {
      // Note:  If you change gameVersion then, because useEffects run from bottom to top, this one will run
      // again before the useCategories one which is needed to load any potential parent categories this category might have
      if(rootStore.categoryStore.categories.length === 0)
        return;

      rootStore.categoryStore.getCategory(parseInt(categoryId))
        .then(category => {
          rootStore.categoryStore.setCurrentCategory(category);
          setResult({ isLoaded: true, category: category})
        })
        .catch(e => {
          publishNotification({
            notificationType: NotificationType.Error,
            title: "Error loading Category",
            message: e.message,
            footer: "Please try refreshing"

          });
        })
    }
    // add category
    else {
      const newCategory = rootStore.categoryStore.addCategory();
      rootStore.categoryStore.setCurrentCategory(newCategory)
      setResult({ isLoaded: true, category: newCategory})
    }

    return () => {
      const currentCategory = rootStore.categoryStore.currentCategory;
      if (currentCategory != null && rootStore.categoryStore.categories.includes(currentCategory) && currentCategory.isNewCategory()) {
        rootStore.categoryStore.removeFromStore(currentCategory);
      }

      rootStore.categoryStore.clearCurrentCategory();
    }

  }, [rootStore.categoryStore, publishNotification, rootStore.categoryStore.categories, categoryId, location]);

  return result;
};
