import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { LabelHeader } from '@yakoffice/shared-components';
import ToolTip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import { FormControl, InputGroup, OverlayTrigger } from 'react-bootstrap';
import { IExperiment } from '../../../model/experiment/Experiment';
import { observer } from 'mobx-react-lite';
import Button from 'react-bootstrap/Button';
import { IVariant } from '../../../model/experiment/Variant';

interface PropTypes {
  experiment: IExperiment
  disabled: boolean
}

export const ExperimentVariants : FC<PropTypes> = observer(({experiment, disabled}) => {

  const handleAddVariant = () => {
    experiment.addVariant();
  }
  const handleRemoveVariant = (variant: IVariant) => {
    experiment.removeVariant(variant);
  }

  return <Row key="experimentBounds">
    <Col xs="12" md="12">
      <Form.Group>
        <Form.Label>
          <LabelHeader><i className="fas fa-tags" />Variants
            <OverlayTrigger placement="right" overlay={
              <ToolTip id="button-tooltip">
                You can add one or more test variants per experiment.  The variant allocation will indicate
                what % of users within the experiment User Range will get the variant value for each experiment property.
                The sum of allocations can not be greater than 100%.  Any remaining allocation (i.e. 100 - sum of allocations)
                will be used as the control sample for the experiment.
              </ToolTip>}>
              <span className="ms-2">  <i className={`fa fa-info-circle`} /></span>
            </OverlayTrigger>
          </LabelHeader>
        </Form.Label>
        <Row className="mt-2" key="rowControl">
          <Col md={8}>
            <InputGroup>
              <InputGroup.Text id="name">&nbsp;-&nbsp;</InputGroup.Text>
              <FormControl type="text" className="w-50" aria-label="Control Description" value="Control group allocation" disabled={true} />
              <FormControl
                type="number"
                aria-label="Control allocation"
                value={Math.round((100 - (experiment.variants.reduce((sum, variantValue) => sum + (variantValue.allocation || 0) , 0))) * 100)/100}
                disabled={true}
              />
              <InputGroup.Text id="percent">%</InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        {experiment.variants.map((variant, index) =>
          <Row className="mt-2" key={variant.id}>
            <Col md={8}>
              <InputGroup>
                <InputGroup.Text id="name" data-testid={`${index}-variantName`}>{variant.name}</InputGroup.Text>
                <FormControl
                  type="text"
                  className="w-50"
                  aria-label="Variant Description"
                  placeholder="Enter variant description..."
                  value={variant.description ?? ""}
                  onChange={e => variant.setDescription(e.target.value)}
                  data-testid={`${index}-variantDescription`}
                  disabled={disabled}
                />
                <FormControl
                  type="number"
                  aria-label="Variant Allocation"
                  placeholder="Allocation..."
                  value={variant.allocation}
                  onChange={e => variant.setAllocation(parseFloat(e.target.value))}
                  data-testid={`${index}-variantValue`}
                  disabled={disabled}
                />
                <InputGroup.Text id="percent">%</InputGroup.Text>
              </InputGroup>
            </Col>
            <Col md={1} className="ps-0">
              {(index > 0 && !disabled) && <Button variant="danger" onClick={() => handleRemoveVariant(variant)}> X </Button>}
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4}>
            {!disabled &&
            <div className="d-grid gap-2">
              <Button variant="outline-primary" className="mt-3" onClick={handleAddVariant}
                      data-testid="btnAddVariant">
                <i className="fas fa-plus-circle" /> Add variant
              </Button>
            </div>
            }
          </Col>
        </Row>
      </Form.Group>
    </Col>
  </Row>
})
