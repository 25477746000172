import {Instance, types} from "mobx-state-tree"
import {pub_api} from "../../index";
import type {IKindPropertyTypeVm} from "@yakoffice/publisher-types";
import {flow} from "mobx";
import {ApiRequest} from "../ApiRequest";

export const KindPropertyTypeApiGateway = types.model(
    "KindPropertyTypeApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl() : string{
            return `${pub_api}/kinds/property-types`;
        }
    }))
    .actions(self => ({
        getKindPropertyTypes: flow(function* () {
            return yield self.apiRequest.get<IKindPropertyTypeVm[]>(self.getRootUrl())
        }),
    }))


export interface IKindPropertyTypeApiGateway extends Instance<typeof KindPropertyTypeApiGateway> {}
