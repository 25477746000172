
export function GenerateId(){
    return Math.floor((Math.random() * 100000000) + 1)
}

export function GenerateIdString(){
  const _p8 = (s: boolean) => {
    const p = (Math.random().toString(16)+"000000000").substr(2,8);
    return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;
  }
  return _p8(false) + _p8(true) + _p8(true) + _p8(false);
}

// e.response is populated by Axios as a result of an HTTP request with a failure HttpCode
// e.response.data is the content of the response if there is any
// e.response.data.message is populated by our APIs error handling (e.g. it contains validation failure details)
// e on its own is required in case the exception is from something other than Axios
export function formatError(e : any) : Error
{
    if(e.response?.data?.message)
        return new Error(e.response.data.message);

    return new Error(e.message);
}
