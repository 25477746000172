import React, {FC, useContext, useState}  from 'react';
import {observer}                         from 'mobx-react-lite';
import {NavLink}                          from 'react-router-dom';
import Accordion                          from 'react-bootstrap/cjs/Accordion';
import RootStoreContext                   from '../../../model/RootStoreContext';
import {ICategory}                        from '../../../model/category/Category';
import {CategoryAccordion}                from './categoryAccordion';
import {PubRouteItems, useGetCategoriesLink} from '../../routes';
import styles from './categoriesNav.module.sass'
import { sortByString } from '@yakoffice/yakoffice-firebase';
import { Button } from 'react-bootstrap';
import {useLocation} from "react-router";

export const PublisherCategoriesNav : FC = observer(() => {

  const rootStore               = useContext(RootStoreContext);
  const getCategoriesLink       = useGetCategoriesLink()
  const location                = useLocation();
  const [expanded, setExpanded] = useState(true);

  const isActive = location.pathname.includes(PubRouteItems.Categories);

  return (
    <Accordion className={`nav-item ${isActive ? 'active' : ''}`} activeKey={expanded ? "folderIconCat" : ""} defaultActiveKey={"folderIconCat"}>
      <div className={`nav-link ${styles.categoryTopLevelNav}`}>
        <NavLink to={getCategoriesLink()} className={styles.categoryTopLevelLink}>
          <i className={`far ${expanded ? 'fa-folder-open' : 'fa-folder'}`} /> Categories
        </NavLink>
        {rootStore.categoryStore.categories.length > 0 &&
          <Button
            className={styles.categoryTopLevelToggle}
            onClick={() => setExpanded(!expanded)}>
            <span id="folderIconCat" className={`fas ${expanded ? 'fa-caret-down' : 'fa-caret-left'}`} />
          </Button>
        }
      </div>
        <Accordion.Collapse eventKey="folderIconCat" id="accordion-categories">
          <div className={styles.categoryList}>
            <ul className={styles.categoryListTop}>
              {rootStore.categoryStore.categories
                .filter(category => category.status !== "archived")
                .filter(category => category.parent == null).slice().sort(sortByString("name"))
                .map((category: ICategory) => {
                  return <CategoryAccordion key={`category-${category.id}`} currentCategory={rootStore.categoryStore.currentCategory} category={category} allCategories={rootStore.categoryStore.categories} />
                })}
            </ul>
          </div>
        </Accordion.Collapse>
    </Accordion>
  )
});
