import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {Route, Routes} from 'react-router';
import {PubRouteItems} from '../../routes';
import {DistributionsIndex} from '../distribution/DistributionsIndex';
import {ExperimentsContainer} from '../experiments/ExperimentsContainer';
import {ClaimType} from '@yakoffice/publisher-types';
import {ExperimentContainer} from '../experiments/ExperimentContainer';
import {CategoryContainer} from '../category/CategoryContainer';
import {EntityCalendarContainer} from '../entityCalendar/EntityCalendarContainer';
import {Selections} from '../selections/Selections';
import {useRootStore} from '../../../loaders/useRootStore';
import {useCategories} from '../../../loaders/useCategories';
import {useSelections} from '../../../loaders/useSelections';
import {useSetGameVersionFromParam} from '../common/useSetGameVersionFromParam';
import {useSetGameEnvironmentFromParam} from '../common/useSetGameEnvironmentFromParam';
import Categories from '../categories/Categories';
import {CategoryIndex} from '../category/CategoryIndex';
import {useKindPropertyTypes} from '../../../loaders/useKindPropertyType';
import Alert from 'react-bootstrap/cjs/Alert';
import Container from 'react-bootstrap/cjs/Container';
import {joinRoute, matchDeeply, NoAccess} from "@yakoffice/shared-components";

export const GameVersionIndex : FC = observer(() => {

  const rootStore = useRootStore()
  useSetGameVersionFromParam();
  useSetGameEnvironmentFromParam();
  const categoriesResult = useCategories();  // NB. required for <PublisherNav>
  const selectionsResult = useSelections();
  const kindPropertyTypesResult = useKindPropertyTypes();


  return !categoriesResult.isLoaded || !selectionsResult.isLoaded || !kindPropertyTypesResult.isLoaded
    ? <CustomSpinner spinnerText="Loading categories and selections..." />
    : (rootStore.gameVersionStore.currentGameVersion?.id !== rootStore.gameVersionStore.gameVersions[0].id) && !rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.ViewAllGameVersions)
        ? <Container><Alert variant="warning" className="mt-3">You do not have permission to view this <strong>Game Version.</strong> You'll need to request permission from a user admin.</Alert></Container>
        : <Routes>
            {/* Require Game Environment */}
            <Route path={matchDeeply(PubRouteItems.GameEnvironments, PubRouteItems.GameEnvironmentIdParam, PubRouteItems.Distributions)} element={<DistributionsIndex />}/>

            <Route path={joinRoute(PubRouteItems.GameEnvironments,PubRouteItems.GameEnvironmentIdParam, PubRouteItems.Experiments)} element={<ExperimentsContainer />}/>
            <Route path={joinRoute(PubRouteItems.GameEnvironments, PubRouteItems.GameEnvironmentIdParam, PubRouteItems.Experiments, PubRouteItems.AddExperiment)}
                   element={rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditExperiments) ? <ExperimentContainer /> : <NoAccess/>}/>
            <Route path={joinRoute(PubRouteItems.GameEnvironments, PubRouteItems.GameEnvironmentIdParam, PubRouteItems.Experiments, PubRouteItems.ExperimentIdParam)}
                   element={rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditExperiments) ? <ExperimentContainer /> : <NoAccess/>}/>

            <Route path={joinRoute(PubRouteItems.GameEnvironments,PubRouteItems.GameEnvironmentIdParam,PubRouteItems.Calendar)} element={<EntityCalendarContainer />} />

            {/* Dont' Require Game Environment */}
            <Route path={PubRouteItems.Categories} element={<Categories />}/>
            <Route path={joinRoute(PubRouteItems.Categories, PubRouteItems.AddCategory)} element={<CategoryContainer />}/>
            <Route path={joinRoute(PubRouteItems.Categories, PubRouteItems.CategoryIdParam, PubRouteItems.AddSubCategory)} element={<CategoryContainer />}/>
            <Route path={joinRoute(PubRouteItems.Categories, PubRouteItems.CategoryIdParam)} element={<CategoryContainer />}/>
            <Route path={matchDeeply(PubRouteItems.Categories, PubRouteItems.CategoryIdParam)} element={<CategoryIndex />} />

            <Route path={matchDeeply(PubRouteItems.Selections)} element={rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditSelection) ? <Selections /> : <NoAccess/>} />
          </Routes>
})

