import { naturalSort, orderArray }  from '../../../utilities';
import { ICheckedEntity }           from './Entities';
import { IEntityOrder }             from './EntitiesTable';
import { IRootStore }               from '../../../model/RootStore';


export const EntitiesTableRowOrdering = (order: IEntityOrder, checkedEntityVersions: ICheckedEntity[], rootStore: IRootStore) => {

  const booleanValueReassign = (value: string | null) => {
    switch (value) {
      case "true":
        return "a";
      case "false":
        return "b";
      default:
        return "c";
    }
  }


  // For Properties
  if (order.orderLevel === "property") {

    //Need to identify the property to order by.
    const propertyIndex = checkedEntityVersions[0].entityVersion.properties.findIndex(obj => obj.kindPropertyKey === order.orderValue); //Assumes the property index of a given property will be the same for all entities of a kind.

    return checkedEntityVersions.sort((a, b) => {

      let valueA: any = a.entityVersion.properties[propertyIndex].value; //Gets the value of the chosen property to order by for entity A
      let valueB: any = b.entityVersion.properties[propertyIndex].value; //Gets the value of the chosen property to order by for entity B

      // Null checks and replace
      if (order.type === "Integer") {
        valueA = (valueA === null || isNaN(parseInt(valueA))) ? 99999999 : parseInt(valueA) //Check for null values. Avoids issues with ordering mixed types.
        valueB = (valueB === null || isNaN(parseInt(valueB))) ? 99999999 : parseInt(valueB)
        return orderArray(valueA, valueB, order.descending);
      } else if (order.type === "Boolean") {
        valueA = booleanValueReassign(valueA)
        valueB = booleanValueReassign(valueB)
      } else {
        valueA = valueA === null ? valueA = "zzzzzz" : valueA //Check for null values. Avoids issues with ordering mixed types.
        valueB = valueB === null ? valueB = "zzzzzz" : valueB //Check for null values. Avoids issues with ordering mixed types.
      }

      // For Property with type "Selection". This is required to order on selection name not value.
      if (order.type === "Selection" || order.type === "EntitySelection") {
        const kind = rootStore.kindVersionStore.kindVersions.find(kindVersion => kindVersion.kind.id === a.entityVersion.kindVersionSummary.kindId); //find kind in kinds

        if (kind) { //if kind exists
          const kindProperty = kind.properties.find(property => property.id === a.entityVersion.properties[propertyIndex].kindPropertyId); //find kind property

          if (kindProperty) {  //if kind property exists
            const selectionId = kindProperty.selectionId; //get the selection id
            const selection = rootStore.selectionStore.selections.find(selection => selection.id === selectionId); //find selection in selections

            if (selection) { //if selection exists
              const aSelectionOption: any = selection.options.find(option => option.value === valueA); //find selection option for value a
              if (aSelectionOption) //if option exists
                valueA = aSelectionOption.name; //update value a with selection option name

              const bSelectionOptionVal: any = selection.options.find(option => option.value === valueB); //find selection option for value a
              if (bSelectionOptionVal) //if option exists
                valueB = bSelectionOptionVal.name; //update value a with selection option name
            }
          }
        }
      }
      return naturalSort(valueA, valueB, order.descending)
    })
  }

  // Below "a" and "b" should be ICheckedEntity. "any" added to remove errors due to string matching on object property.
  if (order.orderLevel === "entity") {
    return checkedEntityVersions.sort((a: any, b: any) => {
      return naturalSort(a.entityVersion.entity[order.orderValue], b.entityVersion.entity[order.orderValue], order.descending);
    })
  }

  if (order.orderLevel === "entityVersion") {
    return checkedEntityVersions.sort((a: any, b: any) => {
      return naturalSort(a.entityVersion[order.orderValue], b.entityVersion[order.orderValue], order.descending);
    })
  }
}
