import React, {FC} from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {NotificationHandler}            from "@yakoffice/notification-handler";
import {ModalHandler}                   from '@yakoffice/custom-modal';
import './app.sass';
import {Publisher} from './publisher';
import {matchDeeply, NotFound} from "@yakoffice/shared-components";
import {PubRouteItems} from "../routes";
import {WithAuthentication, WithAuthorisation} from "@yakoffice/with-authorisation";
import {WithPublisherAuthorisation} from "../../auth/WithPublisherAuthorisation";

const App : FC = () => {
        return <div id="app">
                <BrowserRouter>
                    <Routes>
                        <Route path={matchDeeply(PubRouteItems.Projects, PubRouteItems.ProjectIdParam)} element={
                          <NotificationHandler>
                            <ModalHandler>
                              <WithAuthentication>
                                <WithAuthorisation toolId="publisher">
                                  <WithPublisherAuthorisation>
                                    <Publisher />
                                  </WithPublisherAuthorisation>
                                </WithAuthorisation>
                              </WithAuthentication>
                            </ModalHandler>
                          </NotificationHandler>
                        }/>
                      <Route path={"*"} element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </div>
}

export default App;
