import {getRoot, getSnapshot, Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";
import {EntityProperty, IEntityProperty} from "../entity/EntityProperty";
import {ExperimentProperty, IExperimentProperty} from "./ExperimentProperty";
import type {IKindVersion} from "../kind/KindVersion";
import type {IKindProperty} from "../kind/KindProperty";
import {RootStore} from "../RootStore";
import { IGameEnvironment } from '../gameEnvironment/GameEnvironment';
import { IGameVersion } from '../gameVersion/GameVersion';
import { IVariant, Variant } from './Variant';
import { VariantValue } from './VariantValue';

export const Experiment = types.model(
    "Experiment",
    {
            id: types.optional(types.identifierNumber, GenerateId),
            gameVersionId: 0,
            gameEnvironmentId: 0,
            name: "",
            description: "",
            comment: types.maybeNull(types.string),
            status: types.optional(types.string, "draft"),
            lowerBound : 0,
            upperBound : 0,
            variants: types.array(Variant),
            inLatestDistribution: false,
            inAnyDistribution: false,
            properties: types.array(ExperimentProperty),
            specifications: types.array(EntityProperty),
            createdAt: "",
            originalAuthor: "",
            updatedAt: "",
            lastAuthor: "",
            isNew: false,
            requiresUpdating: false,
            googleSheetId: types.maybeNull(types.string)

    })
    .views(self => ({
      isNewExperiment() {
        return self.isNew;
      },
      getNextVariantName(variant: IVariant | null) {
        return variant == null ? "A" : String.fromCharCode(variant.name.charCodeAt(0) + 1);
      },
    }))
    .actions(self => ({
      setName(name: string) {
        self.name = name;
      },
      setDescription(name: string) {
        self.description = name;
      },
      setComment(name: string) {
        self.comment = name;
      },
      setLowerBound(value: number) {
        self.lowerBound = value;
      },
      setUpperBound(value: number) {
        self.upperBound = value;
      },
      addVariant(){
        // const lastVariant = self.variants.length > 0 ? self.variants[self.variants.length -1] : null;
        const variant = Variant.create({name: self.getNextVariantName(self.variants.slice(-1)[0])});
        self.variants.push(variant);
        self.properties.forEach(p => p.addVariantValue(variant))
      },
      removeVariant(variant: IVariant){
        self.properties.forEach(p => p.removeVariantValue(variant))
        self.variants.remove(variant)
        if(self.variants.length > 0){
          let currentVariant : IVariant | null = null;
          for(const variant of self.variants){
            variant.setName(self.getNextVariantName(currentVariant));
            currentVariant = variant;
          }
        }
      },
      addProperty() {
        const property = ExperimentProperty.create({ isNew: true, values: self.variants.map(v => VariantValue.create({variantId: v.id}))});
        self.properties.push(property);
        return property
      },
      addPropertyFromSelectedEntity(experimentProperty: IExperimentProperty) {
        const property = ExperimentProperty.create(
          {
            isNew: true,
            values: self.variants.map(v => VariantValue.create({variantId: v.id}))
          });
        property.setKindDetails(experimentProperty.kindId, experimentProperty.kindName);
        property.setEntityDetails(experimentProperty.entityId, experimentProperty.entityName);
        self.properties.push(property);
        return property
      },
      removeProperty(property: IExperimentProperty) {
        self.properties.splice(self.properties.indexOf(property), 1);
      },
      addSpecification(specificationKind: IKindVersion, specificationProperty: IKindProperty) {
        self.specifications.push(EntityProperty.create({
          kindPropertyId: specificationProperty.id,
          kindPropertyKindId: specificationKind.kind.id,
          kindPropertyKey: specificationProperty.key,
          value: specificationProperty.default
        }));
      },
      removeSpecification(property: IEntityProperty) {
        self.specifications.splice(self.specifications.indexOf(property), 1);
      },
      copyExperiment(gameVersion: IGameVersion, gameEnvironment: IGameEnvironment, properties : IExperimentProperty[] = self.properties, specifications: IEntityProperty[] = self.specifications) {
        const snapshot = getSnapshot(self);

        const copiedVariants = self.variants.map(v => ({...v, id:GenerateId()}));
        return Experiment.create({
          ...snapshot,
          id: GenerateId(),
          gameEnvironmentId: gameEnvironment.id,
          gameVersionId: gameVersion.id,
          variants: copiedVariants,
          properties: properties.map(({values,...p}) => (
            {
              ...p,
              id: GenerateId(),
              values: values.map(vv => (
                {
                  ...vv,
                  variantId: copiedVariants[self.variants.findIndex(v => v.id === vv.variantId)].id,
                  id: GenerateId()
                }))
            })),
          specifications: specifications.map(s => ({...s, id: GenerateId()})),
          isNew: true
        });
      },
      reorderProperties(movedPropertyId: number, afterPropertyId: number | null) {
        const movedProperty = self.properties.find(p => p.id === movedPropertyId);
        if (movedProperty) {
          const tempList = [...self.properties];
          const indexOfMovedProperty = tempList.indexOf(movedProperty);
          tempList.splice(indexOfMovedProperty, 1);

          if (afterPropertyId == null)
            self.properties.replace([...tempList, movedProperty]);
          else {
            const indexOfAfterProperty = tempList.findIndex(p => p.id === afterPropertyId);
            tempList.splice(indexOfAfterProperty, 0, movedProperty)
            self.properties.replace(tempList);
          }
        }
      },
      updateSpecificationsToToLatestVersion() {
        const updatedSpecifications: IEntityProperty[] = [];

        const specificationKinds: IKindVersion[] = getRoot<typeof RootStore>(self).specificationsForExperimentsStore.getKinds();

        self.specifications.forEach(specificationEntityProperty => {
          const specificationKind = specificationKinds.find(specificationKind => specificationKind.kind.id === specificationEntityProperty.kindPropertyKindId)
          if (specificationKind) {
            // Could have different property ids due to versioning so do best effort match on key
            const specificationKindProperty = specificationKind.properties.find(specificationKindProperty => specificationKindProperty.key === specificationEntityProperty.kindPropertyKey)
            if (specificationKindProperty) {
              specificationEntityProperty.setKindPropertyId(specificationKindProperty.id)
              updatedSpecifications.push(specificationEntityProperty);
            } else {
              self.requiresUpdating = true; // Specification Kind property has been deleted/changed key
            }
          } else {
            self.requiresUpdating = true;// Specification Kind has been deleted
          }
        });

        self.specifications.replace(updatedSpecifications);
      },
      setGoogleSheetId(googleSheetId: string | null) {
        self.googleSheetId = googleSheetId;
      },
    }))

export interface IExperiment extends Instance<typeof Experiment> {}
