import {destroy, detach, flow, Instance, types} from "mobx-state-tree";
import type {IKindVersion} from "./KindVersion";
import {KindVersion} from "./KindVersion";
import type {IKindVersionVm} from "@yakoffice/publisher-types";
import {KindStatus} from "@yakoffice/publisher-types";
import {MapKindVmToKindModel} from "./KindVersionStore";
import {observable} from "mobx";
import type {IKindVersionSearchParams} from "../../api/requests/kinds/kindVersionApiGateway";
import {KindVersionApiGateway} from "../../api/requests/kinds/kindVersionApiGateway";
import { IGameVersion } from '../gameVersion/GameVersion';

const AbstractSpecificationKindStore = types.model(
    "AbstractSpecificationKindStore",
    {
        apiGateway: KindVersionApiGateway
    })
    .actions(self => {
      // NB.  This local array of detached Kinds is required to ensure there are no types.reference lookup key clashes due to having the
      //  same kind loaded in the KindStore and the SpecificationKindStore
      const specificationKinds: IKindVersion[] = observable([]);
      const findKinds = flow(function* (searchParams: IKindVersionSearchParams) {
        try {
          const kindVms = (yield self.apiGateway.getCurrentKindVersions(searchParams)) as IKindVersionVm[];
          return kindVms.map(vm => detach(KindVersion.create(MapKindVmToKindModel(vm))));
        } catch (e : any) {
          throw new Error(`Failed to find specification Kinds: ${e.message}`);
        }
      })
      const loadKinds = flow(function* (searchParams: IKindVersionSearchParams) {
          const detachedKinds = yield findKinds(searchParams);
          specificationKinds.splice(0, specificationKinds.length, ...detachedKinds)
      })
      const getKinds = () => {
        return specificationKinds
      };
      const clearStore = () => {
        specificationKinds.forEach(k => destroy(k))
      };

      return {
        findKinds,
        loadKinds,
        getKinds,
        clearStore
      }
    });

export const SpecificationsForKindsStore = AbstractSpecificationKindStore
    .named("SpecificationsForKindsStore")
    .actions(self => {

      const findSpecifications = async (gameVersion: IGameVersion) => {
        return await self.findKinds({
          gameVersionId: gameVersion.id,
          isSpecificationForKinds: true,
          status: KindStatus.Live
        });
      };
      const loadSpecifications = async () => {
        await self.loadKinds({
          isSpecificationForKinds: true,
          status: KindStatus.Live
        });
      };

      return { findSpecifications, loadSpecifications }
    });
export interface ISpecificationsForKindsStore extends Instance<typeof SpecificationsForKindsStore> {}

export const SpecificationsForExperimentsStore = AbstractSpecificationKindStore
    .named("SpecificationsForExperimentsStore")
    .actions(self => {
      const findSpecifications = async (gameVersion: IGameVersion) => {
        return await self.findKinds({
          gameVersionId: gameVersion.id,
          isSpecificationForExperiments: true,
          status: KindStatus.Live
        });
      };
      const loadSpecifications = async () => {
        await self.loadKinds({
          isSpecificationForExperiments: true,
          status: KindStatus.Live
        });
      };

      return { findSpecifications, loadSpecifications }
    });
export interface ISpecificationsForExperimentsStore extends Instance<typeof SpecificationsForExperimentsStore> {}

export const KindsForEntitySelectionStore = AbstractSpecificationKindStore
    .named("KindsForEntitySelectionStore")
    .actions(self => {

        const loadEntitySelectionKinds = async () => {
            await self.loadKinds({
                isSpecificationForKinds: false,
                status: KindStatus.Live
            });
        };

        return {loadEntitySelectionKinds}
    });
export interface IKindsForEntitySelectionStore extends Instance<typeof KindsForEntitySelectionStore> {}
