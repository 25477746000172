import React, { ChangeEvent, FC, useContext, useState } from 'react';
import { runInAction}                                   from 'mobx';
import { Link, useNavigate }                             from 'react-router-dom';
import { observer}                                      from 'mobx-react-lite';
import ToolTip                                          from 'react-bootstrap/Tooltip';
import OverlayTrigger                                   from 'react-bootstrap/OverlayTrigger';
import { useGetEntityLink }                             from '../../routes';
import RootStoreContext                                 from '../../../model/RootStoreContext';
import type {IEntityVersion}                                 from '../../../model/entity/EntityVersion';
import {useMultiSelect}                                 from '../../components/use-multi-select/useMultiSelect';
import { StatusLabel }                                  from '../../components/status-label/StatusLabel';
import { YoPublisherTable, YoTableClassNames }          from '../../components/yo-publisher-table/YoPublisherTable';
import {PropertyValueDisplay}                           from '../common/PropertyValueDisplay';
import {SpecificationProperties}                        from '../common/SpecificationProperties';
import {SpecificationSchedulingStatus}                  from '../common/SpecificationSchedulingStatus';
import type {ICheckedEntity}                                 from './Entities';
import { EntitiesTableRowOrdering }                     from './EntitesTableRowOrdering';


interface PropsType {
    cssColorClass           : string;
    checkedEntityVersions   : ICheckedEntity[];
    statusStore             : any;
}

export interface IEntityOrder {
  orderValue: string;
  orderLevel: string;
  descending: boolean;
  type      : string;
}


export const EntitiesTable : FC<PropsType> = observer((props) => {

    const rootStore         = useContext(RootStoreContext);
    const getEntityLink     = useGetEntityLink();
    const navigate           = useNavigate();
    const multiSelect       = useMultiSelect<ICheckedEntity>({
                                                             items: props.checkedEntityVersions.filter(e => props.statusStore.statusFilters.includes(e.entityVersion.entity.status)),
                                                             selectItem: (item, selected) => {runInAction(() => item.checked = selected)}
                                                           });
    const [order, setOrder] = useState({
                                       orderValue: "name",
                                       orderLevel: "entityVersion",
                                       descending: false,
                                       type      : "string",
                                      } as IEntityOrder);

    const handleEntityCheckboxToggle = (checked: boolean, entity: ICheckedEntity) => {
        multiSelect.handleItemSelection(entity, checked)
    }

    const handleCheckAllEntities = (e : ChangeEvent<HTMLInputElement>) => {
        multiSelect.handleSelectAll(e.target.checked);
    }

    const getPropTitlesFromKind = () => {
        return rootStore.kindVersionStore.getCurrentKindVersion().properties.map(property => {
            return  <OverlayTrigger key={`overlay-${property.key}`} placement="right" overlay={<ToolTip id="button-tooltip">{property.description}</ToolTip>}>
              <th onClick={() => {
                    setOrder({
                               orderValue: property.key,
                               orderLevel: "property",
                               descending: order.orderValue === property.key ? !order.descending : false,
                               type: property.type})
              }}
                key={property.key} className="link d-none d-md-table-cell">{property.key} {order.orderValue === property.key ? (!order.descending ? <i className="fas fa-sort-alpha-down" /> : <i className="fas fa-sort-alpha-down-alt" /> ) :  <small><i className="fas fa-sort text-muted" /></small>}</th>
            </OverlayTrigger>
        })
    }

    const entityLink = (entityVersion : IEntityVersion) => {return getEntityLink({entityId: entityVersion.entity.id})};

    const entityRedirect = (id: number) => {
      navigate(getEntityLink({entityId: id}))
    }

    const entityRow = () => {

      const renderArray: any[]  = [];

      if(order.orderValue)
        EntitiesTableRowOrdering(order, props.checkedEntityVersions, rootStore);

      props.checkedEntityVersions.forEach(checkedEntry => {

            renderArray.push(
                <tr key={checkedEntry.entityVersion.id}
                    className={`linked-row ${checkedEntry.entityVersion.isOnOldVersionOfKind() ? "table-info" : ""} ${checkedEntry.entityVersion.entity.status}`}
                    data-testid={checkedEntry.entityVersion.name}
                    data-value={rootStore.kindVersionStore.getCurrentKindVersion().kind.id} >
                     <td>
                         <input type="checkbox" className={""} checked={checkedEntry.checked} data-testid="cbEntity"
                                onChange={(event) => handleEntityCheckboxToggle(event.target.checked, checkedEntry)}/>
                     </td>
                    <td data-testid="tdEntityName">
                      <Link to={entityLink(checkedEntry.entityVersion)}>
                        {checkedEntry.entityVersion.isOnOldVersionOfKind() &&<i className="fas fa-exclamation-triangle me-2 text-warning"/>}
                        {checkedEntry.entityVersion.name}
                      </Link>
                    </td>
                    <td className="d-none d-md-table-cell" data-testid="tdEntityDescription">
                      <Link to={entityLink(checkedEntry.entityVersion)}>{checkedEntry.entityVersion.description}</Link>
                    </td>
                    {
                        rootStore.kindVersionStore.getCurrentKindVersion().properties.map((kp, index) => {

                            const entityProperty = checkedEntry.entityVersion.properties.find(ep => ep.kindPropertyKey === kp.key);

                            if(entityProperty && entityProperty.kindPropertyKindId === rootStore.kindVersionStore.getCurrentKindVersion().kind.id) {

                                return <td key={`${checkedEntry.entityVersion.id}-${kp.id}`} className="d-none d-md-table-cell" data-testid={`tdEntity${kp.key}`}>
                                  <Link to={entityLink(checkedEntry.entityVersion)}>
                                    <PropertyValueDisplay kindProperty={kp} value={entityProperty.value} />
                                  </Link>
                                </td>;
                            }
                            else
                                return <td key={`noEntityProperty-${index}+${kp.id}`} onClick={() => entityRedirect(checkedEntry.entityVersion.entity.id)} className="d-none d-md-table-cell" />;
                        })
                    }
                    {rootStore.kindVersionStore.getCurrentKindVersion().canUseSpecifications &&
                     <td data-testid="tdEntitySpecifications">
                       <SpecificationProperties entityLink={entityLink} entityVersion={checkedEntry.entityVersion} entityProperties={checkedEntry.entityVersion.getSpecificationProperties().filter(p => !checkedEntry.entityVersion.getSchedulingSpecificationProperties().includes(p))} specificationKindVersions={rootStore.specificationsForKindsStore.getKinds()} />
                     </td>
                    }
                    {rootStore.kindVersionStore.getCurrentKindVersion().canUseSpecifications &&
                      <td>
                         <SpecificationSchedulingStatus entityLink={entityLink} entityVersion={checkedEntry.entityVersion} entityProperties={checkedEntry.entityVersion.getSchedulingSpecificationProperties()} />
                      </td>
                    }
                    <td>
                      <Link to={entityLink(checkedEntry.entityVersion)}>
                        <StatusLabel status={checkedEntry.entityVersion.entity.status} />
                      </Link>
                    </td>
                    <td>
                        {checkedEntry.entityVersion.inLatestDistribution && <i className="fas fa-check-circle text-success float-end"/>}
                        {!checkedEntry.entityVersion.inLatestDistribution && checkedEntry.entityVersion.entity.inAnyDistribution && <i className="fas fa-check-circle text-warning float-end"/>}
                    </td>
                </tr>
            )
        });
        return renderArray;
    }

    return (
        <YoPublisherTable responsive variant={props.cssColorClass} className={YoTableClassNames.MinWidthContainer} data-testid="tblEntities">
            <thead className="table-dark">
            <tr>
                <th><input type="checkbox" className={""} id={'select-all'} onChange={handleCheckAllEntities}/></th>
                <th className="link" onClick={() => {setOrder({ orderValue: "name", orderLevel: "entityVersion", descending: order.orderValue === "name" ? !order.descending : false, type: "string"})}}>Name {order.orderValue === "name" ? (!order.descending ? <i className="fas fa-sort-alpha-down" /> : <i className="fas fa-sort-alpha-down-alt" /> ) : <small><i className="fas fa-sort text-muted" /></small>}</th>
                <th className="link d-none d-md-table-cell" onClick={() => {setOrder({ orderValue: 'description', orderLevel: "entityVersion", descending: order.orderValue === "description" ? !order.descending : false, type: "string"})}}>Description {order.orderValue === "description" ? (!order.descending ? <i className="fas fa-sort-alpha-down" /> : <i className="fas fa-sort-alpha-down-alt" /> ) : <small><i className="fas fa-sort text-muted" /></small>}</th>
                {getPropTitlesFromKind()}
                {rootStore.kindVersionStore.getCurrentKindVersion().canUseSpecifications && <th>Specifications</th>}
                {rootStore.kindVersionStore.getCurrentKindVersion().canUseSpecifications && <th>Scheduling</th>}
                <th className="link" onClick={() => {setOrder({orderValue: "status", orderLevel: "entity", descending: order.orderValue === "status" ? !order.descending : false, type: "string"})}}>Status {order.orderValue === "status" ? (!order.descending ? <i className="fas fa-sort-alpha-down" /> : <i className="fas fa-sort-alpha-down-alt" /> ) : <small><i className="fas fa-sort text-muted" /></small>}</th>
                <th />
            </tr>
            </thead>
            <tbody>
            {props.checkedEntityVersions.length > 0 ? entityRow() : <tr><td colSpan={42} className="text-center">No Entities Found</td></tr>}
            </tbody>
        </YoPublisherTable>
    )
});
