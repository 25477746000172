import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../../../model/RootStoreContext";
import {autorun} from "mobx";
import {IDistribution} from "../../../model/distributions/Distribution";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { IEntityVersionVm } from '@yakoffice/publisher-types';

interface useDistributedEntitiesResult{
    isLoaded : boolean;
    entityVersions : IEntityVersionVm[]
}

export const useDistributedEntities = (distribution: IDistribution | null) => {
    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useDistributedEntitiesResult>({ isLoaded: false, entityVersions: [] })

    useEffect(() => {
        autorun(() => {
          setResult({ isLoaded: false, entityVersions: [] })

            const entityVersionLoader = distribution != null
                ? () => rootStore.entityVersionStore.getDistributedEntityVersions(distribution.id)
                : () => rootStore.entityVersionStore.getPublishedEntityVersions();

            entityVersionLoader()
                .then((entities) => {
                  setResult({ isLoaded: true, entityVersions: entities })
                })
                .catch(e => publishNotification({
                    notificationType: NotificationType.Error,
                    title: "Error loading distributed entities",
                    message: `${e.message}`,
                    footer: "Please try refreshing"
                }))
        })
    }, [rootStore.entityVersionStore, publishNotification, distribution]);

    return result;
};
