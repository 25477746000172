import React, { FC, useState } from 'react';
import {observer}       from 'mobx-react-lite';
import {useFindKinds}   from '../../../loaders/useFindKinds';
import {ISelection}     from '../../../model/selections/Selection';
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {camelToTitle} from '../../../utilities';
import { YoFormBox, YoFormBoxBody, YoFormBoxHeader } from '../../components/yo-form-box/YoFormBox';
import styles from './UsedInKind.module.sass'

interface PropsType {
  selection: ISelection;
}

export const UsedInKind : FC<PropsType> = observer((props) => {

  const [searchParams] = useState({hasPropertyUsingSelectionId: props.selection.id})
  const usedInKindsResult = useFindKinds(searchParams)

  return (
    <YoFormBox className={styles.form}>
      <YoFormBoxHeader className={styles.formHeader}>
        <i className="fa fa-info-circle" /> Used In Kinds
      </YoFormBoxHeader>
      <YoFormBoxBody className={styles.formBody}>
        {usedInKindsResult.isLoaded
         ? <ul>{usedInKindsResult.kinds.length > 0 ? usedInKindsResult.kinds.map(kind => { return <li key={kind.id}>{camelToTitle(kind.name)}</li>}) : "Not in use"}</ul>
         : <CustomSpinner spinnerText={"Loading kinds"} className={styles.spinner} />
        }
      </YoFormBoxBody>
    </YoFormBox>
  );
});
