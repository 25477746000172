import React, {FC}  from 'react';
import {observer}   from 'mobx-react-lite';
import Button       from 'react-bootstrap/Button';
import {ISelection} from '../../../model/selections/Selection';
import styles from './SelectionButtons.module.sass'
import GoogleSheetsManagerDropDown from '../../components/googlesheets-manager-dropdown/googlesheetsManagerDropDown';


interface PropsType {
  selection         : ISelection;
  isEditedSelection : boolean;
  saveSelection     : () => void;
  archiveSelection  : (archive: boolean) => void;
  deleteSelection   : () => void;
  handleCreateSheet: () => void
  handleViewSheet: () => void
  handleUploadSheet: () => void
  handleRefreshSheet: () => void
  handleDeleteSheet: () => void
}


export const SelectionButtons : FC<PropsType> = observer((props) => {

  return (
    <div className={styles.selectionButtons}>
      {(!props.isEditedSelection && !props.selection.isNewSelection()) &&
        <GoogleSheetsManagerDropDown
          style={{"display": 'inline'}}
          variant={'outline-light'}
          googleSheetExists={props.selection.googleSheetId != null}
          handleCreateSheet={props.handleCreateSheet}
          handleViewSheet={props.handleViewSheet}
          handleRefreshSheet={props.handleRefreshSheet}
          handleUploadSheet={props.handleUploadSheet}
          handleDeleteSheet={props.handleDeleteSheet}
        />
      }
      {(props.isEditedSelection || props.selection.isNewSelection()) &&
       <Button variant="success" onClick={props.saveSelection} data-testid="btnSaveSelection"><i className="fas fa-save"/> Save</Button>
      }
      {!props.selection.isNewSelection() && props.selection.archived === false &&
       <Button variant="warning" onClick={() => props.archiveSelection(true)} data-testid="btnArchiveSelection"><i className="fas fa-archive"/> Archive</Button>
      }
      {props.selection.archived === true &&
       <Button variant="warning" onClick={() => props.archiveSelection(false)} data-testid="btnUnarchiveSelection"><i className="fas fa-archive"/> Unarchive</Button>
      }
      {!props.selection.isNewSelection() &&
       <Button variant="danger" onClick={props.deleteSelection} data-testid="btnDeleteSelection"><i className="fas fa-trash"/> Delete</Button>
      }
    </div>
  )
});
