import React from 'react';
import {Link}               from 'react-router-dom';
import {PubRouteItems} from '../../routes';

export const AddKindButton = () => {

  return (
    <Link to={PubRouteItems.AddKind} className="btn btn-primary me-1">
      <i className="fas fa-file-medical text-white me-1" />New Kind
    </Link>
  )
}
