import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../model/RootStoreContext";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import type {IExperiment} from "../model/experiment/Experiment";
import type {IExperimentSearchParams} from "../api/requests/ExperimentApiGateway";

export interface useExperimentsResult{
    isLoaded : boolean;
    experiments    : IExperiment[] | null
}

export const useExperiments = (searchParams: IExperimentSearchParams) => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useExperimentsResult>({ isLoaded: false, experiments: [] })

  useEffect(() => {
    setResult({ isLoaded: false, experiments: [] })

    rootStore.experimentStore.loadExperiments(searchParams)
      .then(() => {
        setResult({ isLoaded: true, experiments: rootStore.experimentStore.experiments })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading experiments",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.experimentStore, publishNotification, searchParams, rootStore.gameVersionStore.currentGameVersion, rootStore.gameEnvironmentStore.currentGameEnvironment]);

  return result;
};
