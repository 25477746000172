import React, {FC, ReactElement} from "react";
import FieldLayout from "../../components/inputs/fieldLayout";
import {IEntityProperty} from "../../../model/entity/EntityProperty";
import {IKindProperty} from "../../../model/kind/KindProperty";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { observer } from 'mobx-react-lite';
import { RowDivider } from '../../components/dividers/RowDivider';
import { FeatureHeader, LabelHeader } from '@yakoffice/shared-components';

interface PropsType {
    canUseSpecifications: boolean;
    kindProperties : IKindProperty[]
    entityProperties : IEntityProperty[];
    nameControl: ReactElement;
    descriptionControl: ReactElement;
    propertyControlProvider: (kp: IKindProperty,ep: IEntityProperty) => ReactElement;
    propertyComparer?: (ep: IEntityProperty, kp: IKindProperty) => boolean;
}

export const EntityProperties : FC<PropsType> = observer((
  {
    propertyComparer = (ep: IEntityProperty, kp: IKindProperty) => {  return ep.kindPropertyId === kp.id},
    ...props
  }
  ) => {
    return (
        <React.Fragment>
            <Row>
                <Col md="12" lg="6">
                    <FieldLayout
                        label={<LabelHeader><i className="fas fa-signature"/>Name</LabelHeader>}
                        required={true}>
                        {props.nameControl}
                    </FieldLayout>
                </Col>
                <Col md="12" lg="6">
                    <FieldLayout
                        label={<LabelHeader><i className="fas fa-align-left"/>Description</LabelHeader>}
                        required={true}>
                        {props.descriptionControl}
                    </FieldLayout>
                </Col>
            </Row>
            <RowDivider />
            <Row>
                <Col>
                    <FeatureHeader size="sm"><i className="fas fa-home" /> Properties</FeatureHeader>
                    <Row>
                        {props.kindProperties.map(kindProperty => {
                                const entityProperty = props.entityProperties.find(ep => propertyComparer(ep, kindProperty));

                                const colWidthLg = props.canUseSpecifications ? "6" : "4";

                                return entityProperty &&
                                    <Col lg={colWidthLg} key={kindProperty.id}>
                                        <FieldLayout label={kindProperty.key}
                                                     labelForId={kindProperty.id.toString()}
                                                     description={kindProperty.description}
                                                     required={kindProperty.isRequired()}>
                                            {props.propertyControlProvider(kindProperty, entityProperty)}
                                        </FieldLayout>
                                    </Col>
                            }
                        )}
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    )
});
