import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { EntityPropertyFactory } from '../entity/EntityPropertyFactory';
import Form from 'react-bootstrap/Form';
import { FormatPropertyValue } from '../common/PropertyValueDisplay';
import { IKindProperty } from '../../../model/kind/KindProperty';
import { IExperimentProperty } from '../../../model/experiment/ExperimentProperty';
import { useRootStore } from '../../../loaders/useRootStore';
import { IVariantValue } from '../../../model/experiment/VariantValue';
import { Col, Row } from 'react-bootstrap';
import { IVariant } from '../../../model/experiment/Variant';

interface PropTypes{
  variants: IVariant[]
  experimentProperty: IExperimentProperty
  variantValue: IVariantValue
  kindProperty: IKindProperty | null
}

export const ExperimentVariantValue : FC<PropTypes> = observer(({variants, experimentProperty, variantValue, kindProperty}) => {
  const rootStore = useRootStore();

  const variantName = variants.find(v => v.id === variantValue.variantId)?.name

  return <Row key={variantValue.id}  className="mt-2">
    <Col md={3} className="text-end align-self-center">
      {variantName}:
    </Col>
    <Col md={9}>
      {kindProperty != null
        ?<EntityPropertyFactory kindProperty={kindProperty}
                                getPropertyValue={() => variantValue.value}
                                setPropertyValue={variantValue.setValue}
                                variantName={variantName}
        />
        :<Form.Control
            type="text"
            id={variantValue.id.toString()}
            disabled={true}
            defaultValue={FormatPropertyValue(rootStore, experimentProperty.kindPropertyType, experimentProperty.kindPropertySelectionId, experimentProperty.kindPropertyEntitySelectionKindId, variantValue.value) || ""}
        />
      }
    </Col>
  </Row>

})
