import React, {FC} from 'react';
import Col                  from 'react-bootstrap/Col';
import Card                 from 'react-bootstrap/Card';
import {IGameEnvironment}   from '../../../model/gameEnvironment/GameEnvironment';
import { YoCard } from '../../components/yo-card/YoCard';
import { Link } from 'react-router-dom';

interface PropsType{
    gameEnvironments: IGameEnvironment[],
}

export const GameEnvironmentsCard : FC<PropsType> = (props) => {

  return  <React.Fragment>
        {props.gameEnvironments.map(ge => {
            return  <Col xl="2" lg="3" md="3" sm="6" xs="12" key={ge.id} className="mb-3">
                        <YoCard className="h-100" data-testid="yoCardGameEnvironment">
                            <Card.Body>
                              <Link to={ge.id.toString()}>
                                <Card.Title>{ge.name}</Card.Title>
                                <Card.Text>{ge.description}</Card.Text>
                              </Link>
                            </Card.Body>
                        </YoCard>
                    </Col>
    })}
    </React.Fragment>
};
