import {Instance, types} from 'mobx-state-tree';
import {GenerateId} from "../Utils";

export const EntityProperty = types.model(
    "EntityProperty",
    {
        id: types.optional(types.identifierNumber, GenerateId),
        kindPropertyId: 0,
        kindPropertyKindId: 0,
        kindPropertyKey: "",
        value: types.maybeNull(types.string)
    })
    .actions( self => ({
      setKindPropertyId(kindPropertyId: number) {
        self.kindPropertyId = kindPropertyId;
      },
      setKindPropertyKindId(kindId: number) {
        self.kindPropertyKindId = kindId;
      },
      setKindPropertyKey(kindPropertyKey: string) {
        self.kindPropertyKey = kindPropertyKey;
      },
      setValue(value: string | null) {
        self.value = value === "" ? null : value;
      }
    }));

export interface IEntityProperty extends Instance<typeof EntityProperty> {}
