import React, {FC} from 'react';
import moment               from 'moment';
import Row                  from 'react-bootstrap/Row';
import Col                  from 'react-bootstrap/Col';
import {IGameEnvironment}   from '../../../model/gameEnvironment/GameEnvironment';
import { InfoBox, InfoBoxHeader, InfoBoxBody } from '@yakoffice/info-box';
import { CollapsePanel } from '@yakoffice/shared-components';

interface PropsType{
    gameEnvironment: IGameEnvironment
}

export const GameEnvironmentHighlights : FC<PropsType> = (props) => {
  return (
    <CollapsePanel>
      <Row>
        {/*<Col sm="3">*/}
        {/*  <InfoBox>*/}
        {/*    <InfoBoxHeader><i className="far fa-calendar-alt" /> Created at</InfoBoxHeader>*/}
        {/*    <InfoBoxBody>{moment(props.gameEnvironment.createdAt).isValid() && moment(props.gameEnvironment.createdAt).format('Do MMMM YYYY, h:mm')}</InfoBoxBody>*/}
        {/*  </InfoBox>*/}
        {/*</Col>*/}
        {/*<Col sm="3">*/}
        {/*  <InfoBox>*/}
        {/*    <InfoBoxHeader><i className="far fa-user" /> Created by</InfoBoxHeader>*/}
        {/*    <InfoBoxBody>{props.gameEnvironment.originalAuthor}</InfoBoxBody>*/}
        {/*  </InfoBox>*/}
        {/*</Col>*/}
        <Col sm="6">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-calendar-alt" /> Updated at</InfoBoxHeader>
            <InfoBoxBody>{moment(props.gameEnvironment.updatedAt).isValid() && moment(props.gameEnvironment.updatedAt).format('Do MMMM YYYY, h:mm')}</InfoBoxBody>
          </InfoBox>
        </Col>
        <Col sm="6">
          <InfoBox>
            <InfoBoxHeader><i className="far fa-user" /> Updated by</InfoBoxHeader>
            <InfoBoxBody>{props.gameEnvironment.lastAuthor}</InfoBoxBody>
          </InfoBox>
        </Col>
      </Row>
    </CollapsePanel>
  )
};
