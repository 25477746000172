import {useContext, useEffect, useState} from "react";
import RootStoreContext from "../model/RootStoreContext";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import type {IExperimentSearchParams} from "../api/requests/ExperimentApiGateway";

export interface useExperimentsCountResult{
    isLoaded : boolean
    count    : number | null
}

export const useExperimentsCount = (searchParams: IExperimentSearchParams) => {
    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useExperimentsCountResult>({isLoaded: false, count: null});

    useEffect(() => {
        setResult({isLoaded: false, count: null})

        rootStore.experimentStore.countExperiments(searchParams)
            .then((count) => {
                setResult({isLoaded: true, count: count})
            })
            .catch(e => publishNotification({
                notificationType: NotificationType.Error,
                title: "Error counting experiments",
                message: `${e.message}`,
                footer: "Please try refreshing"
            }))
    }, [rootStore.experimentStore, publishNotification, searchParams, rootStore.gameVersionStore.currentGameVersion, rootStore.gameEnvironmentStore.currentGameEnvironment]);

    return result;
};
