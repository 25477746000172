import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";

export const Variant = types.model(
  "Variant",
  {
    id: types.optional(types.identifierNumber, GenerateId),
    name: "",
    description: types.maybeNull(types.string),
    allocation : 0,

  })
  .views(self => ({

  }))
  .actions(self => ({
    setName(name: string) {
      self.name = name;
    },
    setDescription(description: string) {
      self.description = description;
    },
    setAllocation(allocation: number) {
      self.allocation = allocation;
    },
  }))

export interface IVariant extends Instance<typeof Variant> {}
