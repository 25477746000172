import React, {FC} from 'react';
import Row                          from 'react-bootstrap/Row';
import Col                          from 'react-bootstrap/Col';
import Form                         from 'react-bootstrap/Form';
import {StringInput}                from '../common/StringInput';
import {IGameEnvironment}           from '../../../model/gameEnvironment/GameEnvironment';
import {observer} from "mobx-react-lite";
import { GameVersionVersionInformation } from '../gameVersions/GameVersionVersionInformation';
import { RowDivider } from '../../components/dividers/RowDivider';
import { FormGroupHeader, LabelHeader } from '@yakoffice/shared-components';

interface PropsType{
    gameEnvironment: IGameEnvironment
}

export const GameEnvironmentMainInfo : FC<PropsType> = observer(({gameEnvironment} : PropsType) => {

    const handleNameChange = (value: string) => {
        gameEnvironment.setName(value);
    };

    const handleDescriptionChange = (value: string) => {
        gameEnvironment.setDescription(value);
    };

    const handleUrlChange = (value: string) => {
      gameEnvironment.setConfigApiPublishingUrl(value);
    };

    const handleSlackNotificationWebhookUrlChange = (value: string) => {
      gameEnvironment.setSlackNotificationWebhookUrl(value);
    }

    return (
        <React.Fragment>
                <Row>
                    <Col xs="12" sm="6">
                        <Form.Group>
                            <Form.Label><LabelHeader><i className="fas fa-signature"/> Name</LabelHeader></Form.Label>
                            <StringInput id='geName' value={gameEnvironment.name} handleOnChange={handleNameChange} data-testid="txtGameEnvironmentName" />
                        </Form.Group>
                    </Col>

                    <Col xs="12" sm="6">
                        <Form.Group>
                            <Form.Label><LabelHeader><i className="fas fa-align-left"/> Description</LabelHeader></Form.Label>
                            <StringInput id='geDescription' value={gameEnvironment.description} handleOnChange={handleDescriptionChange} data-testid="txtGameEnvironmentDescription" />
                        </Form.Group>
                    </Col>
                </Row>
                <RowDivider/>
                <Row>
                    <Col md="6">
                      <FormGroupHeader><i className="fas fa-server" /> Distribution Settings</FormGroupHeader>
                      <Form.Group>
                            <Form.Label>Config Api Publishing Url <GameVersionVersionInformation /></Form.Label>
                            <StringInput id='geUrl' value={gameEnvironment.configApiPublishingUrl} handleOnChange={handleUrlChange} data-testid="txtGameEnvironmentPubUrl" />
                        </Form.Group>
                    </Col>
                    <Col md="6">
                      <FormGroupHeader><i className="fab fa-slack" /> Slack Settings</FormGroupHeader>
                      <Form.Group>
                        <Form.Label>Slack Notification Web Hook Url</Form.Label>
                        <StringInput id='getSlackUrl' value={gameEnvironment.slackNotificationWebhookUrl} handleOnChange={handleSlackNotificationWebhookUrlChange} data-testid="txtGameEnvironmentWebHookUrl" />
                      </Form.Group>
                    </Col>
                </Row>
        </React.Fragment>
    )
});
