import React, {ChangeEvent, FC} from "react";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    value : string;
    handleOnChange: (e: string) => void;
}

export const HexadecimalInput : FC<PropsType> = ({ id, value, handleOnChange, ...props}) => {

  const toUpperCase = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    if (start != null && end != null)
      input.setSelectionRange(start, end)
  };

  return <Form.Control type="text"
                       pattern="[a-fA-F0-9]"
                       maxLength={6}
                       id={id}
                       placeholder="Enter Hexadecimal"
                       value={value}
                       onInput={toUpperCase}
                       onChange={e => handleOnChange(e.target.value)}
                       step="any"
                       {...props}
  />
};
