import Popover from 'react-bootstrap/Popover';
import React, { FC } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export const GameVersionVersionInformation : FC = () => {

  const popover = (
    <Popover id="configUrlInfo" className="mw-100">
      <Popover.Header as="h3">Config Api Publishing Url Substitution</Popover.Header>
      <Popover.Body>
        The config Api Url is dynamically generated at Distribution time using settings from the Game Environment and Game Version.
        <p/>
        For example:
        <ul>
          <li>
            <strong>Game Environment Screen:</strong>
            <ul><li>Config Api Publishing Url =  https://{'{version}'}.config.dev.ppk.yakuto.dev/Distributions/Create/Product</li></ul>
          </li>
          <li>
            <strong>Game Version Screen:</strong>
            <ul><li>Config API Publishing Url Version = 003</li>
            </ul>
          </li>
          <li>
            <strong>Result:</strong>
            <ul>
              <li>https://003.config.dev.ppk.yakuto.dev/Distributions/Create/Product</li>
            </ul>
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  )

  return (
<OverlayTrigger trigger="click" placement="right" overlay={popover}>
  <span className="info"><i className="fa fa-info-circle text-black-50" /></span>
</OverlayTrigger>
)

}
