import React, {FC, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {IExperiment} from "../../../model/experiment/Experiment";
import {ClaimType} from "@yakoffice/publisher-types";
import RootStoreContext from "../../../model/RootStoreContext";
import { Link } from 'react-router-dom';
import {SpecificationProperties} from "../common/SpecificationProperties";
import {useGameEnvironmentCssClass} from '../common/useGameEnvironmentCssClass';
import {FormatPropertyValue} from "../common/PropertyValueDisplay";
import { useGetExperimentLink } from '../../routes';
import {ContentBox, ContentBoxBody, ContentBoxHeader, ContentBoxNavBar} from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import {YoPublisherTable, YoTableClassNames} from '../../components/yo-publisher-table/YoPublisherTable';
import { Container } from 'react-bootstrap';

interface PropsType {
    experiments : IExperiment[]
}

export const DistributionExperimentsTable : FC<PropsType> = observer((props) => {

    const rootStore = useContext(RootStoreContext);
    const cssClass = useGameEnvironmentCssClass();
    const getExperimentLink = useGetExperimentLink();


    return (
      <ContentBox>
        <ContentBoxHeader>
          <ContentBoxNavBar variant="dark"  expand="lg">
            <Container>
              <Navbar.Brand className="text-white"><i className="fas fa-flask"/>Experiments</Navbar.Brand>
            </Container>
          </ContentBoxNavBar>
        </ContentBoxHeader>
        <ContentBoxBody>
          <YoPublisherTable variant={cssClass.name} responsive className={YoTableClassNames.MinWidth100}>
              <thead className="table-dark">
                  <tr>
                      <th>Name</th>
                      <th>User Range</th>
                      <th>Variants</th>
                      <th>Specifications</th>
                      <th>Property</th>
                      <th>Values</th>
                  </tr>
              </thead>
              <tbody>
              {props.experiments.length === 0
                  ? <tr>
                      <td colSpan={42} className="text-center">No Experiments Found</td>
                  </tr>
                  : props.experiments.map(experiment => (
                      <tr key={experiment.id}>
                          {rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditExperiments)
                              ?<td>
                                <Link to={getExperimentLink({experimentId: experiment.id})}>{experiment.name}</Link>
                               </td>
                              :<td>{experiment.name}</td>
                          }
                          <td>{`${experiment.lowerBound} <= u < ${experiment.upperBound}`}</td>
                          <td style={{height:"4rem"}} data-testid="tdVariants" >
                            {/*<div className="h-100 d-flex flex-column justify-content-between">*/}
                            {experiment.variants.map(variant => <div key={variant.id}>{variant.name}@{variant.allocation}</div>)}
                            {/*</div>*/}
                          </td>
                          <td><SpecificationProperties entityProperties={experiment.specifications} specificationKindVersions={rootStore.specificationsForExperimentsStore.getKinds()} /></td>
                          <td>{experiment.properties.map(p =><div key={p.id}>{p.displayString()}</div>)}</td>
                          <td>{experiment.properties.map(p =>
                            <div key={p.id}>
                                {p.values.map(variantValue =>
                                  <div key={variantValue.id}>
                                    <strong>
                                      {`${experiment.variants.find(v => v.id === variantValue.variantId)?.name}: ${FormatPropertyValue(rootStore, p.kindPropertyType, p.kindPropertySelectionId, p.kindPropertyEntitySelectionKindId, variantValue.value)}`}
                                    </strong>
                                  </div>
                                )}
                            </div>
                          )}</td>
                      </tr>
                  ))
              }
              </tbody>
          </YoPublisherTable>
        </ContentBoxBody>
      </ContentBox>
    )
})
