import { useEffect, useState } from 'react';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";
import { IGameVersion } from '../model/gameVersion/GameVersion';
import { useRootStore } from './useRootStore';
import { IGameVersionSearchParams } from '../api/requests/GameVersionApiGateway';

interface useGameVersionsResult{
    isLoaded            : boolean;
    gameVersions    : IGameVersion[] | null
}

export const useGameVersions = (searchParams?: IGameVersionSearchParams) => {

  const rootStore = useRootStore();
  const publishNotification = usePublishNotification();
  const { projectId } = useParams<{projectId: string}>();

  const [result, setResult] = useState<useGameVersionsResult>({ isLoaded: false, gameVersions: [] })

  useEffect(() => {
    setResult({ isLoaded: false, gameVersions: [] })

    rootStore.gameVersionStore.loadGameVersions({...searchParams})
      .then(() => {
        setResult({ isLoaded: true, gameVersions: rootStore.gameVersionStore.gameVersions })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading game versions",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.gameVersionStore, publishNotification, projectId, searchParams]);

  return result;
};
