import {flow}                       from "mobx";
import {Instance, types, getRoot}   from "mobx-state-tree"
import {ApiRequest}                 from "../ApiRequest";
import {pub_api}                    from "../../index";
import {RootStore}                  from "../../../model/RootStore";
import type {IGameEnvironmentVm}         from "@yakoffice/publisher-types";
import type {IGameEnvironmentDto}        from '@yakoffice/publisher-types';


export const GameEnvironmentApiGateway = types.model(
    "GameEnvironmentApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl() : string {
          const projectId = getRoot<typeof RootStore>(self).projectStore.getCurrentProject().id;
          return `${pub_api}/${projectId}/game-environments/`;
        }
    }))
    .actions(self => ({
        getGameEnvironments: flow(function* () {
            return yield self.apiRequest.get<IGameEnvironmentVm[]>(self.getRootUrl())
        }),
        getGameEnvironment: flow(function* (gameEnvironmentId: number) {
            return yield self.apiRequest.get<IGameEnvironmentVm>(self.getRootUrl()+gameEnvironmentId)
        }),
        saveGameEnvironment: flow(function* (gameEnvironmentDto : IGameEnvironmentDto) {
            return yield self.apiRequest.post<IGameEnvironmentVm>(self.getRootUrl(), gameEnvironmentDto)
        }),
        updateGameEnvironment: flow(function* (gameEnvironmentId: number, gameEnvironmentDto : IGameEnvironmentDto) {
            return yield self.apiRequest.put<IGameEnvironmentVm>(`${self.getRootUrl()}${gameEnvironmentId}`, gameEnvironmentDto)
        }),
        deleteGameEnvironment: flow(function* (gameEnvironmentId: number) {
            yield self.apiRequest.delete(`${self.getRootUrl()}${gameEnvironmentId}`)
        }),
    }));

export interface IGameEnvironmentApiGateway extends Instance<typeof GameEnvironmentApiGateway> {}
