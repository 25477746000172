import {Instance, types} from 'mobx-state-tree';
import {GenerateId} from "../Utils";

export const Distribution = types.model(
    "Distribution",
    {
        id: types.optional(types.identifierNumber, GenerateId),  // optional could be use to provide a default function to generate an id
        gameEnvironmentId: 0,
        distributionVersion: types.maybeNull(types.string),
        comment: types.maybeNull(types.string),
        jiraIssueIds: types.maybeNull(types.string),
        entityVersionCount: 0,
        experimentCount: 0,
        succeeded: false,
        errorMessage: types.maybeNull(types.string),
        deleted: false,
        createdAt: "",
        originalAuthor: "",
        updatedAt: "",
        lastAuthor: ""
    });

export interface IDistribution extends Instance<typeof Distribution> {}
