import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";
import {ValidationType} from "@yakoffice/publisher-types";

export const ValidationRule = types.model(
    "ValidationRule",
    {
        id: types.optional(types.identifierNumber, GenerateId),
        type: types.optional(types.enumeration<ValidationType>("type", Object.values(ValidationType)), ValidationType.Required),
        value: types.maybeNull(types.string)
    })
    .actions(self => ({
        setType(type: ValidationType){
            self.type = type;
            self.value = ""
        },
        setValue(value: string | null){
            self.value = value === "" ? null : value;
        },
    }));

export interface IValidationRule extends Instance<typeof ValidationRule>{}
