import React, {FC}              from "react";
import {observer}               from "mobx-react-lite";
import Button                   from 'react-bootstrap/Button';
import {equalsIgnoreCase}       from "../../../utilities";
import {ICategory}              from "../../../model/category/Category";


interface PropsType {
    category : ICategory
    categoryEdited: boolean,
    handleArchiveCategory: () => void;
    handleUnarchiveCategory: () => void;
    handleSaveCategory: () => void;
    handleDeleteCategory: () => void;
}


export const  CategoryButtons : FC<PropsType> = observer((props) => {

  return (
        <>
            {!props.category.isNewCategory() && !equalsIgnoreCase(props.category.status, "archived") && !props.categoryEdited &&
             <Button variant="warning" onClick={props.handleArchiveCategory} data-testid="btnArchiveCategory"><i className="fas fa-archive icon-with-text"/><span className="text">Archive Category</span></Button>
            }
            {!props.category.isNewCategory() && equalsIgnoreCase(props.category.status, "archived") && !props.categoryEdited &&
             <Button variant="success" onClick={props.handleUnarchiveCategory} data-testid="btnUnarchiveCategory"><i className="fas fa-box-open "/><span className="text">Unarchive Category</span></Button>
            }
            {props.categoryEdited &&
            <Button variant="success" className="pulse" onClick={props.handleSaveCategory} data-testid="btnSaveCategory">
                <i className="fas fa-save"/><span className="text"> {props.category.isNewCategory() ? "Save" : "Update"}</span>
            </Button>
            }
            {!props.category.isNewCategory() &&
             <Button variant="danger" onClick={props.handleDeleteCategory} data-testid="btnDeleteCategory">
               <i className="fas fa-trash-alt"/><span className="text">Delete</span>
             </Button>
            }
    </>);
});
