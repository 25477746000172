import React, {ChangeEvent, FC}     from 'react';
import {observer}                   from 'mobx-react-lite';
import Col                          from 'react-bootstrap/Col';
import Form                         from 'react-bootstrap/Form';
import Button                       from 'react-bootstrap/Button';
import {ISelectionOption}           from '../../../model/selections/SelectionOption';
import { Row } from 'react-bootstrap';


interface PropsType {
    option          : ISelectionOption;
    removeOption   : (arg: ISelectionOption) => void;
}


export const SelectionOption : FC<PropsType> = observer((props) => {

    const updateOptionName = (e : ChangeEvent<HTMLInputElement>) => {
        props.option.setName(e.target.value);
    };

    const updateOptionValue = (e : ChangeEvent<HTMLInputElement>) => {
        props.option.setValue(e.target.value);
    };

    const removeOption      = () => {
        props.removeOption(props.option)
    };

    return (
        <Row key={`key${props.option.id}`}>
            <Col sm="6">
              <Form.Group>
                <Form.Label>Option Name</Form.Label>
                <Form.Control
                    type="text"
                    id={`inputName${props.option.name}${props.option.id}`}
                    placeholder={props.option.name}
                    defaultValue={props.option.name}
                    onChange={updateOptionName}
                    data-testid="txtOptionName"
                />
              </Form.Group>
            </Col>
            <Col sm="5">
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Control
                    type="text"
                    id={`inputValue${props.option.value}${props.option.id}`}
                    placeholder={props.option.value}
                    defaultValue={props.option.value}
                    onChange={updateOptionValue}
                    data-testid="txtOptionValue"
                />
              </Form.Group>
            </Col>
            <Col sm="1">
              <Form.Group>
                <Form.Label>Clear</Form.Label>
                <Button className="d-block" variant="danger" onClick={removeOption} data-testid="btnDeleteOption">X</Button>
              </Form.Group>
            </Col>
        </Row>
    )
});
