import React, {FC, useContext} from "react";
import {observer} from "mobx-react-lite";
import RootStoreContext from "../../../model/RootStoreContext";
import {KindPropertyType} from "@yakoffice/publisher-types";
import {formatISODateString} from "./Utils";
import {thousandsSeparators} from '../../../utilities';
import {IRootStore} from "../../../model/RootStore";
import {IKindProperty} from "../../../model/kind/KindProperty";

interface PropsType {
    kindProperty: IKindProperty
    value: string | null
}

export const PropertyValueDisplay : FC<PropsType> = observer(({kindProperty, value}) => {

  const rootStore = useContext(RootStoreContext);

  if (kindProperty.type === KindPropertyType.Boolean)
    return <span>{value == null ? "" : value.toLowerCase() === 'true' ? <i className="fas fa-check text-success"/> :
      <i className="fas fa-times text-danger"/>}</span>

  const val = FormatPropertyValue(rootStore, kindProperty.type, kindProperty.selectionId, kindProperty.entitySelectionKindId, value);

  return <strong>{val}</strong>
});

export function FormatPropertyValue(rootStore: IRootStore, propertyType: KindPropertyType, selectionId: number | null, entitySelectionKindId: number | null, value: string | null) {
  let val: string | null | undefined = value;

  if (propertyType) {
    if (propertyType === KindPropertyType.DateTime)
      val = formatISODateString(val);

    if (propertyType === KindPropertyType.Selection) {
      const selection = rootStore.selectionStore.selections.find(selection => selection.id === selectionId);

      if (selection) {
        const option = selection.options.find(option => option.value === value);
        if (option)
          val = option.name;
      }
    }

    if (propertyType === KindPropertyType.EntitySelection) {
      const selection = rootStore.entitySelectionStore.getSelections().find(s => s.id === entitySelectionKindId);

      if (selection) {
        const option = selection.options.find(so => so.value === value)
        if (option)
          val = option.name;
      }
    }

    if (propertyType === KindPropertyType.Integer)
      val = val && thousandsSeparators(parseInt(val));

  }
  return val;
}

