import {observer} from "mobx-react-lite";
import React, {FC, PropsWithChildren, ReactElement} from "react";
import Form from "react-bootstrap/Form";
import { YoLabel } from '../yo-label/YoLabel';


export interface IFieldLayoutProps {

    labelForId?: string,
    label?: string | ReactElement,
    description?: string,
    required?: boolean
}


const FieldLayout : FC<PropsWithChildren<IFieldLayoutProps>> = observer((props) => {
    return (
        <Form.Group className="mb-3">
            <YoLabel htmlFor={props.labelForId} required={props.required}>{props.label}</YoLabel>
            {props.children}
            {props.description &&
            <small id={`${props.labelForId}HelpBlock`} className="text-muted">
                {props.description}
            </small>
            }
        </Form.Group>
    )
})

export default FieldLayout;
