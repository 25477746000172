import {flow, Instance, types}              from "mobx-state-tree";
import {GameEnvironment, IGameEnvironment}  from "./GameEnvironment";
import {GameEnvironmentApiGateway}          from "../../api/requests/gameEnv/GameEnvironmentApiGateway";
import type {IGameEnvironmentVm}                 from "@yakoffice/publisher-types";
import type {IGameEnvironmentDto}                from '@yakoffice/publisher-types';
import {formatError}                        from '../Utils';


export const  GameEnvironmentStore = types.model(
    "GameEnvironmentStore",
    {
        apiGateway              : GameEnvironmentApiGateway,
        gameEnvironments        : types.array(GameEnvironment),
        currentGameEnvironment  : types.maybeNull(types.reference(GameEnvironment)),
        isLoading               : false,
    })
    .views(self => ({
        getCurrentGameEnvironment() : IGameEnvironment{
            if(self.currentGameEnvironment)
                return self.currentGameEnvironment;

            throw new Error("The current game environment has not been set")
        }
    }))
    .actions(self => ({
        setCurrentGameEnvironment(gameEnvironmentId : number){

            const selectedGE = self.gameEnvironments.find(ge => ge.id === gameEnvironmentId);

            if(selectedGE){
                self.currentGameEnvironment = null;
                self.currentGameEnvironment = selectedGE;
            }
            else {
                throw new Error(`Game environment with id ${gameEnvironmentId} does not exist`)
            }
        },
        addGameEnvironment() {
            const gameEnvironment = GameEnvironment.create({isNew: true});
            self.gameEnvironments.push(gameEnvironment);
            return gameEnvironment as IGameEnvironment;
        },
        loadGameEnvironments : flow(function*() {
            self.isLoading = true;

            // To prevent an invalid reference
            // self.currentGameEnvironment = null;  //breaks load of game env!!

            try {
                const gameEnvironmentVms = yield self.apiGateway.getGameEnvironments();

                const gameEnvironments = gameEnvironmentVms.map((geVm : IGameEnvironmentVm) => GameEnvironment.create(MapToGEModel(geVm)));
                self.gameEnvironments.replace(gameEnvironments);
                self.isLoading = false;
            } catch (e: any) {
                throw new Error(`Failed to load game environments: ${e.message}`);
            }}),

        getGameEnvironment: flow(function* (gameEnvironmentId: number) {

            self.isLoading = true;

            try {
                const gameEnvironmentVm = yield self.apiGateway.getGameEnvironment(gameEnvironmentId);
                const gameEnvironment = GameEnvironment.create(MapToGEModel(gameEnvironmentVm));

                const indexOf = self.gameEnvironments.findIndex(ge => ge.id === gameEnvironment.id);
                if(indexOf === -1)
                    self.gameEnvironments.push(gameEnvironment);
                else
                    self.gameEnvironments.splice(indexOf, 1, gameEnvironment);

                self.isLoading = false;
                return gameEnvironment as IGameEnvironment;
            } catch (e:any) {
                throw new Error(`Failed to load game environment: ${e.message}`);
            }
        }),

        saveGameEnvironment: flow(function*(gameEnvironment: IGameEnvironment) {
            try {
                const gameEnvironmentDto = MapToDto(gameEnvironment);

                if(gameEnvironment.isNewGameEnvironment())
                    yield self.apiGateway.saveGameEnvironment(gameEnvironmentDto); //flagged for same name
                else
                    yield self.apiGateway.updateGameEnvironment(gameEnvironment.id, gameEnvironmentDto)

            } catch(e: any) {
                throw formatError(e);
            }
        }),

        deleteGameEnvironment: flow(function*(gameEnvironment: IGameEnvironment) {
            try {
                yield self.apiGateway.deleteGameEnvironment(gameEnvironment.id); //flagged for same name
            } catch(e) {
                throw formatError(e);
            }
        }),
    }));



const MapToGEModel = (vm: IGameEnvironmentVm) => {
    return {
        id: vm.id,
        name: vm.name,
        description: vm.description,
        configApiPublishingUrl: vm.configApiPublishingUrl,
        createdAt: vm.createdAt,
        originalAuthor: vm.originalAuthor,
        updatedAt: vm.updatedAt,
        lastAuthor: vm.lastAuthor,
        slackNotificationWebhookUrl: vm.slackNotificationWebhookUrl || ""
    }
}

const MapToDto = (gameEnvironment: IGameEnvironment): IGameEnvironmentDto => {
    return {
        description: gameEnvironment.description,
        name: gameEnvironment.name,
        configApiPublishingUrl: gameEnvironment.configApiPublishingUrl,
        slackNotificationWebhookUrl: gameEnvironment.slackNotificationWebhookUrl
    }
};


export interface IGameEnvironmentStore extends Instance<typeof GameEnvironmentStore> {}
