import React, {FC} from "react";
import {ISelection} from "../../../model/selections/Selection";
import Form from "react-bootstrap/Form";

interface PropsType {
    id: string;
    defaultText: string
    value : string;
    selection: ISelection | undefined;
    handleOnChange: (e: string) => void;
}

export const SelectionInput : FC<PropsType> = ({id, defaultText, value, selection, handleOnChange, ...props}) => {
    return  <Form.Select className="form-control" id={id} value={value} onChange={e => handleOnChange(e.target.value)} {...props}>
                <option value="">{defaultText}</option>
                {value !== "" && (!selection || !selection.options.find(so => so.value === value)) &&
                    <option key={value} value={value} disabled={true} className="text-danger">{`${value} - No longer available for selection`}</option>
                }
                {selection && selection.options.map(so => <option key={so.value} value={so.value}>{so.name}</option>)}
            </Form.Select>
}
