import React, {FC, ReactElement} from "react";
import {observer} from "mobx-react-lite";
import type {IDistribution} from "../../../model/distributions/Distribution";
import {useGameEnvironmentCssClass} from "../common/useGameEnvironmentCssClass";
import { formatISODateStringAsLocal } from '../common/Utils';
import {useDistributionsResult} from "./useDistributions";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import { YoPublisherTable, YoTableClassNames } from '../../components/yo-publisher-table/YoPublisherTable';
import JiraIssueLinks from './JiraIssueLinks';
import { useGetDistributionExperimentsLink, useGetDistributionLink } from '../../routes';
import { useNavigate } from 'react-router';
import { Badge } from 'react-bootstrap';
import {AbbreviatedString} from "../../components/abbreviated-string/AbbreviatedString";

interface PropsType {
    statusHeader : ReactElement | string
    distributionsResult : useDistributionsResult,
    checkedDistributions : IDistribution[],
    handleDistributionCheckedToggle:  (distribution : IDistribution) => void
}

export const DistributionsTable : FC<PropsType> = observer((props) => {

    const navigate = useNavigate();
    const cssClass = useGameEnvironmentCssClass();
    const getDistributionLink = useGetDistributionLink();
    const getDistributionExperimentsLink = useGetDistributionExperimentsLink();

    return (
            <YoPublisherTable variant={cssClass.name} responsive className={YoTableClassNames.MinWidthContainer}>
                <thead className="table-dark">
                <tr>
                    <th/>
                    <th>ID</th>
                    <th>Version</th>
                    <th>Updated</th>
                    <th>{props.statusHeader}</th>
                    <th className="d-none d-md-table-cell">Comment</th>
                    <th className="d-none d-md-table-cell">Jiras</th>
                    <th className="d-none d-md-table-cell">Author</th>
                    <th>Experiments</th>
                </tr>
                </thead>
                <tbody>
                {!props.distributionsResult.isLoaded &&
                <tr>
                    <td colSpan={42}><CustomSpinner spinnerText="Loading Distributions..."/></td>
                </tr>
                }
                {props.distributionsResult.isLoaded && props.distributionsResult.distributions && props.distributionsResult.distributions.length === 0 &&
                <tr>
                    <td colSpan={42} className="text-center">No Distributions found for this Game Version and Environment with current filters</td>
                </tr>
                }
                {props.distributionsResult.isLoaded && props.distributionsResult.distributions && props.distributionsResult.distributions.length > 0 &&
                    props.distributionsResult.distributions.map(d =>
                        <tr key={d.id}>
                            <td>
                                <input type="checkbox" className="checkbox" id={`checkbox${d.id}`}
                                       onClick={() => props.handleDistributionCheckedToggle(d)}
                                       disabled={props.checkedDistributions.length >= 2 && !props.checkedDistributions.includes(d)} />
                            </td>
                            <td onClick={() => navigate(getDistributionLink({ distributionId: d.id} ))} className="text-info cursor-pointer">{d.id}</td>
                            <td>{d.distributionVersion}</td>
                            <td>{formatISODateStringAsLocal(d.updatedAt)}</td>
                            <td>
                                {d.succeeded && <span className="text-success">Succeeded</span>}
                                {!d.succeeded && <span className="text-danger">
                                  <strong>
                                    <AbbreviatedString value={`Failed - ${d.errorMessage}`} lengthToAbbreviate={9} abbreviation={<i className="fas fa-info-circle"/>}/>
                                  </strong>
                                </span>}
                                {d.deleted && <span className="text-danger">Deleted</span>}
                            </td>
                            <td className="d-none d-md-table-cell max-width">{d.comment}</td>
                            <td className="d-none d-md-table-cell max-width"><JiraIssueLinks className="d-inline text-info" delimiter=", " jiraIssueIds={d.jiraIssueIds} /></td>
                            <td className="d-none d-md-table-cell">{d.lastAuthor}</td>
                          <td className={`text-center ${d.experimentCount > 0 && "cursor-pointer"}`} onClick={() => navigate(getDistributionExperimentsLink({ distributionId: d.id} ))}>
                            {d.experimentCount > 0 && <Badge bg="success">{d.experimentCount}</Badge>}
                          </td>
                        </tr>)
                }
                </tbody>
            </YoPublisherTable>
    )
});
