import React, {FC} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ToolTip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import {observer} from "mobx-react-lite";

interface PropsType {
    descriptionUpdating : boolean,
    descriptionUpdated: boolean
    updatingTootip: string | JSX.Element | null,
    updatedTooltip: string | JSX.Element | null
}

export const PropertyUpdatingIconDisplay : FC<PropsType> = observer((props) => {
    return (
        <>
        {props.descriptionUpdating &&
            <OverlayTrigger placement="right" overlay={<ToolTip id="button-tooltip">{props.updatedTooltip}</ToolTip>}>
                <Spinner className="float-end" animation="border" size="sm" />
            </OverlayTrigger>
        }
        {props.descriptionUpdated &&
            <OverlayTrigger placement="right" overlay={<ToolTip id="button-tooltip">{props.updatedTooltip}</ToolTip>}>
                <i className="fas fa-check-circle text-success bg-white float-end"/>
            </OverlayTrigger>
        }
        </>)
})
