import {flow}                               from "mobx";
import {Instance, types, getRoot}           from "mobx-state-tree"
import {pub_api}                            from "../../index";
import {ISelectionDto, ISelectionVm }       from '@yakoffice/publisher-types';
import {RootStore}                          from "../../../model/RootStore";
import {ApiRequest}                         from "../ApiRequest";

export const SelectionApiGateway = types.model(
    "SelectionApiGateway",
  {
    apiRequest: ApiRequest
  })
  .views(self => ({
    getRootUrl(gameVersionId?: number): string {
      const project = getRoot<typeof RootStore>(self).projectStore.getCurrentProject();
      const gameVersion = getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion();
      return `${pub_api}/${project.id}/versions/${gameVersionId ? gameVersionId.toString() : gameVersion.id}/selections/`;
    },
    getSheetRootUrl(selectionId: number) : string{
      const project = getRoot<typeof RootStore>(self).projectStore.getCurrentProject();
      const gameVersion = getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion();
      return `${pub_api}/${project.id}/versions/${gameVersion.id}/selections/${selectionId}/sheet`;
    },
  }))
  .actions(self => ({
    getSelections: flow(function* (archived: boolean) {
      return yield self.apiRequest.get<ISelectionVm[]>(self.getRootUrl() + `archived/${archived}`)
    }),
    getSelection: flow(function* (selectionId: number) {
      return yield self.apiRequest.get<ISelectionVm>(self.getRootUrl() + `${selectionId}`)
    }),
    findSelectionInGameVersion: flow(function* (selectionName: string, gameVersionId: number) {
      return yield self.apiRequest.get<ISelectionVm>(self.getRootUrl(gameVersionId) + `findByName/?name=${selectionName}&gameVersionId=${gameVersionId}`)
    }),
    saveSelection: flow(function* (selectionDto: ISelectionDto) {
      return yield self.apiRequest.post<ISelectionVm>(self.getRootUrl(), selectionDto)
    }),
    saveSelectionToNewGameVersion: flow(function* (selectionDto: ISelectionDto, gameVersionId: number) {
      return yield self.apiRequest.post<ISelectionVm>(self.getRootUrl(gameVersionId), selectionDto)
    }),
    updateSelection: flow(function* (selectionId: number, selectionDto: ISelectionDto) {
      return yield self.apiRequest.put<ISelectionVm>(`${self.getRootUrl()}${selectionId}`, selectionDto)
    }),
    updateSelectionInOtherGameVersion: flow(function* (selectionId: number, gameVersionId: number, selectionDto: ISelectionDto) {
      return yield self.apiRequest.put<ISelectionVm>(`${self.getRootUrl(gameVersionId)}${selectionId}`, selectionDto)
    }),
    deleteSelection: flow(function* (selectionId: number) {
      yield self.apiRequest.delete(`${self.getRootUrl()}${selectionId}`)
    }),
    archiveSelection: flow(function* (selectionId: number, selectionDto: ISelectionDto) {
      return yield self.apiRequest.put<ISelectionVm>(`${self.getRootUrl()}${selectionId}`, selectionDto)
    }),
    createSheet: flow(function* (selectionId: number) {
      return yield self.apiRequest.post<ISelectionVm>(`${self.getSheetRootUrl(selectionId)}`, null)
    }),
    refreshSheet: flow(function* (selectionId: number) {
      yield self.apiRequest.put(`${self.getSheetRootUrl(selectionId)}`, null)
    }),
    uploadSheet: flow(function* (selectionId: number) {
      return yield self.apiRequest.put<ISelectionVm>(`${self.getSheetRootUrl(selectionId)}/upload`, null)
    }),
    deleteSheet: flow(function* (selectionId: number) {
      yield self.apiRequest.delete(`${self.getSheetRootUrl(selectionId)}`)
    }),
  }));

export interface ISelectionApiGateway extends Instance<typeof SelectionApiGateway> {}
