import Form from "react-bootstrap/Form";
import React, {ChangeEvent, FC} from "react";
import {observer} from "mobx-react-lite";
import {IExperimentProperty} from "../../../model/experiment/ExperimentProperty";
import {IKindVersion} from "../../../model/kind/KindVersion";
import Alert from "react-bootstrap/Alert";


interface PropsType {
    property: IExperimentProperty
    kinds: IKindVersion[]
    handleKindChange: (kind: IKindVersion) => void
}

export const ExperimentKindSelection : FC<PropsType> = observer(({property, ...props}) => {

    const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if(e.target.value) {
            const kind = props.kinds.find(k => k.kind.id === parseInt(e.target.value));
            if (kind)
                props.handleKindChange(kind)
        }
    }

    const propertyKindDoesntExistInList = () => !property.isNew && props.kinds.find(k => k.kind.id === property.kindId) == null;

    return (
        <>
            {propertyKindDoesntExistInList() &&
            <Alert variant="warning">
                <strong>Warning:</strong> This Kind is no longer available for experiments
            </Alert>
            }
            <Form.Select  id="experimentKindSelection"
                          value={property.kindId ? property.kindId : ""}
                          onChange={handleSelectionChange}
                          data-testid="ddlExperimentKindSelect">
                <option value="" className={"text-danger"} disabled={true}>Select Kind...</option>
                {propertyKindDoesntExistInList() &&
                <option value={property.kindId} disabled={true}>{property.kindName}</option>
                }
                {props.kinds.map((kindVersion: IKindVersion) => {
                    return <option key={kindVersion.kind.id} value={kindVersion.kind.id}>{kindVersion.name}</option>
                })}
            </Form.Select>
        </>
    )
})
