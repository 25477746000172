import React, {ChangeEvent, FC} from "react";
import {observer} from "mobx-react-lite";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from "react-bootstrap/Form";
import {IExperiment} from "../../../model/experiment/Experiment";
import { LabelHeader } from '@yakoffice/shared-components';

interface PropsType {
    experiment : IExperiment
}

export const ExperimentDefaultProperties : FC<PropsType> = observer(({experiment}) => {

  const updateName = (e: ChangeEvent<HTMLInputElement>) => {
    experiment.setName(e.target.value);
  }

  const updateDescription = (e: ChangeEvent<HTMLInputElement>) => {
    experiment.setDescription(e.target.value);
  }

  return <Row key="experimentName">
    <Col xs="12" md="6">
      <Form.Group>
        <Form.Label>
          <LabelHeader><i className="fas fa-signature" />Name</LabelHeader>
        </Form.Label>
        <Form.Control type="text" id="experimentName" className="form-control"
                      placeholder="Please enter experiment name..." onChange={updateName}
                      value={experiment.name} disabled={experiment.inAnyDistribution}
                      data-testid="txtExperimentName" />
      </Form.Group>
    </Col>
    <Col xs="12" md="6">
      <Form.Group>
        <Form.Label>
          <LabelHeader><i className="fas fa-signature" />Description</LabelHeader>
        </Form.Label>
        <Form.Control type="text" id="experimentDescription" className="form-control"
                      placeholder="Please enter experiment description..." onChange={updateDescription}
                      value={experiment.description} disabled={experiment.inAnyDistribution}
                      data-testid="txtExperimentDescription" />
      </Form.Group>
    </Col>
  </Row>

})
