import {useState} from "react";
import {IDistributionSearchParams} from "../../../api/requests/distribution/DistributionApiGateway";
import {useDistributions} from "./useDistributions";
import {useDistributionsCount} from "./useDistributionsCount";

interface PropTypes{
    defaultPageSize: number
    hideFailed?: boolean
}

export const useDistributionsSearch = (props: PropTypes) => {

    const [query, setQuery] = useState<IDistributionSearchParams>({
        pageSize: props.defaultPageSize,
        pageNumber: 1,
        hideFailed: props.hideFailed})

    const distributionsResult = useDistributions(query);
    const distributionsCountResult = useDistributionsCount(query);

    const setPageNumber = (pageNumber: number) => {
        setQuery({...query, pageNumber: pageNumber});
    }

    const setPageSize = (pageSize: number) => {
        setQuery({...query, pageSize: pageSize, pageNumber: 1});
    }

    const setHideFailed = (hideFailed: boolean) => {
        setQuery({...query, hideFailed: hideFailed, pageNumber: 1});
    }

    return {
        query,
        distributionsResult,
        distributionsCountResult,
        setPageNumber,
        setPageSize,
        setHideFailed
    };
}