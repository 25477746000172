import React, { FC, ReactElement } from 'react';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router';
import { ViewHeader } from '@yakoffice/view-header';

interface PropTypes {
  title: string | ReactElement
  navOptions?: ReactElement
  highlights?: ReactElement
}

export const DistributionOverlayHeader : FC<PropTypes> = ({title, navOptions, highlights}) => {
  const navigate = useNavigate();

  return <Container>
    <ViewHeader title="Distributions"
                customAction={
                  <span className="h4 mb-0 cursor-pointer d-print-none" onClick={() => navigate(-1)}>
                  <i className="fas fa-arrow-alt-circle-left" />
                </span>
                } />
    <ContentBox>
      <ContentBoxHeader>
        <ContentBoxNavBar variant="dark" expand="lg">
          <Container>
            <Navbar.Text>
              <p className="h5 mb-0 text-white">{title}</p>
            </Navbar.Text>
            <Navbar.Collapse className="justify-content-end">
              {navOptions}
            </Navbar.Collapse>
          </Container>
        </ContentBoxNavBar>
      </ContentBoxHeader>
      {highlights &&
      <ContentBoxBody>
        {highlights}
      </ContentBoxBody>
      }
    </ContentBox>
  </Container>
}
