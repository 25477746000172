import React, { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { LabelHeader } from '@yakoffice/shared-components';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ToolTip from 'react-bootstrap/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserRange } from './UserRange';
import { TakenUserRanges } from './TakenUserRanges';
import { DecimalInput } from '../common/DecimalInput';
import { IExperiment } from '../../../model/experiment/Experiment';
import { observer } from 'mobx-react-lite';


interface PropTypes {
  experiment : IExperiment
  publishedExperiments : IExperiment[]
}

export const ExperimentBounds : FC<PropTypes> = observer(({experiment, publishedExperiments}) => {

  function updateLowerBound(value: string) {
    experiment.setLowerBound(parseFloat(value));
  }

  function updateUpperBound(value: string) {
    experiment.setUpperBound(parseFloat(value));
  }

  function updateBounds(lowerBound: number, upperBound: number) {
    experiment.setLowerBound(lowerBound);
    experiment.setUpperBound(upperBound);
  }


  return <Row key="experimentBounds">
    <Col xs="12" md="12">
      <Form.Group>
        <Form.Label>
          <LabelHeader><i className="fas fa-user-friends" />User Range
            <OverlayTrigger placement="right" overlay={
              <ToolTip id="button-tooltip">
                On registering, users are allocated a mark between 0 and 1. If their mark is within
                the user range then they will participate in the experiment. You cannot Publish an experiment
                that would overlap the ranges, indicated, of other Published experiments.
              </ToolTip>}>
              <span className="ms-2">  <i className={`fa fa-info-circle`} /></span>
            </OverlayTrigger>
          </LabelHeader>
        </Form.Label>
        <Row className="mt-4 mb-5">
          <Col md={8}>
            <UserRange
              lowerBound={experiment.lowerBound}
              upperBound={experiment.upperBound}
              displayMinMaxMarks={false}
              onRangeChange={updateBounds}
              disabled={experiment.inAnyDistribution} />
            <div className="mt-2">
              <TakenUserRanges ranges={publishedExperiments.filter(e => e.id !== experiment.id).map(e => ({
                lower: e.lowerBound,
                upper: e.upperBound
              }))} />
            </div>

          </Col>
        </Row>
        <Row>
          <Col md="4">
            <DecimalInput id="lowerBound"
                          value={experiment.lowerBound.toString()}
                          handleOnChange={updateLowerBound}
                          step="0.01"
                          disabled={experiment.inAnyDistribution}
                          data-testid="txtLowerBound" />
            <small className="form-text text-muted">Lower Bound</small>
          </Col>
          <Col md="4">
            <DecimalInput id="upperBound"
                          value={experiment.upperBound.toString()}
                          handleOnChange={updateUpperBound}
                          step="0.01"
                          disabled={experiment.inAnyDistribution}
                          data-testid="txtUpperBound" />
            <small className="form-text text-muted">Upper Bound</small>
          </Col>
          <Col md="4">

          </Col>
        </Row>
      </Form.Group>
    </Col>
  </Row>
})
