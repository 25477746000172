import {useState} from "react";
import {IExperimentSearchParams} from "../../../../api/requests/ExperimentApiGateway";
import {ExperimentStatus} from "@yakoffice/publisher-types";
import {useExperiments} from "../../../../loaders/useExperiments";
import {useExperimentsCount} from "../../../../loaders/useExperimentsCount";

interface PropTypes{
    defaultPageSize: number,
    defaultStatuses: ExperimentStatus[]
}

export const useExperimentsSearch = (props: PropTypes) => {

    const [query, setQuery] = useState<IExperimentSearchParams>({
        pageSize: props.defaultPageSize,
        pageNumber: 1,
        statuses: props.defaultStatuses})

    const experimentsCountResult = useExperimentsCount(query);
    const experimentsResult = useExperiments(query);

    const setPageNumber = (pageNumber: number) => {
        setQuery({...query, pageNumber: pageNumber});
    }

    const setPageSize = (pageSize: number) => {
        setQuery({...query, pageSize: pageSize, pageNumber: 1});
    }

    const setNameLike = (nameLike: string) =>{
        setQuery({...query, nameLike: nameLike.length === 0 ? undefined : nameLike, pageNumber: 1})
    }

    const toggleStatus = (status: ExperimentStatus) => {
        setQuery({
            ...query,
            statuses: query.statuses && (query.statuses.indexOf(status) > -1
                ? query.statuses.filter(s => s !== status)
                : [...query.statuses, status]),
            pageNumber: 1

        })
    }

    return {
        query,
        experimentsResult,
        experimentsCountResult,
        setPageNumber,
        setPageSize,
        setNameLike,
        toggleStatus
    };
}
