import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import { IEntityVersion } from '../model/entity/EntityVersion';
import { IKindVersion } from '../model/kind/KindVersion';

interface useEntityVersionWithKindVersionResult{
  isLoaded : boolean;
  kindVersion : IKindVersion | null
}

export const useKindVersionForEntityVersion = (entityVersion: IEntityVersion | null) => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useEntityVersionWithKindVersionResult>(() => ({
    isLoaded: false,
    kindVersion: null
  }));

  useEffect(() => {
    setResult({ isLoaded: false, kindVersion: null });

    if(entityVersion != null) {
      rootStore.kindVersionStore.loadVersionForCurrentKind(entityVersion.kindVersionSummary.version)
        .then(kindVersion => {
          setResult({ isLoaded: true, kindVersion: kindVersion })
        })
        .catch(e => publishNotification({
          notificationType: NotificationType.Error,
          title: "Error loading kind version",
          message: `${e.message}`,
          footer: "Please try refreshing"
        }));
    }


  }, [rootStore.kindVersionStore, publishNotification, entityVersion, entityVersion?.kindVersionSummary]);

  return result;
};
