import {IEntityVersion}  from '../../../model/entity/EntityVersion';
import {Event}    from 'react-big-calendar';

export const Events = (entityVersions: IEntityVersion[]) => {

  const EVENTS: Event[] = [];

  const createEndTime = (entityVersion: IEntityVersion) => {
    const hasEndTime = entityVersion.properties.find( (p: any) => p.kindPropertyKey === "endTime")

    if(hasEndTime)
      return hasEndTime.value

    else
      return 2051218800000 //Mon Jan 01 2035 00:00:00 GMT+0100
  }

  const eventType = (entityVersion: IEntityVersion) => {

    let hasDayOfTheWeek  = false;
    let hasStartTime    = false;
    let hasEndTime   = false;

    if(entityVersion.properties.find( (p: any) => p.kindPropertyKey === "dayOfTheWeek"))
      hasDayOfTheWeek = true;

    if(entityVersion.properties.find( (p: any) => p.kindPropertyKey === "startTime"))
      hasStartTime = true;

    if(entityVersion.properties.find( (p: any) => p.kindPropertyKey === "endTime"))
      hasEndTime = true;

    return {
      hasDayOfTheWeek,
      hasStartTime,
      hasEndTime
    }
  }

  const start = (entity: IEntityVersion) => {

    const _eventType = eventType(entity);

    if(_eventType.hasStartTime)
        return (entity.properties.find( p => p.kindPropertyKey === "startTime") as any).value;

    if(_eventType.hasDayOfTheWeek && !_eventType.hasStartTime)
      return entity.createdAt;

    if(!_eventType.hasStartTime && _eventType.hasEndTime)
      return entity.properties.find( p => p.kindPropertyKey === "endTime")?.value;

  }

  const end = (entity: IEntityVersion) => {

    const _eventType = eventType(entity);

    if(_eventType.hasEndTime)
      return (entity.properties.find( p => p.kindPropertyKey === "endTime") as any).value;

    if(_eventType.hasDayOfTheWeek && !_eventType.hasEndTime)
      return createEndTime(entity);

    if(!_eventType.hasEndTime && _eventType.hasStartTime)
      return entity.properties.find( p => p.kindPropertyKey === "startTime")?.value;

  }

  const dayOfTheWeekEvent = (entity: any) => {

    const dayOfTheWeekInt = parseInt(entity.properties.find( (p: any) => p.kindPropertyKey === "dayOfTheWeek").value);
    const startTime       = new Date(eventType(entity).hasStartTime ? entity.properties.find( (p: any) => p.kindPropertyKey === "startTime").value : entity.createdAt);
    const endTime         = new Date(eventType(entity).hasEndTime ? entity.properties.find( (p: any) => p.kindPropertyKey === "endTime").value : createEndTime(entity));
    const dates           = [];

    const diff = (dayOfTheWeekInt - startTime.getDay());

    startTime.setDate(startTime.getDate() + diff);

    while (startTime < endTime) {
      dates.push(new Date(startTime.getTime()));
      startTime.setDate(startTime.getDate() + 7);
    }

    dates.forEach(date => {
      EVENTS.push(
        {
          title:    entity.name,
          start:    date,
          end:      date,
          allDay:   true,
          resource: entity
        }
      )
    })
  }

  Object(entityVersions).forEach( (entityVersion: IEntityVersion) => {
    if(eventType(entityVersion).hasDayOfTheWeek)
      dayOfTheWeekEvent(entityVersion);

    else
      EVENTS.push({
        title:    entityVersion.name,
        start:    new Date(start(entityVersion)),
        end:      new Date(end(entityVersion)),
        allDay:   false,
        resource: entityVersion
    })
  })

  return EVENTS;
}
