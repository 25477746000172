import React, {FC} from 'react';
import { Tabs, TabsProps } from 'react-bootstrap';
import styles from './YoTabs.module.sass'

export const YoTabs : FC<TabsProps> = (props) => {
  return <Tabs {...props} className={styles.yoTabs}>{props.children}</Tabs>
}

// export const YoTab : FC<TabProps> = ({className, ...props}) => {
//   return <Tab {...props} className={`${styles.yoTab} ${className ?? ""}`}>{props.children}</Tab>
// }
