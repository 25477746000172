import React, { FC } from 'react';
import Form     from 'react-bootstrap/Form';

interface PropsType {
  defaultValue?: string;
  onChange     : (comment: string) => void;
}

const JiraIssueIdsForm : FC<PropsType> = ({defaultValue, onChange}) => {
  return (
    <Form.Group controlId="formGroupJiraItems" className="mb-3">
      <Form.Label>Please enter Jira items resolved by distribution</Form.Label>
      <Form.Control type="text"
                    placeholder="Comma delimited Jiras (e.g. YO-123,PPK-234,PRD-456 etc)"
                    defaultValue={defaultValue}
                    onChange={(e) => onChange(e.target.value)}
                    maxLength={255}
                    data-testid="txtJiraItems"
      />
    </Form.Group>
  )
}

export default JiraIssueIdsForm
