import React from 'react';
import styles from './noAccess.module.sass';


export const NoAccess = () => {

  return (
    <div className={styles.noAccess} data-testid="divNoAccess">
      <i className="fas fa-user-times" />
      <h1>No Access</h1>
      <p>You do not have the correct user permissions to access this page.</p>
      <a className="btn btn-primary" href="https://portal.yakoffice.app">Click here to return to the <strong>portal</strong></a>
    </div>
  )
}
