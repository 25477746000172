import React, { ChangeEvent, FC, useContext, useEffect } from 'react';
import {observer} from 'mobx-react-lite';
import type {IEntityVersion} from "../../../model/entity/EntityVersion";
import type {IKindVersion} from "../../../model/kind/KindVersion";
import type {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import type {ICategory} from "../../../model/category/Category";
import {EntityHeaderLinks} from "./EntityHeaderLinks";
import {EntityVersionSelect} from "./EntityVersionSelect";
import {isStateTreeNode, onSnapshot} from "mobx-state-tree";
import {useEntityController} from "../../../controllers/useEntityController";
import {EntityProperties} from "./EntityProperties";
import {SpecificationsEditor} from "../common/SpecificationsEditor";
import Form from "react-bootstrap/Form";
import {EntityPropertyFactory} from "./EntityPropertyFactory";
import {ReadonlyEntityPropertyFactory} from "./ReadonlyEntityPropertyFactory";
import {ReadonlyEntitySpecificationProperties} from "./ReadonlyEntitySpecificationProperties";
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import type {IProject} from "../../../model/project/Project";
import {ClaimType, KindStatus} from "@yakoffice/publisher-types";
import {ViewHeader} from '@yakoffice/view-header';
import { useNavigate } from 'react-router-dom';
import { useGetEntitiesLink, useGetEntityVersionLink } from '../../routes';
import RootStoreContext from '../../../model/RootStoreContext';
import { EntityButtons } from './EntityButtons';
import { EntityHighlights } from './EntityHighlights';
import { EntityVersionButtons } from './EntityVersionButtons';
import { EntityVersionHighlights } from './EntityVersionHighlights';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { GameVersionSelector } from '../common/GameVersionSelector';
import { GameEnvironmentSelector } from '../common/GameEnvironmentSelector';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import { YoFormBox, YoFormBoxBody } from '../../components/yo-form-box/YoFormBox';
import styles from './Entity.module.sass'
import {usePrompt} from "@yakoffice/shared-components";
import {Observer} from "mobx-react-lite";

interface PropsType {
    project                   : IProject;
    gameVersion               : IGameVersion;
    category                  : ICategory;
    gameEnvironment           : IGameEnvironment;
    currentKindVersion        : IKindVersion;
    entityVersion             : IEntityVersion;
    entityVersionKindVersion  : IKindVersion;
    specificationKindVersions : IKindVersion[];
    gameEnvironments          : IGameEnvironment[]
}

export const Entity : FC<PropsType> = observer((props) => {

  const rootStore             = useContext(RootStoreContext);
  const controller            = useEntityController(props.entityVersion)
  const navigate               = useNavigate();
  const getEntitiesLink       = useGetEntitiesLink()
  const getEntityVersionLink  = useGetEntityVersionLink()

  const [entityEdited, setEntityEdited] = usePrompt("You have unsaved changes. Are you sure you want to leave?", props.entityVersion.requiresSaving());

  useEffect(() => {
      if (isStateTreeNode(props.entityVersion))// So can pass in mock....
        return onSnapshot(props.entityVersion, () => {
          props.entityVersion.setEdited(true);
          setEntityEdited(true)})
    },[props.entityVersion, setEntityEdited]
  )

  const entityEditable = rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity) && props.currentKindVersion.kind.status !== KindStatus.Archived;
  const entityVersionEditable = entityEditable && props.entityVersion.isCurrentVersionOfEntity();

  // Handlers
  const handleTargetVersionChange = (version: number) => navigate(getEntityVersionLink({version: version}));

  const handleSaveEntity = async (andPublish?: boolean) => {
    // To remove prompt if save succeeds
    await controller.handleSaveEntity(!!andPublish, () => {
      setEntityEdited(false)
    })
  };

  const handleMoveEntity = async (targetGE: IGameEnvironment) => {
    // To remove prompt if save succeeds
    await controller.handleMoveEntity(
      targetGE,
      () => {
        setEntityEdited(false)
      },
      () => {
        setEntityEdited(false)
      })
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.entityVersion.setName(e.target.value)
  }

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.entityVersion.setDescription(e.target.value)
  }

  const onGameVersionChanged = (targetGv: IGameVersion) => controller.handleGameVersionChange(props.entityVersion, targetGv)

  const onGameEnvironmentChanged = (targetGe: IGameEnvironment) => navigate(getEntitiesLink({gameEnvironmentId: targetGe.id}))

  return (
    <div id="entity">
      <Container>
        <ViewHeader
          title={`${props.entityVersion.name ? props.entityVersion.name : "(New Entity Name)"}`}
          customAction={
            <>
              <GameVersionSelector handleGameVersionChange={onGameVersionChanged}/>
              <GameEnvironmentSelector handleGameEnvironmentChange={onGameEnvironmentChanged}/>
            </>
          }
          breadcrumbs={<span><EntityHeaderLinks {...props} kindVersion={props.currentKindVersion}/></span>}
        />
      </Container>

      <Container>
        <div>
          {!props.entityVersion.isNewEntity() &&
            <ContentBox>
              <ContentBoxHeader>
                <ContentBoxNavBar variant="dark" expand="xl" className={props.entityVersion.entity.status}>
                  <Container>
                    <Navbar.Brand className="text-white">Entity Status</Navbar.Brand>
                    <Navbar.Toggle/>
                    <Navbar.Collapse className={`${styles.entityNav} justify-content-end`}>
                      {props.currentKindVersion.kind.status === KindStatus.Archived &&
                        <div className={"text-warning fw-bold"}>
                          <i className="fas fa-archive text-warning"/> Kind archived
                        </div>
                      }
                      {entityEditable && !entityEdited &&
                        <EntityButtons {...props}
                                       currentGameEnvironment={props.gameEnvironment}
                                       handlePublishEntity={controller.handlePublishEntity}
                                       handleStopEntity={controller.handleStopEntity}
                                       handleArchiveEntity={controller.handleArchiveEntity}
                                       handleUnarchiveEntity={controller.handleUnarchiveEntity}
                                       handleDeleteEntity={controller.handleDeleteEntity}
                                       handleMoveEntity={handleMoveEntity}/>
                      }
                    </Navbar.Collapse>
                  </Container>
                </ContentBoxNavBar>
              </ContentBoxHeader>
              <ContentBoxBody>
                <EntityHighlights entityVersion={props.entityVersion}
                                  currentKindVersion={props.currentKindVersion}
                                  currentGameEnvironment={props.gameEnvironment}/>
              </ContentBoxBody>
            </ContentBox>
          }

          <ContentBox>
            <ContentBoxHeader>
              <ContentBoxNavBar variant="dark" expand="xl" className={props.entityVersion.entity.status}>
                <Container>
                  <Navbar.Brand
                    className="text-white">{props.entityVersion.isNewEntity() ? "New Entity" : "Entity Version"}</Navbar.Brand>
                  {!props.entityVersion.isNewEntity() &&
                    <EntityVersionSelect entityVersion={props.entityVersion}
                                         handleTargetVersionChange={handleTargetVersionChange}/>
                  }
                  <Navbar.Toggle/>
                  <Navbar.Collapse className={`${styles.entityNav} justify-content-end`}>
                    {entityVersionEditable &&
                      <EntityVersionButtons {...props}
                                            currentGameEnvironment={props.gameEnvironment}
                                            requiresSaving={entityEdited}
                                            handleCopyEntityVersion={controller.handleCopyEntityVersion}
                                            handleCompareEntityVersion={controller.handleCompareEntityVersion}
                                            handleUpdateToCurrentKindVersion={controller.handleUpdateToCurrentKindVersion}
                                            handleSaveEntityVersion={handleSaveEntity}
                      />
                    }
                  </Navbar.Collapse>
                </Container>
              </ContentBoxNavBar>
            </ContentBoxHeader>
            <ContentBoxBody>
              {!props.entityVersion.isNewEntity() &&
                <EntityVersionHighlights entityVersion={props.entityVersion}/>
              }
              <Row>
                <Col
                  className={props.entityVersion.kindVersionSummary.canUseSpecifications ? "col-lg-8 col-md-6 col-sm-12" : "col-md-12"}>
                  {entityVersionEditable && props.entityVersion.specificationPropertiesUpdated &&
                    <Alert variant="warning">
                      <strong>Warning:</strong> This Entity version has out of date specification properties and has
                      been updated. Please save
                      the changes if you are happy with them.
                    </Alert>
                  }
                  {entityVersionEditable && props.entityVersion.isOnOldVersionOfKind() &&
                    <Alert variant="warning">
                      <strong>Warning:</strong> This Entity version is not on the current Kind version. You can carry on
                      making changes
                      based on the existing Kind version or you can update it to the current Kind version.
                    </Alert>
                  }
                  <EntityProperties
                    kindProperties={props.entityVersionKindVersion.properties}
                    entityProperties={props.entityVersion.getKindProperties()}
                    nameControl={<Observer>{() => <Form.Control type="text" id="entityName"
                                                                placeholder="Please enter entity name..."
                                                                onChange={handleNameChange}
                                                                value={props.entityVersion.name}
                                                                disabled={!entityVersionEditable}
                                                                data-testid="txtEntityName"

                    />}</Observer>}
                    descriptionControl={<Observer>{() => <Form.Control type="text" id="entityDescription"
                                                                       placeholder="Please enter entity description..."
                                                                       onChange={handleDescriptionChange}
                                                                       value={props.entityVersion.description}
                                                                       disabled={!entityVersionEditable}
                                                                       data-testid="txtEntityDescription"

                    />}</Observer>}
                    propertyControlProvider={(kp, ep) =>
                      entityVersionEditable
                        ? <EntityPropertyFactory kindProperty={kp} getPropertyValue={() => ep.value}
                                                 setPropertyValue={ep.setValue}/>
                        : <ReadonlyEntityPropertyFactory kindProperty={kp}
                                                         getPropertyValue={() => ep.value}/>
                    }
                    canUseSpecifications={!!props.entityVersion.kindVersionSummary.canUseSpecifications}
                  />
                </Col>
                <Col xs="12" md="6" lg="4">
                  {props.entityVersionKindVersion.canUseSpecifications &&
                    <YoFormBox>
                      <YoFormBoxBody>
                        {entityVersionEditable
                          ? <Observer>{() =>
                            <SpecificationsEditor  {...props}
                                                   specificationProperties={props.entityVersion.getSpecificationProperties()}
                                                   handleAddSpecificationProperty={controller.handleAddSpecificationProperty}
                                                   handleRemoveSpecificationProperty={controller.handleRemoveSpecificationProperty}/>
                          }</Observer>
                          : <ReadonlyEntitySpecificationProperties
                            specificationProperties={props.entityVersion.getSpecificationProperties()}
                            specificationKinds={props.specificationKindVersions}/>
                        }
                      </YoFormBoxBody>
                    </YoFormBox>
                  }
                </Col>
              </Row>
            </ContentBoxBody>
          </ContentBox>
        </div>
      </Container>
    </div>
  );
});
