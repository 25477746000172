import { useEffect, useState } from 'react';
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import {useRootStore} from "../../../loaders/useRootStore";

interface useGameEnvironmentCssClassResult{
    name : string
}
export const getGameEnvironmentClass = (gameEnvironment: IGameEnvironment) => {
  switch (gameEnvironment.name.toUpperCase()) {
    case "DEV":
      return "success";
    case "STG":
      return "warning"
    case "PRD":
      return "danger";
    default:
      return "info";
  }
}

export const useGameEnvironmentCssClass = (gameEnvironment? : IGameEnvironment ) : useGameEnvironmentCssClassResult => {

  const gameEnvironmentStore = useRootStore().gameEnvironmentStore;

  const [result, setResult] = useState<useGameEnvironmentCssClassResult>({ name: "success" })

  useEffect(() => {
    const switchGE = gameEnvironment ?? gameEnvironmentStore.currentGameEnvironment ?? gameEnvironmentStore.gameEnvironments[0];
    setResult({ name: getGameEnvironmentClass(switchGE)});
  }, [gameEnvironment, gameEnvironmentStore.currentGameEnvironment, gameEnvironmentStore.gameEnvironments])

  return result;
};
