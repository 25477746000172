import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Container from 'react-bootstrap/Container';
import RootStoreContext from '../../../model/RootStoreContext';
import { useGameEnvironmentCssClass } from '../common/useGameEnvironmentCssClass';
import { GameEnvironmentSelector } from '../common/GameEnvironmentSelector';
import { ExperimentsTable } from './ExperimentsTable';
import { useNavigate } from 'react-router-dom';
import { ViewHeader } from '@yakoffice/view-header';
import { YakofficePagination } from '../../components/yakoffice-pagination/YakofficePagination';
import { YakofficePageSize } from '../../components/yakoffice-pagesize/YakofficePageSize';
import Row from 'react-bootstrap/cjs/Row';
import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import { ClaimType, ExperimentStatus } from '@yakoffice/publisher-types';
import { ExperimentStatusToggleDropDownButton } from './ExperimentStatusToggleDropDownButton';
import { WithDelayedChange } from '../common/WithDelayedChange';
import { useExperimentsSearch } from './hooks/useExperimentsSearch';
import Button from 'react-bootstrap/Button';
import { IGameEnvironment } from '../../../model/gameEnvironment/GameEnvironment';
import { GameVersionSelector } from '../common/GameVersionSelector';
import { useAddExperimentLink, useGetExperimentsLink } from '../../routes';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';

export const Experiments : FC = observer(() => {

    const rootStore = useContext(RootStoreContext);
    const navigate = useNavigate();
    const cssClass = useGameEnvironmentCssClass();
    const {query, experimentsResult, experimentsCountResult, setPageNumber, setPageSize, setNameLike, toggleStatus} =
        useExperimentsSearch({
            defaultPageSize: 10,
            defaultStatuses: [ExperimentStatus.Draft, ExperimentStatus.Published, ExperimentStatus.Applied, ExperimentStatus.Stopped]
        })
    const getExperimentsLink = useGetExperimentsLink();
    const addExperimentLink = useAddExperimentLink();

    const onGameVersionChanged = (targetGv: IGameVersion) => navigate(getExperimentsLink({gameVersionId: targetGv.id}))
    const onGameEnvironmentChanged = (targetGe: IGameEnvironment) => navigate(getExperimentsLink({gameEnvironmentId: targetGe.id}));

    const handleCreateExperiment = () => navigate(addExperimentLink);

    return (
        rootStore.gameEnvironmentStore.currentGameEnvironment &&
        <div id={"experiments"} className={cssClass.name}>
                <Container>
                    <ViewHeader title={"Experiments"}
                                customAction={
                                  <>
                                    <GameVersionSelector handleGameVersionChange={onGameVersionChanged} data-testid="ddlGameVersion"/>
                                    <GameEnvironmentSelector handleGameEnvironmentChange={onGameEnvironmentChanged} data-testid="ddlGameEnvironment" />
                                  </>
                                }
                    />
                </Container>
                <Container>
                    <Row>
                        <Col xs="5" md="6">
                            {rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditExperiments) &&
                                <Button onClick={handleCreateExperiment} variant="primary">
                                    <i className="fas fa-plus-circle"/> Create Experiment
                                </Button>
                            }
                        </Col>
                        <Col xs="7" md="6" className="d-flex justify-content-end">
                          {query.pageSize &&
                            <span className="me-2">
                              <YakofficePageSize pageSize={query.pageSize} handleSetPageSize={setPageSize}/>
                            </span>
                          }
                          {(query.pageSize && query.pageNumber && !!experimentsCountResult.count) &&
                              <YakofficePagination pageSize={query.pageSize}
                                                   activePageNumber={query.pageNumber}
                                                   itemsCount={experimentsCountResult.count}
                                                  handleSetPageNumber={setPageNumber}/>
                          }
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <ExperimentsTable experimentsResult={experimentsResult}
                                      cssClass={cssClass.name}
                                      handleCreateExperiment={handleCreateExperiment}
                                      nameHeader={
                                          <WithDelayedChange waitInterval={1000}
                                                             delayedOnChange={value => setNameLike(value)}>{eventHandlers =>
                                              <Form.Control type="text"
                                                            size="sm"
                                                            placeholder="Name"
                                                            onChange={eventHandlers.handleOnChange}
                                                            defaultValue={query.nameLike}
                                                            disabled={!experimentsResult.isLoaded}
                                              />
                                          }</WithDelayedChange>
                                      }
                                      statusHeader={
                                          <ExperimentStatusToggleDropDownButton
                                              variant={cssClass.name}
                                              statuses={query.statuses || []}
                                              handleStatusToggle={toggleStatus}/>}/>

                </Container>
        </div>
    )
});
