import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {useGameEnvironmentCssClass} from "./useGameEnvironmentCssClass";
import styles from './GameEnvironmentSelector.module.sass';
import {useRootStore} from "../../../loaders/useRootStore";

interface PropsType {
  [key: string]: any,
  gameEnvironmentFilter?: (ge: IGameEnvironment) => boolean
  selectedGameEnvironment?: IGameEnvironment,
  handleGameEnvironmentChange?: (targetGE: IGameEnvironment) => void,
}

export const GameEnvironmentSelector : FC<PropsType> = observer(({ gameEnvironmentFilter, selectedGameEnvironment, handleGameEnvironmentChange,onlyGameVersionEnabledEnvironment, className,  ...props}) => {
    const rootStore = useRootStore();
    const cssClass = useGameEnvironmentCssClass(selectedGameEnvironment ?? rootStore.gameEnvironmentStore.getCurrentGameEnvironment());

    const changeGameEnvironment = (targetGE : IGameEnvironment) => {
      handleGameEnvironmentChange && handleGameEnvironmentChange(targetGE);
    }

    return (
            <DropdownButton align="end"
                            title={selectedGameEnvironment ? selectedGameEnvironment.name : rootStore.gameEnvironmentStore.getCurrentGameEnvironment().name}
                            variant={cssClass.name}
                            className={`${styles.gameEnvSelector} ${className ?? ""}`}
                            {...props}>
                {rootStore.gameEnvironmentStore.gameEnvironments
                  .filter(ge => gameEnvironmentFilter ? gameEnvironmentFilter(ge) : true)
                  .map(env => {
                    return <Dropdown.Item key={env.id} as="button" onClick={() => changeGameEnvironment(env)}>{env.name}</Dropdown.Item>
                })}
            </DropdownButton>
    );
})

