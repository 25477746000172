import React, {FC}                      from 'react';
import {observer}                       from 'mobx-react-lite';
import Container                        from 'react-bootstrap/Container';
import Row                              from 'react-bootstrap/Row';
import Col                              from 'react-bootstrap/Col';
import {PubRouteItems} from '../../routes';
import {IGameEnvironment}               from "../../../model/gameEnvironment/GameEnvironment";
import {GameEnvironmentsCard}           from './GameEnvironmentsCard';
import {IconCard}                       from '@yakoffice/icon-card'
import {ViewHeader}                     from '@yakoffice/view-header';


interface PropsType {
    gameEnvironments    : IGameEnvironment[];
}


export const GameEnvironments : FC<PropsType> = observer((props) => {

    return  <div id="gameEnvironments">
        <Container>
            <ViewHeader title="Game Environments" />
        </Container>
        <Container>
                 <Row>
                     <GameEnvironmentsCard gameEnvironments={props.gameEnvironments} />
                     <Col xl="2" lg="3" md="3" sm="12" xs="12" className="mb-4">
                         <IconCard icon={<i className="fas fa-folder-plus" />} title={"Add New Game Environment"} link={PubRouteItems.AddGameEnvironment} />
                     </Col>
                 </Row>
        </Container>
    </div>
});
