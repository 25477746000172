import { useContext, useEffect, useState } from 'react';
import {autorun}                                from "mobx";
import RootStoreContext                         from "../../../model/RootStoreContext";
import {ISelection}                             from '../../../model/selections/Selection';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";

interface useSelectionsResult{
    isLoaded      : boolean;
    selections    : ISelection[];
}

export const useSelections = (archived: boolean, refreshFlag: boolean) => {

    const rootStore             = useContext(RootStoreContext);
    const publishNotification   = usePublishNotification();

  const [result, setResult] = useState<useSelectionsResult>({isLoaded: false, selections: []})

    useEffect(() => {
        autorun(() => {
          setResult({ isLoaded: false, selections: [] })

            rootStore.selectionStore.loadSelections(archived)
                .then(() => {
                  setResult({ isLoaded: true, selections: rootStore.selectionStore.selections })
                })
                .catch(e => publishNotification({
                    notificationType: NotificationType.Error,
                    title           : "Error loading selections",
                    message         : `${e.message}`,
                    footer          : "Please try refreshing"
                }))
        })
    }, [rootStore.selectionStore, publishNotification, rootStore.gameVersionStore.currentGameVersion, archived, refreshFlag]);

    return result;
};
