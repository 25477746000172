import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import type {IKindPropertyType} from "../model/kind/KindPropertyType";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from 'react-router-dom';

interface useKindPropertyTypesResult{
    isLoaded            : boolean;
    kindPropertyTypes    : IKindPropertyType[] | null
}

export const useKindPropertyTypes = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const { projectId } = useParams<{projectId: string}>();

  const [result, setResult] = useState<useKindPropertyTypesResult>({ isLoaded: false, kindPropertyTypes: [] })

  useEffect(() => {
    setResult({ isLoaded: false, kindPropertyTypes: [] })

    rootStore.kindPropertyTypeStore.loadKindPropertyTypes()
      .then(() => {
        setResult({ isLoaded: true, kindPropertyTypes: rootStore.kindPropertyTypeStore.kindPropertyTypes })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading kindPropertyTypes",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.kindPropertyTypeStore, publishNotification, projectId]);

  return result;
};
