import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import type {IGameEnvironment}                       from '../model/gameEnvironment/GameEnvironment';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { useParams } from "react-router-dom";

interface useGameEnvironmentsResult{
    isLoaded            : boolean;
    gameEnvironments    : IGameEnvironment[] | null
}

export const useGameEnvironments = () => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();
  const { projectId } = useParams<{projectId: string}>();

  const [result, setResult] = useState<useGameEnvironmentsResult>({ isLoaded: false, gameEnvironments: [] })

  useEffect(() => {
    setResult({ isLoaded: false, gameEnvironments: [] })

    rootStore.gameEnvironmentStore.loadGameEnvironments()
      .then(() => {
        setResult({ isLoaded: true, gameEnvironments: rootStore.gameEnvironmentStore.gameEnvironments })
      })
      .catch(e => publishNotification({
        notificationType: NotificationType.Error,
        title: "Error loading game environments",
        message: `${e.message}`,
        footer: "Please try refreshing"
      }))
  }, [rootStore.gameEnvironmentStore, publishNotification, projectId]);

  return result;
};
