import React, {useContext} from "react";
import Button from "react-bootstrap/Button";
import RootStoreContext from "../model/RootStoreContext";
import {FormattedErrorMessage} from "../presentation/views/common/Utils";
import CustomCommentForm from "../presentation/components/custom-comment-form/customCommentForm";
import {useShowModal} from '@yakoffice/custom-modal';
import styles from './useDistributionsController.module.sass'
import { useShowModalSpinner } from '@yakoffice/custom-modal';

import {IDistributionDiffVm} from '@yakoffice/publisher-types';
import JiraIssueIdsForm from '../presentation/views/distribution/JiraIssueIdsForm';
import {IGameEnvironment} from "../model/gameEnvironment/GameEnvironment";
import {IDistribution} from "../model/distributions/Distribution";

export interface IDistributionsController{
    handleDistribute : (successCallback : () => void) => void;
    handleCopyDistribution(distribution: IDistribution | null, toGameEnvironment: IGameEnvironment): void;
    handleDownloadAsJson : (successCallback : () => void) => void
}

export const useDistributionsController = () => {

  // Context Hooks
  const showModal = useShowModal();
  const showModalSpinner = useShowModalSpinner();
  const rootStore = useContext(RootStoreContext);

  // Handlers
  const handleDistribute = (successCallback: () => void) => {
    const currentGv = rootStore.gameVersionStore.getCurrentGameVersion();
    const currentGe = rootStore.gameEnvironmentStore.getCurrentGameEnvironment();
    let distributionComment = "";
    let distributionJiraIssueIds = "";

    const distribute = () => {
      showModalSpinner("Run distribution", "Running distribution")
      rootStore.distributionStore.distribute({ comment: distributionComment, jiraIssueIds:distributionJiraIssueIds})
        .then(() => {
          showModal({ body: "", canClose: false, title: '', show: false });
          successCallback();
        })
        .catch(e => {
          showModal({
            show: true,
            title: 'Error Distributing',
            body: <FormattedErrorMessage errorMessage={e.message} />,
            canClose: true,
          });
        })
    };

    const confirmIfProductionAndDistribute = () => {
      if (currentGe.name !== "PRD")
        distribute();
      else {
        showModalSpinner("Distributing", "Checking for changes...");
        rootStore.distributionStore
          .loadDistributions({ pageSize: 1, pageNumber: 1, hideFailed: true }, true)
          .then((distributions) => {
            const lastDistributionId = distributions && distributions.length > 0 ? distributions[0].id : "published"
            rootStore.distributionStore
              .getDistributionDiff(currentGv, currentGe, "published", currentGv, currentGe, lastDistributionId)
              .then((diff : IDistributionDiffVm) => {
                const body = <div>
                  <h3>Danger! <small>Are you sure you want to do this?</small></h3>
                  {lastDistributionId !== "published"
                    ? <ul key="entities">
                      <li>Entities:
                        <ul>
                          <li key="entitiesAdded">{diff.addedEntities.length} Added</li>
                          <li key="entitiesDeleted">{diff.deletedEntities.length} Deleted</li>
                          <li key="entitiesModified">{diff.modifiedEntities.length} Modified</li>
                        </ul>
                      </li>
                      <li key="experiments">Experiments:
                        <ul>
                          <li key="experimentsAdded">{diff.addedExperiments.length} Added</li>
                          <li key="experimentsDeleted">{diff.deletedExperiments.length} Deleted</li>
                        </ul>
                      </li>
                    </ul>
                    : <p>This is the first distribution for this game environment</p>
                  }
                </div>;
                showModal({
                  show: true,
                  title: "Run distribution (Production)",
                  body: body,
                  action: <Button variant="danger" onClick={() => distribute()}>Yes, run the distribution!</Button>,
                  dialogClassName: styles.dangerModal,
                  canClose: true
                });
              })
              .catch(e => showModal({
                  show: true,
                  title: 'Error Loading Diff',
                  body: <FormattedErrorMessage errorMessage={e.message} />,
                  canClose: true
                })
              )
          })
          .catch(e => showModal({
              show: true,
              title: 'Error Loading Latest Distribution',
              body: <FormattedErrorMessage errorMessage={e.message} />,
              canClose: true
            })
          )
      }
    };

    showModal(
      {
        show: true,
        title: "Run distribution",
        body: <>
            <CustomCommentForm handler={comment => distributionComment = comment} />
            <JiraIssueIdsForm onChange={jiras => distributionJiraIssueIds = jiras} />
          </>,
        action: <Button variant="success" onClick={() => confirmIfProductionAndDistribute()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleCopyDistribution = (distribution: IDistribution | null, toGameEnvironment: IGameEnvironment) => {

    const description = `${distribution ? "distribution " + distribution.id : "published"} entities from ${rootStore.gameEnvironmentStore.getCurrentGameEnvironment().name} to ${toGameEnvironment.name}`;

    const copy = () => {
      showModalSpinner("Copy Entities", `Copying ${description}...`);
      rootStore.distributionStore
        .copyEntities(
          rootStore.gameVersionStore.getCurrentGameVersion(),
          rootStore.gameEnvironmentStore.getCurrentGameEnvironment(),
          distribution?.id ?? "published",
          toGameEnvironment)
        .then((result) => showModal({
          show: true,
          title: 'Copy Entities',
          body: <div>
            <div>All Done!  Changes made to {toGameEnvironment.name} entities:</div>
            <ul>
              <li>Archived: {result.archived}</li>
              <li>Added:    {result.added}</li>
              <li>Updated:  {result.updated}</li>
            </ul>
          </div>,
          canClose: true}))
        .catch(e => showModal({
          show: true,
          title: 'Error Copying Entities',
          body: <FormattedErrorMessage errorMessage={e.message} />,
          canClose: true
        }));
    }

    showModal(
      {
        show: true,
        title: "Copy Entities",
        body: `Are you sure you want to copy ${description}?`,
        action: <Button variant="success" onClick={() => copy()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleDownloadAsJson = async (successCallback : () => void) => {

    const downloadJson = (json: any) => {
      const jsonString  = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
      const link        = document.createElement("a");
      link.href         = jsonString;
      const timeStamp   = Date.now();
      link.download     = `distributedEntitiesAsJson-${timeStamp}.json`;
      link.click();
      showModal({show: false});
    }
    const fetchDistributionAsJson = async () => {

      showModalSpinner("Download Distribution as JSON", "Generating JSON")
      rootStore.distributionStore.downloadDistributionAsJSON({gameVersionId: rootStore.gameVersionStore.getCurrentGameVersion().id, gameEnvironmentId: rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id})
        .then((res) => {
          showModal(
            {
              show: true,
              title: "Download Distribution as JSON",
              body: `JSON ready. Click the button below to download.`,
              action: <Button variant="success" className="me-2" onClick={() => downloadJson(res)} data-testid="btnModalSave"><i className="fa fa-download"/> Download</Button>,
              canClose: true
            });
        })
        .catch(e => {
          showModal({
                      show: true,
                      title: 'Error downloading distribution as JSON',
                      body: <FormattedErrorMessage errorMessage={e.message} />,
                      canClose: true,
                    });
        })
    }
    showModal(
      {
        show: true,
        title: "Download Distribution as JSON",
        body: `A JSON file will be generated with the distribution and downloaded.`,
        action: <Button variant="success" className="me-2" onClick={fetchDistributionAsJson} data-testid="btnModalSave">OK</Button>,
        canClose: true
      }
    );
  }


  const controller: IDistributionsController = {
    handleDistribute,
    handleCopyDistribution,
    handleDownloadAsJson
  };


  return controller;
};
