import React, {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {IDistribution} from "../../../model/distributions/Distribution";
import {useDistributedEntities} from "./useDistributedEntities";
import {CustomSpinner} from '@yakoffice/custom-spinner';
import Container from "react-bootstrap/Container";
import {DistributionExperimentsTable} from "./DistributionExperimentsTable";
import {useFindExperiments} from "../../../loaders/useFindExperiments";
import {IExperimentSearchParams} from "../../../api/requests/ExperimentApiGateway";
import Badge from "react-bootstrap/Badge";
import {ExperimentStatus} from "@yakoffice/publisher-types";
import {GroupedByKindEntityVersions} from "./GroupedByKindEntityVersions";
import {YoTabs} from '../../components/yo-tabs/YoTabs';
import {Tab} from 'react-bootstrap';
import {DistributionOverlayHeader} from './DistributionOverlayHeader';
import {DistributionHighlights} from './DistributionHighlights';
import {rootStore} from '../../../model/RootStoreContext';
import {useDistributionsController} from '../../../controllers/useDistributionsController';


interface PropsType {
  distribution: IDistribution | null;
  title: string
}


export const Distribution: FC<PropsType> = observer(({distribution, title}) => {

  const distributedEntitiesResult = useDistributedEntities(distribution);
  const [searchParams] = useState<IExperimentSearchParams>(distribution != null
                                                           ? {distributionId: distribution.id}
                                                           : {statuses: [ExperimentStatus.Published]})
  const distributedExperimentsResult = useFindExperiments(searchParams);

  return <>
    <DistributionOverlayHeader title={title} highlights={distribution ? <DistributionHighlights distribution={distribution}/> : undefined}/>
    <Container>
      <div className="mt-2">
        <YoTabs>
          <Tab eventKey="entitiesTab"
               title={
                 <span>
                        Entities <Badge bg="secondary">{distributedEntitiesResult.entityVersions.length}</Badge>
                      </span>}>
            {!distributedEntitiesResult.isLoaded
             ? <CustomSpinner spinnerText="Loading entities..."/>
             : <GroupedByKindEntityVersions entityVersions={distributedEntitiesResult.entityVersions} />
            }
          </Tab>
          <Tab eventKey="experimentsTab"
               title={
                 <span>
                        Experiments  <Badge bg="secondary">{distributedExperimentsResult.experiments.length}</Badge>
                      </span>}>
            {distributedExperimentsResult.isLoaded
             ? <DistributionExperimentsTable experiments={distributedExperimentsResult.experiments}/>
             : <CustomSpinner spinnerText="Loading experiments..."/>
            }
          </Tab>
        </YoTabs>
      </div>
    </Container>
  </>
});
