import React, { FC } from 'react';

import styles from './feature-header.module.sass';

export interface FeatureHeaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  size?: "sm" | "lg"
}

export const FeatureHeader : FC<FeatureHeaderProps> = ({size, className, ...props}) => {
  return  <span {...props} className={`${styles.header} ${size ? styles[size] : ""} ${className ?? ""}`}>
    {props.children}
  </span>
}
