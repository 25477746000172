import {flow}                       from "mobx";
import {Instance, types, getRoot}   from "mobx-state-tree"
import {ApiRequest}                 from "./ApiRequest";
import type { IGameVersionVm, ISearchResultVm } from '@yakoffice/publisher-types';
import type {IGameVersionDto}        from '@yakoffice/publisher-types';
import { IPaginationSearchParams } from './IPaginationSearchParams';
import {pub_api}                    from "../index";
import {RootStore}                  from "../../model/RootStore";

export interface IGameVersionSearchParams extends IPaginationSearchParams{
  status?: string | string[]
}

export const GameVersionApiGateway = types.model(
    "GameVersionApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl() : string{
          const projectId = getRoot<typeof RootStore>(self).projectStore.getCurrentProject().id;
          return `${pub_api}/${projectId}/versions/`;
        }
    }))
    .actions(self => ({
      getGameVersions: flow(function* (searchParams: IGameVersionSearchParams) {
        const queryString = buildSearchQueryString(searchParams);
        return yield self.apiRequest.get<IGameVersionVm[]>(`${self.getRootUrl()}?${queryString}`)
      }),
      getGameVersion: flow(function* (gameVersionId: number) {
        return yield self.apiRequest.get<IGameVersionVm>(self.getRootUrl() + gameVersionId)
      }),
      saveGameVersion: flow(function* (gameVersionDto: IGameVersionDto) {
        return yield self.apiRequest.post<IGameVersionVm>(self.getRootUrl(), gameVersionDto)
      }),
      updateGameVersion: flow(function* (gameVersionId: number, gameVersionDto: IGameVersionDto) {
        return yield self.apiRequest.put<IGameVersionVm>(`${self.getRootUrl()}${gameVersionId}`, gameVersionDto)
      }),
      branchGameVersion: flow(function* (gameVersionId: number, gameVersionDto: IGameVersionDto) {
        return yield self.apiRequest.post<IGameVersionVm>(`${self.getRootUrl()}${gameVersionId}/branch`, gameVersionDto)
      }),
      deleteGameVersion: flow(function* (gameVersionId: number) {
        yield self.apiRequest.delete(`${self.getRootUrl()}${gameVersionId}`)
      }),
      updateGameVersionStatus: flow(function* (gameVersionId: number, status) {
        return yield self.apiRequest.put<IGameVersionVm>(`${self.getRootUrl()}${gameVersionId}/status/${status}`, null)
      }),
      searchInGameVersion: flow(function* (gameVersionId: number, searchValue: string, searchLimit: number) {
        return yield self.apiRequest.get<ISearchResultVm>(`${self.getRootUrl()}${gameVersionId}/search?query-text=${searchValue}&page-size=${searchLimit}`)
      }),
    }));

function buildSearchQueryString(searchParams: IGameVersionSearchParams) {
  let queryString = "";
  if (searchParams.status) {
    if (Array.isArray(searchParams.status))
      searchParams.status.forEach(status => {
        queryString += `status=${status}&`;
      })
    else
      queryString += `status=${searchParams.status}&`;
  }
  return queryString;
}

export interface IGameVersionApiGateway extends Instance<typeof GameVersionApiGateway> {}
