import React, { FC, Fragment, ReactElement } from 'react';

interface PropsType {
  jiraIssueIds: string | null;
  delimiter?: string | ReactElement
  className?: string
}

const JiraIssueLinks : FC<PropsType> = ({jiraIssueIds, className, delimiter}) => {
  const jiraIssueIdsArray = jiraIssueIds != null ? jiraIssueIds.split(",") : [];
  return <>
    {jiraIssueIdsArray.map((id, index) => <Fragment key={`${id}-${index}`}>
        <a  className={className} href={`https://yakuto.atlassian.net/browse/${id}`} target="_blank" rel="noreferrer">{id}</a>
        {(delimiter && jiraIssueIdsArray.length > 1 && index < jiraIssueIdsArray.length-1 ) && delimiter}
      </Fragment>
    )}
  </>
}

export default JiraIssueLinks
