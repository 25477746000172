import React, { ChangeEvent, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import RootStoreContext from '../model/RootStoreContext';
import { CustomSpinner } from '@yakoffice/custom-spinner';
import { FormattedErrorMessage } from '../presentation/views/common/Utils';
import { useNavigate } from 'react-router-dom';
import { IExperiment } from '../model/experiment/Experiment';
import { Observer } from 'mobx-react-lite';
import FieldLayout from '../presentation/components/inputs/fieldLayout';
import Form from 'react-bootstrap/Form';
import { ExperimentStatus } from '../api/requests/ExperimentApiGateway';
import { IKindVersion } from '../model/kind/KindVersion';
import { IKindProperty } from '../model/kind/KindProperty';
import { IEntityProperty } from '../model/entity/EntityProperty';
import { useShowModal } from '@yakoffice/custom-modal';
import { useGetExperimentsLink } from '../presentation/routes';
import { IGameVersion } from '../model/gameVersion/GameVersion';
import { IGameEnvironment } from '../model/gameEnvironment/GameEnvironment';
import { IVariant } from '../model/experiment/Variant';


export interface IExperimentController {
  handleSaveExperiment: (andPublish: boolean, andCreateSheet: boolean, onSuccessCallback: () => void) => Promise<void>;
  handleApplyExperiment: (variant: IVariant) => Promise<void>;
  handlePublishExperiment: () => Promise<void>;
  handleStopExperiment: () => Promise<void>;
  handleArchiveExperiment: () => Promise<void>;
  handleUnarchiveExperiment: () => Promise<void>;
  handleDeleteExperiment: () => Promise<void>;
  handleCopyExperiment: (targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment) => Promise<void>;
  handleAddSpecification: (specificationKind: IKindVersion, specificationProperty: IKindProperty) => Promise<void>;
  handleRemoveSpecification: (property: IEntityProperty) => Promise<void>;
  handleCreateSheet: (onSuccessCallback: () => void) => Promise<void>;
  handleViewSheet: () => void;
  handleDeleteSheet: (onSuccessCallback: () => void) => Promise<void>;
  handleRefreshSheet: () => Promise<void>;
  handleUploadSheet: (completedCallback: () => void) => void;
}

export const useExperimentController = (experiment: IExperiment) => {

  // Context Hooks
  const showModal = useShowModal();
  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const getExperimentsLink = useGetExperimentsLink();

  // Handlers
  const showModalSpinner = (title: string, body: string) => {
    showModal({
                show:     true,
                title:    title,
                body:     <CustomSpinner spinnerText={body} position={'relative'} />,
                canClose: false
              });
  };

  function hideSpinnerAndRedirect(
    targetGEId: number = rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id,
    targetGVId: number = rootStore.gameVersionStore.getCurrentGameVersion().id
  ) {
    showModal({ show: false, title: '', body: '', canClose: false });
    navigate(getExperimentsLink({ gameVersionId: targetGVId, gameEnvironmentId: targetGEId }));
  }


  const handleSaveExperiment = async (andPublish: boolean, andCreateSheet: boolean, onSuccessCallback: () => void) => {

    const handleExperimentCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
      experiment.setComment(e.target.value);
    };

    const saveExperiment = async () => {
      try {
        showModalSpinner(contextTextValues(), `Saving experiment ${andPublish ? "and publishing" : ""} ${andCreateSheet ? ' and creating Google Sheet' : ""}...`);

        const vm = await rootStore.experimentStore.saveExperiment(experiment, andPublish);

        if(andCreateSheet) {

          if(experiment.googleSheetId === null) {
            await handleCreateSheet(onSuccessCallback, vm.id);
            hideSpinnerAndRedirect();
          }
          else
            await handleRefreshSheet(onSuccessCallback, vm.id);
        } else {
          onSuccessCallback();
          hideSpinnerAndRedirect();
        }

      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Saving/Updating Experiment',
                    body:     <FormattedErrorMessage errorMessage={e.message} />,
                    canClose: true
                  });
      }
    };

    const contextTextValues = () => {
      if (andPublish)
        return 'Save and Publish Experiment';
      else if (andCreateSheet)
        return `Save and ${experiment.isNewExperiment() ? 'Create' : 'Update'} Google Sheet`;
      else
        return 'Save/Update Experiment';
    };

    showModal(
      {
        show:     true,
        title:    contextTextValues(),
        body:     <Observer>{() => <FieldLayout label='You are about to update this experiment. Please enter a comment.'>
          <Form.Control type='text' id='comment' value={experiment.comment == null ? undefined : experiment.comment}
                        onChange={handleExperimentCommentChange} data-testid='txtCustomComment' />
        </FieldLayout>
        }</Observer>,
        action:   <Button variant='success' onClick={saveExperiment} data-testid='btnModalConfirmSave'>{contextTextValues()}</Button>,
        canClose: true
      }
    );
  };

  const handleApplyExperiment = async (variant: IVariant) => {
    const updateExperimentStatus = async () => {
      try {
        showModalSpinner('Apply Experiment Variant', 'Applying experiment....');
        await rootStore.experimentStore.applyExperimentVariantToEntities(experiment, variant);
        hideSpinnerAndRedirect();
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Applying Experiment Variant',
                    body:     `Could not ${ExperimentStatus.Apply} experiment. Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    `Apply Experiment Variant`,
        body:     `You are about to apply the "${variant.name}" variant changes in this experiment to the entities. Are you sure you want to do this?`,
        action:   <Button variant='success' onClick={updateExperimentStatus}>{`Apply Experiment`}</Button>,
        canClose: true
      }
    );
  };

  const handleExperimentStatusChange = async (status: string, comment: string, newStatus: ExperimentStatus, noConfirm?: boolean) => {
    const updateExperimentStatus = async () => {
      try {
        showModalSpinner('Changing Experiment Status', 'Saving experiment status....');
        await rootStore.experimentStore.updateExperimentStatus(experiment, newStatus);
        hideSpinnerAndRedirect();
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Changing Experiment Status',
                    body:     `Could not ${newStatus} experiment. Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    noConfirm ? await updateExperimentStatus() : showModal(
      {
        show:     true,
        title:    `${status} Experiment`,
        body:     comment,
        action:   <Button variant='success' onClick={updateExperimentStatus}>{`${status} Experiment`}</Button>,
        canClose: true
      }
    );
  };

  const handlePublishExperiment = async (noConfirm?: boolean) => {
    await handleExperimentStatusChange(
      'Publish',
      'You are about to publish this experiment. Once published this experiment will be live in this game environment. Are you sure you want to do this?',
      ExperimentStatus.Publish, noConfirm && noConfirm);
  };

  const handleStopExperiment = async () => {
    await handleExperimentStatusChange(
      'Stop',
      'You are about to stop this experiment. Once stopped this experiment will no longer be live in this game environment. Are you sure you want to do this?',
      ExperimentStatus.Stop);
  };

  const handleArchiveExperiment = async () => {
    await handleExperimentStatusChange(
      'Archive',
      'You are about to archive this experiment. Are you sure you want to do this?',
      ExperimentStatus.Archive);
  };

  const handleUnarchiveExperiment = async () => {
    await handleExperimentStatusChange(
      'Unarchive',
      'You are about to un-archive this experiment. Are you sure you want to do this?',
      ExperimentStatus.Draft);
  };

  const handleDeleteExperiment = async () => {
    const deleteExperiment = async () => {
      try {
        showModalSpinner('Deleting Experiment', 'Deleting experiment....');
        await rootStore.experimentStore.deleteExperiment(experiment);
        hideSpinnerAndRedirect();
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Deleting Experiment',
                    body:     `Could not delete experiment ${experiment.name}. Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Delete Experiment',
        body:     'You are about to DELETE this experiment. This is a permanent action! Are you sure you want to delete this experiment?',
        action:   <Button variant='success' onClick={deleteExperiment}>Delete Experiment</Button>,
        canClose: true
      }
    );
  };

  const confirmAndSaveCopiedExperiment = (copiedExperiment: IExperiment) => {
    copiedExperiment.setComment(`Copied by ${rootStore.authStore.currentAuthUser.displayName}`);
    const copyExperiment = async () => {
      try {
        showModalSpinner('Copying Experiment', 'Copying experiment....');
        await rootStore.experimentStore.saveExperiment(copiedExperiment, false);
        hideSpinnerAndRedirect(copiedExperiment.gameEnvironmentId, copiedExperiment.gameVersionId);
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Copying Experiment',
                    body:     `Could not copy experiment ${copiedExperiment.name}. Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    const handleCopyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      copiedExperiment.setName(e.target.value);
    };

    showModal(
      {
        show:     true,
        title:    'Copy Experiment',
        body:     <Observer>{() => <FieldLayout
          label='You are about to copy this experiment. Please enter a new name, if desired:'>
          <Form.Control type='text' id='txtExperimentName' value={copiedExperiment.name} onChange={handleCopyNameChange} data-testid='txtModalNewName' />
        </FieldLayout>
        }</Observer>,
        action:   <Button variant='success' onClick={copyExperiment} data-testid='btnModalSave'>Save/Update Experiment</Button>,
        canClose: true
      }
    );
  };

  const handleCopyExperiment = async (targetGameVersion: IGameVersion, targetGameEnvironment: IGameEnvironment) => {
    try {
      showModalSpinner('Checking Existing Entity Versions', `Checking existing entities/updates in ${targetGameEnvironment.name} (${targetGameVersion.name})....`);
      const copiedExperiment = await rootStore.experimentStore.copyExperiment(experiment, targetGameVersion, targetGameEnvironment);
      confirmAndSaveCopiedExperiment(copiedExperiment);
    } catch (e: any) {
      showModal({
                  show:     true,
                  title:    'Error Copying Experiment',
                  body:     `Error:  ${e.message}`,
                  canClose: true
                });
    }
  };

  const handleAddSpecification = async (specificationKind: IKindVersion, specificationProperty: IKindProperty) => {
    if (experiment.specifications.find(p => p.kindPropertyKey === specificationProperty.key)) {
      showModal({
                  show:     true,
                  title:    'Error',
                  body:     `Cannot add this specification property to the entity because it has the same key as an existing property`,
                  canClose: true
                });
      return;
    }

    experiment.addSpecification(specificationKind, specificationProperty);
  };

  const handleRemoveSpecification = async (property: IEntityProperty) => {
    experiment.removeSpecification(property);
  };

  const handleCreateSheet = async (onSuccessCallback: () => void, overwriteId?: number) => {

    try {
      showModalSpinner('Creating Google Sheet', `Creating Google Sheet...`);

      await rootStore.experimentStore.createSheet(experiment, overwriteId ? overwriteId : experiment.id);
      onSuccessCallback();
      showModal({
                  show:     true,
                  title:    'Open Google Sheet?',
                  body:     'Do you want to open and view the Experiment in Google Sheets?',
                  action:   <Button variant='success' onClick={() => handleViewSheet()}>Open Sheet</Button>,
                  canClose: true
                })
    } catch (e: any) {
      showModal({
                  show:     true,
                  title:    'Error Creating Google Sheet',
                  body:     <FormattedErrorMessage errorMessage={e.message} />,
                  canClose: true
                });
    }
  };

  const handleViewSheet = () => {
    experiment.googleSheetId != null && window.open(`https://docs.google.com/spreadsheets/d/${experiment.googleSheetId}/edit`, '_blank');
  };

  const handleDeleteSheet = async ( onSuccessCallback: () => void) => {
    const deleteGoogleSheet = async () => {
      try {
        showModalSpinner('Delete Google Sheet', 'Uploading...');
        await rootStore.experimentStore.deleteSheet(experiment);
        onSuccessCallback();
        showModal(
          {
            show:     true,
            title:    'Delete Google Sheet',
            body:     <div>Google sheet deleted.</div>,
            canClose: true
          }
        );
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Deleting Sheet',
                    body:     `Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Delete Google Sheet',
        body:     `Are you sure you want to delete the google sheet?  It will delete worksheets for all game environments `,
        action:   <Button variant='primary' onClick={async () => deleteGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleRefreshSheet = async (onSuccessCallback?: () => void, overwriteId?: number) => {

    const refreshGoogleSheet = async () => {
      try {
        showModalSpinner('Refresh Google Sheet', 'Refreshing...');

        await rootStore.experimentStore.refreshSheet(overwriteId ? overwriteId : experiment.id);
        onSuccessCallback && onSuccessCallback();
        showModal(
          {
            show:            true,
            title:           'Refresh Google Sheet',
            body:            <div>Google sheet refreshed.</div>,
            action:          <Button variant='primary' onClick={handleViewSheet}>Open Sheet</Button>,
            canClose:        true,
            closeButtonText: 'Close'
          }
        );
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Refreshing Sheet',
                    body:     `Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Regenerate Google Sheet',
        body:     `Are you sure you want to refresh the google sheet?`,
        action:   <Button variant='primary' onClick={async () => refreshGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const handleUploadSheet = (completedCallback: () => void) => {

    const uploadGoogleSheet = async () => {
      try {
        showModalSpinner('Upload Google Sheet', 'Uploading...');
        await rootStore.experimentStore.uploadSheet(experiment);
        completedCallback();
        showModal(
          {
            show:     true,
            title:    'Upload Google Sheet',
            body:     <div>Upload complete.</div>,
            canClose: true
          }
        );
      } catch (e: any) {
        showModal({
                    show:     true,
                    title:    'Error Uploading Sheet',
                    body:     `Error message: ${e.message}`,
                    canClose: true
                  });
      }
    };

    showModal(
      {
        show:     true,
        title:    'Upload Google Sheet',
        body:     `Are you sure you want to upload the google sheet?`,
        action:   <Button variant='primary' onClick={async () => uploadGoogleSheet()}>OK</Button>,
        canClose: true
      }
    );
  };

  const controller: IExperimentController = {
    handleSaveExperiment,
    handlePublishExperiment,
    handleApplyExperiment,
    handleStopExperiment,
    handleArchiveExperiment,
    handleUnarchiveExperiment,
    handleDeleteExperiment,
    handleCopyExperiment,
    handleAddSpecification,
    handleRemoveSpecification,
    handleCreateSheet,
    handleViewSheet,
    handleDeleteSheet,
    handleRefreshSheet,
    handleUploadSheet,
  };

  return controller;
};
