import React, { FC, useEffect, useState } from 'react';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { IconRightDiff } from './IconRightDiff';
import { IconLeftDiff } from './IconLeftDiff';
import { ModifiedEntityTable } from './ModifiedEntityTable';
import { Checked } from '../common/Checked';
import { IEntityVersionDiffVm } from '@yakoffice/publisher-types';
import { useMultiSelect } from '../../components/use-multi-select/useMultiSelect';
import { Container } from 'react-bootstrap';


interface PropTypes {
  className?: string
  title? : string
  modifiedEntities  : IEntityVersionDiffVm[],
  allowSelection : boolean,
  handleMergeChanges? : (entityVersionDiffs: IEntityVersionDiffVm[]) => void
}

export const GroupedByKindModifiedEntityVersionsContent : FC<PropTypes> = ({className, title, modifiedEntities, allowSelection, handleMergeChanges}) => {
  const [checkAll, setCheckAll] = useState(false);
  const [checkedEntities, setCheckedEntities] = useState<Checked<IEntityVersionDiffVm>[]>( modifiedEntities.map(e => ({checked: checkAll, value: e})))
  const multiSelect = useMultiSelect<Checked<IEntityVersionDiffVm>>({
    items: checkedEntities,
    selectItem: (item, selected) => {
      if(item.value.modifiedProperties.length === 0)
        return;

      item.checked = selected;
      setCheckedEntities(prevState => prevState.map(p => p));
    }
  });

  useEffect(() => {
      setCheckAll(false);
      setCheckedEntities(modifiedEntities.map(e => ({ checked: false, value: e })))
    }
    ,[modifiedEntities])

  const handleCheckedChange = (checkedEntity: Checked<IEntityVersionDiffVm>, checked: boolean) => {
    multiSelect.handleItemSelection(checkedEntity, checked)
  }

  const handleCheckAll = () => {
    const newState = !checkAll;
    multiSelect.handleSelectAll(newState);
    setCheckAll(newState);
  }

  return (
    <ContentBox>
      <ContentBoxHeader>
        <ContentBoxNavBar variant="dark" expand="lg">
          <Container>
            <Navbar.Brand className="text-white">
              <i className="fas fa-file" />{title ?? "Modified Entities"}
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className={`justify-content-end`}>
              {allowSelection && modifiedEntities.map(me => me.modifiedProperties).flat().length > 0 && checkedEntities.length > 0 &&
              <>
                <Button variant="success"
                        onClick={() => handleMergeChanges && handleMergeChanges(checkedEntities.filter(c => c.checked).map(c => c.value))}
                        disabled={!checkedEntities.find(ce => ce.checked)}
                        className="me-2"
                        data-testid="btnMergeEntities">
                  <i className="fas fa-save" /> Merge modified <IconRightDiff /> into <IconLeftDiff
                  className="ms-2" />
                </Button>

                <Button variant="light" onClick={() => handleCheckAll()}>
                  {checkAll ? "Unselect All" : "Select All"}
                </Button>
              </>
              }
            </Navbar.Collapse>
          </Container>
        </ContentBoxNavBar>
      </ContentBoxHeader>
      <ContentBoxBody>
        {checkedEntities
          .map(checkedEntity => <ModifiedEntityTable key={checkedEntity.value.diffEntityId}
                                                     className={className}
                                                     entityVersionDiff={checkedEntity.value}
                                                     displayCheckbox={allowSelection && checkedEntity.value.modifiedProperties.length > 0}
                                                     checked={checkedEntity.checked}
                                                     handleCheckedChange={(checked: boolean) => handleCheckedChange(checkedEntity, checked)} />)}
      </ContentBoxBody>
    </ContentBox>
  )
}
