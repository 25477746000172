import React, {FC} from 'react';
import Form from 'react-bootstrap/Form';
import { FormLabelOwnProps, FormLabelWithColProps } from 'react-bootstrap/FormLabel';
import styles from './YoLabel.module.sass'
import { ColProps } from 'react-bootstrap/Col';

export interface PropTypesFormLabelOwnProps extends FormLabelOwnProps {
  required? : boolean
}
export interface PropTypesFormLabelWithColProps extends FormLabelWithColProps, ColProps {
  required? : boolean
}

export const YoLabel : FC<PropTypesFormLabelOwnProps | PropTypesFormLabelWithColProps> = ({required, className,  ...props}) => {
  return <Form.Label {...props} className={`${className} ${required ? styles.required : ""}`}>{props.children}</Form.Label>
}

