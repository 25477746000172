import React, {FC} from 'react';
import {Kind} from "./kind";
import {useKindVersion} from "../../../loaders/useKindVersion";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import { useKindsForEntitySelection } from '../../../loaders/useKindsForEntitySelection';

export const KindContainer : FC = () => {

  const loadKindsForEntitySelectionResult = useKindsForEntitySelection();
  const kindVersionResult = useKindVersion();

  return !loadKindsForEntitySelectionResult.isLoaded || !kindVersionResult.isLoaded || kindVersionResult.kindVersion == null
    ? <CustomSpinner spinnerText="Loading kind..." />
    : <Kind kindVersion={kindVersionResult.kindVersion} />
}
