import {Instance, types, getRoot} from "mobx-state-tree"
import {pub_api} from "../../index";
import { ICategoryVm, IEntityVersionVm } from '@yakoffice/publisher-types';
import {flow} from "mobx";
import {RootStore} from "../../../model/RootStore";
import {ApiRequest} from "../ApiRequest";
import type {ICategoryDto} from "@yakoffice/publisher-types";
import { IEntityVersionSearchParams } from '../entities/entityVersionApiGateway';

export interface ICategorySearchParams{
  name?: string,
  gameVersionId?:number
}

export const CategoryApiGateway = types.model(
    "CategoryApiGateway",
    {
        apiRequest: ApiRequest
    })
    .views(self => ({
        getRootUrl(gameVersionId?: number) : string{
          const projectId = getRoot<typeof RootStore>(self).projectStore.getCurrentProject().id;
          const routeGameVersionId = gameVersionId ?? getRoot<typeof RootStore>(self).gameVersionStore.getCurrentGameVersion().id;
            return `${pub_api}/${projectId}/versions/${routeGameVersionId}/categories/`;
        }
    }))
    .actions(self => ({
        getCategory: flow(function* (categoryId: number) {
            return yield self.apiRequest.get<ICategoryVm>(self.getRootUrl()+categoryId)
        }),
        getCategories: flow(function* (searchParams: ICategorySearchParams) {
          const queryString = buildSearchQueryString(searchParams);
          return yield self.apiRequest.get<IEntityVersionVm[]>(`${self.getRootUrl(searchParams.gameVersionId)}?${queryString}`)
        }),
        saveCategory: flow(function* (categoryDto : ICategoryDto) {
            return yield self.apiRequest.post<ICategoryVm>(self.getRootUrl(), categoryDto)
        }),
        updateCategory: flow(function* (categoryId: number, categoryDto : ICategoryDto) {
            return yield self.apiRequest.put<ICategoryVm>(`${self.getRootUrl()}${categoryId}`, categoryDto)
        }),
        deleteCategory: flow(function* (categoryId: number) {
            yield self.apiRequest.delete(`${self.getRootUrl()}${categoryId}`)
        }),
        archiveCategory: flow(function* (categoryId: number) {
            return yield self.apiRequest.put<ICategoryVm>(`${self.getRootUrl()}${categoryId}/status/archived`, null)
        }),
        unArchiveCategory: flow(function* (categoryId: number) {
            return yield self.apiRequest.put<ICategoryVm>(`${self.getRootUrl()}${categoryId}/status/live`, null)
        }),
    }));

function buildSearchQueryString(searchParams: IEntityVersionSearchParams) {

  let queryString = "";
  if (searchParams.name)
    queryString += `name=${searchParams.name}&`;

  return queryString;
}

export interface ICategoryApiGateway extends Instance<typeof CategoryApiGateway> {}
