import React, {FC}          from 'react';
import {observer}           from 'mobx-react-lite';
import {CustomSpinner}      from '@yakoffice/custom-spinner';
import {useKindVersions}           from "../../../loaders/useKindVersions";
import {Kinds}              from "./kinds";
import {useSubCategories} from '../../../loaders/useSubCategories';

export const KindsContainer : FC = observer(() => {

    const subCategoriesResult = useSubCategories();
    const kindsResult         = useKindVersions();

    return (kindsResult.isLoaded && kindsResult.kindVersions != null)
        ? <Kinds subCategories={subCategoriesResult} kindVersions={kindsResult.kindVersions} />
        : <CustomSpinner spinnerText={'Loading kinds...'}/>

});
