import { useContext, useEffect, useState }        from 'react';
import { useParams }                              from "react-router-dom";
import {autorun}                                  from "mobx";
import RootStoreContext                           from "../model/RootStoreContext";
import type {IGameVersion}                             from '../model/gameVersion/GameVersion';
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";


interface useGameVersionResult{
  isLoaded    : boolean;
  gameVersion : IGameVersion | null
}


export const useGameVersion = () => {

  const rootStore             = useContext(RootStoreContext);
  const publishNotification   = usePublishNotification();
  const {gameVersionId}         = useParams<{gameVersionId: string}>();
  const [result, setResult]   = useState<useGameVersionResult>({
                                                                   isLoaded: false,
                                                                   gameVersion: null})
  useEffect(() => {
    autorun(() => {
      setResult({isLoaded:false, gameVersion: null})

      if(gameVersionId) {
        rootStore.gameVersionStore.getGameVersion(parseInt(gameVersionId))
          .then((gameVersion) => {
            setResult({isLoaded:true, gameVersion: gameVersion})
          })
          .catch(e => publishNotification({
                                            notificationType: NotificationType.Error,
                                            title:            "Error loading game version",
                                            message:          `${e.message}`,
                                            footer:           "Please try refreshing"
                                          }))
      } else {
        setResult({isLoaded:true, gameVersion: rootStore.gameVersionStore.addGameVersion()})
      }
    })

    return () => {
      rootStore.gameVersionStore.clearCurrentGameVersion();
    }
  }, [rootStore.gameVersionStore, publishNotification, gameVersionId]);

  return result;
};
