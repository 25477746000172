import {Instance, types} from "mobx-state-tree";
import {KindPropertyTypeStore} from "./kind/KindPropertyTypeStore";
import {SelectionStore} from "./selections/SelectionStore";
import {ProjectStore} from "./project/ProjectStore";
import {GameEnvironmentStore} from "./gameEnvironment/GameEnvironmentStore";
import {CategoryStore} from "./category/CategoryStore";
import {
    KindsForEntitySelectionStore,
    SpecificationsForExperimentsStore,
    SpecificationsForKindsStore
} from "./kind/SpecificationKindStore";
import {DistributionStore} from "./distributions/DistributionStore";
import {AuthStore} from "./auth/AuthStore";
import {ExperimentStore} from "./experiment/ExperimentStore";
import {EntitySelectionStore} from "./selections/EntitySelectionStore";
import { EntityVersionStore } from './entity/EntitytVersionStore';
import { GameVersionStore } from './gameVersion/GameVersionStore';
import { KindVersionStore } from './kind/KindVersionStore';


export const RootStore =  types.model('RootStore', {
    authStore: AuthStore,
    projectStore: ProjectStore,
    categoryStore: CategoryStore,
    gameVersionStore: GameVersionStore,
    gameEnvironmentStore: GameEnvironmentStore,
    kindVersionStore: KindVersionStore,
    entityVersionStore: EntityVersionStore,
    kindPropertyTypeStore : KindPropertyTypeStore,
    selectionStore: SelectionStore,
    entitySelectionStore: EntitySelectionStore,
    specificationsForKindsStore: SpecificationsForKindsStore,
    specificationsForExperimentsStore: SpecificationsForExperimentsStore,
    kindsForEntitySelectionStore: KindsForEntitySelectionStore,
    distributionStore: DistributionStore,
    experimentStore:     ExperimentStore
    });

export interface IRootStore extends Instance<typeof RootStore> {}
