import {useContext, useEffect, useState} from "react";
import RootStoreContext                         from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {IEntityVersion} from "../model/entity/EntityVersion";
import type {IEntityVersionSearchParams} from "../api/requests/entities/entityVersionApiGateway";

interface useFindEntitiesResult{
    isLoaded : boolean
    entityVersions : IEntityVersion[]
}

export const useFindEntities = (searchParams: IEntityVersionSearchParams) => {

    const rootStore = useContext(RootStoreContext);
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useFindEntitiesResult>(() => ({isLoaded: false, entityVersions: []}));

    useEffect(() => {
        setResult({isLoaded: false, entityVersions: []})
        rootStore.entityVersionStore.findAllCurrentEntityVersions(searchParams)
            .then((entityVersions) => { setResult({isLoaded: true, entityVersions: entityVersions})})
            .catch(e => publishNotification({
                notificationType: NotificationType.Error,
                title: "Error finding current entity versions",
                message: `${e.message}`,
                footer: "Please try refreshing"
            }))
    }, [rootStore.entityVersionStore, publishNotification, rootStore.gameVersionStore.currentGameVersion, searchParams]);

    return result;
};
