import { useContext, useEffect, useState } from 'react';
import RootStoreContext from "../../../model/RootStoreContext";
import {autorun} from "mobx";
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import { IDistributionDiffVm } from '@yakoffice/publisher-types';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import { DistributionId } from '@yakoffice/publisher-types';

interface useDistributionDiffResult{
    isLoaded : boolean;
    distributionDiff : IDistributionDiffVm | null
}

export const useDistributionDiff = (gameVersion: IGameVersion,
                                    gameEnvironment : IGameEnvironment,
                                    distributionId: DistributionId,
                                    diffGameVersion: IGameVersion,
                                    diffGameEnvironment: IGameEnvironment,
                                    diffDistributionId : DistributionId,
                                    refreshToggle: boolean) => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useDistributionDiffResult>({ isLoaded: false, distributionDiff: null })

  useEffect(() => {
    autorun(() => {
      setResult({ isLoaded: false, distributionDiff: null })

      rootStore.distributionStore.getDistributionDiff(gameVersion, gameEnvironment, distributionId, diffGameVersion, diffGameEnvironment, diffDistributionId)
        .then((distributionDiff) => {
          setResult({ isLoaded: true, distributionDiff: distributionDiff })
        })
        .catch(e => publishNotification({
          notificationType: NotificationType.Error,
          title: "Error loading diff",
          message: `${e.message}`,
          footer: "Please try refreshing"
        }))
    })
  }, [rootStore.distributionStore, publishNotification, gameVersion, gameEnvironment, distributionId, diffGameVersion, diffGameEnvironment, diffDistributionId, refreshToggle]);

  return result;
};
