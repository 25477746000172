import React, { FC, useEffect, useState }   from 'react';
import { observer }                         from 'mobx-react-lite';
import ListGroup                            from 'react-bootstrap/cjs/ListGroup';
import { ListGroupItem }                    from 'react-bootstrap';
import { IEntityVersion }                   from '../../../model/entity/EntityVersion';
import { IKindVersionSummary }              from '../../../model/kind/KindVersionSummary';
import styles                               from './CalendarKey.module.sass';


interface PropsType {
  entityVersions: IEntityVersion[];
  activeKinds   : number[];
  setActiveKinds: React.Dispatch<React.SetStateAction<number[]>>
}

export const EntityCalendarKey : FC<PropsType> = observer((props) => {

  const [kindVersionSummaries, setKindVersionSummaries] = useState<IKindVersionSummary[]>([])
  const {entityVersions, setActiveKinds} = props;


  useEffect(() => {
    const _kindVersionSummaries  : IKindVersionSummary[] = [];

    entityVersions.forEach((entityVersion: IEntityVersion) => {
      _kindVersionSummaries.push(entityVersion.kindVersionSummary as IKindVersionSummary)
    })

    //Removes duplicates
    const distinctKindIdSummaryMap = new Map<number, IKindVersionSummary>(_kindVersionSummaries.map(kindSummary => [kindSummary.kindId, kindSummary]));
    setActiveKinds([...distinctKindIdSummaryMap.keys()]);
    setKindVersionSummaries([...distinctKindIdSummaryMap.values()]);
  }, [entityVersions, setActiveKinds]);

  const getHexColor = (kindVersionSummary: IKindVersionSummary) => {
    return kindVersionSummary.kindHexColour ? kindVersionSummary.kindHexColour : "000000";
  }

  const toggleActiveKind = (kindId: number) => {
    if(props.activeKinds.includes(kindId)) {
      props.setActiveKinds((currentActiveKinds: number[]) => (currentActiveKinds.filter(_kindId => _kindId !== kindId)))
    }
    else {
      props.setActiveKinds((currentActiveKinds: number[]) => ([...currentActiveKinds, kindId]))
    }
  }

  return (
    <div className={styles.calendarKey}>
      <div>
        {kindVersionSummaries.map(kindVersionSummary => {
          if(kindVersionSummary)
            return (
              <ListGroup key={kindVersionSummary.kindId}>
                <ListGroupItem active={props.activeKinds.includes(kindVersionSummary.kindId)} onClick={() => toggleActiveKind(kindVersionSummary.kindId)}>
                  <i className="fas fa-square" style={{ color: `#${getHexColor(kindVersionSummary)}` }} /> = {kindVersionSummary.name}
                </ListGroupItem>
              </ListGroup>
            )
          else
            return null;
        })}
      </div>
    </div>
  )
})
