import React, { FC, useState } from 'react';
import {observer} from "mobx-react-lite";
import {IEntityVersionDiffVm} from "@yakoffice/publisher-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ContentBox, ContentBoxBody, ContentBoxHeader, ContentBoxNavBar} from '../../components/content-box/ContentBox';
import Navbar from 'react-bootstrap/Navbar';
import {CollapsePanel} from '@yakoffice/shared-components';
import {YoListGroup, YoListGroupItem} from '../../components/yo-list-group/YoListGroup';
import { Container, NavLink } from 'react-bootstrap';
import { GroupedByKindModifiedEntityVersionsContent } from './GroupedByKindModifiedEntityVersionsContent';

interface PropsType {
    className?: string
    modifiedEntities  : IEntityVersionDiffVm[],
    allowSelection: boolean,
    handleMergeChanges : (entityVersionDiffs: IEntityVersionDiffVm[]) => void
}

export const GroupedByKindModifiedEntityVersions : FC<PropsType> = observer(({className, modifiedEntities, allowSelection, handleMergeChanges}) => {

  const [kindName, setKindName] = useState(modifiedEntities.length > 0 ? modifiedEntities[0].kindName : null)
  const [kindNames] = useState([...new Set(modifiedEntities.map(e => e.kindName))])

  return <Row>
          <Col sm="4" lg="3">
            <ContentBox>
              <ContentBoxHeader>
                <ContentBoxNavBar variant="dark" expand="lg">
                  <Container>
                    <Navbar.Brand className="text-white"><i className="fas fa-folder" />Kinds</Navbar.Brand>
                  </Container>
                </ContentBoxNavBar>
              </ContentBoxHeader>
              <ContentBoxBody>
                <CollapsePanel>
                  <YoListGroup>
                    {kindNames.map(kn =>
                      <YoListGroupItem key={kn} active={kn === kindName}>
                        <NavLink bsPrefix="text-link" eventKey={kn} onClick={() => setKindName(kn)} data-testid={`pill${kn}`}>
                          {kn} ({modifiedEntities.filter(e => e.kindName === kn).length})
                        </NavLink>
                      </YoListGroupItem>
                    )}
                  </YoListGroup>
                </CollapsePanel>
              </ContentBoxBody>
            </ContentBox>
          </Col>
          <Col sm="8" lg="9">
            <GroupedByKindModifiedEntityVersionsContent className={className} modifiedEntities={modifiedEntities.filter(e => e.kindName === kindName)} allowSelection={allowSelection}  handleMergeChanges={handleMergeChanges} />
          </Col>
        </Row>
})
