import React, {FC, useContext} from 'react';
import {observer} from "mobx-react-lite";
import RootStoreContext from "../../../model/RootStoreContext";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import {IDistribution} from "../../../model/distributions/Distribution";
import {ClaimType} from "@yakoffice/publisher-types";
import {NotificationType, usePublishNotification} from "@yakoffice/notification-handler";
import {useNavigate} from 'react-router';
import {useGetDistributionDiffLink, useGetDistributionPreviewLink} from '../../routes';
import DropdownButton from 'react-bootstrap/cjs/DropdownButton';
import Dropdown from 'react-bootstrap/cjs/Dropdown';
import {IGameVersion} from '../../../model/gameVersion/GameVersion';
import {IGameEnvironment} from '../../../model/gameEnvironment/GameEnvironment';
import {CompareDistributionsButton} from "./CompareDistributionsButton";
import {CopyDistributionButton} from "./CopyDistributionButton";


interface PropsType {
  hasDistributions: boolean,
  checkedDistributions: IDistribution[],
  handleDistribute: () => void,
  handleCopyDistribution: (toGameEnvironment: IGameEnvironment) => void,
  handleDownloadAsJson: () => void
}


export const DistributionsNav: FC<PropsType> = observer((props) => {

  const rootStore = useContext(RootStoreContext);
  const navigate = useNavigate();
  const publishNotification = usePublishNotification();
  const getDistributionDiffLink = useGetDistributionDiffLink()
  const getDistributionPreviewLink = useGetDistributionPreviewLink()

  const handleComparePublishedWithLastDistribution = (diffGameVersion: IGameVersion, diffGameEnvironment: IGameEnvironment, rightDiff: boolean) => {
    rootStore.distributionStore.loadDistributions({
                                                    gameVersionId:     diffGameVersion.id,
                                                    gameEnvironmentId: diffGameEnvironment.id,
                                                    pageSize:          1,
                                                    pageNumber:        1,
                                                    hideFailed:        true
                                                  }, true)
      .then((distributions) => {

        if (distributions == null || distributions.length === 0) {
          publishNotification({
                                notificationType: NotificationType.Info,
                                title:            "No Distribution Found",
                                message:          "There is no latest distribution to compare with for this game version and environment."
                              })
          return;
        }

        navigate(getDistributionDiffLink({
                                           gameVersionId:         rightDiff ? rootStore.gameVersionStore.getCurrentGameVersion().id : diffGameVersion.id,
                                           gameEnvironmentId:     rightDiff ? rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id : diffGameEnvironment.id,
                                           distributionId:        rightDiff ? 'published' : distributions[0].id,
                                           diffGameVersionId:     rightDiff ? diffGameVersion.id : rootStore.gameVersionStore.getCurrentGameVersion().id,
                                           diffGameEnvironmentId: rightDiff ? diffGameEnvironment.id : rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id,
                                           diffDistributionId:    rightDiff ? distributions[0].id : 'published'
                                         }))
      })
      .catch(e => publishNotification({
                                        notificationType: NotificationType.Error,
                                        title:            "Error loading distributions",
                                        message:          `${e.message}`,
                                        footer:           "Please try refreshing"
                                      }))
  }

  return (
    <Nav>
      <Nav.Item className="me-1 mb-2">
        <CompareDistributionsButton
          checkedDistributions={props.checkedDistributions}
          handleComparePublishedWithLastDistribution={handleComparePublishedWithLastDistribution}/>
      </Nav.Item>

      <Nav.Item className="me-1 mb-2">
        <DropdownButton variant="primary" title={<><i className="fas fa-eye"/> Preview </>}>
          <Dropdown.Item key="previewChanges"
                         onClick={() => handleComparePublishedWithLastDistribution(rootStore.gameVersionStore.getCurrentGameVersion(), rootStore.gameEnvironmentStore.getCurrentGameEnvironment(), true)}>Changes</Dropdown.Item>
          <Dropdown.Item key="previewAll" onClick={() => navigate(getDistributionPreviewLink())}>All
            Published</Dropdown.Item>
        </DropdownButton>
      </Nav.Item>
      {props.checkedDistributions.length < 2 && rootStore.gameEnvironmentStore.gameEnvironments
                                           .filter(ge => ge !== rootStore.gameEnvironmentStore.getCurrentGameEnvironment())
                                           .find(ge => rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.EditEntity, ge)) &&
       <Nav.Item className="me-1 mb-2">
         <CopyDistributionButton distribution={props.checkedDistributions.length === 1 ? props.checkedDistributions[0] : null} handleCopyDistribution={props.handleCopyDistribution}/>
       </Nav.Item>
      }
      {rootStore.authStore.currentAuthUser.hasGameEnvironmentClaim(ClaimType.DistributeEntities) &&
       <>
         <Nav.Item className="me-1 mb-2">
           <Button variant="danger" onClick={props.handleDistribute}>
             <i className="far fa-play-circle"/> Distribute</Button>
         </Nav.Item>
         <Nav.Item className="me-1 mb-2">
           <Button variant="info" onClick={props.handleDownloadAsJson}>
             <i className="fa fa-download"/> JSON</Button>
         </Nav.Item>
       </>
      }
    </Nav>
  )
});
