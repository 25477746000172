import React, {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Container                        from 'react-bootstrap/Container';
import {CustomSpinner} from '@yakoffice/custom-spinner';
import {useFindExperiments} from "../../../loaders/useFindExperiments";
import {IDistribution} from "../../../model/distributions/Distribution";
import {DistributionHighlights} from "./DistributionHighlights";
import {IExperimentSearchParams} from "../../../api/requests/ExperimentApiGateway";
import {DistributionExperimentsTable} from "./DistributionExperimentsTable";
import { DistributionOverlayHeader } from './DistributionOverlayHeader';

interface PropsType {
    distribution  : IDistribution;
}

export const DistributionExperiments : FC<PropsType> = observer(({distribution}) => {

  const [searchParams] = useState<IExperimentSearchParams>({ distributionId: distribution.id })

  const experimentsResult = useFindExperiments(searchParams);

  return <>
    <DistributionOverlayHeader title={`Distribution ${distribution.id} - Experiments`} highlights={distribution? <DistributionHighlights distribution={distribution} />: undefined} />
    <Container>
      {experimentsResult.isLoaded
        ? <DistributionExperimentsTable experiments={experimentsResult.experiments} />
        : <CustomSpinner spinnerText="Loading experiments..." />
      }
    </Container>
  </>
});
