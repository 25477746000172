import {getEnv, Instance, types} from "mobx-state-tree";
import Axios, {AxiosResponse} from "axios";
import type {IResponse} from "./IResponse";
import {flow} from "mobx";

export const ApiRequest = types.model(
    "ApiRequest",
    {})
    .actions(self => ({
        createHeaderOption : flow(  function* () {
          const token = yield getEnv(self).bearerTokenProvider.getToken();
          return {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token
                }
            };
        })
    }))
    .actions(self => ({
        get : flow(  function* <T>(url : string) {
            const res = yield Axios.get<IResponse<T>>(url, yield self.createHeaderOption());
            return (res as unknown as AxiosResponse<IResponse<T>>).data.data;
        }),
        post : flow( function* <T>(url : string, data : unknown) {
            const res = yield Axios.post(url, data, yield self.createHeaderOption());
            return (res as unknown as AxiosResponse<IResponse<T>>).data.data;
        }),
        put : flow( function* <T>(url : string, data : unknown) {
            const res = yield Axios.put(url, data, yield self.createHeaderOption());
            return (res as unknown as AxiosResponse<IResponse<T>>).data.data;
        }),
        patch : flow( function* <T>(url : string, data : unknown) {
            const res = yield Axios.patch(url, data, yield self.createHeaderOption());
            return (res as unknown as AxiosResponse<IResponse<T>>).data.data;
        }),
        delete : flow( function* (url : string) {
            yield Axios.delete(url, yield self.createHeaderOption());
        }),
    }))


export interface IHeaderProvider extends Instance<typeof ApiRequest> {}
