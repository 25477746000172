import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {CustomSpinner} from "@yakoffice/custom-spinner";
import { useDistribution } from './useDistribution';
import { Distribution } from './Distribution';

export const DistributionContainer : FC = observer(() => {

  const distributionResult = useDistribution()

  return distributionResult.isLoaded && distributionResult.distribution != null
    ? <Distribution title={`Distribution ${distributionResult.distribution.id}`} distribution={distributionResult.distribution} />
    : <CustomSpinner spinnerText={'Loading distribution...'} />

})
