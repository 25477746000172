import Form from "react-bootstrap/Form";
import React, {ChangeEvent, FC} from "react";
import {observer} from "mobx-react-lite";
import {IExperimentProperty} from "../../../model/experiment/ExperimentProperty";
import Alert from "react-bootstrap/Alert";
import {IEntityVersion} from "../../../model/entity/EntityVersion";
import {IKindVersion} from "../../../model/kind/KindVersion";
import {IEntityProperty} from "../../../model/entity/EntityProperty";
import {IKindProperty} from "../../../model/kind/KindProperty";

const kindPropertyMissingWarning = "This Kind property is no longer available for experiments.  Check Kind version or status.";
const entityPropertyMissingWarning = "This Entity property is no longer available for experiments. Check Entity version or status.";

interface PropsType {
    property: IExperimentProperty
    kind: IKindVersion | null
    entity: IEntityVersion | null
    specificationsForKinds: IKindVersion[]
    handlePropertyChange: (kindProperty: IKindProperty, entityProperty: IEntityProperty) => void
}

// This has to handle properties that are on the kind and kind specification properties that may have been added to the entity version
export const ExperimentPropertySelection : FC<PropsType> = observer(({property, kind, entity, specificationsForKinds, handlePropertyChange}) => {

  const findEntityProperty = (propertyId: number) => entity?.properties.find(ep => ep.id === propertyId)
  const findKindProperty = (propertyId: number ) => kind?.properties.find(kp => kp.id === propertyId)
  const findKindSpecificationProperty = (kindId: number, propertyId: number ) => specificationsForKinds.find(k => k.kind.id === kindId)?.getProperties().find(kp => kp.id === propertyId)

  const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      const entityProperty = findEntityProperty(parseInt(e.target.value));
      if (entityProperty) {
        const kindProperty = findKindProperty(entityProperty.kindPropertyId) ?? findKindSpecificationProperty(entityProperty.kindPropertyKindId, entityProperty.kindPropertyId);
        if (kindProperty)
          handlePropertyChange(kindProperty, entityProperty)
      }
    }
  }

  const entityPropertyDoesntExistInEntity = () => {
    const entityProperty = findEntityProperty(property.entityPropertyId);
    return !property.isNew && property.hasValidEntityPropertyId() && entityProperty == null;
  }

  const kindPropertyDoesntExistInKind = () => {
    const kindProperty = findKindProperty(property.kindPropertyId);
    return !property.isNew && property.hasValidKindPropertyId() && kindProperty == null;
  }

  const kindPropertyDoesntExistInSpecificationsForKind = () => {
    const kindProperty = findKindSpecificationProperty(property.kindPropertyKindId, property.kindPropertyId);
    return !property.isNew && property.hasValidKindPropertyId() && kindProperty == null;
  }

  const kindPropertyDoesntExist = () => kindPropertyDoesntExistInKind() && kindPropertyDoesntExistInSpecificationsForKind();

  function buildKindPropertyOptions() {
    return kind && kind.properties.map((kindProperty: IKindProperty) => {
      const entityProperty = entity?.properties.find(ep => ep.kindPropertyId === kindProperty.id);
      return <option key={kindProperty.id} value={entityProperty?.id}
                     className={entityProperty == null ? "text-danger" : ""} disabled={entityProperty == null}>
        {`${kindProperty.key}${entityProperty == null ? ` - ${entityPropertyMissingWarning}  ` : ""}`}
      </option>
    })
  }

  const getEntitySpecificationProperties = () => {
    const result = new Map<IEntityProperty, IKindProperty>();
    const entityPropertiesNotOnKind = entity?.getProperties().filter(ep => !kind?.properties.find(kp => kp.id === ep.kindPropertyId)) ?? [];
    for (const entityProperty of entityPropertiesNotOnKind) {
      const specificationKindProperty = findKindSpecificationProperty(entityProperty.kindPropertyKindId, entityProperty.kindPropertyId);
      if (specificationKindProperty != null)
        result.set(entityProperty, specificationKindProperty)
    }
    return result;
  }

  function buildKindSpecificationPropertyOptions() {
    return <>
      {
        [...getEntitySpecificationProperties()].map(([entityProperty, kindProperty]) => (
          <option key={kindProperty.id} value={entityProperty?.id} className={entityProperty == null ? "text-danger" : ""} disabled={entityProperty == null}>
            {`${kindProperty.key}${entityProperty == null ? ` - ${entityPropertyMissingWarning}  ` : ""}`}
          </option>
      ))
      }
    </>;
  }

  return (
    <>
      {kindPropertyDoesntExist() &&
        <Alert variant="warning"><strong>Warning:</strong> {kindPropertyMissingWarning}</Alert>}
      {entityPropertyDoesntExistInEntity() &&
        <Alert variant="warning"><strong>Warning:</strong> {entityPropertyMissingWarning}</Alert>}
      <Form.Select id="experimentPropertiesSelection"
                   value={property.entityPropertyId ? property.entityPropertyId : ""}
                   onChange={handleSelectionChange}
                   data-testid="ddlExperimentPropertySelect">
        <option value="" className={"text-danger"} disabled={true}>Select Property...</option>
        {(entityPropertyDoesntExistInEntity() || kindPropertyDoesntExist()) &&
          <option value={property.entityPropertyId} disabled={true}
                  className="text-danger">{property.kindPropertyKey}</option>
        }
        {buildKindPropertyOptions()}
        {buildKindSpecificationPropertyOptions()}
      </Form.Select>
      {
        <small id={`description`} className="form-text text-muted">
          {property.kindPropertyId && findKindProperty(property.kindPropertyId)?.description}
        </small>
      }
    </>
  )
})
