import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Route, Routes} from 'react-router';
import {PubRouteItems} from '../../routes';
import {ClaimType} from "@yakoffice/publisher-types";
import {GameEnvironmentContainer} from "../gameEnvs/GameEnvironmentContainer";
import {GameEnvironmentsContainer} from "../gameEnvs/GameEnvironmentsContainer";
import PublisherNav from '../../components/publisher-nav/publisherNav';
import {useRootStore} from '../../../loaders/useRootStore';
import {GameVersionsContainer} from '../gameVersions/GameVersionsContainer';
import {GameVersionIndex} from '../gameVersions/GameVersionIndex';
import {GameVersionContainer} from '../gameVersions/GameVersionContainer';
import styles from './project.module.sass';
import {joinRoute, matchDeeply, NoAccess} from "@yakoffice/shared-components";

export const Project : FC = observer(() => {

  const rootStore = useRootStore();
  const user = rootStore.authStore.currentAuthUser;

  return (
    <div className={styles.project}>
      <PublisherNav />
      <div className={`${styles.projectContent} mt-3`}>
        <Routes>

          <Route path={PubRouteItems.GameEnvironments} element={user.hasProjectClaim(ClaimType.EditGameEnvironment) ? <GameEnvironmentsContainer /> : <NoAccess/>} />

          <Route path={joinRoute(PubRouteItems.GameEnvironments, PubRouteItems.AddGameEnvironment)}
                 element={user.hasProjectClaim(ClaimType.EditGameEnvironment) ? <GameEnvironmentContainer /> : <NoAccess/>} />
          <Route path={joinRoute(PubRouteItems.GameEnvironments, PubRouteItems.GameEnvironmentIdParam)}
                 element={user.hasProjectClaim(ClaimType.EditGameEnvironment) ? <GameEnvironmentContainer/> : <NoAccess/>} />

          <Route path={PubRouteItems.GameVersions}
                 element={user.hasProjectClaim(ClaimType.EditGameVersion) ? <GameVersionsContainer /> : <NoAccess/>} />

          <Route path={joinRoute(PubRouteItems.GameVersions, PubRouteItems.AddGameVersion)}
                 element={user.hasProjectClaim(ClaimType.EditGameVersion) ? <GameVersionContainer /> : <NoAccess/>}/>
          <Route path={joinRoute(PubRouteItems.GameVersions, PubRouteItems.GameVersionIdParam)}
                 element={user.hasProjectClaim(ClaimType.EditGameVersion) ? <GameVersionContainer /> : <NoAccess/>} />

          <Route path={matchDeeply(PubRouteItems.GameVersions, PubRouteItems.GameVersionIdParam)} element={<GameVersionIndex />} />

        </Routes>
      </div>
    </div>
  )
});

export default Project;
