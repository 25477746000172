import {destroy, Instance, types} from "mobx-state-tree";
import {AuthUser} from "./AuthUser";
import {Claim} from "./Claim";
import { IClaimVm } from '@yakoffice/publisher-types';

export const AuthStore = types.model(
    "AuthStore",
    {
        currentAuthUser : AuthUser
    })
    // .views(self => ({}))
    // .views(self => ({}))//  Add dependent getters here
    .actions(self => ({
        setCurrentAuthUser(email: string, displayName: string | null, photoURL: string | null, claims: IClaimVm[]){
          const oldAuthUser = self.currentAuthUser;
            self.currentAuthUser = AuthUser.create({email: email, displayName: displayName, photoURL: photoURL, claims: claims.map(a => Claim.create(a))});
            if(oldAuthUser)
                destroy(oldAuthUser);
        },
    }));

export interface IAuthStore extends Instance<typeof AuthStore> {}
