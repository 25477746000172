import React, { FC, useContext } from 'react';
import {observer}    from 'mobx-react-lite';
import {isStateTreeNode, onPatch}   from "mobx-state-tree"
import Container                    from 'react-bootstrap/Container';
import Row                          from 'react-bootstrap/Row';
import Col                          from 'react-bootstrap/Col';
import Navbar                       from 'react-bootstrap/Navbar';
import type {IKindVersion}                      from "../../../model/kind/KindVersion";
import {KindButtons}                from "./KindButtons";
import {KindHighlights}             from "./KindHighlights";
import {KindDefaultProperties}      from "./KindDefaultProperties";
import {KindHeaderLinks}            from "./KindHeaderLinks";
import {KindCustomProperties}       from "./KindCustomProperties";
import {ViewHeader} from '@yakoffice/view-header';
import RootStoreContext from "../../../model/RootStoreContext";
import {Link} from 'react-router-dom';
import { useKindController } from '../../../controllers/useKindController';
import { useGetEntitiesLink } from '../../routes';
import { GameVersionSelector } from '../common/GameVersionSelector';
import { IGameVersion } from '../../../model/gameVersion/GameVersion';
import {
  ContentBox,
  ContentBoxBody,
  ContentBoxHeader,
  ContentBoxNavBar
} from '../../components/content-box/ContentBox';
import { RowDivider } from '../../components/dividers/RowDivider';
import {FeatureHeader, usePrompt} from '@yakoffice/shared-components';
import { Badge } from 'react-bootstrap';


interface PropsType {
    kindVersion : IKindVersion;
}


export const Kind : FC<PropsType> = observer((props) => {

  const rootStore = useContext(RootStoreContext);
  const controller = useKindController(props.kindVersion)
  const getEntitiesLink = useGetEntitiesLink()
  const [kindEdited, setKindEdited] = usePrompt("You have unsaved changes. Are you sure you want to leave?", false);

  if (isStateTreeNode(props.kindVersion))
    onPatch(props.kindVersion, (patch) => {
      if (props.kindVersion.isNewKind() || !["^/kindHexColour.*$", "^/description.*$", "^/properties/\\d*/description.*$",].find(regexp => patch.path.match(regexp)))
        setKindEdited(true)
    });

  const onGameVersionChanged = (targetGv: IGameVersion) => controller.handleGameVersionChange(rootStore.kindVersionStore.getCurrentKindVersion(), targetGv)

  return (
    <div id="kind">
      <Container>
        <ViewHeader title={props.kindVersion.name ? props.kindVersion.name : "(New Kind Name)"}
                    breadcrumbs={<span><KindHeaderLinks /></span>}
                    customAction={
                      <>
                        <GameVersionSelector handleGameVersionChange={onGameVersionChanged} />
                        {
                          !props.kindVersion.isNewKind() && !props.kindVersion.isSpecificationForKinds &&
                          <Link to={getEntitiesLink()} className="btn btn-primary">See all Entities <i className="fas fa-arrow-right" /></Link>
                        }
                      </>
                    }
        />
      </Container>

      <Container>
        <ContentBox>
          <ContentBoxHeader>
            <ContentBoxNavBar variant="dark"  expand="lg" className={props.kindVersion.kind.status}>
              <Container>
                <Navbar.Brand className="text-white">{props.kindVersion.isNewKind() ? "New Kind" : "Kind Version"}</Navbar.Brand>
                {!props.kindVersion.isNewKind() &&
                 <Badge bg="light" text="dark" className="ms-0 me-auto">{props.kindVersion.kind.versionCurrent}</Badge>
                }
                <Navbar.Toggle/>
                <Navbar.Collapse id="kindNav" className="justify-content-end">
                  <KindButtons
                    kindVersion={props.kindVersion}
                    kindVersionEdited={kindEdited}
                    currentCategory={rootStore.categoryStore.getCurrentCategory()}
                    categories={rootStore.categoryStore.categories}
                    handleMoveKind={(category) => controller.handleMoveKind(
                      category,
                      () => setKindEdited(false),
                      () => setKindEdited(false),
                    )}
                    handleArchiveKind={() => controller.handleArchiveKind(() => setKindEdited(false))}
                    handleUnarchiveKind={() => controller.handleUnarchiveKind(() => setKindEdited(false))}
                    handleSaveKindVersion={() => controller.handleSaveKind(() => setKindEdited(false))}
                    handleDeleteKind={() => controller.handleDeleteKind(() => setKindEdited(false))}
                    handleCopyKindVersion={() => controller.handleCopyKind(() => setKindEdited(false))}
                  />
                </Navbar.Collapse>
              </Container>
            </ContentBoxNavBar>
          </ContentBoxHeader>
          <ContentBoxBody>
            {!props.kindVersion.isNewKind() && <KindHighlights kindVersion={props.kindVersion} />}
            <KindDefaultProperties kindVersion={props.kindVersion} handleUpdateDescription={controller.handleUpdateDescription} handleUpdateHexColour={controller.handleUpdateHexColour} />
            <RowDivider />
            <Row>
              <Col>
                <FeatureHeader size="sm"><i className="fas fa-home" />Properties</FeatureHeader>
              </Col>
            </Row>
            <KindCustomProperties kindVersion={props.kindVersion} controller={controller} />
          </ContentBoxBody>
        </ContentBox>
      </Container>
    </div>
  );
});

