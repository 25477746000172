import React, {FC} from "react";
import {observer} from "mobx-react-lite";
import {IKindVersion} from "../../../model/kind/KindVersion";
import Button from 'react-bootstrap/Button';
import {equalsIgnoreCase} from "../../../utilities";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {ICategory} from "../../../model/category/Category";


interface PropsType {
    kindVersion : IKindVersion
    kindVersionEdited: boolean,
    currentCategory: ICategory,
    categories: ICategory[],
    handleMoveKind: (category: ICategory) => void;
    handleArchiveKind: () => void;
    handleUnarchiveKind: () => void;
    handleSaveKindVersion: () => void;
    handleDeleteKind: () => void;
    handleCopyKindVersion: () => void;
}

export const  KindButtons : FC<PropsType> = observer((props) => {

    return (
        <>
            {!props.kindVersion.isNewKind() &&
            props.categories.find(category => category.id !== props.currentCategory.id) &&
            <DropdownButton id="move" title={<span><i className="fas fa-file-import"/> Move</span>} variant="light">
                {
                    props.categories
                        .filter(category => category.id !== props.currentCategory.id)
                        .map((category) => {
                            return <Dropdown.Item key={category.id} onClick={() => props.handleMoveKind(category)}>{category.name}</Dropdown.Item>
                        })
                }
            </DropdownButton>
            }

            <Button variant="light" onClick={props.handleCopyKindVersion}><i className="fas fa-copy icon-with-text"/><span className="text">Copy Kind</span></Button>


            {!props.kindVersion.isNewKind() && !equalsIgnoreCase(props.kindVersion.kind.status || "", "archived") && !props.kindVersionEdited &&
             <Button variant="warning" onClick={props.handleArchiveKind}><i className="fas fa-archive icon-with-text"/><span className="text">Archive Kind</span></Button>
            }
            {!props.kindVersion.isNewKind() && equalsIgnoreCase(props.kindVersion.kind.status || "", "archived") && !props.kindVersionEdited &&
            <Button variant="info" onClick={props.handleUnarchiveKind}><i className="fas fa-box-open icon-with-text"/><span className="text">Unarchive
                Kind</span></Button>
            }
            {props.kindVersionEdited &&
            <Button variant="success" onClick={props.handleSaveKindVersion} data-value="updateKind" data-testid="btnSaveKind">
                <i className="fas fa-save icon-with-text"/>
                {
                    <span className="text">{props.kindVersion.isNewKind() ? "Save Kind" : "Update Kind"}</span>
                }
            </Button>
            }
            {!props.kindVersion.isNewKind() &&
             <Button variant="danger" onClick={props.handleDeleteKind}><i className="fas fa-trash-alt icon-with-text"/><span className="text">Delete Kind</span></Button>
            }
    </>);
});
