import {Instance, types} from "mobx-state-tree";
import {ValidationType} from "./ValidationType";
import {KindPropertyType as KindPropertyTypeVm } from "@yakoffice/publisher-types";

export const KindPropertyType = types.model(
    "KindPropertyType",
    {
        propertyType: types.optional(types.enumeration<KindPropertyTypeVm>("propertyType", Object.values(KindPropertyTypeVm)), KindPropertyTypeVm.Boolean),
        validationTypes: types.array(ValidationType)
    });

export interface IKindPropertyType extends Instance<typeof KindPropertyType> {}
