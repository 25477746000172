import { Instance, types } from 'mobx-state-tree';

export const Kind = types.model(
  "Kind",
  {
    id:  types.identifierNumber,
    categoryId: types.number,
    status: types.string,
    versionCurrent: 0,
    versionPrevious: types.maybeNull(types.number),
    lastActionId: 0,
    kindHexColor: types.maybeNull(types.string),
    googleSheetId: types.maybeNull(types.string),
    createdAt: "",
    createdBy: "",
    updatedAt: "",
    updatedBy: "",

  }
)

export interface IKind extends Instance<typeof Kind> {}
