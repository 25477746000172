import React, {FC} from 'react';
import { TableProps } from 'react-bootstrap';
import styles from './YoPublisherTable.module.sass'
import { YoTable } from '@yakoffice/shared-components';

export const YoTableClassNames = {
  MinWidthContainer: styles.minWidthContainer,
  MinWidth100: styles.mnw100,
  AlignTop: styles.alignTop,
  CustomTdDropdown: styles.customTdDropdown
}

export const YoPublisherTable: FC<TableProps> = ({ className, children, ...props }) => {
  return <YoTable {...props} className={`${styles.table} ${className ?? ""}`}>{children}</YoTable>
}

  // NOTE:  this is a stylised version of the table npm install stylised-comp
// Requires: npm install styled-components @types/styled-components polish
// Pros:
// * Dynamic themeing (i.e. would reduce duplication of styles for game environment colors)
// * Potentially better dev experience with everything being in code
// * Remove css issues of namespaces/specificity etc (i.e. it uses the styles property which overrides any other css selectors)
// Cons:
// * If using with bootstrap at same time then need to duplicate bootstrap variables (i.e. and custom overrides) into code for use by stylised components
// const DefaultStyles = {
//   Colors : {
//     Green : "#7cdf91",
//     Yellow : "#ffe066",
//     Red : "#e3513e",
//     Teal : "#20c997",
//     White : "#fff",
//     Black : "#000",
//     Gray200 : "#e9ecef",
//     Gray800 : "#343a40",
//   },
//   Font : {
//     Weight : {
//       Bold: "700"
//     }
//   }
// }
//
// const Themes = {
//   "DEV" : {
//     thBgColor: DefaultStyles.Colors.Green,
//     thColor: DefaultStyles.Colors.Gray800
//   },
//   "STG" : {
//     thBgColor: DefaultStyles.Colors.Yellow,
//     thColor: DefaultStyles.Colors.Gray800
//   },
//   "PRD" : {
//     thBgColor: DefaultStyles.Colors.Red,
//     thColor: DefaultStyles.Colors.White
//   },
//   "QAS" : {
//     thBgColor: DefaultStyles.Colors.Teal,
//     thColor: DefaultStyles.Colors.White
//   }
// }
//
// const YoStyledTable = styled.div`
//   width: auto !important;
//   tr {
//     background-color: ${DefaultStyles.Colors.White};
//     &:hover {
//       background-color: ${DefaultStyles.Colors.Gray200};
//     }
//   }
//   td {
//     background-color: transparent;
//     &:hover {
//       background-color: transparent;
//     }
//   }
//   .table-dark {
//     th {
//       background-color: ${props => props.theme.thBgColor};
//       color: ${props => props.theme.thColor};
//       border-color: ${props => shade-color(10, props.theme.thBgColor)};
//     }
//     th {
//       button {
//         padding: 0;
//         border: 0;
//         font-weight: ${DefaultStyles.Font.Weight.Bold};
//         background-color: ${props => props.theme.thBgColor};
//         color: ${props => props.theme.thColor};
//
//         &:hover, &:focus, &:active {
//           background-color: initial;
//           border: 0;
//           box-shadow: none;
//         }
//       }
//     }
//   }
// `
//
// YoStyledTable.defaultProps = {
//   theme : {
//     thBgColor: DefaultStyles.Colors.Black,
//     thColor: DefaultStyles.Colors.White
//   }
// }
//
//
//
// export const YoTable : FC<TableProps> = ({className, children, ...props}) => {
//
//   const theme = props.variant === "success" ?  Themes.DEV : Themes.PRD;
//
//   return  <ThemeProvider theme={theme}>
//             <YoStyledTable {...props}>
//               <Table {...props} className={`${className ?? ""}`} >{children}</Table>
//             </YoStyledTable>
//           </ThemeProvider>
// }
