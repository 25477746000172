import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import {EntityStatus} from "@yakoffice/publisher-types";
import type {IEntityVersion} from "../model/entity/EntityVersion";
import type {IEntityVersionSearchParams} from "../api/requests/entities/entityVersionApiGateway";
import type {IExperimentProperty} from "../model/experiment/ExperimentProperty";

interface useExperimentEntitiesResult{
    isLoaded : boolean
    entityVersions : IEntityVersion[]
}

export const  useExperimentEntities = (experimentProperty: IExperimentProperty) => {

  const rootStore = useContext(RootStoreContext);
  const publishNotification = usePublishNotification();

  const [result, setResult] = useState<useExperimentEntitiesResult>({ isLoaded: false, entityVersions: [] })

  useEffect(() => {
    setResult({ isLoaded: false, entityVersions: [] })

    if (experimentProperty.hasValidKindId()) {
      const searchParams: IEntityVersionSearchParams = {
        kindId: experimentProperty.kindId,
        gameEnvironmentId: rootStore.gameEnvironmentStore.getCurrentGameEnvironment().id,
        status: EntityStatus.Published
      }
      rootStore.entityVersionStore.findAllCurrentEntityVersions(searchParams)
        .then((entities) => {
          setResult({ isLoaded: true, entityVersions: entities })
        })
        .catch(e => publishNotification({
          notificationType: NotificationType.Error,
          title: "Error loading experiment entities",
          message: `${e.message}`,
          footer: "Please try refreshing"
        }))
    }
  }, [rootStore.entityVersionStore, rootStore.gameEnvironmentStore, publishNotification, experimentProperty, experimentProperty.kindId]);

  return result;
};
