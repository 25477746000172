import { useContext, useEffect, useState } from 'react';
import RootStoreContext                         from "../model/RootStoreContext";
import {usePublishNotification, NotificationType} from "@yakoffice/notification-handler";
import type {IExperiment} from "../model/experiment/Experiment";
import { useParams } from "react-router-dom";

interface useExperimentResult{
    isLoaded    : boolean;
    experiment        : IExperiment | null
}

export const useExperiment = (refreshToken: boolean) => {

    const rootStore = useContext(RootStoreContext);
    const params = useParams<{experimentId: string}>();
    const publishNotification = usePublishNotification();

    const [result, setResult] = useState<useExperimentResult>({
      isLoaded: false,
      experiment: null
    })

    useEffect(() => {
            setResult({isLoaded: false, experiment: null})

            if(params.experimentId) {
                rootStore.experimentStore.getExperiment(parseInt(params.experimentId))
                    .then((experiment) => {
                        if(!experiment.inAnyDistribution)
                            experiment.updateSpecificationsToToLatestVersion();
                      setResult({isLoaded: true, experiment: experiment})
                    })
                    .catch(e => publishNotification({
                        notificationType: NotificationType.Error,
                        title:            "Error loading experiment",
                        message:          `${e.message}`,
                        footer:           "Please try refreshing"
                    }))
            } else {
              setResult({isLoaded: true, experiment: rootStore.experimentStore.addExperiment()})
            }
    }, [rootStore.experimentStore, publishNotification, params.experimentId, refreshToken]);

    return result;
};
