import {flow, Instance, types} from "mobx-state-tree";
import {KindPropertyType} from "./KindPropertyType";
import {
    IKindPropertyTypeVm,
    KindPropertyType as KindPropertyTypeVm,
    ValidationType
} from "@yakoffice/publisher-types";
import type {IValidationType} from "./ValidationType";
import {KindPropertyTypeApiGateway} from "../../api/requests/kinds/kindPropertyTypeApiGateway";
import { ValidationReferenceType } from '@yakoffice/publisher-types';
import { ValidationValueType } from '@yakoffice/publisher-types';

export const KindPropertyTypeStore = types.model(
    "KindPropertyTypeStore",
    {
        apiGateway: KindPropertyTypeApiGateway,
        kindPropertyTypes: types.array(KindPropertyType),
        isLoading: false,
    })
    .actions(self => ({
        loadKindPropertyTypes : flow(function*() {

            self.isLoading = true;

            try {
              const vms = (yield self.apiGateway.getKindPropertyTypes()) as IKindPropertyTypeVm[];

              const propertyTypes = vms.map(vm => KindPropertyType.create(MapToKindPropertyTypeModel(vm)))
              self.kindPropertyTypes.replace(propertyTypes);

              self.isLoading = false;
            } catch (e:any) {
                throw new Error(`Failed to load Kind Property Types.  Error message: ${e.message}`);
            }
        })
    }));

const MapToKindPropertyTypeModel = (vm: IKindPropertyTypeVm) => {
    return {
        propertyType: (KindPropertyTypeVm as any)[vm.propertyType],
        validationTypes : vm.validationTypes.map((vt) : IValidationType => {
            return {
                name: (ValidationType as any)[vt.name],
                requirementType: {
                  referenceType: (ValidationReferenceType as any)[vt.requirementType.referenceType],
                  validationValueType: (ValidationValueType as any)[vt.requirementType.validationValueType]
                }
            }
        })
    }
}

export interface IKindPropertyTypeStore extends Instance<typeof KindPropertyTypeStore> {}
