import React, {FC, useContext}  from 'react';
import {CustomSpinner}          from '@yakoffice/custom-spinner';
import {ClaimType}              from "@yakoffice/publisher-types";
import {useCategory}            from "../../../loaders/useCategory";
import RootStoreContext         from "../../../model/RootStoreContext";
import {Category}               from "./Category";
import {NoAccess} from "@yakoffice/shared-components";

export const CategoryContainer : FC = () => {

  const rootStore = useContext(RootStoreContext);
  const categoryResult = useCategory();

  return !categoryResult.isLoaded || categoryResult.category == null
    ? <CustomSpinner spinnerText={'Loading category...'} />
    : rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditCategory)
      ? <Category category={categoryResult.category} />
      : <NoAccess/>
}
