import {Instance, types} from "mobx-state-tree";
import {GenerateId} from "../Utils";

export const Project = types.model(
    "Project",
    {
            id: types.optional(types.identifierNumber, GenerateId),
            name: "",
            imageUrl: types.maybeNull(types.string),
            createdAt: "",
            originalAuthor: "",
            updatedAt: "",
            lastAuthor: ""
    });

export interface IProject extends Instance<typeof Project> {}
