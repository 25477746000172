import React, { FC, useState } from 'react';
import {observer}                from 'mobx-react-lite';
import Container                                from 'react-bootstrap/Container';
import {useGameEnvironmentController}           from '../../../controllers/useGameEnvironmentController';
import {Link}                                   from 'react-router-dom';
import {GameEnvironmentHighlights}              from './GameEnvironmentHighlights';
import {GameEnvironmentMainInfo} from './GameEnvironmentMainInfo';
import {isStateTreeNode, onSnapshot} from "mobx-state-tree";
import {GameEnvironmentNav} from "./GameEnvironmentNav";
import {IGameEnvironment} from "../../../model/gameEnvironment/GameEnvironment";
import {ViewHeader} from '@yakoffice/view-header';
import { useGetGameEnvironmentsLink } from '../../routes';
import {ContentBox, ContentBoxBody, ContentBoxHeader} from '../../components/content-box/ContentBox';


interface PropsType {
    gameEnvironment     : IGameEnvironment
}


export const GameEnvironment : FC<PropsType> = observer(({gameEnvironment}) => {

    // Context
    const controller = useGameEnvironmentController(gameEnvironment);
    const getGameEnvironmentsLink = useGetGameEnvironmentsLink();

    // Local Observables
    const [edited, setEdited] = useState(false);

    if (isStateTreeNode(gameEnvironment))
        onSnapshot(gameEnvironment, () => {
            setEdited(true);
        });

    return <div id="gameEnvironments">
        <Container>
            <ViewHeader title={`${gameEnvironment.name != null && gameEnvironment.name.length > 0
                                  ? gameEnvironment.name
                                  : "(New Game Environment Name)"}`}
                        breadcrumbs={<span><Link to={getGameEnvironmentsLink()}>Game Environments</Link></span>}
            />
        </Container>
        <Container>
            <ContentBox>
                    <ContentBoxHeader>
                        <GameEnvironmentNav
                            gameEnvironment={gameEnvironment}
                            geEdited={edited}
                            handleSaveGameEnvironment={controller.handleSaveGameEnvironment}
                            handleDeleteGameEnvironment={controller.handleDeleteGameEnvironment}
                        />
                    </ContentBoxHeader>
                    <ContentBoxBody>
                      {!gameEnvironment.isNewGameEnvironment() && <GameEnvironmentHighlights gameEnvironment={gameEnvironment}/> }

                       <GameEnvironmentMainInfo gameEnvironment={gameEnvironment}/>
                    </ContentBoxBody>
                </ContentBox>
        </Container>
    </div>
});
