import React, {FC, useContext}  from 'react';
import {Route, Routes}          from 'react-router';
import {CustomSpinner}          from '@yakoffice/custom-spinner';
import {ClaimType}              from "@yakoffice/publisher-types";
import RootStoreContext         from "../../../model/RootStoreContext";
import {KindsContainer}         from "../kinds/KindsContainer";
import { KindIndex } from '../kinds/KindIndex';
import { useCategoryFromStore } from '../../../loaders/useCategoryFromStore';
import {PubRouteItems} from "../../routes";
import {joinRoute, matchDeeply, NoAccess} from "@yakoffice/shared-components";
import {KindContainer} from "../kinds/KindContainer";

export const CategoryIndex : FC = () => {

  const rootStore = useContext(RootStoreContext);
  const categoryResult = useCategoryFromStore();

  return !categoryResult.isLoaded || categoryResult.category == null
    ? <CustomSpinner spinnerText={'Loading category...'}/>
    : <Routes>
        <Route path={PubRouteItems.Kinds} element={<KindsContainer/>}/>
        <Route path={joinRoute(PubRouteItems.Kinds, PubRouteItems.AddKind)}
               element={rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind) ? <KindContainer/> : <NoAccess/>}/>
        <Route path={joinRoute(PubRouteItems.Kinds, PubRouteItems.KindIdParam)}
               element={rootStore.authStore.currentAuthUser.hasProjectClaim(ClaimType.EditKind) ? <KindContainer/> : <NoAccess/>}/>
        <Route path={matchDeeply(PubRouteItems.Kinds, PubRouteItems.KindIdParam)} element={<KindIndex/>}/>
      </Routes>
}
